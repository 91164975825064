import { Controller, useForm } from 'react-hook-form';

import DateFnsUtils from '@date-io/date-fns';

import {
  Box,
  Grid,
  Select,
  MenuItem,
  TextField,
  FormControl,
  InputLabel,
  ThemeProvider,
  Typography,
} from '@material-ui/core';

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import { useUpdateProfile } from 'hooks/user.hook';

import { Button } from 'components/atoms';
import { generateTheme } from './utils';
import {
  WellnubCountryCodeInput,
  WellnubNumberInput,
} from '@wellnub/web-common';

const UserProfileForm = ({ profile, variant = 'dark' }) => {
  const { control, handleSubmit } = useForm({
    defaultValues: {
      name: profile?.name ?? '',
      lastName: profile?.lastName ?? '',
      email: profile?.email ?? '',
      countryCode: profile?.countryCode ?? '52',
      phoneNumber: profile.phoneNumber || '',
      sex: profile?.sex ?? '',
      birthdate: profile?.birthdate ? new Date(profile.birthdate) : null,
    },
  });

  const { mutate, isLoading } = useUpdateProfile();

  const onSubmitHandler = data => {
    const { email, ...rest } = data;
    const payload = {
      ...rest,
    };
    mutate(payload);
  };

  return (
    <ThemeProvider theme={theme => generateTheme(theme, variant)}>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <Grid container spacing={4}>
          {/* --- FIRST NAME --- */}
          <Grid item xs={12} md={6}>
            <Controller
              name="name"
              control={control}
              defaultValue={profile.name}
              rules={{
                required: 'Este campo es requerido',
                pattern: {
                  value: /^([^0-9]*)$/,
                  message: 'El nombre no debe de contener números',
                },
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  fullWidth
                  label="Nombre *"
                  type="text"
                  color="secondary"
                  value={value}
                  error={!!error}
                  onChange={onChange}
                  helperText={error ? error.message : 'Escribe tu(s) nombre(s)'}
                />
              )}
            />
          </Grid>
          {/* --- LAST NAME --- */}
          <Grid item xs={12} md={6}>
            <Controller
              name="lastName"
              control={control}
              defaultValue={profile.lastName}
              rules={{
                required: 'Este campo es requerido',
                pattern: {
                  value: /^([^0-9]*)$/,
                  message: 'Los apellidos no deben de contener números',
                },
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  fullWidth
                  type="text"
                  label="Apellidos *"
                  color="secondary"
                  value={value}
                  error={!!error}
                  onChange={onChange}
                  helperText={error ? error.message : 'Escribe tus apellidos'}
                />
              )}
            />
          </Grid>
          {/* --- E-MAIL --- */}
          <Grid item xs={12} md={6}>
            <Controller
              name="email"
              control={control}
              defaultValue={profile.email}
              rules={{
                required: 'Este campo es requerido',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Escribe un correo válido',
                },
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  fullWidth
                  disabled
                  type="email"
                  label="E-mail *"
                  color="secondary"
                  value={value}
                  error={!!error}
                  onChange={onChange}
                  helperText={error ? error.message : 'Escribe tu e-mail'}
                />
              )}
            />
          </Grid>
          {/* --- PHONE NUMBER --- */}
          <Grid item xs={12} md={6}>
            <Grid container spacing={1}>
              <Grid item xs={6} sm={6}>
                <WellnubCountryCodeInput
                  label="Región"
                  variant="standard"
                  control={control}
                  name="countryCode"
                  defaultValue={profile.countryCode}
                  rules={{ required: 'Este campo es requerido' }}
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <WellnubNumberInput
                  fullWidth
                  variant="standard"
                  label="Celular *"
                  name="phoneNumber"
                  control={control}
                  defaultValue={profile.phoneNumber || ''}
                  rules={{
                    pattern: {
                      value: /[0-9]{10}/,
                      message: 'Escribe un número válido',
                    },
                  }}
                  format="## #### ####"
                  helperText="Celular de contacto a 10 dígitos"
                />
              </Grid>
            </Grid>
            {/*<Controller*/}
            {/*  control={control}*/}
            {/*  defaultValue={profile.phoneNumber}*/}
            {/*  name="phoneNumber"*/}
            {/*  rules={{*/}
            {/*    validate: value => validatePhoneNumber(value),*/}
            {/*  }}*/}
            {/*  render={({*/}
            {/*    field: { value, onChange },*/}
            {/*    fieldState: { error },*/}
            {/*  }) => (*/}
            {/*    <MUIPhoneInput*/}
            {/*      value={value}*/}
            {/*      fullWidth*/}
            {/*      error={!!error}*/}
            {/*      label="Celular *"*/}
            {/*      defaultCountry="mx"*/}
            {/*      onChange={onChange}*/}
            {/*      helperText={error?.message ?? 'Escribe el número de teléfono'}*/}
            {/*      onlyCountries={['mx', 'us']}*/}
            {/*    />*/}
            {/*  )}*/}
            {/*/>*/}
          </Grid>
          {/* --- GENDER --- */}
          <Grid item xs={12} md={6}>
            <Controller
              name="sex"
              control={control}
              // defaultValue={profile.sex}
              render={({ field: { onChange, value } }) => (
                <FormControl style={{ width: '100%' }}>
                  <InputLabel id="label-gender">Sexo</InputLabel>
                  <Select
                    fullWidth
                    labelId="label-gender"
                    color="secondary"
                    value={value}
                    onChange={onChange}
                  >
                    <MenuItem value="male">Masculino</MenuItem>
                    <MenuItem value="female">Femenino</MenuItem>
                    <MenuItem value="other">Otro</MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          {/* --- BIRTH DAY --- */}
          <Grid item xs={12} md={6}>
            <Controller
              name="birthdate"
              control={control}
              // defaultValue={new Date(profile.birthdate)}
              render={({ field: { onChange, value } }) => (
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    fullWidth
                    color="secondary"
                    variant="inline"
                    format="dd/MM/yyyy"
                    label="Fecha de nacimiento"
                    value={value}
                    onChange={onChange}
                  />
                </MuiPickersUtilsProvider>
              )}
            />
          </Grid>
        </Grid>
        <Box pt={4} textAlign="right">
          <Button type="submit" variant="contained" isLoading={isLoading}>
            Actualizar mi perfil
          </Button>
          <Typography variant="h6" style={{ paddingTop: 16 }}>
            * Datos obligatorios
          </Typography>
        </Box>
      </form>
    </ThemeProvider>
  );
};

export default UserProfileForm;
