import axios from './auth.axios';
import { BASE_URL } from '../constants';

class ProgramService {
  create = payload => axios.post(`${BASE_URL}/v1/program`, payload);

  get = id => axios.get(`${BASE_URL}/v1/program/${id}`);

  list = () => axios.get(`${BASE_URL}/v1/program`);

  update = (id, payload) => axios.patch(`${BASE_URL}/v1/program${id}`, payload);

  delete = id => axios.post(`${BASE_URL}/v1/program/${id}`);

  getCustomer = id => axios.get(`${BASE_URL}/v1/costumer/class/${id}`);

  listCostumer = () => axios.get(`${BASE_URL}/v1/costumer/topClasses`);

  addCard = payload => axios.post(`${BASE_URL}/v1/costumer/card`, payload);

  getCards = () => axios.get(`${BASE_URL}/v1/costumer/card`);
}

export default new ProgramService();
