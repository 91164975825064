import { Tooltip, Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { useStyles } from './ServicesList.styles';

const ServicesList = ({ services }) => {
  const classes = useStyles();

  return (
    <ul className={classes.list}>
      {services.map(({ service, _id, description }) => (
        <li className={classes.item} key={_id}>
          <Typography variant="body2" className={classes.mr}>
            {service.name}
          </Typography>

          <Tooltip title={description}>
            <InfoIcon classes={{ root: classes.tooltip }} />
          </Tooltip>
        </li>
      ))}
    </ul>
  );
};

export default ServicesList;
