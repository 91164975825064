import React, { Fragment } from 'react';

import { Box, Button, CircularProgress, Typography } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { UserProfileForm, UserPasswordForm } from 'components/organisms';
import { useDispatch } from 'react-redux';
import { setModalStatus } from 'redux/slices/modal.slice';
import { useGetNutritionist } from 'hooks/nutritionists.hook';

const Main = ({ profile }) => {
  const nutritionistId = profile?.patientProfile?.nutritionist;

  const { data, isLoading, isError } = useGetNutritionist(nutritionistId);

  const dispatch = useDispatch();

  const onClickHandler = () => {
    dispatch(
      setModalStatus({
        name: 'UNLINK_NUTRITIONIST_WARNING',
        isActive: true,
      }),
    );
  };

  if (isLoading) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Fragment>
      <Typography
        variant="h4"
        color="primary"
        style={{
          textTransform: 'uppercase',
          textAlign: 'center',
          paddingBottom: 16,
        }}
      >
        Mi perfil
      </Typography>
      <Typography>Manten tu información en Wellnub actualizada</Typography>
      <Box pt={4} pb={4}>
        <Typography
          variant="h6"
          style={{
            paddingBottom: 8,
            color: '#1A7992',
            textTransform: 'uppercase',
          }}
        >
          Información personal
        </Typography>
        <UserProfileForm profile={profile} variant="light" />
      </Box>
      {!isEmpty(data) && (
        <Box pt={4} pb={4}>
          <Typography
            variant="h5"
            style={{
              paddingBottom: 8,
              color: '#1A7992',
              textTransform: 'uppercase',
            }}
          >
            Mi nutriólogo: {`${data?.name ?? ''} ${data?.lastName ?? ''}`}
          </Typography>
          <Button variant="outlined" color="primary" onClick={onClickHandler}>
            Deseo desvincularme
          </Button>
        </Box>
      )}
      <Typography variant="h3" color="secondary">
        Contraseña
      </Typography>
      <Box pt={4} pb={4}>
        <Typography
          variant="h6"
          style={{
            paddingBottom: 8,
            color: '#1A7992',
            textTransform: 'uppercase',
          }}
        >
          Cambio de contraseña
        </Typography>
        <Typography
          style={{
            paddingBottom: 8,
          }}
        >
          Utiliza esta sección si quieres cambiar de contraseña
        </Typography>
        <UserPasswordForm variant="light" />
      </Box>
    </Fragment>
  );
};

export default Main;
