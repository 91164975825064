import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useSnackbar } from 'notistack';

import {
  requestGetSuscriptions,
  requestGetUser,
  getPasses,
  requestAppointment,
  requestGetAppointments,
  requestGetMedicalProfile,
  requestGetProfile,
  requestUpdateProfile,
  requestUpdatePassword,
  requestGetNutritionalPlans,
  requestGetBiochemicalPlans,
  requestGetAvailablePasses,
  requestGetLastNutritionalPlan,
  requestGetLastNutritionalPlanDetail,
  getLinkRequests,
  acceptLinkRequest,
  cancelLinkRequest,
  postLinkRequest,
  getPaymentsHistory,
  getNutritionalHistory,
  rejectLinkRequest,
  getMissingRatings,
  postMissingRating,
  getAppointmentTicket,
  requestReScheduleAppointment,
  requestGetVirtualAgendaAppointments,
  createAppointmentWithNutritionist,
  getAppointmentsWithNutritionist,
  acceptAppointmentSendedByNutritionist,
  rejectAppointmentSendedByNutritionist,
  cancelAppointmentSendedByNutritionist,
  rescheduleAppointmentWithNutritionist,
  unlinkNutritionist,
  deleteBiochemicalPlan,
  uploadBiochemicalPlan,
  addBiochemicalPlan,
} from 'network/services/user.service';

import { useDrawer } from './drawer.hook';

import {
  setBiochemicalResults,
  setInbodyResults,
  setPasses,
  setRatingLocation,
} from 'redux/slices/user.slice';
import {
  setScheduleVisibility,
  setSpotAppointment,
} from 'redux/slices/spot.slice';
import { setModalStatus } from 'redux/slices/modal.slice';

import { DRAWERS } from 'utils/constants';
import { setBackdropVisibility } from '../redux/slices/backdrop.slice';
import { setVirtualAgendaAppointments } from '../redux/slices/virtualAgenda.slice';
import { setLinkRequests } from '../redux/slices/linkRequests.slice';
import { setMyCurrentNutritionist } from '../redux/slices/myCurrentNutritionist.slice';
import { setPendingAppointments } from '../redux/pendingAppointmentsWithNutritionist/pendingAppointmentsWithNutritionist.slice';
import { setWnUserData } from '../redux/slices/wnUser/wnUser.slice';

// ---- TRAE LA INFORMACIÓN DEL USUARIO ----
export const useUser = id => useQuery('GET_USER', () => requestGetUser(id));

// ---- TRAE LAS SUSCRIPCIONES ACTIVAS DEL USUARIO ----
export const useSuscriptions = () =>
  useQuery('GET_SUSCRIPTIONS', requestGetSuscriptions);

// ---- TRAE EL LISTADO DE PRÓXIMOS EVENTOS DISPONIBLES ----
export const useGetAppointments = () =>
  useQuery('GET_APPOINTMENTS', requestGetAppointments);

// ---- TRAE LOS PASES DISPONIBLES DEL USUARIO ----
export const usePasses = () => {
  const dispatch = useDispatch();

  return useQuery('GET_PASSES', getPasses, {
    onSuccess: ({ data }) => {
      dispatch(setPasses(data.total));
    },
  });
};

// ---- HACE UNA RESERVACIÓN PARA EL ESCANEO CORPORAL ----
export const useAppointment = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { push } = useHistory();
  const { handleClose } = useDrawer(DRAWERS.appoinment);
  const dispatch = useDispatch();

  return useMutation(requestAppointment, {
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries('GET_AVAILABLE_PASSES');
      queryClient.invalidateQueries('GET_APPOINTMENTS');
      queryClient.invalidateQueries('GET_PASSES');
      enqueueSnackbar(
        'Se realizó la reservación con éxito, tu ticket de reservación se descargará en unos segundos',
        {
          variant: 'success',
        },
      );
      dispatch(setSpotAppointment(data));
      push('/dashboard/main');
    },
    onError: () => {
      enqueueSnackbar('No se pudo realizar la reservación. Intenta más tarde', {
        variant: 'error',
      });
    },
    onSettled: () => {
      dispatch(setScheduleVisibility(false));
      handleClose();
    },
  });
};

// ---- TRAE EL PERFIL MÉDICO ----
export const useMedicalProfile = () => {
  const dispatch = useDispatch();

  return useQuery('GET_MEDICAL_PROFILE', requestGetMedicalProfile, {
    onSuccess: ({ data }) => {
      const { inbodyResults } = data;
      dispatch(setInbodyResults(inbodyResults));
    },
  });
};

// ---- TRAE EL PERFIL DEL USUARIO ----
export const useGetProfile = isAuthenticated => {
  const dispatch = useDispatch();

  return useQuery('GET_PROFILE', requestGetProfile, {
    enabled: isAuthenticated,
    onSuccess: ({ data }) => {
      dispatch(setWnUserData(data));
    },
  });
};

// ---- ACTUALIZA EL PERFIL DE USUARIO ----
export const useUpdateProfile = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(requestUpdateProfile, {
    onSuccess: () => {
      queryClient.invalidateQueries('GET_PROFILE');
      enqueueSnackbar('Se actualizó la información con éxito', {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar(
        'No se pudo actualizar la información. Intenta más tarde',
        {
          variant: 'error',
        },
      );
    },
  });
};

// ---- ACTUALIZA LA CONTRASEÑA DEL USUARIO ----
export const useUpdatePassword = () => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(requestUpdatePassword, {
    onSuccess: () => {
      enqueueSnackbar('Contraseña actualizada con éxito', {
        variant: 'success',
      });
    },
    onError: ({ response }) => {
      if (response.data.isPublic) {
        enqueueSnackbar(response.data.message, {
          variant: 'error',
        });
      } else {
        enqueueSnackbar(
          'No se pudo actualizar la contraseña. Intenta más tarde',
          {
            variant: 'error',
          },
        );
      }
    },
  });
};

// ---- TRAE EL LISTADO DE PLANES NUTRICIONALES ----
export const useGetNutritionalPlans = () => {
  return useQuery('GET_NUTRITIONAL_PLANS', requestGetNutritionalPlans);
};

// ---- TRAE EL LISTADO DE PLANES BIOQUÍMICOS ----
export const useGetBiochemicalPlans = () => {
  const dispatch = useDispatch();

  return useQuery('GET_BIOCHEMICAL_PLANS', requestGetBiochemicalPlans, {
    onSuccess: data => {
      dispatch(setBiochemicalResults(data));
    },
  });
};

// ---- TRAE LOS PASES ENVIADOS POR EL NUTRIÓLOGO ----
export const useGetAvailablePasses = () => {
  return useQuery('GET_AVAILABLE_PASSES', requestGetAvailablePasses);
};

// ---- TRAE EL ÚLTIMO PLAN NUTRICIONAL ----
export const useGetLastNutritionalPlan = () => {
  const dispatch = useDispatch();

  return useQuery('GET_LAST_NUTRITIONAL_PLAN', requestGetLastNutritionalPlan, {
    onSuccess: data => {
      dispatch(setMyCurrentNutritionist(data.nutritionist));
    },
  });
};

export const useGetLastNutritionalPlanDetail = id => {
  return useQuery(['GET_LAST_NUTRITIONAL_PLAN_DETAIL', id], () =>
    requestGetLastNutritionalPlanDetail(id),
  );
};

// ---- TRAE LA LISTA DE SOLICITUDES DE VINCULACIÓN ----
export const useGetLinkRequests = isAuthenticated => {
  const dispatch = useDispatch();

  return useQuery('GET_LINK_REQUESTS', getLinkRequests, {
    enabled: isAuthenticated,
    onSuccess: data => {
      dispatch(setLinkRequests(data));
    },
  });
};

// ---- CREA UNA NUEVA SOLICITUD DE VINCULACIÓN ----
export const usePostLinkRequest = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  return useMutation(postLinkRequest, {
    onSuccess: () => {
      queryClient.invalidateQueries('GET_LINK_REQUESTS');
      enqueueSnackbar('Se realizó la solicitud de vinculación con éxito', {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar('No se pudo realizar la operación, intente más tarde', {
        variant: 'error',
      });
    },
    onSettled: () => {
      dispatch(
        setModalStatus({
          name: 'GET_IN_TOUCH_NUTRITION_STEP_2',
          isActive: false,
        }),
      );
    },
  });
};

// ---- ACEPTA LA SOLICITUD DE VINCULACIÓN ----
export const useAcceptLinkRequest = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useMutation(acceptLinkRequest, {
    onSuccess: () => {
      queryClient.invalidateQueries('GET_LINK_REQUESTS');
      queryClient.invalidateQueries('GET_PASSES');
      queryClient.invalidateQueries('GET_AVAILABLE_PASSES');
      queryClient.invalidateQueries('GET_LAST_NUTRITIONAL_PLAN');
      enqueueSnackbar('Se vinculó correctamente a tu nutriólogo', {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar('No se pudo realizar la operación, intente más tarde', {
        variant: 'error',
      });
    },
    onSettled: () => {
      dispatch(
        setModalStatus({
          name: 'NUTRITIONIST_INVITATION_ALERT',
          isActive: false,
        }),
      );
    },
  });
};

// ---- CANCELA LA SOLICITUD DE VINCULACIÓN ----
export const useCancelLinkRequest = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useMutation(cancelLinkRequest, {
    onSuccess: () => {
      queryClient.invalidateQueries('GET_LINK_REQUESTS');
      enqueueSnackbar('Se rechazó correctamente la solicitud de vinculación', {
        variant: 'success',
      });
    },
  });
};

// ---- RECHAZA LA SOLICITUD DE VINCULACIÓN ----
export const useRejectLinkRequest = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useMutation(rejectLinkRequest, {
    onSuccess: () => {
      queryClient.invalidateQueries('GET_LINK_REQUESTS');
      enqueueSnackbar('Se rechazó correctamente la solicitud de vinculación', {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar('No se pudo realizar la operación, intenta más tarde', {
        variant: 'error',
      });
    },
  });
};

// ---- TRAE EL HISTORIAL DE COMPRAS ----
export const useGetPaymentsHistory = () => {
  return useQuery('GET_PAYMENTS_HISTORY', getPaymentsHistory);
};

// ---- TRAE EL HISTORIAL DE VINCULACIONES NUTRICIONALES ----
export const useGetNutritionalHistory = () => {
  return useQuery('GET_NUTRITIONAL_HISTORY', getNutritionalHistory);
};

// ---- TRAE LOS RATINGS PENDIENTES ----
export const useGetMissingRatings = () => {
  const dispatch = useDispatch();

  return useQuery('GET_MISSING_RATINGS', getMissingRatings, {
    onSuccess: response => {
      if (response.inbodyPass) {
        console.log(response);
        const name = response.inbodyPass?.location?.name ?? '';
        const date = response.inbodyPass?.measurementDate;
        const id = response.inbodyPass?._id;
        dispatch(
          setRatingLocation({
            id,
            name,
            date,
          }),
        );
        setTimeout(() => {
          dispatch(setBackdropVisibility(true));
        }, 1500);
      }
    },
  });
};

// ---- ACTUALIZA EL RATING DE LA PRUEBA INBODY ----
export const usePostMissingRating = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useMutation(postMissingRating, {
    onSuccess: () => {
      queryClient.invalidateQueries('GET_MISSING_RATINGS');
      enqueueSnackbar('Se envió tu calificación con éxito', {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar('No se pudo realizar la operación, intente más tarde', {
        variant: 'error',
      });
    },
    onSettled: () => {
      dispatch(setBackdropVisibility(false));
    },
  });
};

// ---- TRAE EL TICKET DE UNA CITA ----
export const useGetAppointmentTicket = id => {
  const { enqueueSnackbar } = useSnackbar();

  return useQuery('GET_APPOINTMENT_TICKET', () => getAppointmentTicket(id), {
    enabled: false,
    onError: () => {
      enqueueSnackbar(
        'No se pudo obtener el ticket, intenta de nuevo más tarde',
        {
          variant: 'error',
        },
      );
    },
  });
};

// ---- SOLICITA UNA MODIFICACIÓN DE HORARIOS DE RESERVACIÓN PARA CITAS INBODY ----
export const useRequestReScheduleAppointment = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { handleClose } = useDrawer(DRAWERS.appoinment);
  const dispatch = useDispatch();
  const { push } = useHistory();

  return useMutation(requestReScheduleAppointment, {
    onSuccess: () => {
      queryClient.invalidateQueries('GET_APPOINTMENTS');
      enqueueSnackbar('Se actualizó correctamente tu cita', {
        variant: 'success',
      });
      push('/dashboard/main');
    },
    onError: () => {
      enqueueSnackbar('No se pudo actualizar tu cita, intenta más tarde', {
        variant: 'error',
      });
    },
    onSettled: () => {
      handleClose();
      dispatch(setScheduleVisibility(false));
    },
  });
};

// ---- TRAE LAS CITAS DE LA AGENDA VIRTUAL ----
export const useGetVirtualAgendaAppointments = () => {
  const dispatch = useDispatch();

  return useQuery(
    'GET_VIRTUAL_AGENDA_APPOINTMENTS',
    requestGetVirtualAgendaAppointments,
    {
      onSuccess: data => {
        dispatch(setVirtualAgendaAppointments(data));
      },
    },
  );
};

// ---- TRAE LA LISTA DE CITAS PARA MOSTRAR EN "PROXIMOS EVENTOS" -----
export const useGetAppointmentsWithNutritionist = () => {
  const dispatch = useDispatch();

  return useQuery(
    'GET_APPOINTMENTS_WITH_NUTRITIONIST',
    getAppointmentsWithNutritionist,
    {
      onSuccess: data => {
        dispatch(setPendingAppointments(data));
      },
    },
  );
};

// ---- CREA UNA SOLICITUD DE CITA CON EL NUTRIOLOGO VINCULADO ----
export const useCreateAppointmentWithNutritionist = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { replace } = useHistory();

  return useMutation(createAppointmentWithNutritionist, {
    onSuccess: () => {
      queryClient.invalidateQueries('GET_APPOINTMENTS_WITH_NUTRITIONIST');
      replace('/dashboard/main');
      enqueueSnackbar(
        'Se creó la cita con éxito, espera la confirmación por parte de tu nutriólogo',
        {
          variant: 'success',
        },
      );
    },
    onError: ({ response }) => {
      if (response?.data?.isPublic) {
        enqueueSnackbar(response?.data?.message, {
          variant: 'warning',
        });
      } else {
        enqueueSnackbar('Algo salió mal, intenta de nuevo más tarde.', {
          variant: 'error',
        });
      }
    },
  });
};

// ---- ACEPTA UNA PROPUESTA DE CITA ENVIADA POR EL NUTRIOLOGO ----
export const useAcceptAppointmentSendedByNutritionist = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { handleClose } = useDrawer(DRAWERS.nutritionistAppointment);

  return useMutation(acceptAppointmentSendedByNutritionist, {
    onSuccess: () => {
      queryClient.invalidateQueries('GET_VIRTUAL_AGENDA_APPOINTMENTS');
      queryClient.invalidateQueries('GET_APPOINTMENTS_WITH_NUTRITIONIST');
      enqueueSnackbar('Se confirmó correctamente la cita con tu nutriologo', {
        variant: 'success',
      });
      handleClose();
    },
    onError: ({ response }) => {
      if (response?.data?.isPublic) {
        enqueueSnackbar(response?.data?.message, { variant: 'warning' });
      } else {
        enqueueSnackbar('Algo salió mal, intenta de nuevo más tarde', {
          variant: 'error',
        });
      }
    },
    onSettled: () => {
      dispatch(
        setModalStatus({
          name: 'APPOINTMENT_SENDED_BY_NUTRITIONIST_CONFIRMATION',
          isActive: false,
        }),
      );
    },
  });
};

// ---- RECHAZA UNA PROPUESTA DE CITA ENVIADA POR EL NUTRIOLOGO ----
export const useRejectAppointmentSendedByNutritionist = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(rejectAppointmentSendedByNutritionist, {
    onSuccess: () => {
      queryClient.invalidateQueries('GET_VIRTUAL_AGENDA_APPOINTMENTS');
      queryClient.invalidateQueries('GET_APPOINTMENTS_WITH_NUTRITIONIST');
      enqueueSnackbar('Se rechazó correctamente la cita con tu nutriologo', {
        variant: 'success',
      });
    },
    onError: ({ response }) => {
      if (response?.data?.isPublic) {
        enqueueSnackbar(response?.data?.message, { variant: 'warning' });
      } else {
        enqueueSnackbar('Algo salió mal, intenta de nuevo más tarde', {
          variant: 'error',
        });
      }
    },
    onSettled: () => {
      dispatch(
        setModalStatus({
          name: 'APPOINTMENT_SENDED_BY_NUTRITIONIST_CONFIRMATION',
          isActive: false,
        }),
      );
    },
  });
};

// ---- CANCELA UNA PROPUESTA DE CITA ENVIADA POR EL NUTRIOLOGO ----
export const useCancelAppointmentSendedByNutritionist = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { handleClose } = useDrawer(DRAWERS.nutritionistAppointment);

  return useMutation(cancelAppointmentSendedByNutritionist, {
    onSuccess: () => {
      queryClient.invalidateQueries('GET_VIRTUAL_AGENDA_APPOINTMENTS');
      queryClient.invalidateQueries('GET_APPOINTMENTS_WITH_NUTRITIONIST');
      enqueueSnackbar('Se canceló correctamente la cita con tu nutriologo', {
        variant: 'success',
      });
    },
    onError: ({ response }) => {
      if (response?.data?.isPublic) {
        enqueueSnackbar(response?.data?.message, { variant: 'warning' });
      } else {
        enqueueSnackbar('Algo salió mal, intenta de nuevo más tarde', {
          variant: 'error',
        });
      }
    },
    onSettled: () => {
      handleClose();
      dispatch(
        setModalStatus({
          name: 'APPOINTMENT_SENDED_BY_NUTRITIONIST_CONFIRMATION',
          isActive: false,
        }),
      );
    },
  });
};

// ---- ACTUALIZA UNA CITA CON UN NUTRIOLOGO ----
export const useRescheduleAppointmentWithNutritionist = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { replace } = useHistory();

  return useMutation(rescheduleAppointmentWithNutritionist, {
    onSuccess: () => {
      replace('/dashboard/main');
      queryClient.invalidateQueries('GET_VIRTUAL_AGENDA_APPOINTMENTS');
      queryClient.invalidateQueries('GET_APPOINTMENTS_WITH_NUTRITIONIST');
      enqueueSnackbar(
        'Tu solicitud para reagendar ha sido enviada exitosamente. Espera la confirmación de tu nutriólogo(a)',
        {
          variant: 'success',
        },
      );
    },
    onError: ({ response }) => {
      if (response?.data?.isPublic) {
        enqueueSnackbar(response?.data?.message, { variant: 'warning' });
      } else {
        enqueueSnackbar('Algo salió mal, intenta de nuevo más tarde', {
          variant: 'error',
        });
      }
    },
  });
};

export const useUnlinkNutritionist = () => {
  const dispatch = useDispatch();
  const { replace } = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(unlinkNutritionist, {
    onSuccess: () => {
      enqueueSnackbar('Se desvinculó correctamente a tu nutriólogo', {
        variant: 'success',
      });
      dispatch(
        setModalStatus({
          name: 'UNLINK_NUTRITIONIST_WARNING',
          isActive: false,
        }),
      );
      setTimeout(() => {
        replace('/dashboard');
      }, 1000);
    },
    onError: ({ response }) => {
      if (response?.data?.isPublic) {
        enqueueSnackbar(response?.data?.message, { variant: 'warning' });
      } else {
        enqueueSnackbar('Algo salió mal, intenta de nuevo más tarde', {
          variant: 'error',
        });
      }
    },
  });
};

export const useUploadBiochemicalPlan = () => {
  return useMutation(uploadBiochemicalPlan);
};

export const useAddBiochemicalPlan = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useMutation(addBiochemicalPlan, {
    onSuccess: () => {
      queryClient.invalidateQueries('GET_BIOCHEMICAL_PLANS');
      enqueueSnackbar('Se agrego correctamente el análisis bioquímico', {
        variant: 'success',
      });
    },
    onError: ({ response }) => {
      if (response?.data?.isPublic) {
        enqueueSnackbar(response?.data?.message, { variant: 'warning' });
      } else {
        enqueueSnackbar('Algo salió mal, intenta de nuevo más tarde', {
          variant: 'error',
        });
      }
    },
  });
};

export const useDeleteBiochemicalPlan = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useMutation(deleteBiochemicalPlan, {
    onSuccess: data => {
      queryClient.invalidateQueries('GET_BIOCHEMICAL_PLANS');
      enqueueSnackbar('Se eliminó correctamente el análisis bioquímico', {
        variant: 'success',
      });
    },
    onError: ({ response }) => {
      if (response?.data?.isPublic) {
        enqueueSnackbar(response?.data?.message, { variant: 'warning' });
      } else {
        enqueueSnackbar('Algo salió mal, intenta de nuevo más tarde', {
          variant: 'error',
        });
      }
    },
    onSettled: () => {
      dispatch(
        setModalStatus({
          name: 'REMOVE_BIOCHEMICAL_PLAN_WARNING',
          isActive: false,
        }),
      );
    },
  });
};
