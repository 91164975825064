import { SHOW_ALERT, HIDE_ALERT } from './constants';

const initialState = {
  message: '',
  type: '',
  title: '',
  isOpen: false,
};

const alertReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SHOW_ALERT:
      return {
        ...state,
        message: payload.message,
        type: payload.type,
        title: payload.title,
        isOpen: true,
      };
    case HIDE_ALERT:
      return {
        ...state,
        isOpen: payload.isOpen,
      };
    default:
      return state;
  }
};

export default alertReducer;
