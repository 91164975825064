import { wellnub } from '../wellnub.api';

export const requestGetUser = id => wellnub.get(`/v1/users/${id}`);

export const requestGetSuscriptions = () =>
  wellnub.get('/v1/client/suscription');

export const getPasses = () =>
  wellnub.get('/v1/client/inbodyPass/total?statusList=readyForSchedule');

export const requestAppointment = payload =>
  wellnub.post('/v1/client/inbodyPass/use', payload);

export const requestGetAppointments = () =>
  wellnub.get('/v1/client/inbodyPass?statusList=scheduled');

export const requestGetMedicalProfile = () => wellnub.get('/v1/client/patient');

export const requestGetProfile = () => wellnub.get('/v1/client/profile');

export const requestUpdateProfile = data =>
  wellnub.patch('/v1/client/user', data);

export const requestUpdatePassword = data =>
  wellnub.patch('/v1/users/password', data);

export const requestGetNutritionalPlans = async () => {
  const { data } = await wellnub.get('/v1/client/nutritionPlan');
  return data;
};

export const requestGetBiochemicalPlans = async () => {
  const { data } = await wellnub.get('/v1/client/analysis');
  return data;
};

export const requestGetAvailablePasses = async () => {
  const { data } = await wellnub.get('/v1/client/inbodyPass/available');
  return data;
};

export const requestGetLastNutritionalPlan = async () => {
  const { data } = await wellnub.get('/v1/client/user/nutritionist/current');
  return data;
};

export const requestGetLastNutritionalPlanDetail = async id => {
  const { data } = await wellnub.get(`/v1/client/nutritionPlan/${id}`);
  return data;
};

export const getLinkRequests = async () => {
  const { data } = await wellnub.get('/v1/client/linkRequest');
  return data;
};

export const postLinkRequest = payload =>
  wellnub.post('/v1/client/linkRequest', payload);

export const acceptLinkRequest = id => {
  return wellnub.patch(`/v1/client/linkRequest/accept/${id}`);
};

export const rejectLinkRequest = id => {
  return wellnub.patch(`/v1/client/linkRequest/reject/${id}`);
};

export const cancelLinkRequest = id => {
  return wellnub.patch(`/v1/client/linkRequest/cancel/${id}`);
};

export const getPaymentsHistory = async () => {
  const { data } = await wellnub.get('/v1/client/payment/history');
  return data;
};

export const getNutritionalHistory = async () => {
  const { data } = await wellnub.get('/v1/client/nutritionPlan/history');
  return data;
};

export const getMissingRatings = async () => {
  const { data } = await wellnub.get('/v1/client/inbodyPass/pendingRating');
  return data;
};

export const postMissingRating = async payload => {
  const { data } = await wellnub.post(
    `/v1/client/inbodyPass/rate/${payload.id}`,
    payload.data,
  );
  return data;
};

export const getAppointmentTicket = async id => {
  const { data } = await wellnub.get(`/v1/client/inbodyPass/image/${id}`);
  return data;
};

export const requestReScheduleAppointment = async payload => {
  const { data } = await wellnub.patch(
    `/v1/client/inbodyPass/reschedule/${payload.id}`,
    payload.data,
  );
  return data;
};

export const requestGetVirtualAgendaAppointments = async () => {
  const { data } = await wellnub.get(`/v1/client/appointment`);
  return data;
};

export const getAppointmentsWithNutritionist = async () => {
  const { data } = await wellnub.get(
    `/v1/client/wellnubService/nutritionistAppointment`,
  );
  return data;
};

export const createAppointmentWithNutritionist = async payload => {
  const { data } = await wellnub.post(
    `/v1/client/wellnubService/nutritionistAppointment`,
    payload,
  );
  return data;
};

export const acceptAppointmentSendedByNutritionist = async id => {
  const { data } = await wellnub.patch(
    `/v1/client/wellnubService/nutritionistAppointment/accept/${id}`,
  );
  return data;
};

export const rejectAppointmentSendedByNutritionist = async id => {
  const { data } = await wellnub.patch(
    `/v1/client/wellnubService/nutritionistAppointment/reject/${id}`,
  );
  return data;
};

export const cancelAppointmentSendedByNutritionist = async id => {
  const { data } = await wellnub.patch(
    `/v1/client/wellnubService/nutritionistAppointment/cancel/${id}`,
  );
  return data;
};

export const rescheduleAppointmentWithNutritionist = async payload => {
  const { data } = await wellnub.patch(
    `/v1/client/wellnubService/nutritionistAppointment/${payload.id}`,
    payload.data,
  );
  return data;
};

export const unlinkNutritionist = async () => {
  const { data } = await wellnub.delete('/v1/client/linkRequest');
  return data;
};

export const uploadBiochemicalPlan = async payload => {
  const { data } = await wellnub.post('/v1/client/file/biochemist', payload);
  return data;
};

export const addBiochemicalPlan = async payload => {
  const { data } = await wellnub.post('/v1/client/analysis', payload);
  return data;
};
export const deleteBiochemicalPlan = async id => {
  const { data } = await wellnub.delete(`/v1/client/analysis/${id}`);
  return data;
};
