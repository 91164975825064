import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  cards: [],
  target: {},
  order: {},
  paymentMethod: {},
  subscription: {},
};

const paymentsSlice = createSlice({
  name: 'payments',
  initialState,
  reducers: {
    setCards: (state, action) => {
      state.cards = action.payload;
    },
    setOrder: (state, action) => {
      state.order = action.payload;
    },
    addCard: (state, action) => {
      state.cards.push(action.payload);
    },
    setTarget: (state, action) => {
      state.target = action.payload;
    },
    setPaymentMethod: (state, action) => {
      state.paymentMethod = action.payload;
    },
    setPaymentSubscription: (state, action) => {
      state.subscription = action.payload;
    },
  },
});

export const {
  setCards,
  setOrder,
  setTarget,
  addCard,
  setPaymentMethod,
  setPaymentSubscription,
} = paymentsSlice.actions;

export const paymentsReducer = paymentsSlice.reducer;
