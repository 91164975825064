import { batch, useDispatch } from 'react-redux';

import { Box, Button, Typography } from '@material-ui/core';

import { setModalStatus } from 'redux/slices/modal.slice';
import { setLinkRequestId } from 'redux/slices/user.slice';

import { useRejectLinkRequest } from 'hooks/user.hook';

import { useStyles } from './NutritionistInvitationCard.styles';

const NutritionistInvitationCard = ({ data }) => {
  const { mutate } = useRejectLinkRequest();

  const dispatch = useDispatch();

  const classes = useStyles();

  const onAcceptClickHandler = () => {
    batch(() => {
      dispatch(setLinkRequestId(data._id));
      dispatch(
        setModalStatus({
          name: 'NUTRITIONIST_INVITATION_ALERT',
          isActive: true,
        }),
      );
    });
  };

  const onDeclineClickHandler = () => {
    mutate(data._id);
  };

  return (
    <Box component="aside" className={classes.card}>
      <Typography variant="h6" className={classes.title}>
        Vinculación con nuevo nutriólogo
      </Typography>
      <Typography variant="body2" className={classes.mb}>
        Acepta a tu nuevo nutriólogo
      </Typography>
      <Typography variant="body2">
        Nutriólogo: {data.nutritionPartner.name}{' '}
        {data.nutritionPartner.lastName}
      </Typography>
      <Box component="footer" className={classes.footer}>
        <Box width="80%">
          <Button
            fullWidth
            variant="contained"
            onClick={onAcceptClickHandler}
            size="small"
          >
            Aceptar
          </Button>
        </Box>
        <Box mt={2} width="80%">
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            onClick={onDeclineClickHandler}
            size="small"
          >
            Rechazar
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default NutritionistInvitationCard;
