import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  requests: [],
};
const linkRequestsSlice = createSlice({
  name: 'linkRequest',
  initialState,
  reducers: {
    setLinkRequests: (state, action) => {
      state.requests = action.payload;
    },
  },
});

export const { setLinkRequests } = linkRequestsSlice.actions;

export const LinkRequestsReducer = linkRequestsSlice.reducer;
