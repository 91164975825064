import { wellnub } from '../wellnub.api';

export const getInbodyLocations = async () => {
  const { data } = await wellnub.get('/v1/client/inbodyLocation');
  return data;
};

export const getSpotDetails = async id => {
  const { data } = await wellnub.get(
    `/v1/client/inbodyLocation/schedule/${id}`,
  );
  return data;
};

export const requestGetPrices = () =>
  wellnub.get('/v1/client/storeProduct/coin');
