import { wellnub } from '../wellnub.api';

export const requestSignIn = credentials =>
  wellnub.post('/v1/auth/login?role=client', credentials);

export const requestSignUp = data => wellnub.post('/v1/client/register', data);

export const requestResetPassword = email =>
  wellnub.post('/v1/auth/tempPassword', email);

export const requestValidateEmail = async payload => {
  const { data } = await wellnub.post(
    '/v1/client/validationToken/email/validate',
    payload,
  );
  return data;
};

export const requestResendEmailToken = async id => {
  const { data } = await wellnub.post(
    `/v1/client/validationToken/email/resend/${id}`,
  );
  return data;
};

export const requestUpdateEmail = async payload => {
  const { data } = await wellnub.patch(
    `/v1/client/validationToken/email/${payload.id}`,
    payload.data,
  );
  return data;
};

export const requestValidatePhoneNumber = async payload => {
  const { data } = await wellnub.post(
    '/v1/client/validationToken/phoneNumber/validate',
    payload,
  );
  return data;
};

export const requestResendPhoneNumberToken = async id => {
  const { data } = await wellnub.post(
    `/v1/client/validationToken/phoneNumber/resend/${id}`,
  );
  return data;
};

export const requestUpdatePhoneNumber = async payload => {
  const { data } = await wellnub.patch(
    `/v1/client/validationToken/phoneNumber/${payload.id}`,
    payload.data,
  );
  return data;
};
