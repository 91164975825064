import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  _id: '',
  name: '',
  offices: [],
  services: {
    online: [],
    site: [],
    home: [],
  },
  getInTouchData: {},
  mode: '',
  collection: [],
};

const nutritionistSlice = createSlice({
  name: 'nutritionist',
  initialState,
  reducers: {
    setNutritionist: (state, action) => {
      state.services.online = action.payload.services.online;
      state.services.site = action.payload.services.site;
      state.services.home = action.payload.services.home;
      state.name = action.payload.name;
      state._id = action.payload._id;
      state.offices = action.payload.offices;
    },
    setNutritionistGetInTouchData: (state, action) => {
      state.getInTouchData = action.payload;
    },
    setNutritionistId: (state, action) => {
      state._id = action.payload;
    },
    setNutritionistMode: (state, action) => {
      state.mode = action.payload;
    },
    setNutritionistsCollection: (state, action) => {
      state.collection = action.payload;
    },
  },
});

export const {
  setNutritionist,
  setNutritionistGetInTouchData,
  setNutritionistId,
  setNutritionistMode,
  setNutritionistsCollection,
} = nutritionistSlice.actions;

export const nutritionistReducer = nutritionistSlice.reducer;
