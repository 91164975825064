import WellnubService from './WellnubService';

import { BASE_URL } from '../constants';

class CoachesService extends WellnubService {
  get = id => this.axios.get(`${BASE_URL}/v1/users/${id}`)

  list = () => this.axios.get(`${BASE_URL}/v1/users`);
}

export default CoachesService;