/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux';
// import { connectRouter } from 'connected-react-router';

import history from 'utils/history';

import languageProviderReducer from 'containers/LanguageProvider/reducer';

import errorMessageReducer from './components/ErrorMessage/reducer';

import alertReducer from './redux/alert/reducer';
import userAuthenticationReducer from './redux/user/reducer';
import livesReducer from './redux/lives/reducer';
import vwodsReducer from './redux/vwods/reducer';
import coachesReducer from './redux/coaches/reducer';
import modalBehaviorsReducer from './redux/modalBehaviors/reducer';
import { drawerReducer } from './redux/drawer/reducer';
import { drawersReducer } from './redux/slices/drawers.slice';
import { spotReducer } from './redux/slices/spot.slice';
import { userReducer } from './redux/slices/user.slice';
import { authenticationReducer } from './redux/slices/authentication.slice';
import { paymentsReducer } from './redux/slices/payments.slice';
import { modalReducer } from './redux/slices/modal.slice';
import { inbodyPassesReducer } from './redux/slices/inbodyPasses.slice';
import { nutritionistReducer } from './redux/slices/nutritionist.slice';
import { viewerReducer } from './redux/slices/viewer.slice';
import { BackdropReducer } from './redux/slices/backdrop.slice';
import { LayoutReducer } from './redux/slices/layout.slice';
import { VirtualAgendaReducer } from './redux/slices/virtualAgenda.slice';
import { LinkRequestsReducer } from './redux/slices/linkRequests.slice';
import { NutritionistTestReducer } from './redux/nutritionistTest/nutritionistTest.slice';
import { MyCurrentNutritionistReducer } from './redux/slices/myCurrentNutritionist.slice';
import { PendingAppointmentsWithNutritionistReducer } from './redux/pendingAppointmentsWithNutritionist/pendingAppointmentsWithNutritionist.slice';
import { NutritionistAppointmentReducer } from './redux/slices/nutritionistAppointment.slice';
import { WnUserReducer } from './redux/slices/wnUser/wnUser.slice';
import { WnModalContentReducer } from './redux/slices/wnModalContent/wnModalContent.slice';
/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    language: languageProviderReducer,
    // router: connectRouter(history),
    userAuthentication: userAuthenticationReducer,
    errorMessage: errorMessageReducer,
    lives: livesReducer,
    vwods: vwodsReducer,
    coaches: coachesReducer,
    modalBehaviors: modalBehaviorsReducer,
    alertStatus: alertReducer,
    drawer: drawerReducer,
    drawers: drawersReducer,
    spot: spotReducer,
    user: userReducer,
    authentication: authenticationReducer,
    payments: paymentsReducer,
    modal: modalReducer,
    inbodyPasses: inbodyPassesReducer,
    nutritionist: nutritionistReducer,
    viewer: viewerReducer,
    backdrop: BackdropReducer,
    layout: LayoutReducer,
    virtualAgenda: VirtualAgendaReducer,
    linkRequests: LinkRequestsReducer,
    nutritionistTest: NutritionistTestReducer,
    myCurrentNutritionist: MyCurrentNutritionistReducer,
    pendingAppointmentsWithNutritionist:
      PendingAppointmentsWithNutritionistReducer,
    nutritionistAppointment: NutritionistAppointmentReducer,
    wnUser: WnUserReducer,
    wnModalContent: WnModalContentReducer,
    ...injectedReducers,
  });

  return rootReducer;
}
