import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga4';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';

import {
  Typography,
  Box,
  Container,
  Grid,
  Button,
  CardMedia,
  Hidden,
  Link,
  useMediaQuery,
} from '@material-ui/core';

import landing2 from 'assets/images/clients-landing-2.jpg';
import landing3 from 'assets/images/clients-landing-3.jpg';
import landing4 from 'assets/images/clients-landing-4.jpg';
import landing5 from 'assets/images/clients-landing-5.jpg';
import landing6 from 'assets/images/clients-landing-6.jpg';
import landing7 from 'assets/images/clients-landing-7.jpg';
import landing8 from 'assets/images/clients-landing-8.jpg';

import iconLanding1 from 'assets/icons/icon-landing-1.png';
import iconLanding2 from 'assets/icons/icon-landing-2.png';
import iconLanding3 from 'assets/icons/icon-landing-3.png';

import CheckIcon from '@material-ui/icons/Check';

import { LandingCard } from 'components';

import { LandingImage } from './subcomponents';

import { setModalBehavior } from 'redux/modalBehaviors/actions';

import { MODAL_NAMES } from 'utils/constants';

import { useStyles } from './Home.styles';

const Home = () => {
  const responsive = useMediaQuery(theme => theme.breakpoints.down('xs'));

  const dispatch = useDispatch();

  const location = useLocation();

  const { replace, push } = useHistory();

  const classes = useStyles();

  const onSignUpClickHandler = () => {
    if (responsive) {
      replace('/signUp');
    } else {
      dispatch(
        setModalBehavior({
          name: MODAL_NAMES.SIGN_UP,
          isVisible: true,
        }),
      );
    }
  };

  const onSignInClickHandler = () => {
    push('/signin');
  };

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname + location.search,
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Wellnub | Clientes</title>
      </Helmet>
      <Box className={classes.hero}>
        <Box className={classes.heroGrid}>
          <Container maxWidth="lg">
            <Typography
              variant="h1"
              color="secondary"
              className={classes.mainTitle}
            >
              La app con nutriólogos reales para lograr resultados reales
            </Typography>
            <Typography color="secondary" className={classes.heroSubtitle}>
              Transformamos tu experiencia en consulta acompañándote hacia tu
              mejor versión
            </Typography>
            <Box
              pt={responsive ? 2 : 4}
              textAlign={responsive ? 'center' : 'left'}
            >
              <Button
                variant="contained"
                onClick={onSignUpClickHandler}
                className={classes.heroButton}
              >
                Empieza hoy, regístrate aquí
              </Button>
              {responsive && (
                <Box mt={2}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={onSignInClickHandler}
                  >
                    ¿Ya tienes cuenta? Inicia sesión
                  </Button>
                </Box>
              )}
            </Box>
          </Container>
        </Box>
      </Box>

      <Box component="section" className={classes.section}>
        <Container maxWidth="lg">
          <Typography variant="h2" className={classes.sectionTitle}>
            La app digital de nutrición que te conecta con un simple click con
            los expertos{' '}
            <span className={`${classes.action} ${classes.asLink}`}>
              <Link
                to="/nutritionists"
                component={RouterLink}
                underline="none"
                color="inherit"
              >
                ¡Encuentra a tu nutriólogo ideal!
              </Link>
            </span>
          </Typography>
          <Box pt={2}>
            <Grid container spacing={6}>
              <Grid item sm={6} xs={12}>
                <LandingCard
                  image={landing2}
                  title="Tú decides cuándo, dónde y cómo"
                >
                  Sólo necesitas tu computadora o tu smartphone para encontrar
                  el nutriólogo perfecto para ti. Toma tus consultas desde la
                  comodidad de tu casa
                </LandingCard>
              </Grid>
              <Grid item sm={6} xs={12}>
                <LandingCard
                  image={landing3}
                  title="La medición más exacta, en menos de un minuto"
                >
                  ¿Alguna vez te has preguntado de qué estás hecho? Descúbrelo
                  con las más avanzada tecnología de InBody&reg; y mide mucho
                  mas allá de tu peso: grasa, músculo, minerales, proteína y
                  agua. Reserva tu prueba de composición corporal en un solo
                  click, en cualquiera de nuestros spots InBody&reg;
                </LandingCard>
              </Grid>
              <Grid item sm={6} xs={12}>
                <LandingCard
                  image={landing4}
                  title="Elige tu spot favorito para reservar tu escaneo"
                >
                  Actualmente contamos con 8 spots en la Ciudad de México. Elige
                  el que te queda más cercano, reserva tu prueba InBody&reg;
                  desde la app y recibe ahí mismo los resultados para seguir de
                  cerca tu progreso
                </LandingCard>
              </Grid>
              <Grid item sm={6} xs={12}>
                <LandingCard
                  image={landing5}
                  title="Simplificamos la consulta con tu nutriólogo"
                >
                  Todo desde tu app. De principio a fin.
                  <Typography className={classes.check}>
                    <CheckIcon fontSize="small" />
                    Haz cita con el nutriólogo de tu elección.
                  </Typography>
                  <Typography className={classes.check}>
                    <CheckIcon fontSize="small" />
                    Agenda y realiza tu prueba InBody&reg;
                  </Typography>
                  <Typography className={classes.check}>
                    <CheckIcon fontSize="small" />
                    Ten tu consulta
                  </Typography>
                  <Typography className={classes.check}>
                    <CheckIcon fontSize="small" />
                    Recibe tu menú personalizado
                  </Typography>
                  <Typography className={classes.check}>
                    <CheckIcon fontSize="small" />
                    Monitorea tu proceso
                  </Typography>
                </LandingCard>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>

      <Box component="section" className={classes.section}>
        <Container maxWidth="lg">
          <Typography variant="h2" className={classes.sectionTitle}>
            Revolucionamos la nutrición personalizada con la mejor tecnología
          </Typography>
          <Grid container spacing={4}>
            <Grid item md={4} sm={12}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <CardMedia
                  component="img"
                  image={iconLanding1}
                  className={classes.benefitIcon}
                />
                <Typography variant="h5" className={classes.benefitTitle}>
                  Facilitamos tu búsqueda
                </Typography>
                <Typography className={classes.benefitText}>
                  Tus objetivos y necesidades son únicos, nosotros te brindamos
                  las opciones para que encuentres justo lo que deseas
                </Typography>
              </Box>
            </Grid>
            <Grid item md={4} sm={12}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <CardMedia
                  component="img"
                  image={iconLanding2}
                  className={classes.benefitIcon}
                />
                <Typography variant="h5" className={classes.benefitTitle}>
                  Tu camino al éxito con un solo click
                </Typography>
                <Typography className={classes.benefitText}>
                  Rápido y sencillo, con nuestra tecnología de punta, vive la
                  experiencia de asesoría nutricional de principio a fin
                </Typography>
              </Box>
            </Grid>
            <Grid item md={4} sm={12}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <CardMedia
                  component="img"
                  image={iconLanding3}
                  className={classes.benefitIcon}
                />
                <Typography variant="h5" className={classes.benefitTitle}>
                  Nutrición y medición online
                </Typography>
                <Typography className={classes.benefitText}>
                  Ni el tiempo ni la distancia debería ser lo que te impida
                  lograrlo, nosotros nos encargamos
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box
        py={10}
        component="section"
        bgcolor="primary.main"
        color="secondary.main"
      >
        <Container maxWidth="lg">
          <Typography variant="h2" className={classes.sectionTitle}>
            El primer paso hacia tu mejor versión &nbsp;
            <Typography
              variant="h2"
              component="span"
              className={`${classes.sectionTitle} ${classes.sectionTitleWrapped}`}
            >
              te toma menos de 1 minuto
            </Typography>
          </Typography>
          <Box textAlign="center">
            <Button
              variant="contained"
              onClick={onSignUpClickHandler}
              className={classes.heroButton}
            >
              Regístrate ahora
            </Button>
            <Typography variant="body2" className={classes.caption}>
              completamente gratis y haz realidad esos objetivos que tanto
              deseas
            </Typography>
          </Box>
        </Container>
      </Box>

      <Box component="section" className={classes.section}>
        <Container maxWidth="lg">
          <Grid container spacing={4}>
            <Grid item md={4} sm={12}>
              <LandingImage image={landing6} title="Tu plataforma Wellnub">
                <Typography className={classes.benefitDescription}>
                  Explora nuestra plataforma, encuentra e interactúa con tus
                  expertos en salud fácil y rápido sin salir de tu casa u
                  oficina
                </Typography>
              </LandingImage>
            </Grid>
            <Grid item md={4} sm={12}>
              <LandingImage image={landing7} title="Tecnología Inbody">
                <Typography className={classes.benefitDescription}>
                  Mide con tecnología InBody&reg; tu composición corporal.
                  Descubre de qué está hecho tu cuerpo y mide tu progreso
                </Typography>
              </LandingImage>
            </Grid>
            <Grid item md={4} sm={12}>
              <LandingImage image={landing8} title="Plataforma móvil">
                <Typography className={classes.benefitDescription}>
                  ¡Recibe tus planes alimenticios, consultas y resultados
                  InBody&reg; en tu perfil! ¡Nunca fue más fácil lograr tus
                  objetivos!
                </Typography>
              </LandingImage>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box component="section" className={classes.section}>
        <Container maxWidth="lg">
          <Typography variant="h2" className={classes.sectionTitle}>
            Comienza a usar Wellnub y comprueba &nbsp;
            <Typography
              variant="h2"
              component="span"
              className={`${classes.sectionTitle} ${classes.sectionTitleWrapped}`}
            >
              por ti mismo lo que ofrecemos
            </Typography>
          </Typography>
          <Box textAlign="center">
            <Button
              variant="contained"
              onClick={onSignUpClickHandler}
              style={{ paddingLeft: '70px', paddingRight: '70px' }}
            >
              Empieza hoy, regístrate aquí
            </Button>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Home;
