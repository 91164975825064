// import produce from 'immer';

// import { useAuthentication } from 'utils/hooks/authentication.hook';

import {
  SET_TOKEN,
  SET_ID,
  SET_IS_AUTHENTICATED,
  USER_SIGNING_IN,
  USER_SIGNING_UP,
  REQUEST_SIGN_OUT,
  SET_SUSCRIPTIONS,
} from './constants';

// const { isAuthenticated, token, id } = useAuthentication();

export const initialState = {
  token: '',
  id: '',
  isAuthenticated: false,
  isRequestingSigningIn: false,
  isRequestingSigningUp: false,
  suscriptions: [],
};

const userAuthenticationReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_TOKEN:
      return {
        ...state,
        token: payload,
      };
    case SET_ID:
      return {
        ...state,
        id: payload,
      };
    case SET_IS_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: payload,
      };
    case USER_SIGNING_IN:
      return {
        ...state,
        isRequestingSigningIn: payload,
      };
    case USER_SIGNING_UP:
      return {
        ...state,
        isRequestingSigningUp: payload,
      };
    case REQUEST_SIGN_OUT:
      return {
        ...state,
        token: null,
        id: null,
        isAuthenticated: false,
      };
    case SET_SUSCRIPTIONS:
      return {
        ...state,
        suscriptions: payload,
      };
    default:
      return state;
  }
};

export default userAuthenticationReducer;
