import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  card: {
    padding: theme.spacing(2, 3),
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.primary.light,
  },
  name: {
    color: theme.palette.action.light,
    textTransform: 'uppercase',
    cursor: 'pointer',
  },
  avatar: {
    width: 60,
    height: 60,
    cursor: 'pointer',
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
  infoWrapper: {
    flexGrow: 1,
  },
  icon: {
    cursor: 'pointer',
  },
}));
