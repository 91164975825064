import { memo, forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
  AppBar,
  Box,
  Hidden,
  Toolbar,
  Button,
  Link,
  Container,
  CardMedia,
  useMediaQuery,
} from '@material-ui/core';

import wellnubLogo from 'assets/images/logo-white.svg';

import SignInDropdown from '../../../Dropdowns/SignIn/SignInDropdown.component';
import SignUpDropdown from '../../../Dropdowns/SignUp/SignUpDropdown.component';
import ProfileDropdown from '../../../Dropdowns/Profile/ProfileDropdown.component';

import { ResponsiveMenu } from './subcomponents';

import { useStyles } from './Navbar.styles';

const Navbar = (props, ref) => {
  const classes = useStyles();

  const { isAuthenticated } = useSelector(state => state.authentication);

  const isResponsive = useMediaQuery(theme => theme.breakpoints.down('xs'));

  const onRedirectClickHandler = () => {
    window.open('https://nutrition.wellnub.com', '_blank');
  };

  return (
    <AppBar
      position={isResponsive ? 'sticky' : 'static'}
      className={classes.navbar}
      ref={ref}
    >
      <Container maxWidth="xl">
        <Toolbar component="nav">
          <Link
            to={isAuthenticated ? '/dashboard' : '/'}
            component={RouterLink}
          >
            <CardMedia
              component="img"
              alt="wellnub logo"
              src={wellnubLogo}
              className={classes.logo}
            />
          </Link>
          <Hidden smDown>
            <Box className={classes.wrapper}>
              <Box pl={4} component="ul">
                <Link
                  to="/nutritionists"
                  component={RouterLink}
                  color="inherit"
                  underline="none"
                >
                  ¿Buscas Nutriólogo?
                </Link>
                {isAuthenticated && (
                  <Box component="span" ml={4}>
                    <Link
                      to="/tutorials"
                      component={RouterLink}
                      color="inherit"
                      underline="none"
                    >
                      Tutoriales
                    </Link>
                  </Box>
                )}
              </Box>
              <Box component="ul" className={classes.dropdownList}>
                {isAuthenticated && <ProfileDropdown />}
                {!isAuthenticated && (
                  <>
                    <SignInDropdown />
                    <SignUpDropdown />
                    <Button
                      size="small"
                      variant="contained"
                      color="secondary"
                      onClick={onRedirectClickHandler}
                    >
                      ¿Eres nutriólogo?
                    </Button>
                  </>
                )}
              </Box>
            </Box>
          </Hidden>
          <Hidden mdUp>
            <ResponsiveMenu />
          </Hidden>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default memo(forwardRef(Navbar));
