import { useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  Box,
  Button,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  Typography,
} from '@material-ui/core';

import { setModalStatus } from 'redux/slices/modal.slice';
import { setModalBehavior } from 'redux/modalBehaviors/actions';

import SignInForm from '../../Forms/SignIn/SignInForm.component';

import { MODAL_NAMES } from 'utils/constants';

import { useStyles } from './SignInDropdown.styles';

const SignInDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [anchor, setAnchor] = useState(null);

  const dispatch = useDispatch();

  const classes = useStyles();

  const onOpenHandler = event => {
    setIsOpen(true);
    setAnchor(event.currentTarget);
  };

  const onCloseHandler = () => {
    setIsOpen(false);
  };

  const onSignUpClickHandler = () => {
    dispatch(
      setModalBehavior({
        name: MODAL_NAMES.SIGN_UP,
        isVisible: true,
      }),
    );
  };

  const onResetClickHandler = () => {
    dispatch(
      setModalStatus({
        name: 'RESET_PASSWORD',
        isActive: true,
      }),
    );
  };

  const onSignInNutriClickHandler = () => {
    window.open('https://nutrition.wellnub.com/signIn', '_blank');
  };

  return (
    <>
      <Typography
        variant="body2"
        onClick={onOpenHandler}
        className={`${classes.asLink} ${classes.mr}`}
      >
        Iniciar sesión
      </Typography>
      <Popper
        open={isOpen}
        anchorEl={anchor}
        transition
        disablePortal
        style={{ zIndex: 10 }}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <ClickAwayListener onClickAway={onCloseHandler}>
              <Paper classes={{ root: classes.paper }}>
                <SignInForm />
                <Box pt={2} textAlign="center">
                  <Typography className={classes.helper}>
                    ¿No tienes cuenta?{' '}
                    <span
                      className={classes.action}
                      onClick={onSignUpClickHandler}
                    >
                      Regístrate gratis
                    </span>
                  </Typography>
                  <Typography
                    className={`${classes.helper} ${classes.action} ${classes.mt}`}
                    onClick={onResetClickHandler}
                  >
                    ¿Olvidaste tu contraseña?
                  </Typography>
                  <Box mt={1}>
                    <Button
                      size="small"
                      variant="contained"
                      color="secondary"
                      onClick={onSignInNutriClickHandler}
                    >
                      ¿Eres nutriólogo? Inicia sesión aquí
                    </Button>
                  </Box>
                </Box>
              </Paper>
            </ClickAwayListener>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default SignInDropdown;
