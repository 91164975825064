import React from 'react';
import { useController } from 'react-hook-form';

import NumberFormat from 'react-number-format';

import { TextField } from '@material-ui/core';

const WellnubNumberInput = ({
  name,
  rules,
  control,
  helperText,
  ...restProps
}) => {
  const {
    field: { ref, onChange },
    fieldState: { error },
  } = useController({ name, rules, control });

  return (
    <NumberFormat
      {...restProps}
      error={!!error}
      inputRef={ref}
      customInput={TextField}
      onValueChange={({ value }) => onChange(value)}
      helperText={error?.message ?? helperText}
    />
  );
};

export default WellnubNumberInput;
