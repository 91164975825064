import { string, object, ref } from 'yup';

const NAME_REGEX = new RegExp(/^([^0-9]*)$/);

export const defaultValues = {
  name: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  password: '',
  passwordConfirm: '',
  countryCode: '52',
};

export const signUpSchema = object({
  name: string()
    .matches(NAME_REGEX, 'El nombre no debe de contener números')
    .required('Este campo es requerido'),
  lastName: string()
    .matches(NAME_REGEX, 'El nombre no debe de contener números')
    .required('Este campo es requerido'),
  email: string()
    .email('Escribe un email válido')
    .required('Este campo es requerido'),
  phoneNumber: string().required('Este campo es requerido'),
  countryCode: string().required('Este campo es requerido'),
  // phoneNumber: string().test('isPhoneNumber', '', function (value = '') {
  //   if (value) {
  //     if (value.includes('+52')) {
  //       if (value.length !== 13) {
  //         return this.createError({
  //           message: 'El número debe ser de 10 dígitos',
  //         });
  //       } else {
  //         return true;
  //       }
  //     } else {
  //       const [, ...rest] = value.split(' ');
  //       const phoneNumber = rest.join('').replace(/[^\d]/g, '');
  //       if (phoneNumber.length < 10) {
  //         return this.createError({
  //           message: 'El número debe ser de 10 dígitos',
  //         });
  //       } else {
  //         return true;
  //       }
  //     }
  //   } else {
  //     return this.createError({
  //       message: 'Este campo es requerido',
  //     });
  //   }
  // }),
  password: string()
    .min(6, 'La contraseña debe de tener al menos 6 caracteres')
    .required('Este campo es requerido'),
  passwordConfirm: string()
    .min(6, 'La contraseña debe de tener al menos 6 caracteres')
    .oneOf([ref('password')], 'Las contraseñas deben coincidir')
    .required('Este campo es requerido'),
});
