import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    alignItems: 'center',
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.primary.main,
  },
  title: {
    textTransform: 'uppercase',
    flexGrow: 1,
  },
  body: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.light,
  },
}));
