import { Box, Button, Typography } from '@material-ui/core';

import { useStyles } from './PaymentSubscriptionCard.styles';
import { useDrawer } from '../../../hooks/drawer.hook';
import { DRAWERS } from '../../../utils/constants';

const PaymentSubscriptionCard = ({ subscription }) => {
  const { handleOpen } = useDrawer(DRAWERS.paymentSubscription);

  const classes = useStyles();

  const onClickHandler = () => {
    handleOpen();
  };

  return (
    <Box component="aside" className={classes.card}>
      <Box component="header">
        <Typography variant="h6" className={classes.name}>
          Suscripción: {subscription?.storeProduct?.name}
        </Typography>
      </Box>
      <Box component="main" mt={2} textAlign="center">
        <Typography variant="body2" style={{ marginBottom: 16 }}>
          ¡Tienes un pase para escaneo InBody&reg; cada{' '}
          {
            { 'Programa Trimestral': 'trimestre', 'Programa Mensual': 'mes' }[
              subscription?.storeProduct?.name
            ]
          }
          !
        </Typography>
        <Button
          size="small"
          variant="contained"
          style={{ paddingLeft: 32, paddingRight: 32 }}
          onClick={onClickHandler}
        >
          Ver mas
        </Button>
      </Box>
    </Box>
  );
};

export default PaymentSubscriptionCard;
