import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { isEmpty } from 'lodash';

import NumberFormat from 'react-number-format';

import {
  Drawer,
  Typography,
  Box,
  IconButton,
  TextField,
  ThemeProvider,
  Button,
  Radio,
  CardMedia,
  RadioGroup,
  Grid,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';

import amex from 'assets/icons/creditcards/card-amex.svg';
import visa from 'assets/icons/creditcards/card-visa.svg';
import master from 'assets/icons/creditcards/card-master.svg';

import { useDrawer } from 'hooks/drawer.hook';

import { setPaymentMethod } from 'redux/slices/payments.slice';

import { DRAWERS } from 'utils/constants';
import { currencyFormatter, generateTheme } from 'utils/helpers';

import { useStyles } from './CheckoutDrawer.styles';
import {
  useBuyPasses,
  useBuySubscription,
  useValidateDiscountCode,
} from '../../../hooks/payments.hook';
import conekta from '../../../assets/images/conekta.png';
import { WellnubTextInput } from '@wellnub/web-common';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';

const CheckoutDrawer = () => {
  const [isDefault, setIsDefault] = useState(true);
  const [quantity, setQuantity] = useState(1);
  const [discountCode, setDiscountCode] = useState('');
  const [error, setError] = useState(false);
  const [promo, setPromo] = useState({});
  const [errorResponse, setErrorResponse] = useState('');

  const { bundles, details, active } = useSelector(state => state.inbodyPasses);
  const { paymentMethod, cards } = useSelector(state => state.payments);

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      discountCode: '',
    },
  });

  const {
    mutate: buyPasses,
    isLoading: isLoadingPurchase,
    isSuccess,
  } = useBuyPasses();

  const { mutate: buySubscription, isLoading: isLoadingBuySubscription } =
    useBuySubscription();

  const { refetch: validateDiscountCode, isFetching } =
    useValidateDiscountCode(discountCode);

  const { enqueueSnackbar } = useSnackbar();

  const { handleOpen } = useDrawer(DRAWERS.addCard);
  const { visibility, handleClose } = useDrawer(DRAWERS.checkout);

  const dispatch = useDispatch();

  const classes = useStyles();

  const hasPaymentMethod = !isEmpty(paymentMethod);

  const onQuantityChangeHandler = event => {
    setQuantity(+event.target.value);
  };

  const onShowCardsHandler = event => {
    event.preventDefault();
    setIsDefault(false);
  };

  const onConfirmOrderHandler = () => {
    if (details?.type === 'INDIVIDUAL') {
      buyPasses({
        quantity,
        cardId: paymentMethod.id,
        storeProductId: bundles?.find(bundle => bundle.type === 'coinBundle')
          ?._id,
        discountCode: promo?.code ?? null,
      });
    }
    if (details?.type === 'SUBSCRIPTION') {
      buySubscription({
        storeProductId: active?._id,
        cardId: paymentMethod.id,
      });
    }
  };

  const onNewCardClickHandler = event => {
    event.preventDefault();
    handleOpen();
  };

  const onChangePaymentHandler = event => {
    const { value } = event.target;
    const newPaymentMethod = cards.find(card => card.id === value);
    dispatch(setPaymentMethod(newPaymentMethod));
  };

  const onSubmitHandler = data => {
    const { discountCode } = data;
    setDiscountCode(discountCode);
  };

  useEffect(() => {
    if (!isEmpty(discountCode)) {
      validateDiscountCode()
        .then(response => {
          console.dir(response);
          if (response?.isSuccess) {
            setError(false);
            setPromo(response.data);
            setErrorResponse('');
            if (quantity > response.data.passLimit) {
              setQuantity(response.data.passLimit);
            }
            if (quantity < response.data.minimun) {
              setQuantity(response.data.minimun);
            }
          }
          if (response?.isError) {
            if (response?.error?.response?.data?.isPublic) {
              setError(false);
              setPromo({});
              setErrorResponse(response?.error?.response?.data?.message);
            }
            if (response?.error?.response?.status === 404) {
              setError(true);
              setPromo({});
              setErrorResponse('');
            }
          }
        })
        .catch(error => {
          if (error?.response?.data?.isPublic) {
            enqueueSnackbar('Algo salió mal, intenta de nuevo más tarde', {
              variant: 'error',
            });
          }
        });
    }
  }, [discountCode, validateDiscountCode, enqueueSnackbar]);

  useEffect(() => {
    if (!isEmpty(promo)) {
      if (quantity > promo.passLimit) {
        setQuantity(+promo.passLimit);
      }
    }
  }, [promo, quantity]);

  useEffect(() => {
    if (isSuccess) {
      setPromo({});
      setQuantity(1);
      reset();
    }
  }, [isSuccess, reset]);

  return (
    <Drawer
      anchor="right"
      open={visibility}
      onClose={handleClose}
      classes={{ paper: classes.paper }}
    >
      <Box component="header">
        <IconButton onClick={handleClose}>
          <CloseIcon color="primary" />
        </IconButton>
        <Typography
          variant="h5"
          className={`${classes.title} ${classes.center}`}
        >
          Adquiere pases Wellnub - InBody&reg;
        </Typography>
      </Box>
      <Box pt={4} component="section">
        <Typography
          variant="h6"
          className={`${classes.subtitle} ${classes.center}`}
        >
          Detalles de la compra
        </Typography>
        <Typography>
          Al adquirir tus pases, podrás realizarte periódicamente y cuando tú lo
          desees, escaneos corporales con tecnología InBody&reg; en el spot
          Wellnub de tu elección
        </Typography>
        <br />
        <Typography>
          ¡Tienes hasta un año para utilizar los pases que adquieras!
        </Typography>
      </Box>
      <Box pt={2} component="section" textAlign="center">
        <Typography
          variant="h6"
          className={`${classes.subtitle} ${classes.center}`}
        >
          Número de pases Wellnub InBody® que deseas adquirir
          {/*{promo?.type === 'twoForOne' && 'Paquete 2 x 1 pases Wellnub InBody®'}*/}
          {/*{promo?.type === 'discount' && 'Número de pases Wellnub InBody® que desea adquirir'}*/}
        </Typography>
        {details?.type === 'INDIVIDUAL' && (
          <ThemeProvider theme={theme => generateTheme(theme, 'light')}>
            <TextField
              type="number"
              variant="outlined"
              color="primary"
              inputProps={{
                min: promo?.minimun ?? 1,
                max: promo?.passLimit ?? 100,
              }}
              value={quantity}
              onChange={onQuantityChangeHandler}
            />
          </ThemeProvider>
        )}
        <Typography variant="h6" className={classes.total}>
          Total:&nbsp;
          <NumberFormat
            displayType="text"
            value={
              details?.type === 'INDIVIDUAL'
                ? quantity *
                  bundles?.find(bundle => bundle.type === 'coinBundle')?.price
                    ?.total *
                  100
                : active?.price?.total * 100
            }
            format={currencyFormatter}
            className={`${!isEmpty(promo) && classes.discount}`}
          />
          {!isEmpty(promo) && (
            <>
              &nbsp;
              <NumberFormat
                displayType="text"
                value={
                  quantity *
                    bundles?.find(bundle => bundle.type === 'coinBundle')?.price
                      ?.total *
                    100 -
                  quantity *
                    bundles?.find(bundle => bundle.type === 'coinBundle')?.price
                      ?.total *
                    100 *
                    (promo.discount / 100)
                }
                format={currencyFormatter}
              />
            </>
          )}
        </Typography>
        {promo?.type === 'twoForOne' && (
          <Typography
            variant="h6"
            className={`${classes.subtitle} ${classes.center} ${classes.special}`}
          >
            Adquiere {promo?.minimun ?? 0} pruebas y paga solamente 1
            <br />
            (limitado a una promoción por persona)
            {/*{quantity}{' '}paquete{quantity > 1 && <span>s</span>} (2 pruebas)*/}
          </Typography>
        )}
        {promo?.type === 'discount' && (
          <Typography
            variant="h6"
            className={`${classes.subtitle} ${classes.center} ${classes.special}`}
          >
            Tus pases tienen {promo?.discount ?? 0}% de descuento
            {/*{quantity}{' '}paquete{quantity > 1 && <span>s</span>} (2 pruebas)*/}
          </Typography>
        )}
        {details?.type === 'SUBSCRIPTION' && (
          <Box>
            <Typography
              variant="h6"
              className={`${classes.subtitle} ${classes.center}`}
              style={{ marginTop: 16 }}
            >
              Este cobro se realizará cada{' '}
              {{
                'Programa Trimestral': 'trimestre',
                'Programa Mensual': 'mes',
              }[active.name] || ''}{' '}
              a la tarjeta ingresada y se asignará un pase a tu cuenta Wellnub.
            </Typography>
          </Box>
        )}
      </Box>
      {details?.type === 'INDIVIDUAL' && (
        <Box pt={4} component="section">
          <Typography
            variant="h6"
            className={`${classes.subtitle} ${classes.center}`}
          >
            Código promocional
          </Typography>
          <form onSubmit={handleSubmit(onSubmitHandler)}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <ThemeProvider theme={theme => generateTheme(theme, 'light')}>
                  <WellnubTextInput
                    fullWidth
                    name="discountCode"
                    color="primary"
                    control={control}
                    variant="outlined"
                    label="Código promocional"
                  />
                </ThemeProvider>
                <Typography variant="caption" className={classes.error}>
                  {error ? 'Código no válido' : ' '}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  fullWidth
                  disabled={isFetching}
                  type="submit"
                  color="primary"
                  variant="contained"
                >
                  Aplicar
                </Button>
              </Grid>
            </Grid>
          </form>
          {!isEmpty(errorResponse) && (
            <ThemeProvider theme={theme => generateTheme(theme, 'light')}>
              <Alert severity="error">{errorResponse}</Alert>
            </ThemeProvider>
          )}
        </Box>
      )}
      <Box pt={4} component="section">
        <Typography
          variant="h6"
          className={`${classes.subtitle} ${classes.center}`}
        >
          Forma de pago
        </Typography>
        {hasPaymentMethod &&
          (isDefault ? (
            <>
              <Box display="flex" alignItems="center">
                <Radio
                  value={paymentMethod.id}
                  color="primary"
                  checked={paymentMethod.default}
                  classes={{ root: classes.radio }}
                />
                <CardMedia
                  component="img"
                  image={
                    {
                      visa,
                      mastercard: master,
                      american_express: amex,
                    }[paymentMethod.brand]
                  }
                  className={classes.cardLogo}
                />
                <Typography variant="body2" className={classes.cardNumber}>
                  &#8226;&#8226;&#8226;&#8226; {paymentMethod.last4}
                </Typography>
              </Box>
              <Box pt={2}>
                <Typography
                  variant="body2"
                  className={classes.asLink}
                  onClick={onShowCardsHandler}
                >
                  Usar otra forma de pago
                </Typography>
              </Box>
            </>
          ) : (
            <>
              <RadioGroup
                name="payments"
                value={paymentMethod.id}
                onChange={onChangePaymentHandler}
              >
                {cards.map(card => (
                  <Box display="flex" alignItems="center" key={card.id}>
                    <Radio
                      value={card.id}
                      color="primary"
                      classes={{ root: classes.radio }}
                    />
                    <CardMedia
                      component="img"
                      image={
                        {
                          visa,
                          mastercard: master,
                          american_express: amex,
                        }[card.brand]
                      }
                      className={classes.cardLogo}
                    />
                    <Typography variant="body2" className={classes.cardNumber}>
                      &#8226;&#8226;&#8226;&#8226; {card.last4}
                    </Typography>
                  </Box>
                ))}
              </RadioGroup>
              <Box pt={2}>
                <Button
                  fullWidth
                  variant="outlined"
                  color="primary"
                  endIcon={<AddIcon />}
                  onClick={onNewCardClickHandler}
                >
                  Agregar forma de pago
                </Button>
              </Box>
            </>
          ))}
        {!hasPaymentMethod && (
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            endIcon={<AddIcon />}
            onClick={onNewCardClickHandler}
          >
            Agregar forma de pago
          </Button>
        )}

        <Box pt={2}>
          <Button
            fullWidth
            variant="contained"
            disabled={
              !hasPaymentMethod || isLoadingPurchase || isLoadingBuySubscription
            }
            onClick={onConfirmOrderHandler}
            classes={{
              disabled: classes.disabled,
            }}
          >
            Confirmar orden
          </Button>
        </Box>

        <Box pt={2}>
          <Typography variant="body2">
            Datos protegidos encriptados y pagos seguros con Conekta&reg;
          </Typography>
          <CardMedia
            component="img"
            image={conekta}
            className={classes.conekta}
          />
        </Box>
      </Box>
    </Drawer>
  );
};

export default CheckoutDrawer;
