import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { QueryCache, QueryClient, QueryClientProvider } from 'react-query';

import { SnackbarProvider } from 'notistack';

import { ThemeProvider, Slide, CssBaseline } from '@material-ui/core';

import { ModalSelector, AlertSelector } from 'components/organisms';

import history from 'utils/history';

import App from './App';

import configureStore from './configureStore';

import { theme } from 'styles/Theme';

const store = configureStore({}, history);

export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: error => {
      if (
        error &&
        error.response &&
        error.response.status === 403 &&
        error.response?.data?.apiCode === 'user-deactivated'
      ) {
        window.location.replace('/account-inactive');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('authentication');
        localStorage.removeItem('refreshToken');
      }
    },
  }),
  defaultOptions: {
    queries: {
      retry: (count, { response: { data } }) => {
        return data.code === 401 && data.message === 'jwt expired';
      },
      refetchOnWindowFocus: false,
    },
  },
});

window.Conekta.setPublicKey(process.env.REACT_APP_CONEKTA_KEY);

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <SnackbarProvider
            preventDuplicate
            maxSnack={4}
            TransitionComponent={Slide}
          >
            <App />
            <AlertSelector />
            <ModalSelector />
            <CssBaseline />
          </SnackbarProvider>
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  </QueryClientProvider>,
  document.getElementById('root'),
);
