import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'inline-block',
    position: 'relative',
    width: props => (props.fullWidth ? '100%' : 'max-content'),
  },
  button: {
    width: '100%',
    '&:disabled': {
      backgroundColor: '#eaeaea',
    },
  },
  spinner: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  action: {
    color: theme.palette.info.dark,
  },
}));
