import { makeStyles } from '@material-ui/core';
import landing2 from 'assets/images/clients-landing-2.jpg';
import herobg from 'assets/images/bg_hero.jpg';

export const useStyles = makeStyles(theme => ({
  landingImage1: {
    maxWidth: 500,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  mainSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  section: {
    padding: theme.spacing(12, 0),
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.main,
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(6, 0),
    },
  },
  sectionHero: {
    padding: 0,
  },
  sectionTitle: {
    paddingBottom: theme.spacing(4),
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: 24,
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  sectionTitleWrapped: {
    display: 'inline-block',
    padding: 0,
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
      display: 'block',
    },
  },
  imageDescriptorTitle: {
    paddingBottom: theme.spacing(2),
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  benefitIcon: {
    width: 100,
  },
  appStore: {
    width: 200,
  },
  benefitDescription: {
    textAlign: 'center',
    paddingTop: theme.spacing(2),
    fontSize: 13,
    [theme.breakpoints.down('xs')]: {
      paddingTop: 0,
      fontSize: 10,
    },
  },
  mainTitle: {
    textTransform: 'uppercase',
    paddingBottom: theme.spacing(4),
    maxWidth: '30ch',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      fontSize: 18,
    },
  },
  mainDescription: {
    paddingBottom: theme.spacing(4),
  },
  asideTitle: {
    textAlign: 'center',
  },
  cardImage: {
    position: 'relative',
    width: 500,
  },
  aside: {
    position: 'absolute',
    top: 30,
    left: 30,
  },
  grid: {
    display: 'grid',
    gap: theme.spacing(4),
    gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
  },
  benefitGrid: {
    display: 'grid',
    gap: theme.spacing(4),
    gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
  },
  caption: {
    paddingTop: theme.spacing(1),
    color: theme.palette.gray.light,
  },

  grid2: {
    display: 'grid',
    gap: '50px',
    gridTemplateRows: 350,
    gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
  },

  wrapper: {
    width: '100%',
    backgroundImage: `url(${landing2})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'relative',
    boxShadow: '0px 7px 10px rgba(0, 0, 0, 0.3)',
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
    '& .title': {
      textAlign: 'center',
      textTransform: 'uppercase',
      padding: theme.spacing(1, 0),
      color: 'white',
      opacity: 1,
      transition: 'opacity 250ms linear',
      backgroundColor: 'rgba(0, 0, 0, .70)',
    },
    '& .content': {
      position: 'absolute',
      padding: theme.spacing(4),
      backgroundColor: 'rgba(0, 0, 0, .70)',
      opacity: 0,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      transition: 'opacity 250ms linear, transform 250ms ease',
      transform: 'translateY(-100%)',
      borderRadius: theme.shape.borderRadius,
    },
    '&:hover .title': {
      opacity: 0,
    },
    '&:hover .content': {
      opacity: 1,
      transform: 'translateY(0)',
    },
  },
  content: {
    position: 'absolute',
    opacity: 0,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    transition: 'opacity 150ms linear',
  },
  hero: {
    position: 'relative',
    height: '500px',
    backgroundSize: 'cover',
    backgroundPosition: 'bottom left',
    backgroundImage: `linear-gradient(to right bottom, rgba(22, 50, 107, .3), rgba(22, 81, 117, .3)), url(${herobg})`,
    // backgroundImage: `url(${herobg})`,
  },
  heroGrid: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    paddingLeft: theme.spacing(8),
    paddingTop: theme.spacing(20),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(0),
      paddingTop: theme.spacing(0),
    },
  },
  spacing: {
    paddingTop: theme.spacing(8),
  },
  benefitTitle: {
    padding: theme.spacing(2, 0),
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  benefitText: {
    textAlign: 'center',
    fontSize: 12,
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
    },
  },
  heroSubtitle: {
    color: 'black',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  heroButton: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
  check: {
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
  },
  action: {
    color: theme.palette.action.main,
  },
  asLink: {
    cursor: 'pointer',
  },
}));
