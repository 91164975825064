import { Typography } from '@material-ui/core';
import React from 'react';

const Privacy = () => {
  return (
    <Typography variant="h3" color="secondary">
      Aviso de privacidad
    </Typography>
  );
};

export default Privacy;
