import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  paper: {
    maxWidth: 500,
    padding: theme.spacing(4),
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.light,
  },
  title: {
    textTransform: 'uppercase',
    color: theme.palette.primary.dark,
  },
  subtitle: {
    paddingBottom: theme.spacing(2),
    color: theme.palette.primary.dark,
  },
  center: {
    textAlign: 'center',
  },
}));
