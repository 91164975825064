import { useForm, Controller } from 'react-hook-form';

import {
  Box,
  Button,
  TextField,
  Typography,
  ThemeProvider,
} from '@material-ui/core';

import { useResetPassword } from 'hooks/authentication.hook';

import { generateTheme } from 'utils/helpers';

import { useStyles } from './ResetPasswordForm.styles';

const ResetPasswordForm = () => {
  const { control, handleSubmit } = useForm();

  const { mutate: resetPassword, isLoading } = useResetPassword();

  const classes = useStyles();

  const onSubmitHandler = data => {
    console.log('Datos: ', data);
    resetPassword(data);
  };
  return (
    <>
      <Typography>
        ¿Olvidaste tu contraseña? por favor ingresa tu email con el que te diste
        de alta y te enviaremos un correo con una contraseña provisional
      </Typography>
      <Box pt={2}>
        <form onSubmit={handleSubmit(onSubmitHandler)}>
          <Controller
            name="email"
            defaultValue=""
            control={control}
            rules={{
              required: 'Este campo es requerido',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Escribe un correo válido',
              },
            }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <ThemeProvider theme={theme => generateTheme(theme, 'light')}>
                <TextField
                  fullWidth
                  type="email"
                  variant="outlined"
                  error={!!error}
                  value={value}
                  onChange={onChange}
                  helperText={
                    error ? error.message : 'Correo para iniciar sesión'
                  }
                />
              </ThemeProvider>
            )}
          />
          <Box pt={2} textAlign="center">
            <Button
              type="submit"
              variant="contained"
              disabled={isLoading}
              className={classes.button}
            >
              Enviar
            </Button>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default ResetPasswordForm;
