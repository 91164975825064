import { batch, useDispatch } from 'react-redux';

import { format } from 'date-fns';
import { es } from 'date-fns/locale';

import { Box, Typography } from '@material-ui/core';

import { useDrawer } from 'hooks/drawer.hook';

import {
  setAppointmentScheduleDate,
  setAppointmentScheduleTime,
  setSpotAppointment,
  setSpotData,
} from 'redux/slices/spot.slice';

import { DRAWERS } from 'utils/constants';

import { useStyles } from './AppointmentCard.styles';

const AppointmentCard = ({ appointment }) => {
  const { handleOpen } = useDrawer(DRAWERS.indications);

  const dispatch = useDispatch();

  const classes = useStyles();

  const onOpenDrawerClickHandler = event => {
    batch(() => {
      dispatch(setAppointmentScheduleDate(appointment.scheduleDate));
      dispatch(setAppointmentScheduleTime(appointment.scheduleDate));
      dispatch(setSpotData(appointment.location));
      dispatch(setSpotAppointment(appointment));
    });
    setTimeout(() => {
      handleOpen();
    }, 100);
  };

  return (
    <Box component="aside" className={classes.card}>
      <Typography
        variant="h6"
        className={`${classes.title} ${classes.action}`}
        onClick={onOpenDrawerClickHandler}
      >
        Análisis corporal agendado
      </Typography>
      <Box pt={2} component="main">
        <Box pb={2} display="flex" alignItems="center">
          <Typography variant="body2" className={classes.info}>
            📍 {appointment.location?.name ?? ''} -{' '}
            {appointment.location?.address?.neighborhood ?? ''}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Typography variant="body2" className={classes.info}>
            ⏰&nbsp;
            {format(
              new Date(appointment.scheduleDate),
              "EEEEEE', 'd'-'LLL'-'y' @'hh:mma",
              {
                locale: es,
              },
            )}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default AppointmentCard;
