import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  paper: {
    maxWidth: 350,
    padding: theme.spacing(2, 3),
    backgroundColor: theme.palette.gray.dark,
  },
  asLink: {
    cursor: 'pointer',
  },
  helper: {
    fontSize: 14,
  },
  action: {
    color: theme.palette.action.light,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  mt: {
    marginTop: theme.spacing(1),
  },
  mr: {
    marginRight: theme.spacing(2),
  },
}));
