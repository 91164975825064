import React from 'react';
import { useController } from 'react-hook-form';

import { TextField } from '@material-ui/core';

const WellnubTextInput = ({
  name,
  rules,
  control,
  helperText,
  defaultValue,
  ...restProps
}) => {
  const {
    field: { ref, ...inputProps },
    fieldState: { error },
  } = useController({
    name,
    rules,
    control,
    defaultValue,
  });

  return (
    <TextField
      {...inputProps}
      {...restProps}
      inputRef={ref}
      error={!!error}
      helperText={error?.message ?? helperText}
    />
  );
};

export default WellnubTextInput;
