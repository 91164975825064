import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useForm, Controller } from 'react-hook-form';

import { makeStyles, Box, TextField, IconButton } from '@material-ui/core';

import { Visibility, VisibilityOff } from '@material-ui/icons';

import { Button } from 'components/atoms';

import { requestSignIn } from 'redux/user/actions';
import { useSignIn } from '../../../hooks/authentication.hook';

const useStyles = makeStyles(theme => ({
  button: {
    flexGrow: 1,
  },
  link: {
    cursor: 'pointer',
    color: theme.palette.action.light,
  },
}));

const SignInForm = ({ dropdownHandler }) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  // const { isRequestingSigningIn } = useSelector(
  //   state => state.userAuthentication,
  // );

  const { mutate, isLoading } = useSignIn();

  // const dispatch = useDispatch();

  const { handleSubmit, control } = useForm();

  const classes = useStyles();

  const onSubmitHandler = data => {
    mutate({ ...data });
    dropdownHandler(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <Box>
        <Controller
          name="email"
          control={control}
          defaultValue=""
          rules={{
            required: 'Ingresa tu e-mail',
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'Escribe un correo válido',
            },
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              fullWidth
              type="email"
              color="secondary"
              variant="outlined"
              value={value}
              error={!!error}
              onChange={onChange}
              label="Correo electrónico"
              helperText={error ? error.message : null}
            />
          )}
        />
      </Box>
      <Box pt={3}>
        <Controller
          name="password"
          control={control}
          defaultValue=""
          rules={{
            required: 'Ingresa tu contraseña',
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              fullWidth
              type={isPasswordVisible ? 'text' : 'password'}
              color="secondary"
              variant="outlined"
              value={value}
              error={!!error}
              onChange={onChange}
              label="Contraseña"
              helperText={error ? error.message : null}
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={() =>
                      setIsPasswordVisible(prevState => !prevState)
                    }
                  >
                    {isPasswordVisible ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                ),
              }}
            />
          )}
        />
      </Box>
      <Box pt={3} display="flex" alignItems="center">
        <Button
          fullWidth
          type="submit"
          variant="contained"
          isLoading={isLoading}
        >
          Iniciar sesión
        </Button>
      </Box>
    </form>
  );
};

export default SignInForm;
