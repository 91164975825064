import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  list: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
    listStyleType: 'disc',
  },
  item: {
    display: 'flex',
    paddingBottom: theme.spacing(1),
    alignItems: 'center',
  },
  mr: {
    marginRight: theme.spacing(1),
  },
  tooltip: {
    fontSize: 16,
    color: '#8dccec',
  },
}));
