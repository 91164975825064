import React, { useState } from 'react';
import { useController } from 'react-hook-form';

import { IconButton, TextField } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

const WellnubPasswordInput = ({ name, control, helperText, ...restProps }) => {
  const [isVisible, setIsVisible] = useState(false);

  const {
    field: { ref, ...restField },
    fieldState: { error },
  } = useController({ name, control });

  const onToggleVisibilityHandler = () => {
    setIsVisible(prevState => !prevState);
  };

  return (
    <TextField
      {...restField}
      {...restProps}
      type={isVisible ? 'text' : 'password'}
      inputRef={ref}
      error={!!error}
      helperText={error?.message ?? helperText}
      InputProps={{
        endAdornment: (
          <IconButton onClick={onToggleVisibilityHandler}>
            {isVisible ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        ),
      }}
    />
  );
};

export default WellnubPasswordInput;
