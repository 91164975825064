import { useSelector } from 'react-redux';

import { Box, Typography, Button } from '@material-ui/core';

import { useDeleteCard } from 'hooks/payments.hook';

const DeleteCardForm = () => {
  const { id } = useSelector(state => state.payments.target);

  const { mutate: deleteCard } = useDeleteCard();

  const onClickHandler = event => {
    event.preventDefault();
    deleteCard(id);
  };

  return (
    <>
      <Typography>¿Estás seguro de eliminar esta forma de pago?</Typography>
      <Box pt={2} textAlign="center">
        <Button variant="contained" onClick={onClickHandler}>
          Confirmar
        </Button>
      </Box>
    </>
  );
};

export default DeleteCardForm;
