import React, { Children } from 'react';

import {
  makeStyles,
  Grow,
  Paper,
  Popper,
  MenuList,
  MenuItem,
  ClickAwayListener,
} from '@material-ui/core';

import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  dropdown: {
    backgroundColor: theme.palette.gray.dark,
  },
  link: {
    width: '100%',
  },
}));

const Dropdown = ({ open, anchor, closeHandler, children }) => {
  const classes = useStyles();

  const count = Children.count(children);

  return (
    <Popper
      open={open}
      anchorEl={anchor}
      transition
      disablePortal
      style={{ zIndex: 10 }}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === 'bottom' ? 'center top' : 'center bottom',
          }}
        >
          <Paper classes={{ root: classes.dropdown }}>
            <ClickAwayListener onClickAway={closeHandler}>
              {count === 1 ? (
                children
              ) : (
                <MenuList autoFocusItem={open}>
                  {Children.map(children, child => (
                    <MenuItem
                      key={child.id}
                      onClick={() => {
                        closeHandler();
                      }}
                    >
                      {child}
                    </MenuItem>
                  ))}
                </MenuList>
              )}
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

Dropdown.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default Dropdown;
