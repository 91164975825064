import React, { useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { useController } from 'react-hook-form';
import 'react-datepicker/dist/react-datepicker.css';

const WellnubDatePicker = ({ name, control, ...restProps }) => {
  const {
    field: { value, onChange, ...restField },
    fieldState: { error },
  } = useController({ name, control });

  return (
    <DatePicker
      {...restProps}
      {...restField}
      selected={value}
      onChange={onChange}
    />
  );
};

export default WellnubDatePicker;
