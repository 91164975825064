import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Helmet } from 'react-helmet';

import { makeStyles, Container, Typography, Box } from '@material-ui/core';

import { fetchCoaches } from 'redux/coaches/actions';

import { CoachList } from 'components/organisms';

import { Spinner } from 'components/atoms';

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  heading: {
    textAlign: 'center',
    color: theme.palette.action.main,
  },
}));

const Coaches = () => {
  const { data, isLoading } = useSelector(state => state.coaches.list);

  const dispatch = useDispatch();

  const classes = useStyles();

  useEffect(() => {
    dispatch(fetchCoaches());
  }, []);

  return (
    <Container className={classes.container}>
      <Helmet>
        <meta
          name="description"
          content="¡Entrena con nuestros coaches en vivo! encuentra motivación, guía
          técnica, grupos de entrenamiento y toda la atención del coach en nuestro
          gimnasio virtual"
        />
        <title>Wellnub | Entrenadores</title>
      </Helmet>
      <Typography variant="h1" className={classes.heading}>
        Coaches
      </Typography>
      {isLoading ? (
        <Spinner color="secondary" />
      ) : (
        <Box pt={4} component="section">
          <CoachList coaches={data} />
        </Box>
      )}
    </Container>
  );
};

export default Coaches;
