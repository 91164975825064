import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: 100,
  },
  playerWrapper: {
    padding: theme.spacing(4),
    backgroundColor: '#c2c2c2',
    borderRadius: theme.shape.borderRadius,
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      width: '80%',
      height: 'max-content',
    },
  },
  iframe: {
    width: '853px',
    height: '480px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  icon: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
}));
