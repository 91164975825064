import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  navbar: {
    backgroundColor: theme.palette.gray.main,
  },
  logo: {
    display: 'block',
    width: 120,
    marginRight: theme.spacing(2),
  },
  link: {
    width: '100%',
    cursor: 'pointer',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  action: {
    color: theme.palette.action.light,
  },
  wrapper: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  dropdownList: {
    display: 'flex',
    alignItems: 'center',
  },
}));
