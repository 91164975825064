import axios from './auth.axios';
import { BASE_URL } from '../constants';

class ProfileService {
  get = async () => axios.get(`${BASE_URL}/v1/profile`);

  update = async payload => axios.put(`${BASE_URL}/v1/profile`, payload);
}

export default new ProfileService();
