import { Box, Typography } from '@material-ui/core';

import { useStyles } from './LandingCard.styles';

const LandingCard = ({ image, title, children }) => {
  const classes = useStyles({ image });

  return (
    <Box component="aside" className={classes.card}>
      <Typography variant="h6" className="title" style={{ fontSize: '10px' }}>
        {title}
      </Typography>
      <Box className="content">
        <Typography>{children}</Typography>
      </Box>
    </Box>
  );
};

export default LandingCard;
