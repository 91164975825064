import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  file: '',
  type: '',
};

const viewerSlice = createSlice({
  name: 'viewer',
  initialState,
  reducers: {
    setViewerFile: (state, action) => {
      state.file = action.payload;
    },
    setViewerType: (state, action) => {
      state.type = action.payload;
    },
  },
});

export const { setViewerFile, setViewerType } = viewerSlice.actions;

export const viewerReducer = viewerSlice.reducer;
