import React from 'react';

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';

import { useStyles } from './WellnubTable.styles';

import WellnubTableRow from '../TableRow/WellnubTableRow.component';

const WellnubTable = ({
  headers,
  rows,
  actions,
  disabled,
  variant = 'primary',
  actionPosition = 'start',
}) => {
  const classes = useStyles({ variant });

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead className={classes.head}>
          <TableRow>
            {headers.map(header => (
              <TableCell key={header} align="center">
                <Typography variant="body2" className={classes.cell}>
                  {header}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <WellnubTableRow
              key={row.id}
              row={row}
              actions={actions}
              disabled={disabled}
              variant={variant}
              actionPosition={actionPosition}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default WellnubTable;
