import { SHOW_ALERT, HIDE_ALERT } from './constants';

export const showAlert = settings => ({
  type: SHOW_ALERT,
  payload: { ...settings },
});

export const hideAlert = () => ({
  type: HIDE_ALERT,
  payload: {
    isOpen: false,
  },
});
