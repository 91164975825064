import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  paper: {
    maxWidth: 500,
    padding: theme.spacing(4),
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.light,
  },
  title: {
    textTransform: 'uppercase',
    color: theme.palette.primary.dark,
  },
  subtitle: {
    paddingBottom: theme.spacing(2),
    color: theme.palette.primary.dark,
  },
  center: {
    textAlign: 'center',
  },
  total: {
    paddingTop: theme.spacing(2),
  },
  radio: {
    color: theme.palette.secondary.dark,
  },
  cardNumber: {
    paddingLeft: theme.spacing(1),
  },
  cardLogo: {
    maxWidth: 45,
  },
  asLink: {
    width: 'max-content',
    cursor: 'pointer',
    color: theme.palette.action.main,
  },
  disabled: {
    backgroundColor: `${theme.palette.secondary.dark} !important`,
  },
  conekta: {
    width: 120,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  error: {
    display: 'inline-block',
    margin: theme.spacing(1, 0, 1, 1),
    color: '#ff4262',
  },
  discount: {
    textDecoration: 'line-through #ff4262 2px',
  },
  special: {
    color: '#c2c2c2',
  },
}));
