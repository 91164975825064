import { BASE_URL } from 'utils/constants';

import WellnubService from './WellnubService';
class AuthService extends WellnubService {
  signIn = payload => this.axios.post(`${BASE_URL}/v1/auth/login`, payload);

  signUp = payload =>
    this.axios.post(`${BASE_URL}/v1/client/register`, payload);

  recoverPassword = email =>
    this.axios.post(`${BASE_URL}/v1/auth/tempPassword`, { email });
}

export default AuthService;
