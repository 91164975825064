import React from 'react';

import PropTypes from 'prop-types';

import { Link as RouterLink, useRouteMatch } from 'react-router-dom';

import { makeStyles, Box, Link, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  image: {
    backgroundImage: ({ image }) => (image ? `url(${image})` : 'none'),
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
  },
  footer: {
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
  },
  noWrap: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));

const CoachCard = ({ coach }) => {
  const { _id: coachID, pictureUrl: image, name, lastName } = coach;

  const classes = useStyles({ image });

  const { url } = useRouteMatch();

  return (
    <Box component="article" bgcolor="secondary.main" borderRadius={8}>
      <Link to={`${url}/${coachID}`} underline="none" component={RouterLink}>
        <Box height={280} className={classes.image} />
        <Box
          p={1}
          component="footer"
          bgcolor="gray.main"
          className={classes.footer}
        >
          <Typography
            variant="body2"
            color="secondary"
            align="center"
            className={classes.noWrap}
          >
            {name}&nbsp;{lastName}
          </Typography>
        </Box>
      </Link>
    </Box>
  );
};

CoachCard.propTypes = {
  coach: PropTypes.object.isRequired,
};

export default CoachCard;
