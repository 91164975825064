import React, { useEffect, Fragment } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Helmet } from 'react-helmet';

import { useParams } from 'react-router-dom';

import { isEmpty } from 'lodash';

import {
  makeStyles,
  Container,
  Typography,
  Box,
  CardMedia,
  Hidden,
  Divider,
} from '@material-ui/core';

import { PROGRAM_TYPES, MODAL_NAMES } from 'utils/constants';

import { ProgramList } from 'components/organisms';

import { Spinner } from 'components/atoms';

import { setModalBehavior } from 'redux/modalBehaviors/actions';

import { fetchCoach } from 'redux/coaches/actions';

import GetInTouch from './sections/GetInTouch.section';

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  grid: {
    display: 'grid',
    gap: 30,
    gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
    gridTemplateAreas: `"photo name about"`,
    [theme.breakpoints.down('xs')]: {
      gridTemplateAreas: `
      "name"
      "photo"
      "about"`,
    },
  },
  image: {
    width: '80%',
    gridArea: 'photo',
    justifySelf: 'center',
    borderRadius: theme.shape.borderRadius,
  },
  info: {
    gridArea: 'name',
  },
  about: {
    gridArea: 'about',
  },
  divider: {
    backgroundColor: theme.palette.gray.dark,
  },
  action: {
    color: theme.palette.action.main,
  },
}));

const Coach = () => {
  const { data, isLoading } = useSelector(state => state.coaches.coach);

  const dispatch = useDispatch();

  const { id } = useParams();

  const classes = useStyles();

  const getInTouchHandler = () => {
    dispatch(
      setModalBehavior({
        name: MODAL_NAMES.GET_IN_TOUCH,
        isVisible: true,
      }),
    );
  };

  useEffect(() => {
    dispatch(fetchCoach(id));
  }, []);

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Helmet>
        <meta
          name="description"
          content="entrena conmigo en vivo. Tendrás un grupo para motivarte, toda mi atención y guía técnica dentro de mi sala de gimnasio virtual"
        />
        <title>Wellnub | Tu entrenador personal</title>
      </Helmet>
      {isLoading ? (
        <Spinner color="secondary" />
      ) : (
        <Fragment>
          <Box pt={4} pb={4} component="section" className={classes.grid}>
            <CardMedia
              component="img"
              alt="Tu entenador personal"
              title="Tu entrenador personal"
              image={data.pictureUrl}
              className={classes.image}
            />
            <Box component="aside" className={classes.info}>
              <Typography variant="h1" color="secondary" align="center">
                Coach{' '}
                <Typography
                  variant="h1"
                  component="span"
                  className={classes.action}
                >
                  {data.name}&nbsp;{data.lastName}
                </Typography>
              </Typography>
              <Hidden xsDown>
                <GetInTouch clickHandler={getInTouchHandler} />
              </Hidden>
            </Box>
            <Box component="aside" className={classes.about}>
              <Typography variant="h2" color="secondary" align="center">
                Acerca de mi
              </Typography>
              <Box
                pt={4}
                component="p"
                fontSize="body1.fontSize"
                textAlign="center"
              >
                {data.about}
              </Box>
            </Box>
            <Hidden smUp>
              <Box>
                <GetInTouch clickHandler={getInTouchHandler} />
              </Box>
            </Hidden>
          </Box>
          <Divider className={classes.divider} />
          <Box pt={4} component="section">
            <Typography>
              <Typography component="span" className={classes.action}>
                Lives
              </Typography>{' '}
              entrena conmigo en vivo. Tendrás un grupo para motivarte, toda mi
              atención y mi guía técnica dentro de mi sala de gimnasio virtual.
            </Typography>
            <Box pt={4} pb={4}>
              <ProgramList
                programs={
                  isEmpty(data)
                    ? []
                    : data.programs.filter(
                        program => program.typeService === PROGRAM_TYPES.LIVE,
                      )
                }
              />
            </Box>
            <Typography>
              <Typography component="span" className={classes.action}>
                VWODS
              </Typography>{' '}
              videos que guían todo tu entrenamiento y te permiten entrenar
              donde sea cuando quieras.
            </Typography>
            <Box pt={4}>
              <ProgramList
                programs={
                  isEmpty(data)
                    ? []
                    : data.programs.filter(
                        program => program.typeService === PROGRAM_TYPES.VWOD,
                      )
                }
              />
            </Box>
          </Box>
        </Fragment>
      )}
    </Container>
  );
};

export default Coach;
