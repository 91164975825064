import { useEffect, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import {
  useAcceptAppointmentSendedByNutritionist,
  useRejectAppointmentSendedByNutritionist,
} from 'hooks/user.hook';
import { setModalStatus } from 'redux/slices/modal.slice';
import {
  RESCHEDULE_MODES,
  setVirtualAgendaAppointmentToReschedule,
  setVirtualAgendaScheduleMode,
} from '../../../../redux/slices/virtualAgenda.slice';

const useStyles = makeStyles(theme => ({
  title: {
    textTransform: 'uppercase',
  },
}));

const AppointmentSendedByNutritionistPanel = () => {
  const [appointment, setAppointment] = useState({});
  const [isRejected, setIsRejected] = useState(false);

  const { appointments } = useSelector(
    state => state.pendingAppointmentsWithNutritionist,
  );

  const { mutate: acceptAppointment, isLoading: isLoadingAccept } =
    useAcceptAppointmentSendedByNutritionist();

  const { mutate: rejectAppointment, isLoading: isLoadingReject } =
    useRejectAppointmentSendedByNutritionist();

  const { push } = useHistory();

  const dispatch = useDispatch();

  const classes = useStyles();

  const onConfirmHandler = () => {
    acceptAppointment(appointment?._id ?? '');
  };

  const onCancelHandler = () => {
    setIsRejected(true);
  };

  const onRejectHandler = () => {
    rejectAppointment(appointment?._id ?? '');
  };

  const onChangeAppointmentHandler = () => {
    batch(() => {
      dispatch(
        setModalStatus({
          name: 'APPOINTMENT_SENDED_BY_NUTRITIONIST_CONFIRMATION',
          isActive: false,
        }),
      );
      dispatch(setVirtualAgendaAppointmentToReschedule(appointment));
      dispatch(setVirtualAgendaScheduleMode(RESCHEDULE_MODES.RESCHEDULE));
    });
    setTimeout(() => {
      push('/dashboard/main/create-appointment');
    }, 500);
  };

  useEffect(() => {
    const appointment = appointments.find(
      item => item.nextActionRole === 'client' && item.status === 'pending',
    );
    if (appointment) {
      setAppointment(appointment);
    }
  }, [appointments]);

  return (
    <Box textAlign="center">
      {!isRejected && (
        <>
          <Typography variant="h6" className={classes.title}>
            Confirma la consulta de tu nutriólogo
          </Typography>
          <Typography style={{ marginTop: '16px' }}>
            Por favor, confirma la consulta que el nutriólogo agendó para ti.
          </Typography>
          <Typography>
            {format(
              new Date(appointment?.startDate ?? null),
              "EEEE' 'd' de 'LLLL' del 'y' a las 'HH:mm'",
              { locale: es },
            )}
          </Typography>
          <Typography style={{ marginTop: '16px' }}>
            Si tienes alguna duda, comunícate directo con el/ella.
          </Typography>
          <Box
            mt={2}
            display="flex"
            alignItems="center"
            justifyContent="space-evenly"
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={onCancelHandler}
              disabled={isLoadingAccept}
            >
              Rechazar
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={onConfirmHandler}
              disabled={isLoadingAccept}
            >
              Confirmar
            </Button>
          </Box>
        </>
      )}
      {isRejected && (
        <>
          <Typography variant="h6" className={classes.title}>
            ¿Deseas rechazar o proponer otra fecha?
          </Typography>
          <Typography style={{ marginTop: '16px' }}>
            Si esta fecha no se acomoda, puedes seleccionar otra fecha para no
            perder tu consulta
          </Typography>
          <Box
            mt={2}
            display="flex"
            alignItems="center"
            justifyContent="space-evenly"
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={onRejectHandler}
              disabled={isLoadingReject}
            >
              Rechazar
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={onChangeAppointmentHandler}
              disabled={isLoadingReject}
            >
              Proponer otra fecha
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

export default AppointmentSendedByNutritionistPanel;
