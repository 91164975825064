import React from 'react';
import { useController } from 'react-hook-form';

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from '@material-ui/core';

const WellnubCheckboxInput = ({
  name,
  label,
  control,
  defaultValue,
  helperText,
  defaultChecked,
  rules,
  ...restProps
}) => {
  const {
    field: { ref, value, ...inputProps },
    fieldState: { error },
  } = useController({ name, control, defaultValue, rules });
  return (
    <FormControl error={!!error} {...inputProps} {...restProps}>
      <FormControlLabel
        label={label}
        control={
          <Checkbox
            {...inputProps}
            checked={value}
            color="primary"
            defaultChecked={defaultChecked}
          />
        }
      />
      <FormHelperText>{error?.message ?? helperText}</FormHelperText>
    </FormControl>
  );
};

export default WellnubCheckboxInput;
