import { useEffect, useState } from 'react';
import { batch, useDispatch } from 'react-redux';
import { compareDesc, format } from 'date-fns';

import {
  Link,
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';

import {
  Box,
  Tabs,
  Tab,
  useMediaQuery,
  List,
  ListItem,
  ListItemText,
  Typography,
  ListItemIcon,
  Divider,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  Paper,
  Button,
} from '@material-ui/core';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import AttachFileIcon from '@material-ui/icons/AttachFile';

import { WellnubTable } from '@wellnub/web-common';
import { setViewerFile, setViewerType } from 'redux/slices/viewer.slice';

import { useDrawer } from 'hooks/drawer.hook';

import { DRAWERS } from 'utils/constants';
import { isEmpty } from 'lodash';

import { useStyles } from './Activity.styles';
import { setDetails } from '../../../redux/slices/inbodyPasses.slice';

const paths = [
  { label: 'Compras', url: 'purchases' },
  { label: 'Programas', url: 'programs' },
];

const PROGRAMS_HEADERS = [
  'ID Operación',
  'Fecha',
  'Nutriólogo',
  'Concepto',
  'Archivo',
];

const PURCHASES_HEADERS = [
  'ID compra',
  'Fecha',
  'Concepto',
  'Monto',
  'Forma de pago',
];

const CONCEPTS = {
  linkHistory: 'Historial de vinculación',
  plan: 'Plan nutricional',
};

const PAYMENT_METHODS = {
  conekta: 'Pago con tarjeta',
  admin: 'Abono del administrador',
};

const NutritionalTable = ({ data, handler }) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead style={{ backgroundColor: '#293241' }}>
          <TableRow>
            {PROGRAMS_HEADERS.map(header => (
              <TableCell key={header} align="center">
                <Typography variant="body2">{header}</Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(item => (
            <TableRow key={item.id} style={{ backgroundColor: '#f5f5f5' }}>
              <TableCell align="center" style={{ color: '#23282c' }}>
                <Typography variant="body2">{item.id}</Typography>
              </TableCell>
              <TableCell align="center" style={{ color: '#23282c' }}>
                <Typography variant="body2">{item.date}</Typography>
              </TableCell>
              <TableCell align="center" style={{ color: '#23282c' }}>
                <Typography variant="body2">{item.nutritionistName}</Typography>
              </TableCell>
              <TableCell align="center" style={{ color: '#23282c' }}>
                <Typography variant="body2">{item.concept}</Typography>
              </TableCell>
              <TableCell align="center" style={{ color: '#23282c' }}>
                {item.file ? (
                  <Tooltip title="Ver">
                    <IconButton onClick={() => handler(item.file)} size="small">
                      <AttachFileIcon fontSize="small" color="primary" />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Typography>&nbsp;</Typography>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const ActivityPage = ({ nutritionalHistory, paymentsHistory }) => {
  const [PROGRAMS_ROWS, setNutritionalRows] = useState([]);
  const [PURCHASES_ROWS, setPaymentsRows] = useState([]);

  const dispatch = useDispatch();

  const { url, path } = useRouteMatch();
  const { pathname } = useLocation();
  const { push } = useHistory();

  const isResponsive = useMediaQuery(theme => theme.breakpoints.down('xs'));

  const { handleOpen: openCheckoutDrawer } = useDrawer(DRAWERS.checkout);

  const classes = useStyles();

  const tabValueHandler = () => {
    if (pathname.includes('programs')) return 'programs';
    if (pathname.includes('purchases')) return 'purchases';
  };

  const openFileHandler = file => {
    const type = file.includes('.pdf') ? 'PDF' : 'IMAGE';
    batch(() => {
      dispatch(setViewerFile(file));
      dispatch(setViewerType(type));
    });
    push('/viewer/1');
  };

  const onPurchaseClickHandler = () => {
    openCheckoutDrawer();
    dispatch(
      setDetails({
        type: 'INDIVIDUAL',
      }),
    );
  };

  useEffect(() => {
    const paymentsMapped = paymentsHistory
      .map(item => ({
        id: item._id,
        data: {
          id: item.transactionId,
          date: new Date(item.transactionDate),
          concept: item.product.name,
          price: `$${item.payedAmount}`,
          method: PAYMENT_METHODS[item.plataform],
        },
      }))
      .sort((a, b) => compareDesc(new Date(a.data.date), new Date(b.data.date)))
      .map(el => ({
        ...el,
        data: { ...el.data, date: format(new Date(el.data.date), 'Pp') },
      }));
    const nutritionalMapped = nutritionalHistory
      .map(item => ({
        id: item._id,
        date:
          item.type === 'plan'
            ? new Date(item.startDate)
            : new Date(item.actionDate),
        nutritionistName: item.nutritionist.name,
        concept: CONCEPTS[item.type],
        file: item?.fileUrl ?? '',
      }))
      .sort((a, b) => compareDesc(new Date(a.date), new Date(b.date)))
      .map(el => ({ ...el, date: format(new Date(el.date), 'Pp') }));
    setNutritionalRows(nutritionalMapped);
    setPaymentsRows(paymentsMapped);
  }, [nutritionalHistory, paymentsHistory]);

  return (
    <Box>
      <Tabs
        variant="fullWidth"
        value={tabValueHandler()}
        indicatorColor="primary"
      >
        {paths.map(path => (
          <Tab
            key={path.url}
            value={path.url}
            component={Link}
            label={path.label}
            to={`${url}/${path.url}`}
          />
        ))}
      </Tabs>
      <Box component="main">
        <Switch>
          <Route exact path={path}>
            <Redirect to={`${path}/purchases`} />
          </Route>
          <Route exact path={`${path}/programs`}>
            <Box mt={2}>
              {isResponsive && (
                <Box>
                  <Typography variant="h5" className={classes.title}>
                    Mis programas
                  </Typography>
                  <List>
                    {PROGRAMS_ROWS.map(item => (
                      <>
                        <ListItem alignItems="flex-start">
                          <ListItemText
                            primary={item.id}
                            secondary={
                              <>
                                <Typography
                                  variant="body2"
                                  style={{ color: '#0b1015' }}
                                >
                                  {item.date}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  style={{ color: '#0b1015' }}
                                >
                                  {item.nutritionistName}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  style={{ color: '#0b1015' }}
                                >
                                  {item.concept}
                                </Typography>
                              </>
                            }
                          />
                          {item.file && (
                            <ListItemIcon>
                              <IconButton>
                                <InsertDriveFileIcon fontSize="small" />
                              </IconButton>
                            </ListItemIcon>
                          )}
                        </ListItem>
                        <Divider
                          variant="inset"
                          style={{ margin: 0, backgroundColor: '#c2c2c2' }}
                        />
                      </>
                    ))}
                  </List>
                </Box>
              )}
              {!isResponsive && (
                <Box className={classes.contentWrapper}>
                  <Typography variant="h5" className={classes.title}>
                    Mis programas
                  </Typography>
                  {isEmpty(PROGRAMS_ROWS) && (
                    <Typography variant="body2">
                      Aún no tienes programas registrados
                    </Typography>
                  )}
                  {!isEmpty(PROGRAMS_ROWS) && (
                    <NutritionalTable
                      data={PROGRAMS_ROWS}
                      handler={openFileHandler}
                    />
                  )}
                </Box>
              )}
            </Box>
          </Route>
          <Route exact path={`${path}/purchases`}>
            <Box mt={2}>
              {isResponsive && (
                <Box>
                  <Typography variant="h5" className={classes.title}>
                    Mis compras
                  </Typography>
                  <Box className={classes.contentWrapper}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={onPurchaseClickHandler}
                    >
                      Comprar pases Wellnub - InBody&reg;
                    </Button>
                    {isEmpty(PURCHASES_ROWS) && (
                      <Typography variant="body2" style={{ marginTop: 16 }}>
                        Aún no tienes compras registradas
                      </Typography>
                    )}
                    {!isEmpty(PURCHASES_ROWS) && (
                      <List>
                        {PURCHASES_ROWS.map(item => (
                          <>
                            <ListItem alignItems="flex-start">
                              <ListItemText
                                primary={item.data.id}
                                secondary={
                                  <>
                                    <Typography
                                      variant="body2"
                                      style={{ color: '#0b1015' }}
                                    >
                                      {format(new Date(item.data.date), 'Pp')}
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      style={{ color: '#0b1015' }}
                                    >
                                      {item.data.concept}
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      style={{ color: '#0b1015' }}
                                    >
                                      {item.data.price}
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      style={{ color: '#0b1015' }}
                                    >
                                      {item.data.method}
                                    </Typography>
                                  </>
                                }
                              />
                            </ListItem>
                            <Divider
                              variant="inset"
                              style={{ margin: 0, backgroundColor: '#0b1015' }}
                            />
                          </>
                        ))}
                      </List>
                    )}
                  </Box>
                </Box>
              )}
              {!isResponsive && (
                <Box>
                  <Typography variant="h5" className={classes.title}>
                    Mis compras
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onPurchaseClickHandler}
                  >
                    Comprar pases Wellnub - InBody&reg;
                  </Button>
                  <Box mt={1}>
                    {isEmpty(PURCHASES_ROWS) && (
                      <Typography variant="body2">
                        Aún no tienes compras registradas
                      </Typography>
                    )}
                    {!isEmpty(PURCHASES_ROWS) && (
                      <WellnubTable
                        headers={PURCHASES_HEADERS}
                        rows={PURCHASES_ROWS}
                        variant="secondary"
                      />
                    )}
                  </Box>
                </Box>
              )}
            </Box>
          </Route>
        </Switch>
      </Box>
    </Box>
  );
};

export default ActivityPage;
