import {
  Box,
  CircularProgress,
  Container,
  Divider,
  Typography,
} from '@material-ui/core';

import { CreditCardForm } from 'components';

import { useCards } from 'hooks/payments.hook';

const PaymentsPage = () => {
  const { data: cards, isLoading: isLoadingCards } = useCards();

  if (isLoadingCards) {
    return (
      <Box pt={4} display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="lg">
      <Typography variant="h2">Tarjetas</Typography>
      <Box pt={4}>
        <Typography>Mis tarjetas</Typography>
        {cards.data.map(card => (
          <Typography>
            **** {card.last4} - {card.brand}{' '}
            {card.default ? ' - predeterminada' : null}
          </Typography>
        ))}
      </Box>
      <Box pt={4}>
        <CreditCardForm />
      </Box>
      <br />
      <Divider />
      <br />
      <br />
      <Typography variant="h2">Pagos</Typography>
      <Box>
        <Typography>Aquí va la información de paquetes</Typography>
      </Box>
    </Container>
  );
};

export default PaymentsPage;
