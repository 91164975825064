import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  card: {
    textAlign: 'center',
    padding: theme.spacing(2),
    border: '1px solid #e2e2e1',
    boxShadow: '0 1px 2px #c2c2c2',
    transition: 'all linear 150ms',
    '&:hover': {
      cursor: 'pointer',
      boxShadow: '0 3px 5px #9e9d9d',
    },
  },
  header: {
    padding: theme.spacing(1),
  },
  title: {
    textTransform: 'uppercase',
    color: '#1A7992',
  },
  price: {
    fontSize: '2rem',
    color: '#1A7992',
  },
  small: {
    fontSize: '14px',
  },
  list: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: theme.spacing(1),
  },
  benefit: {
    paddingLeft: theme.spacing(1),
  },
  message: {
    color: '#1A7992',
    margin: theme.spacing(1, 0),
  },
  asLink: {
    textDecoration: 'underline',
    color: '#1A7992',
    cursor: 'pointer',
  },
}));
