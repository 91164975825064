import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
  },
  title: {
    textAlign: 'center',
    textTransform: 'uppercase',
    color: theme.palette.action.main,
  },
  button: {
    backgroundColor: '#377990',
    '&:disabled': {
      backgroundColor: '#c2c2c2',
    },
    '&:hover': {
      backgroundColor: '#1a4758',
    },
  },
  formControl: {
    width: '100%',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  mt: {},
}));
