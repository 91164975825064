import { batch, useDispatch } from 'react-redux';
import { useQuery } from 'react-query';

import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';

import {
  getSpotDetails,
  getInbodyLocations,
  requestGetPrices,
} from 'network/services/inbody.service';

import { setDetails, setInbodyBundles } from 'redux/slices/inbodyPasses.slice';
import {
  setActiveTime,
  setAppointmentScheduleDate,
  setAppointmentScheduleTime,
  setOpeningTimes,
  setSpotList,
  setSpotSchedule,
} from 'redux/slices/spot.slice';
import { compareAsc } from 'date-fns';

// ---- TRAE EL LISTADO DE LOCACIONES INBODY -----
export const useGetInbodyLocations = () => {
  const dispatch = useDispatch();

  return useQuery('GET_LOCATIONS', getInbodyLocations, {
    onSuccess: data => {
      dispatch(setSpotList(data));
    },
  });
};

// ---- TRAE LOS PRECIOS DE PASES INOBDY ----
export const useInbodyPrices = () => {
  const dispatch = useDispatch();

  return useQuery('GET_PRICES', requestGetPrices, {
    onSuccess: ({ data }) => {
      batch(() => {
        dispatch(
          setDetails({
            type: 'INDIVIDUAL',
          }),
        );
        dispatch(setInbodyBundles(data));
      });
    },
  });
};

// ---- TRAE EL DETALLE DEL HORARIO DEL SPOT ----
export const useGetSpotDetails = id => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  return useQuery(['GET_SPOT_DETAIL', id], () => getSpotDetails(id), {
    enabled: false,
    onSuccess: response => {
      const selected = response.schedules
        .filter(
          item =>
            !isEmpty(item.morning) ||
            !isEmpty(item.afternoon) ||
            !isEmpty(item.night),
        )
        .sort((a, b) => compareAsc(new Date(a.date), new Date(b.date)))[0];
      const active =
        [
          ...(selected?.morning ?? []),
          ...(selected?.afternoon ?? []),
          ...(selected?.night ?? []),
        ].find(item => item.isAvailable) || {};
      batch(() => {
        dispatch(setAppointmentScheduleDate(selected?.date ?? null));
        dispatch(setAppointmentScheduleTime(active?.time ?? null));
        dispatch(setActiveTime({ ...active, isSelected: true }));
        dispatch(setSpotSchedule(response));
        dispatch(
          setOpeningTimes({
            morning:
              selected?.morning?.map(item => ({
                ...item,
                isSelected: false,
              })) ?? [],
            afternoon:
              selected?.afternoon?.map(item => ({
                ...item,
                isSelected: false,
              })) ?? [],
            night:
              selected?.night?.map(item => ({ ...item, isSelected: false })) ??
              [],
          }),
        );
      });
    },
    onError: () => {
      enqueueSnackbar('No se pudo realizar la operación, intente más tarde', {
        variant: 'error',
      });
    },
  });
};
