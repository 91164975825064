import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  row: {
    '&:nth-child(odd)': {
      backgroundColor: '#e3e3e3',
    },
    '&:nth-child(even)': {
      backgroundColor: '#a9a9a9',
    },
  },
  cardNumber: {
    paddingLeft: theme.spacing(1),
  },
  cardLogo: {
    maxWidth: 45,
  },
}));
