import React from 'react';
import PropTypes from 'prop-types';

import { CardMedia, Box } from '@material-ui/core';

import { useStyles } from './LandingImage.styles';

const LandingImage = ({ image, title, children }) => {
  const classes = useStyles();

  return (
    <Box component="article" className={classes.card}>
      <CardMedia
        component="img"
        image={image}
        title={title}
        className={classes.image}
      />
      {children}
    </Box>
  );
};

LandingImage.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
};

export default LandingImage;
