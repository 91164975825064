import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  title: {
    textTransform: 'uppercase',
    textAlign: 'center',
    paddingBottom: theme.spacing(1),
  },
  card: {
    padding: theme.spacing(2, 3),
    border: `1px solid ${theme.palette.action.main}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.secondary.main,
  },
  mb: {
    marginBottom: theme.spacing(1),
  },
  text: {
    color: theme.palette.primary.main,
  },
}));
