import { SET_VWODS } from './constants';

const vwodsReducer = (state = [], action) => {
  if (action.type === SET_VWODS) {
    return action.payload;
  }
  return state;
};

export default vwodsReducer;
