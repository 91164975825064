import { Box, Button, Typography, useMediaQuery } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { nanoid } from '@reduxjs/toolkit';
import { useDrawer } from 'hooks/drawer.hook';
import { DRAWERS } from 'utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveBundle, setDetails } from 'redux/slices/inbodyPasses.slice';
import { useStyles } from './PriceCard.styles';
import { useLocation } from 'react-router-dom';
import { setModalStatus } from '../../../redux/slices/modal.slice';

const PriceCard = ({ promo }) => {
  const { subscription } = useSelector(state => state.payments);

  const isResponsive = useMediaQuery(theme => theme.breakpoints.down('xs'));

  const { handleOpen: openCheckoutDrawer } = useDrawer(DRAWERS.checkout);

  const { pathname } = useLocation();

  const dispatch = useDispatch();

  const classes = useStyles();

  const onClickHandler = () => {
    const { type } = promo;
    if (type === 'passSubscription') {
      dispatch(setDetails({ type: 'SUBSCRIPTION' }));
    } else {
      dispatch(setDetails({ type: 'INDIVIDUAL' }));
    }
    dispatch(setActiveBundle(promo));
    openCheckoutDrawer();
  };

  const onCancelSubscriptionHandler = () => {
    dispatch(
      setModalStatus({
        name: 'CANCEL_PAYMENT_SUBSCRIPTION',
        isActive: true,
      }),
    );
  };

  return (
    <article
      className={classes.card}
      onClick={
        pathname === '/payment-subscriptions' &&
        subscription?.storeProduct?._id === promo._id
          ? onCancelSubscriptionHandler
          : onClickHandler
      }
    >
      <header className={classes.header}>
        <Typography variant="h5" className={classes.title}>
          {promo.name}
        </Typography>
      </header>
      <Typography className={classes.price}>
        <small>$</small>
        {promo.price.total}
        <span className={classes.small}>
          {' '}
          {
            {
              'Pase InBody': 'por pase',
              'Programa Trimestral': 'por trimestre',
              'Programa Mensual': 'por mes',
            }[promo.name]
          }
        </span>
      </Typography>
      <ul className={classes.list}>
        {promo.benefits.map(benefit => (
          <li className={classes.listItem} key={nanoid()}>
            <CheckIcon fontSize="small" />
            <Typography variant="body2" className={classes.benefit}>
              {benefit}
            </Typography>
          </li>
        ))}
      </ul>
      {pathname === '/payment-subscriptions' &&
        subscription?.storeProduct?._id === promo._id && (
          <Box>
            <Typography variant="body2" className={classes.message}>
              Estás suscrito a este programa
            </Typography>
            <Typography variant="body2" className={classes.asLink}>
              Cancelar
            </Typography>
          </Box>
        )}
      {isResponsive && (
        <Box pt={2}>
          <Button variant="outlined" color="primary" onClick={onClickHandler}>
            Seleccionar
          </Button>
        </Box>
      )}
    </article>
  );
};

export default PriceCard;
