export const typography = {
  fontFamily: "'Open Sans', 'Helvetica', 'sans-serif'",
  h1: {
    fontFamily: "'Montserrat', 'sans-serif'",
    fontSize: 34,
    fontWeight: 700,
    '@media (max-width:600px)': {
      fontSize: 22,
    },
  },
  h2: {
    fontFamily: "'Montserrat', 'sans-serif'",
    fontSize: 28,
    fontWeight: 700,
    '@media (max-width:600px)': {
      fontSize: 20,
    },
  },
  h3: {
    fontFamily: "'Montserrat', 'sans-serif'",
    fontSize: 20,
    fontWeight: 700,
    '@media (max-width:600px)': {
      fontSize: 18,
    },
  },
  h4: {
    fontFamily: "'Montserrat', 'sans-serif'",
    fontSize: 18,
    fontWeight: 700,
    '@media (max-width:960px)': {
      fontSize: 16,
    },
  },
  h5: {
    fontFamily: "'Montserrat', 'sans-serif'",
    fontSize: 16,
    fontWeight: 700,
  },
  h6: {
    fontFamily: "'Montserrat', 'sans-serif'",
    fontSize: 14,
    fontWeight: 700,
  },
  body1: {
    fontSize: 16,
    fontWeight: 400,
    letterSpacing: 0.1,
    lineHeight: 1.6,
    '@media (max-width:960px)': {
      fontSize: 15,
    },
    '@media (max-width:600px)': {
      fontSize: 14,
    },
  },
  button: {
    fontFamily: "'Open Sans', 'sans-serif'",
    fontSize: 14,
    fontWeight: 400,
  },
};
