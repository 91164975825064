import { SET_BEHAVIOR } from './constants';

const initialState = {
  SIGN_UP: false,
  SIGN_IN: false,
  GET_IN_TOUCH: false,
};

const modalBehaviorsReducer = (state = initialState, { type, payload }) => {
  if (type === SET_BEHAVIOR) {
    return {
      ...state,
      [payload.name]: payload.isVisible,
    };
  }
  return state;
};

export default modalBehaviorsReducer;
