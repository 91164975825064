import React, { useEffect, useState } from 'react';

import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

import {
  Typography,
  Backdrop,
  Box,
  CircularProgress,
  IconButton,
  useMediaQuery,
} from '@material-ui/core';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import { useStyles } from './PDFViewer.styles';

const WellnubPDFViewer = ({ url }) => {
  const [totalPages, setTotalPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [isZoomed, setIsZoomed] = useState(false);

  const isResponsive = useMediaQuery(theme => theme.breakpoints.down('xs'));

  const classes = useStyles();

  const onLoadSuccessHandler = ({ numPages }) => {
    setTotalPages(numPages);
  };

  const onZoomInHandler = () => {
    setIsZoomed(true);
  };

  const onZoomOutHandler = () => {
    setIsZoomed(false);
  };

  const onNextPageHandler = () => {
    setPageNumber(prevState => prevState + 1);
  };

  const onPreviousPageHandler = () => {
    setPageNumber(prevState => prevState - 1);
  };

  useEffect(() => {
    return () => {
      setPageNumber(1);
    };
  }, [url]);

  return (
    <>
      <Box>
        <Box className={classes.controls}>
          {pageNumber > 1 && (
            <IconButton onClick={onPreviousPageHandler}>
              <NavigateBeforeIcon
                fontSize="small"
                className={classes.navigateButton}
              />
            </IconButton>
          )}
          <Typography variant="body2">
            Página {pageNumber} de {totalPages}
          </Typography>
          {pageNumber < totalPages && (
            <IconButton onClick={onNextPageHandler}>
              <NavigateNextIcon
                fontSize="small"
                className={classes.navigateButton}
              />
            </IconButton>
          )}
        </Box>
        <Box className={classes.canvas}>
          <Document
            file={url}
            onClick={onZoomInHandler}
            onLoadSuccess={onLoadSuccessHandler}
            loading={<CircularProgress />}
          >
            <Page width={isResponsive ? 420 : 800} pageNumber={pageNumber} />
          </Document>
        </Box>
      </Box>
      <Backdrop
        className={classes.backdrop}
        open={isZoomed}
        onClick={onZoomOutHandler}
      >
        <Document file={url}>
          <Page pageNumber={pageNumber} scale={1.5} />
        </Document>
      </Backdrop>
    </>
  );
};

export default WellnubPDFViewer;
