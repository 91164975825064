import React, { useEffect } from 'react';

import { Helmet } from 'react-helmet';

import { useDispatch, useSelector } from 'react-redux';

import { makeStyles, Container, Typography, Box } from '@material-ui/core';

import { PROGRAM_TYPES } from 'utils/constants';

import wellnubAPI from 'utils/network/wellnub.api';

import { setVwods } from 'redux/vwods/actions';

import { ProgramList } from 'components/organisms';

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  heading: {
    textAlign: 'center',
    textTransform: 'uppercase',
    color: theme.palette.action.main,
  },
  description: {
    paddingTop: theme.spacing(2),
    textAlign: 'center',
  },
}));

const Vwods = () => {
  const vwods = useSelector(state => state.vwods);

  const dispatch = useDispatch();

  const classes = useStyles();

  const fetchPrograms = async () => {
    const { data } = await wellnubAPI.programs.list();
    const temp = data.filter(
      program => program.typeService === PROGRAM_TYPES.VWOD,
    );
    dispatch(setVwods(temp));
  };

  useEffect(() => {
    fetchPrograms().catch(error => alert(error));
  }, []);

  return (
    <Container className={classes.container}>
      <Helmet>
        <meta
          name="description"
          content="Videos de entrenamientos para que entrenes donde sea y cuando quieras"
        />
        <title>Wellnub | Entrenamientos on demand</title>
      </Helmet>
      <Typography variant="h1" className={classes.heading}>
        VWODS
      </Typography>
      <Typography className={classes.description}>
        Videos de entrenamientos para que entrenes donde sea y cuando quieras
      </Typography>
      <Box pt={4} component="section">
        <ProgramList programs={vwods} />
      </Box>
    </Container>
  );
};

export default Vwods;
