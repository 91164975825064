import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  image: {
    position: 'relative',
  },
  content: {
    position: 'absolute',
    width: '75%',
    padding: theme.spacing(2),
    top: '20%',
    right: 20,
    borderRadius: theme.shape.borderRadius,
  },
}));
