import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(4),
    maxWidth: 500,
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.light,
  },
  title: {
    textAlign: 'center',
  },
  subtitle: {
    paddingTop: theme.spacing(2),
    textAlign: 'center',
  },
  mainTitle: {
    paddingLeft: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
    },
  },
  dashed: {
    padding: theme.spacing(2),
    textAlign: 'center',
    borderRadius: theme.shape.borderRadius,
    border: `1px dashed ${theme.palette.action.main}`,
  },
}));
