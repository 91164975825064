import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  paper: {
    backgroundColor: theme.palette.gray.dark,
  },
  asLink: {
    cursor: 'pointer',
  },
  link: {
    fontSize: 14,
  },
  mr: {
    marginRight: theme.spacing(2),
  },
}));
