import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  title: {
    textTransform: 'uppercase',
    marginBottom: theme.spacing(1),
    color: 'black',
    textAlign: 'center',
  },
  contentWrapper: {
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
}));
