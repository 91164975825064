import React from 'react';

import { Carousel } from 'react-responsive-carousel';

import 'react-responsive-carousel/lib/styles/carousel.min.css';

const WellnubCarousel = ({ children }) => {
  return (
    <Carousel centerMode showArrow infiniteLoop centerSlidePercentage={80}>
      {children}
    </Carousel>
  );
};

export default WellnubCarousel;
