import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: {},
};

const wnUserSlice = createSlice({
  name: 'wnUser',
  initialState,
  reducers: {
    setWnUserData: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setWnUserData } = wnUserSlice.actions;
export const WnUserReducer = wnUserSlice.reducer;