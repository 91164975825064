import { Box, Button, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { setModalStatus } from 'redux/slices/modal.slice';
import { useCancelAppointmentSendedByNutritionist } from 'hooks/user.hook';

const CancelAppointmentWithNutritionistWarningPanel = () => {
  const { data: appointment } = useSelector(
    state => state.nutritionistAppointment,
  );

  const { mutate: cancelAppointment, isLoading } =
    useCancelAppointmentSendedByNutritionist();

  const dispatch = useDispatch();

  const onAcceptHandler = () => {
    cancelAppointment(appointment._id);
  };

  const onCancelHandler = () => {
    dispatch(
      setModalStatus({
        name: 'CANCEL_APPOINTMENT_WITH_NUTRITIONIST_WARNING',
        isActive: false,
      }),
    );
  };

  return (
    <Box>
      <Typography align="center" variant="h6">
        Cancelación consulta de tu nutriólogo
      </Typography>
      <Typography align="center">
        ¿Estás seguro que deseas cancelar tu consulta?
      </Typography>
      <Box
        mt={2}
        display="flex"
        alignItems="center"
        justifyContent="space-evenly"
      >
        <Button
          variant="outlined"
          color="primary"
          onClick={onAcceptHandler}
          disabled={isLoading}
        >
          Si, deseo cancelar
        </Button>
        <Button
          variant="contained"
          onClick={onCancelHandler}
          disabled={isLoading}
        >
          No, continuar con mi cita
        </Button>
      </Box>
    </Box>
  );
};

export default CancelAppointmentWithNutritionistWarningPanel;
