export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
export const DAEMON = '@@saga-injector/daemon';
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';

export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const PROGRAM_TYPES = {
  LIVE: 'live-classes',
  VWOD: 'recorded-trainings',
  CUSTOM: 'custom-trainings',
};

export const ALERT_TYPES = {
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
  INFO: 'info',
};

export const MODAL_NAMES = {
  SIGN_UP: 'SIGN_UP',
  SIGN_IN: 'SIGN_IN',
  GET_IN_TOUCH: 'GET_IN_TOUCH',
};

export const DRAWERS = {
  appoinment: 'appoinment',
  addCard: 'addCard',
  checkout: 'checkout',
  indications: 'indications',
  schedule: 'schedule',
  paymentSubscription: 'paymentSubscription',
  nutritionistAppointment: 'nutritionistAppointment',
  appointmentConfirmation: 'appointmentConfirmation',
};

export const DAY_NAMES = {
  monday: 'Lunes',
  tuesday: 'Martes',
  wednesday: 'Miércoles',
  thursday: 'Jueves',
  friday: 'Viernes',
  saturday: 'Sábado',
  sunday: 'Domingo',
};

export const FITNESS_LEVELS = {
  beginner: 'Principiante',
  intermediate: 'Intermedio',
  advanced: 'Avanzado',
};

export const DISCIPLINES = {
  martialArts: 'Artes marciales',
  dance: 'Danza',
  barre: 'Barre',
  box: 'Box',
  calistenia: 'Calistenia',
  functionalTraining: 'Funcional',
  hiit: 'Hiit',
  pesas: 'Pesas',
  pilates: 'Pilates',
  yoga: 'Yoga',
  zumba: 'Zumba',
};

export const BENEFITS = {
  agility: 'Agilidad',
  lowImpact: 'Bajo impacto',
  cardiovascular: 'Cardiovascular',
  strength: 'Fuerza',
  muscle: 'Músculo',
  lossFat: 'Pérdida de grasa',
  prenatal: 'Pernatal',
  resistance: 'Resistencia',
  withoutMaterial: 'Sin material',
};

export const PAYPAL_CLIENT_ID =
  process.env.NODE_ENV === 'production'
    ? 'Ae0JWnb8lBzDMefOZCMWVC5J7B1h8n3dmZ6mYJcEc6nzHome4hYUcPdOdTY_1I5yHq3u9OdMH-hwVDRi'
    : 'AQNpKzBdCpbSh5bkz4WpKRRcz_ehtgrFg3dIfGXaXdI-nzlbUn-bkOcxPJXrIQAFhBL9sUP1iDdI_NKJ';

export const YEARS = [
  { name: '2023', value: '2023' },
  { name: '2024', value: '2024' },
  { name: '2025', value: '2025' },
  { name: '2026', value: '2026' },
  { name: '2027', value: '2027' },
  { name: '2028', value: '2028' },
  { name: '2029', value: '2029' },
  { name: '2030', value: '2030' },
  { name: '2031', value: '2031' },
  { name: '2032', value: '2032' },
  { name: '2033', value: '2033' },
  { name: '2034', value: '2034' },
  { name: '2035', value: '2035' },
];

export const MONTHS = [
  { name: 'Enero', value: '01' },
  { name: 'Febrero', value: '02' },
  { name: 'Marzo', value: '03' },
  { name: 'Abril', value: '04' },
  { name: 'Mayo', value: '05' },
  { name: 'Junio', value: '06' },
  { name: 'Julio', value: '07' },
  { name: 'Agosto', value: '08' },
  { name: 'Septiembre', value: '09' },
  { name: 'Octubre', value: '10' },
  { name: 'Noviembre', value: '11' },
  { name: 'Diciembre', value: '12' },
];

export const DAYS = {
  0: 'Domingo',
  1: 'Lunes',
  2: 'Martes',
  3: 'Miércoles',
  4: 'Jueves',
  5: 'Viernes',
  6: 'Sábado',
};

export const NUTRITIONISTS_FILTERS = {
  fitnessGoals: {
    name: 'Complementar mi proceso fitness',
    options: [],
  },
  goodLooking: {
    name: 'Verme o sentirme mejor',
    options: [
      {
        name: 'Control de peso',
        value: 'weight-control',
        specialties: [
          {
            name: 'Control de peso',
            key: 'weigth-control',
            type: 'keySpecialities',
          },
          {
            name: 'Aumento de masa',
            key: 'muscle-gain',
            type: 'keySpecialities',
          },
        ],
      },
      {
        name: 'Hábitos saludables',
        value: 'healty-habits',
        specialties: [
          {
            name: 'Nutriólogo general',
            key: 'general',
            type: 'nutriTypes',
          },
          {
            name: 'Hábitos saludables',
            key: 'healty-habits',
            type: 'keySpecialities',
          },
          {
            name: 'Health coaching',
            key: 'health-coaching',
            type: 'keySpecialities',
          },
        ],
      },
      {
        name: 'Psicología de los alimentos',
        value: 'food-psicology',
        specialties: [
          {
            name: 'Psicología de los alimentos',
            key: 'food-psicology',
            type: 'keySpecialities',
          },
          {
            name: 'Bulímia, anorexia',
            key: 'food-desorders',
            type: 'keySpecialities',
          },
        ],
      },
      {
        name: 'Desordenes alimenticios',
        value: 'food-desorders',
        specialties: [
          {
            name: 'Psicología de los alimentos',
            key: 'food-psicology',
            type: 'keySpecialities',
          },
          {
            name: 'Bulímia, anorexia',
            key: 'food-desorders',
            type: 'keySpecialities',
          },
        ],
      },
      {
        name: 'Cambiar mi tipo de dieta por razones éticas/filosofía',
        value: 'etical-changes',
        specialties: [],
      },
    ],
  },
  revertSyntoms: {
    name: 'Revertir o mejorar mis síntomas',
    options: [
      {
        name: 'Digestivos',
        value: 'digestive',
        specialties: [
          {
            name: 'Nutriólogo general',
            key: 'general',
            type: 'nutriTypes',
          },
          {
            name: 'Problemas gastrointestinales',
            key: 'gastro-issues',
            type: 'keySpecialities',
          },
          {
            name: 'Medicina funcional',
            key: 'functional-medicine',
            type: 'keySpecialities',
          },
        ],
      },
      {
        name: 'Hormonales',
        value: 'hormonal',
        specialties: [
          {
            name: 'Enfermedades tiroideas',
            key: 'thyroid',
            type: 'keySpecialities',
          },
          {
            name: 'Balance hormonal',
            key: 'hormonal-balance',
            type: 'keySpecialities',
          },
          {
            name: 'Medicina funcional',
            key: 'functional-medicine',
            type: 'keySpecialities',
          },
        ],
      },
      {
        name: 'Dermatológicos',
        value: 'dermatology',
        specialties: [
          {
            name: 'Dermatología',
            key: 'dermatology',
            type: 'keySpecialities',
          },
          {
            name: 'Alergias / intolerancias alimenticias',
            key: 'alergies',
            type: 'keySpecialities',
          },
        ],
      },
      {
        name: 'Alergias y/o intolerancias alimenticias',
        value: 'alergies',
        specialties: [
          {
            name: 'Alergias / intolerancias alimenticias',
            key: 'alergies',
            type: 'keySpecialities',
          },
          {
            name: 'Problemas gastrointestinales',
            key: 'gastro-issues',
            type: 'keySpecialities',
          },
          {
            name: 'Medicina funcional',
            key: 'functional-medicine',
            type: 'keySpecialities',
          },
        ],
      },
      {
        name: 'Hipertensión',
        value: 'hipertension',
        specialties: [
          {
            name: 'Nutriólogo clínico',
            key: 'clinical-nutrition',
            type: 'nutriTypes',
          },
          {
            name: 'Hipertensión',
            key: 'hipertension',
            type: 'keySpecialities',
          },
          {
            name: 'Medicina funcional',
            key: 'functional-medicine',
            type: 'keySpecialities',
          },
        ],
      },
      {
        name: 'Desordenes alimenticios',
        value: 'food-desorders',
        specialties: [
          {
            name: 'Psicología de los alimentos',
            key: 'food-psicology',
            type: 'keySpecialities',
          },
          {
            name: 'Bulímia, anorexia',
            key: 'food-desorders',
            type: 'keySpecialities',
          },
        ],
      },
      {
        name: 'Sistema inmunológico',
        value: 'immune',
        specialties: [
          {
            name: 'Inmonutrición',
            key: 'inmunonutrition',
            type: 'keySpecialities',
          },
          {
            name: 'Nutriólogo clínico',
            key: 'clinical-nutrition',
            type: 'nutriTypes',
          },
        ],
      },
      {
        name: 'COVID-19',
        value: 'covid-19',
        specialties: [
          { name: 'COVID-19', key: 'covid-19', type: 'keySpecialities' },
          {
            name: 'Inmonutrición',
            key: 'inmunonutrition',
            type: 'keySpecialities',
          },
          {
            name: 'Nutriólogo clínico',
            key: 'clinical-nutrition',
            type: 'nutriTypes',
          },
        ],
      },
    ],
  },
  medicalTreatment: {
    name: 'Complementar mi tratamiento médico',
    options: [
      {
        name: 'Diabetes',
        value: 'diabetes',
        specialties: [
          {
            name: 'Educador en diabetes',
            key: 'diabetes-education',
            type: 'keySpecialities',
          },
          {
            name: 'Nutriólogo clínico',
            key: 'clinical-nutrition',
            type: 'nutriTypes',
          },
        ],
      },
      {
        name: 'Desnutrición/anemia',
        value: 'malnutrition',
        specialties: [
          {
            name: 'Nutriólogo clínico',
            key: 'clinical-nutrition',
            type: 'nutriTypes',
          },
        ],
      },
      {
        name: 'Cáncer/oncología',
        value: 'cancer',
        specialties: [
          {
            name: 'Cáncer y oncología',
            key: 'cancer-oncology',
            type: 'keySpecialities',
          },
          {
            name: 'Nutriólogo clínico',
            key: 'clinical-nutrition',
            type: 'nutriTypes',
          },
        ],
      },
      {
        name: 'Riñones/enfermedad renal',
        value: 'renal',
        specialties: [
          {
            name: 'Enfermedad renal',
            key: 'renal-desease',
            type: 'keySpecialities',
          },
          {
            name: 'Nutriólogo clínico',
            key: 'clinical-nutrition',
            type: 'nutriTypes',
          },
        ],
      },
      {
        name: 'Hígado/enfermedad hepática',
        value: 'hepatic',
        specialties: [
          {
            name: 'Enfermedad hepática',
            key: 'hepatic-desease',
            type: 'keySpecialities',
          },
          {
            name: 'Alergias/intolerancias alimenticias',
            key: 'alergies',
            type: 'keySpecialities',
          },
        ],
      },
      {
        name: 'Corazón/cardiología',
        value: 'cardiology',
        specialties: [
          {
            name: 'Enfermedad cardiaca',
            key: 'cardiac-desease',
            type: 'keySpecialities',
          },
          {
            name: 'Nutriólogo clínico',
            key: 'clinical-nutrition',
            type: 'nutriTypes',
          },
        ],
      },
      {
        name: 'Cirugía bariátrica',
        value: 'bariatic',
        specialties: [
          {
            name: 'Cirugía bariátrica',
            key: 'bariatric-surgery',
            type: 'keySpecialities',
          },
        ],
      },
      {
        name: 'Gastrointestinal',
        value: 'gastro',
        specialties: [
          {
            name: 'Problemas gastrointestinales',
            key: 'gastro-issues',
            type: 'keySpecialities',
          },
          {
            name: 'Medicina funcional',
            key: 'functional-medicine',
            type: 'keySpecialities',
          },
        ],
      },
      {
        name: 'COVID-19',
        value: 'covid-19',
        specialties: [
          { name: 'COVID-19', key: 'covid-19', type: 'keySpecialities' },
          {
            name: 'Inmunonutrición',
            key: 'inmunonutrition',
            type: 'keySpecialities',
          },
          {
            name: 'Nutriólogo clínico',
            key: 'clinical-nutrition',
            type: 'nutriTypes',
          },
        ],
      },
      {
        name: 'Dermatológicos',
        value: 'dermatologic',
        specialties: [
          {
            name: 'Dermatología',
            key: 'dermatology',
            type: 'keySpecialities',
          },
          {
            name: 'Medicina funcional',
            key: 'functional-medicine',
            type: 'keySpecialities',
          },
        ],
      },
      {
        name: 'Hormonal',
        value: 'hormonal',
        specialties: [
          {
            name: 'Balance hormonal/endocrino',
            key: 'hormonal-balance',
            type: 'keySpecialities',
          },
          {
            name: 'Enfermedades tiroideas',
            key: 'thyroid',
            type: 'keySpecialities',
          },
          {
            name: 'Síndrome de ovario poliquístico (SOP)',
            key: 'sop',
            type: 'keySpecialities',
          },
        ],
      },
      {
        name: 'Reumatología',
        value: 'reumatologic',
        specialties: [
          {
            name: 'Reumatología',
            key: 'reaumatology',
            type: 'keySpecialities',
          },
          {
            name: 'Nutriólogo clínico',
            key: 'clinical-nutrition',
            type: 'nutriTypes',
          },
        ],
      },
      {
        name: 'Terapia intensiva',
        value: 'therapyIntensive',
        specialties: [
          {
            name: 'Terapia intensiva',
            key: 'intensive-care',
            type: 'keySpecialities',
          },
          {
            name: 'Nutriólogo clínico',
            key: 'clinical-nutrition',
            type: 'nutriTypes',
          },
        ],
      },
      {
        name: 'Nutrición entenal',
        value: 'etenalNutrition',
        specialties: [
          {
            name: 'Nutrición entenal',
            key: 'enteral-nutrition',
            type: 'keySpecialities',
          },
          {
            name: 'Nutriólogo clínico',
            key: 'clinical-nutrition',
            type: 'nutriTypes',
          },
        ],
      },
      {
        name: 'Nutrición parenteral',
        value: 'prenatal',
        specialties: [
          {
            name: 'Nutrición parenteral',
            key: 'parenteral-nutrition',
            type: 'keySpecialities',
          },
          {
            name: 'Nutriólogo clínico',
            key: 'clinical-nutrition',
            type: 'nutriTypes',
          },
        ],
      },
    ],
  },
  olderPeople: {
    name: 'Nutrición del adulto mayor',
    options: [],
    extra: [
      {
        name: 'Tercera edad/geriatría',
        key: 'old-geriatry',
        type: 'keySpecialities',
      },
    ],
  },
  pregnancy: {
    name: 'Nutrición en embarazo y/o lactancia',
    options: [],
    extra: [
      {
        name: 'Embarazo y lactancia',
        key: 'pregnancy-breast-feed',
        type: 'keySpecialities',
      },
    ],
  },
  corporate: {
    name: 'Programas para empresas/corporativos',
    options: [],
  },
  baby: {
    name: 'Nutrición del bebé o niño',
    options: [],
    extra: [
      {
        name: 'Pediatría',
        key: 'pedatry',
        type: 'keySpecialities',
      },
      {
        name: 'Baby-led weaning',
        key: 'baby-weaning',
        type: 'keySpecialities',
      },
      {
        name: 'Embarazo y lactancia',
        key: 'pregnancy-breast-feed',
        type: 'keySpecialities',
      },
    ],
  },
};
