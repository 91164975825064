import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  card: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: ({ isActive }) => (isActive ? 'white' : 'black'),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: ({ isActive }) => (isActive ? '#293241' : '#c2c2c2'),
    '&:hover': {
      cursor: 'pointer',
    },
  },
  ellipsis: {
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  title: {
    textTransform: 'uppercase',
  },
  titleWrapper: {
    paddingBottom: 4,
  },
  address: {
    height: 60,
  },
  link: {
    cursor: 'pointer',
    color: theme.palette.action.main,
  },
}));
