import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: {},
  list: [],
  appointment: {},
  isScheduleVisible: false,
  schedule: {},
  isLoadingScheduleData: false,
  openingTimes: {
    morning: [],
    afternoon: [],
    night: [],
  },
  activeTime: {},
  appointmentSchedule: {
    date: null,
    time: null,
  },
  appointmentMode: 'NORMAL',
};

export const spotSlice = createSlice({
  name: 'spot',
  initialState,
  reducers: {
    setSpotData: (state, action) => {
      state.data = action.payload;
    },
    setSpotList: (state, action) => {
      state.list = action.payload;
    },
    setSpotAppointment: (state, action) => {
      state.appointment = action.payload;
    },
    setScheduleVisibility: (state, action) => {
      state.isScheduleVisible = action.payload;
    },
    setSpotSchedule: (state, action) => {
      state.schedule = action.payload;
    },
    setIsLoadingScheduleData: (state, action) => {
      state.isLoadingScheduleData = action.payload;
    },
    setOpeningTimes: (state, action) => {
      state.openingTimes.morning = action.payload.morning;
      state.openingTimes.afternoon = action.payload.afternoon;
      state.openingTimes.night = action.payload.night;
    },
    setActiveTime: (state, action) => {
      state.activeTime = action.payload;
    },
    setAppointmentScheduleDate: (state, action) => {
      state.appointmentSchedule.date = action.payload;
    },
    setAppointmentScheduleTime: (state, action) => {
      state.appointmentSchedule.time = action.payload;
    },
    setAppointmentMode: (state, action) => {
      state.appointmentMode = action.payload;
    },
  },
});

export const {
  setSpotData,
  setSpotList,
  setSpotAppointment,
  setScheduleVisibility,
  setSpotSchedule,
  setIsLoadingScheduleData,
  setOpeningTimes,
  setActiveTime,
  setAppointmentScheduleDate,
  setAppointmentScheduleTime,
  setAppointmentMode,
} = spotSlice.actions;

export const spotReducer = spotSlice.reducer;
