import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { Menu, MenuItem, Typography, Link } from '@material-ui/core';

import { setModalBehavior } from 'redux/modalBehaviors/actions';

import { MODAL_NAMES } from 'utils/constants';

import { useStyles } from './SignUpDropdown.styles';

const SignUpDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [anchor, setAnchor] = useState(null);

  const dispatch = useDispatch();

  const classes = useStyles();

  const onOpenHandler = event => {
    setIsOpen(true);
    setAnchor(event.currentTarget);
  };

  const onCloseHandler = () => {
    setIsOpen(false);
  };

  const onSignUpClickHandler = () => {
    dispatch(
      setModalBehavior({
        name: MODAL_NAMES.SIGN_UP,
        isVisible: true,
      }),
    );
  };

  return (
    <>
      <Typography
        variant="body2"
        onClick={onOpenHandler}
        className={`${classes.asLink} ${classes.mr}`}
      >
        Registrarme
      </Typography>
      <Menu
        open={isOpen}
        anchorEl={anchor}
        classes={{ paper: classes.paper }}
        onClick={onCloseHandler}
        onClose={onCloseHandler}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      >
        <MenuItem
          classes={{ root: classes.link }}
          onClick={onSignUpClickHandler}
        >
          Como usuario
        </MenuItem>
        <MenuItem classes={{ root: classes.link }}>
          <Link
            to={{
              pathname: 'https://nutrition.wellnub.com/signUp',
            }}
            underline="none"
            component={RouterLink}
            target="_blank"
            rel="noopener noreferrer"
            color="inherit"
          >
            Como nutriólogo
          </Link>
        </MenuItem>
      </Menu>
    </>
  );
};

export default SignUpDropdown;
