import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { isEmpty } from 'lodash';

import { AppBar, Box, Tab, Tabs, Typography } from '@material-ui/core';

import SchedulePanel from '../../TabPanels/Schedules/SchedulesPanel.component';

import { useStyles } from './ScheduleDatePicker.styles';

const ScheduleDatePicker = () => {
  const [tab, setTab] = useState(0);

  const {
    openingTimes: { morning, afternoon, night },
  } = useSelector(state => state.spot);

  const classes = useStyles();

  const onTabChangeHandler = (e, value) => {
    setTab(value);
  };

  useEffect(() => {
    if (!isEmpty(morning)) {
      setTab(0);
    } else if (isEmpty(morning) && isEmpty(afternoon)) {
      setTab(2);
    } else {
      setTab(1);
    }
  }, [morning, afternoon, night]);

  return (
    <Box style={{ width: '100%' }}>
      {isEmpty(morning) && isEmpty(afternoon) && isEmpty(night) ? (
        <Typography variant="h6" style={{ textAlign: 'center' }}>
          Actualmente no existen horarios disponibles
        </Typography>
      ) : (
        <>
          <AppBar position="static" classes={{ root: classes.appBar }}>
            <Tabs
              variant="scrollable"
              value={tab}
              onChange={onTabChangeHandler}
              classes={{
                root: classes.tabs,
                scrollButtons: classes.scrollButtons,
              }}
              scrollButtons="auto"
              indicatorColor="primary"
            >
              <Tab
                label="Mañana"
                classes={{ root: classes.tab, selected: classes.selected }}
              />
              <Tab
                label="Tarde"
                classes={{ root: classes.tab, selected: classes.selected }}
              />
              <Tab
                label="Noche"
                classes={{ root: classes.tab, selected: classes.selected }}
              />
            </Tabs>
          </AppBar>
          <SchedulePanel value={0} tab={tab} times={morning} />
          <SchedulePanel value={1} tab={tab} times={afternoon} />
          <SchedulePanel value={2} tab={tab} times={night} />
        </>
      )}
    </Box>
  );
};

export default ScheduleDatePicker;
