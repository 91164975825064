import React, { useState } from 'react';

import Lightbox from 'react-awesome-lightbox';
import 'react-awesome-lightbox/build/style.css';

import { Box, CardMedia } from '@material-ui/core';

const WellnubImageViewer = ({ url }) => {
  const [isZoomed, setIsZoomed] = useState(false);

  const onOpenHandler = () => {
    setIsZoomed(true);
  };

  const onCloseHandler = () => {
    setIsZoomed(false);
  };

  return (
    <Box>
      <CardMedia component="img" image={url} onClick={onOpenHandler} />
      {isZoomed && <Lightbox image={url} onClose={onCloseHandler} />}
    </Box>
  );
};

export default WellnubImageViewer;
