import React from 'react';
import { useController } from 'react-hook-form';

import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';

const WellnubSelectInput = ({
  fullWidth,
  control,
  rules,
  name,
  label,
  defaultValue,
  options,
  variant,
  helperText,
  ...restProps
}) => {
  const {
    field: { ref, ...inputProps },
    fieldState: { error },
  } = useController({
    name,
    rules,
    control,
    defaultValue,
  });

  return (
    <FormControl
      variant={variant}
      fullWidth={fullWidth}
      error={Boolean(error)}
      {...restProps}
    >
      <InputLabel id={`select-label-${name}`}>{label}</InputLabel>
      <Select label={label} inputRef={ref} {...inputProps}>
        {options.map(option => (
          <MenuItem key={option.id} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{error?.message ?? helperText}</FormHelperText>
    </FormControl>
  );
};

export default WellnubSelectInput;
