import { useDispatch } from 'react-redux';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { useSnackbar } from 'notistack';

import {
  requestAddCard,
  requestCards,
  requestSetDefaultCard,
  requestDeleteCard,
  requestBuyPasses,
  requestValidateDiscountCode,
  requestGetSubscriptions,
  requestBuySubscription,
  requestCancelSubscription,
} from 'network/services/payments.service';

import { useDrawer } from './drawer.hook';

import { setModalStatus } from 'redux/slices/modal.slice';
import {
  addCard,
  setCards,
  setPaymentMethod,
  setPaymentSubscription,
} from 'redux/slices/payments.slice';

import { DRAWERS } from 'utils/constants';
import { useHistory } from 'react-router-dom';

// ---- TRAE EL LISTADO DE TARJETAS ----
export const useCards = () => {
  const dispatch = useDispatch();

  return useQuery('GET_CREDIT_CARDS', requestCards, {
    onSuccess: ({ data }) => {
      const selected = data.find(card => card.default) ?? {};
      dispatch(setCards(data));
      dispatch(setPaymentMethod(selected));
    },
  });
};

// ---- CREA UNA NUEVA TARJETA ----
export const useCreateCard = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { handleClose } = useDrawer(DRAWERS.addCard);

  return useMutation(requestAddCard, {
    onSuccess: ({ data }) => {
      dispatch(addCard(data));
      queryClient.invalidateQueries('GET_CREDIT_CARDS');
      enqueueSnackbar('Tarjeta añadida con éxito', {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar('No se pudo crear la tarjeta. Intente más tarde', {
        variant: 'error',
      });
    },
    onSettled: () => {
      handleClose();
    },
  });
};

// ---- ESTABLECE POR DEFECTO UNA TARJETA ----
export const useSetDefaultCard = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  return useMutation(requestSetDefaultCard, {
    onSuccess: () => {
      queryClient.invalidateQueries('GET_CREDIT_CARDS');
      enqueueSnackbar('Se cambió la forma de pago principal', {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar('No se pudo realizar la operación. Intenta más tarde', {
        variant: 'error',
      });
    },
    onSettled: () => {
      dispatch(
        setModalStatus({
          name: 'SET_DEFAULT_CARD',
          isActive: false,
        }),
      );
    },
  });
};

// ---- ELIMINA UNA TARJETA ----
export const useDeleteCard = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  return useMutation(requestDeleteCard, {
    onSuccess: () => {
      queryClient.invalidateQueries('GET_CREDIT_CARDS');
      enqueueSnackbar('Se eliminó correctamente la tarjeta', {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar('No se pudo realizar la operación. Intenta más tarde', {
        variant: 'error',
      });
    },
    onSettled: () => {
      dispatch(
        setModalStatus({
          name: 'DELETE_CARD',
          isActive: false,
        }),
      );
    },
  });
};

// ---- REALIZA LA COMPRA DE PASES ----
export const useBuyPasses = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { handleClose } = useDrawer(DRAWERS.checkout);

  return useMutation(requestBuyPasses, {
    onSuccess: () => {
      queryClient.invalidateQueries('GET_PASSES');
      enqueueSnackbar('Se realizó la compra con éxito', {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar('No se pudo realizar la transacción. Intenta más tarde', {
        variant: 'error',
      });
    },
    onSettled: () => {
      handleClose();
    },
  });
};

// ---- REALIZA LA VALIDACIÓN DE UN CÓDIGO PROMOCIONAL ----
export const useValidateDiscountCode = code => {
  return useQuery(
    'VALIDATE_DISCOUNT_CODE',
    () => requestValidateDiscountCode(code),
    {
      enabled: false,
    },
  );
};

// ---- TRAE LA LISTA DE SUSCRIPCIONES ACTIVAS ----
export const useGetPaymentSubscriptions = () => {
  const dispatch = useDispatch();

  return useQuery('GET_SUBSCRIPTIONS', requestGetSubscriptions, {
    onSuccess: data => {
      dispatch(setPaymentSubscription(data?.subscription));
    },
  });
};

// ---- REALIZA LA COMPRA DE SUSCRIPCION ----
export const useBuySubscription = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { handleClose } = useDrawer(DRAWERS.checkout);
  const { replace } = useHistory();

  return useMutation(requestBuySubscription, {
    onSuccess: () => {
      queryClient.invalidateQueries('GET_SUBSCRIPTIONS');
      enqueueSnackbar('Se realizó la compra de la suscripción con éxito', {
        variant: 'success',
      });
      replace('/dashboard/main');
    },
    onError: ({ response }) => {
      if (response.data.isPublic) {
        enqueueSnackbar(response.data.message, {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Algo salió mal, intenta más tarde', {
          variant: 'error',
        });
      }
    },
    onSettled: () => {
      handleClose();
    },
  });
};

// ---- REALIZA LA CANCELACIÓN DE SUSCRIPCION ----
export const useCancelSubscription = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  return useMutation(requestCancelSubscription, {
    onSuccess: () => {
      queryClient.invalidateQueries('GET_SUBSCRIPTIONS');
      enqueueSnackbar('Se realizó la cancelación de la suscripción con éxito', {
        variant: 'success',
      });
    },
    onError: ({ response }) => {
      if (response.data.isPublic) {
        enqueueSnackbar(response.data.message, {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Algo salió mal, intenta más tarde', {
          variant: 'error',
        });
      }
    },
    onSettled: () => {
      dispatch(
        setModalStatus({
          name: 'CANCEL_PAYMENT_SUBSCRIPTION',
          isActive: false,
        }),
      );
    },
  });
};
