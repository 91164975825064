import React from 'react';

import { useSelector } from 'react-redux';

import { MODAL_NAMES } from 'utils/constants';

import SignUpModal from './modals/SignUp.component';
import GetInTouchModal from './modals/GetInTouch.component';

const ModalSelector = () => {
  const { SIGN_UP, GET_IN_TOUCH } = MODAL_NAMES;

  const behaviors = useSelector(state => state.modalBehaviors);

  const activeModal = Object.keys(behaviors).find(
    modal => behaviors[modal] === true,
  );

  switch (activeModal) {
    case SIGN_UP:
      return <SignUpModal />;
    case GET_IN_TOUCH:
      return <GetInTouchModal />;
    default:
      return null;
  }
};

export default ModalSelector;
