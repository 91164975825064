import { ALERT_TYPES } from 'utils/constants';

import { showAlert } from 'redux/alert/actions';

import wellnubAPI from 'utils/network/wellnub.api';

export const requestEmail = payload => dispatch => {
  wellnubAPI.contact
    .post(payload)
    .then(response => {
      dispatch(
        showAlert({
          type: ALERT_TYPES.SUCCESS,
          title: 'Información Enviada',
          message: 'Pronto el coach se pondrá en contacto contigo',
        }),
      );
    })
    .catch(error => {
      dispatch(
        showAlert({
          type: ALERT_TYPES.ERROR,
          title: 'Error',
          message: error,
        }),
      );
    });
};
