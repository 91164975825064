import { useSelector } from 'react-redux';

import { Box, Grid, Typography } from '@material-ui/core';

import { isEmpty } from 'lodash';

import ChipTimePicker from '../../Pickers/ChipTime/ChipTimePicker.component';

import { useStyles } from './SchedulesPanel.styles';

const SchedulePanel = ({ times, value, tab }) => {
  const { activeTime } = useSelector(state => state.spot);

  const classes = useStyles();

  if (value === tab)
    return (
      <Box component="section" className={classes.contentWrapper}>
        {isEmpty(times) && (
          <Box textAlign="center">
            <Typography variant="body2">
              Esta locación no cuenta con opciones disponibles en esta hora del
              día.
            </Typography>
            <Typography variant="body2">¡Intenta con otra opción!</Typography>
          </Box>
        )}
        {!isEmpty(times) && (
          <Grid container spacing={1}>
            {times.map(item => (
              <Grid
                item
                xs={4}
                sm={3}
                className={classes.chipWrapper}
                key={item.time}
              >
                <ChipTimePicker data={item} activeTime={activeTime} />
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
    );
  else return null;
};

export default SchedulePanel;
