import React, { useEffect } from 'react';

import { Helmet } from 'react-helmet';

import { useSelector, useDispatch } from 'react-redux';

import { Container, Typography, Box, makeStyles } from '@material-ui/core';

import { PROGRAM_TYPES } from 'utils/constants';

import wellnubAPI from 'utils/network/wellnub.api';

import { setLives } from 'redux/lives/actions';

import { ProgramList } from 'components/organisms';

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  heading: {
    textAlign: 'center',
    color: theme.palette.action.main,
  },
  description: {
    paddingTop: theme.spacing(2),
    textAlign: 'center',
  },
}));

const Lives = () => {
  const lives = useSelector(state => state.lives);

  const dispatch = useDispatch();

  const classes = useStyles();

  const fetchPrograms = async () => {
    const { data } = await wellnubAPI.programs.list();
    const temp = data.filter(
      program => program.typeService === PROGRAM_TYPES.LIVE,
    );
    dispatch(setLives(temp));
  };

  useEffect(() => {
    fetchPrograms().catch(error => alert(error));
  }, []);

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Helmet>
        <meta
          name="description"
          content="¡Entrena con nuestros coaches en vivo! encuentra motivación, guía
          técnica, grupos de entrenamiento y toda la atención del coach en nuestro
          gimnasio virtual"
        />
        <title>Wellnub | Programas en vivo</title>
      </Helmet>
      <Typography variant="h1" className={classes.heading}>
        Lives
      </Typography>
      <Typography className={classes.description}>
        ¡Entrena con nuestros coaches en vivo! Encuentra motivación, guía
        técnica, grupos de entrenamiento y toda la atención del coach en nuestro
        gimnasio virtual
      </Typography>
      <Box pt={4} component="section">
        <ProgramList programs={lives} />
      </Box>
    </Container>
  );
};

export default Lives;
