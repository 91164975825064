import { useHistory } from 'react-router-dom';

import { Box, Button, Container, Typography } from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';

import { useStyles } from './SuccessfullyVerification.styles';

const SuccessfullyVerificationPage = () => {
  const { replace } = useHistory();

  const classes = useStyles();

  const onClickHandler = () => {
    replace('/');
  };

  return (
    <Box className={classes.backdrop}>
      <Container className={classes.container}>
        <Box className={classes.wrapper}>
          <Typography variant="h5" component="h1" className={classes.title}>
            Registro Exitoso
          </Typography>
          <Typography
            variant="h5"
            component="h2"
            className={`${classes.title} ${classes.mt}`}
          >
            Wellnub te da la bienvenida
          </Typography>
          <Box component="section" my={2}>
            <Typography>
              Hemos enviado un correo con tus datos de acceso a Wellnub
            </Typography>
            <Box mt={2}>
              <EmailIcon fontSize="large" />
            </Box>
            <Box mt={2}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={onClickHandler}
              >
                Aceptar
              </Button>
            </Box>
          </Box>
          <Typography variant="caption">
            * Asegúrate de revisar en tu bandeja de correo no deseado
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default SuccessfullyVerificationPage;
