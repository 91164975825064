import { Box, Button, Typography } from '@material-ui/core';
import { batch, useDispatch, useSelector } from 'react-redux';
import { setModalStatus } from 'redux/slices/modal.slice';
import { wnModalContentActiveBiochemicalPlan } from 'redux/slices/wnModalContent/wnModalContent.selector';
import { setWnModalContentActiveBiochemicalPlan } from 'redux/slices/wnModalContent/wnModalContent.slice';
import { useDeleteBiochemicalPlan } from 'hooks/user.hook';

const RemoveBiochemicalPlanWarning = () => {
  const biochemicalPlan = useSelector(wnModalContentActiveBiochemicalPlan);

  const { mutate: deleteBiochemicalPlan, isLoading } =
    useDeleteBiochemicalPlan();

  const dispatch = useDispatch();
  const onCancelHandler = () => {
    batch(() => {
      dispatch(
        setModalStatus({
          name: 'REMOVE_BIOCHEMICAL_PLAN_WARNING',
          isActive: false,
        }),
      );
      dispatch(setWnModalContentActiveBiochemicalPlan({}));
    });
  };

  const onAcceptHandler = () => {
    deleteBiochemicalPlan(biochemicalPlan._id);
  };

  return (
    <Box>
      <Typography variant="h6" align="center" style={{ paddingBottom: 8 }}>
        ¿Estás seguro que deseas borrar este análisis bioquímico?
      </Typography>
      <Typography variant="body2" align="center">
        Esta acción no se puede revertir
      </Typography>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-evenly"
        pt={2}
      >
        <Button variant="outlined" color="primary" onClick={onCancelHandler}>
          Cancelar
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onAcceptHandler}
          disabled={isLoading}
        >
          Aceptar
        </Button>
      </Box>
    </Box>
  );
};

export default RemoveBiochemicalPlanWarning;
