import React, { useEffect, useState } from 'react';
import { useController } from 'react-hook-form';
import { isEmpty } from 'lodash';
import { format, isEqual } from 'date-fns';
import {
  AppBar,
  Box,
  Chip,
  Tabs,
  Tab,
  Typography,
  Grid,
  makeStyles,
} from '@material-ui/core';
import { formatInTimeZone } from 'date-fns-tz';

const useStyles = makeStyles(theme => ({
  appBar: {
    boxShadow: 'unset',
  },
  tabs: {
    backgroundColor: theme.palette.secondary.main,
  },
  tab: {
    color: theme.palette.action.main,
    fontFamily: theme.typography.h1.fontFamily,
    fontSize: 12,
    fontWeight: 'bold',
  },
  chip: {
    width: '100%',
  },
}));

export const WellnubTimeChipPicker = ({ name, control, schedule }) => {
  const [activeTime, setActiveTime] = useState(() => new Date());
  const [tab, setTab] = useState(0);
  const [times, setTimes] = useState([]);

  const {
    field: { value, onChange },
  } = useController({ name, control });

  const onTabChangeHandler = (e, value) => {
    setTab(value);
  };

  const onClickChipHandler = time => {
    onChange(time.time);
    setActiveTime(time.time);
  };

  const classes = useStyles();

  useEffect(() => {
    if (!isEmpty(schedule?.morning)) {
      const actives = schedule?.morning?.filter(item => item.isAvailable) ?? [];
      setTimes(schedule?.morning ?? []);
      setTab(0);
      setActiveTime(actives?.[0]?.time ?? new Date());
      onChange(actives?.[0]?.time ?? new Date());
    } else if (!isEmpty(schedule?.afternoon)) {
      const actives = schedule?.afternoon?.filter(item => item.isAvailable) ?? [];
      setTimes(schedule?.afternoon ?? []);
      setTab(1);
      setActiveTime(actives?.[0]?.time ?? new Date());
      onChange(actives?.[0]?.time ?? new Date());
    } else {
      const actives = schedule?.night?.filter(item => item.isAvailable) ?? [];
      setTimes(schedule?.night ?? []);
      setTab(2);
      setActiveTime(actives?.[0]?.time ?? new Date());
      onChange(actives?.[0]?.time ?? new Date());
    }
  }, [schedule]);

  useEffect(() => {
    switch (tab) {
      case 0:
        const actives0 = schedule?.morning?.filter(item => item.isAvailable) ?? [];
        setTimes(schedule?.morning ?? []);
        setActiveTime(actives0?.[0]?.time ?? new Date());
        onChange(actives0?.[0]?.time ?? new Date());
        return;
      case 1:
        const actives1 = schedule?.afternoon?.filter(item => item.isAvailable) ?? [];
        setTimes(schedule?.afternoon);
        setActiveTime(actives1?.[0]?.time ?? new Date());
        onChange(actives1?.[0]?.time ?? new Date());
        return;
      case 2:
        const actives2 = schedule?.night?.filter(item => item.isAvailable) ?? [];
        setTimes(schedule?.night);
        setActiveTime(actives2?.[0]?.time ?? new Date());
        onChange(actives2?.[0]?.time ?? new Date());
        return;
      default:
        setTimes([]);
    }
  }, [tab]);

  return (
    <Box>
      <AppBar position="static" classes={{ root: classes.appBar }}>
        <Tabs
          variant="fullWidth"
          indicatorColor="primary"
          value={tab}
          onChange={onTabChangeHandler}
          classes={{ root: classes.tabs }}
        >
          <Tab
            label="Mañana"
            classes={{ root: classes.tab }}
            disabled={isEmpty(schedule?.morning)}
          />
          <Tab
            label="Tarde"
            classes={{ root: classes.tab }}
            disabled={isEmpty(schedule?.afternoon)}
          />
          <Tab
            label="Noche"
            classes={{ root: classes.tab }}
            disabled={isEmpty(schedule?.night)}
          />
        </Tabs>
      </AppBar>
      <Box component="section" mt={2}>
        {isEmpty(times) && (
          <Typography align="center" variant="body2">
            No hay horarios disponibles para mostrar
          </Typography>
        )}
        {!isEmpty(times) && (
          <Grid container spacing={1}>
            {times.map(time => (
              <Grid item xs={4} sm={3} key={time.time}>
                <Chip
                  disabled={!time.isAvailable}
                  size="small"
                  onClick={_ => onClickChipHandler(time)}
                  label={formatInTimeZone(time.time, time.timeZone, 'HH:mm')}
                  classes={{ root: classes.chip }}
                  style={{
                    backgroundColor: isEqual(
                      new Date(time.time),
                      new Date(activeTime),
                    )
                      ? '#1A7992'
                      : '#9b9b9b',
                  }}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
    </Box>
  );
};

export default WellnubTimeChipPicker;
