import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpen: false,
};

const backdropSlice = createSlice({
  name: 'backdrop',
  initialState,
  reducers: {
    setBackdropVisibility: (state, action) => {
      state.isOpen = action.payload;
    },
  },
});

export const { setBackdropVisibility } = backdropSlice.actions;

export const BackdropReducer = backdropSlice.reducer;
