import { Box } from '@material-ui/core';

import ServicesList from '../../../Lists/Services/ServicesList.component';

const HomeBasedTabPanel = ({ value, index, home }) => {
  return (
    <Box role="tabpanel" hidden={value !== index}>
      <ServicesList services={home} />
    </Box>
  );
};

export default HomeBasedTabPanel;
