import { wellnub } from '../wellnub.api';

export const requestCards = () => wellnub.get('/v1/client/payment/card');

export const requestAddCard = data =>
  wellnub.post('/v1/client/payment/card', data);

export const requestSetDefaultCard = id =>
  wellnub.patch(`/v1/client/payment/card/defaultCard/${id}`);

export const requestDeleteCard = id =>
  wellnub.delete(`/v1/client/payment/card/${id}`);

export const requestBuyPasses = payload =>
  wellnub.post('/v1/client/payment/buyCoins', payload);

export const requestValidateDiscountCode = async code => {
  const { data } = await wellnub.get(
    `/v1/client/payment/promotion/search?code=${code}`,
  );
  return data;
};

export const requestGetSubscriptions = async () => {
  const { data } = await wellnub.get('/v1/client/payment/subscription');
  return data;
};

export const requestBuySubscription = async payload => {
  const { data } = await wellnub.post(
    '/v1/client/payment/subscription',
    payload,
  );
  return data;
};

export const requestCancelSubscription = async () => {
  const { data } = await wellnub.delete('/v1/client/payment/subscription');
  return data;
};
