import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  contentWrapper: {
    paddingTop: theme.spacing(2),
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.main,
    width: '100%',
  },
  chipWrapper: {
    textAlign: 'center',
  },
}));
