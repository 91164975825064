import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  appBar: {
    boxShadow: 'unset',
  },
  tabs: {
    backgroundColor: theme.palette.secondary.main,
  },
  tab: {
    color: theme.palette.primary.light,
    fontFamily: theme.typography.h1.fontFamily,
    fontSize: 12,
  },
  selected: {
    fontWeight: 'bold',
  },
  scrollButtons: {
    color: 'black',
  },
}));
