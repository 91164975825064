import React from 'react';

import PropTypes from 'prop-types';

import { Link as RouterLink } from 'react-router-dom';

import {
  makeStyles,
  Box,
  Link,
  Typography,
  CardMedia,
} from '@material-ui/core';

import { PROGRAM_TYPES, DAY_NAMES } from 'utils/constants';

import { withFormat } from './Program.utils';

const useStyles = makeStyles(theme => ({
  card: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.secondary.main,
  },
  image: {
    backgroundImage: ({ image }) =>
      image ? `url(${image})` : theme.palette.secondary.main,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
  },
  bold: {
    fontWeight: 'bold',
  },
  noWrap: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  footer: {
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
  },
  action: {
    color: theme.palette.action.main,
  },
}));

const ProgramCard = ({ program }) => {
  const {
    _id: programID,
    pictureUrl: image,
    typeService: type,
    startClass: startTime,
    user: { name: userName },
    days,
    programName,
  } = program;

  const classes = useStyles({ image });

  const FORMATTED_DAYS = days
    .map(day => withFormat(day, DAY_NAMES))
    .join(' • ')
    .split(' ');

  return (
    <Box component="article" className={classes.card}>
      <Link
        to={location => {
          if (['/lives', '/vwods'].includes(location.pathname)) {
            return {
              ...location,
              pathname: `${location.pathname}/${programID}`,
            };
          }
          const path = {
            [PROGRAM_TYPES.LIVE]: '/lives',
            [PROGRAM_TYPES.VWOD]: '/vwods',
            [PROGRAM_TYPES.CUSTOM]: '/custom',
          }[type];

          return {
            ...location,
            pathname: `${path || '/'}/${programID}`,
          };
        }}
        component={RouterLink}
        underline="none"
      >
        <Box className={classes.image} height={150} position="relative">
          <Box
            p={1}
            display="flex"
            position="absolute"
            top={0}
            left={0}
            width="100%"
            alignItems="center"
            borderRadius={8}
            justifyContent="space-around"
          >
            {program.benefitsList.map(benefit => (
              <Box key={benefit.id} width={30} height={30}>
                <CardMedia
                  component="img"
                  width="100%"
                  height="100%"
                  title={benefit.benefitName}
                  image={benefit.imageURL}
                />
              </Box>
            ))}
          </Box>
        </Box>
        <Box pt={1} component="ul" display="flex" justifyContent="space-around">
          {FORMATTED_DAYS.map((day, index) => (
            <Box
              key={`item-${day}-${index}`}
              component="li"
              className={classes.action}
            >
              <Typography variant="body2" color="inherit">
                {day}
              </Typography>
            </Box>
          ))}
        </Box>
        <Box pt={1} pl={2} pr={2}>
          <Typography variant="h6" align="center" className={classes.noWrap}>
            {programName}
          </Typography>
        </Box>
        <Box p={1}>
          <Typography variant="body2" align="center" className={classes.noWrap}>
            {userName}
          </Typography>
        </Box>
        <Box
          p={1}
          component="footer"
          bgcolor="gray.main"
          className={classes.footer}
        >
          <Typography
            variant="body2"
            color="secondary"
            align="center"
            className={classes.noWrap}
          >
            {type === PROGRAM_TYPES.LIVE ? (
              `${startTime} hrs.`
            ) : (
              <Typography variant="caption">&nbsp;</Typography>
            )}
          </Typography>
        </Box>
      </Link>
    </Box>
  );
};

ProgramCard.propTypes = {
  program: PropTypes.object.isRequired,
};

export default ProgramCard;
