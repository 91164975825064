import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  appointments: [],
};

const pendingAppointmentsWithNutritionistSlice = createSlice({
  name: 'pendingAppointmentsWithNutritionist',
  initialState,
  reducers: {
    setPendingAppointments: (state, action) => {
      state.appointments = action.payload;
    },
  },
});

export const { setPendingAppointments } =
  pendingAppointmentsWithNutritionistSlice.actions;
export const PendingAppointmentsWithNutritionistReducer =
  pendingAppointmentsWithNutritionistSlice.reducer;
