import React, { useState } from 'react';
import { useRouteMatch, Link } from 'react-router-dom';

import { Tabs, Tab, useMediaQuery } from '@material-ui/core';

import { useStyles } from './NavigationTabs.styles';

const WellnubNavigationTabs = ({ paths = [] }) => {
  const [currentTab, setCurrentTab] = useState(paths[0].url ?? '');

  const { url } = useRouteMatch();

  const responsive = useMediaQuery(theme => theme.breakpoints.down('xs'));

  const classes = useStyles();

  const onTabChangeHandler = (event, value) => {
    setCurrentTab(value);
  };

  return (
    <Tabs
      variant="scrollable"
      value={currentTab}
      className={classes.tabs}
      onChange={onTabChangeHandler}
      scrollButtons={responsive ? 'on' : 'off'}
      orientation={responsive ? 'horizontal' : 'vertical'}
    >
      {paths.map(path => (
        <Tab
          disabled={path?.isActive ?? false}
          key={path.url}
          value={path.url}
          component={Link}
          label={path.label}
          to={`${url}/${path.url}`}
          classes={{ wrapper: classes.tab }}
        />
      ))}
    </Tabs>
  );
};

export default WellnubNavigationTabs;
