import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  tab: {
    fontSize: 12,
    fontFamily: theme.typography.h1.fontFamily,
    fontWeight: theme.typography.h1.fontWeight,
    color: theme.palette.primary.dark,
  },
  selected: {
    color: '#2a95ca !important',
  },
  indicator: {
    backgroundColor: '#2a95ca',
  },
  disabled: {
    color: '#bbbbbb',
  },
}));
