import { createSlice } from '@reduxjs/toolkit';

export const RESCHEDULE_MODES = {
  SCHEDULE: 'SCHEDULE',
  RESCHEDULE: 'RESCHEDULE',
};

const initialState = {
  appointments: [],
  scheduleMode: RESCHEDULE_MODES.SCHEDULE,
  appointmentToReschedule: {},
};

const virtualAgendaSlice = createSlice({
  name: 'virtualAgenda',
  initialState,
  reducers: {
    setVirtualAgendaAppointments: (state, action) => {
      state.appointments = action.payload;
    },
    setVirtualAgendaScheduleMode: (state, action) => {
      state.scheduleMode = action.payload;
    },
    setVirtualAgendaAppointmentToReschedule: (state, action) => {
      state.appointmentToReschedule = action.payload;
    },
  },
});

export const {
  setVirtualAgendaAppointments,
  setVirtualAgendaScheduleMode,
  setVirtualAgendaAppointmentToReschedule,
} = virtualAgendaSlice.actions;

export const VirtualAgendaReducer = virtualAgendaSlice.reducer;
