import React from 'react';

import { makeStyles, Typography, Container, Box } from '@material-ui/core';

import { SignUpForm } from 'components/organisms';

const useStyles = makeStyles(theme => ({
  backdrop: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.main,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  heading: {
    textAlign: 'center',
  },
  parragraph: {
    paddingTop: theme.spacing(2),
    textAlign: 'center',
  },
  link: {
    textTransform: 'uppercase',
    fontSize: 11,
    fontFamily: theme.typography.h1.fontFamily,
    fontWeight: theme.typography.h1.fontWeight,
    color: 'lightblue',
  },
}));

const SignUp = () => {
  const classes = useStyles();

  return (
    <div className={classes.backdrop}>
      <Container maxWidth="lg" className={classes.container}>
        <Typography variant="h1" className={classes.heading}>
          Regístrate en Wellnub
        </Typography>
        <Typography className={classes.parragraph}>
          En Wellnub podrás encontrar a tu nutriólogo ideal, realizarte análisis
          de composición corporal con tecnología InBody&reg; y mucho más para
          lograr ser la mejor versión de ti mismo.
        </Typography>
        <Typography className={classes.parragraph}>
          Este perfil es únicamente para registrarte como cliente, si quieres
          registrarte como nutriólogo, has click{' '}
          <a
            href="https://nutrition.wellnub.com/signUp"
            target="_blank"
            rel="noreferrer"
            className={classes.link}
          >
            aquí
          </a>
          .
        </Typography>
        <Box component="section" pt={4}>
          <SignUpForm type="light" />
        </Box>
      </Container>
    </div>
  );
};

export default SignUp;
