/* eslint-disable no-loop-func */
/**
 *
 * VideoWorkOut
 *
 */

import React, { useState, useEffect } from 'react';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import ReactPlayer from 'react-player';

import { Box, Grid, Typography, IconButton } from '@material-ui/core';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import wellnubAPI from '../../utils//network/api/wellnub.api';
import WorkoutVideoSideBar from '../../components/VWoD/WorkoutVideoSideBar';
import * as timeLenghtCalculator from '../../utils/VWoD/timeCalculator';
import videoAlertMP3 from '../../assets/audio/videoAlert.mp3';
import { INITIALS_TRANSLATIONS, ABREV_TRANSLATIONS } from '../../utils/strings/translations';
import styled from 'styled-components';

const MainContainer = styled.div`
display: flex;
height: 100vh;
@media screen and (min-width: 1px) and (max-width: 767px) and (orientation: portrait) {
  transform: rotate(-90deg);
  transform-origin: left top;
  width: 100vh;
  height: 100vw;
  overflow-x: hidden;
  position: absolute;
  top: 100%;
  left: 0;
}
`;

export const VideoWorkOut = props => {
  const [isFullScreenOn, setIsFullScreenOn] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [excercise, setExcercise] = useState(null);
  const [totalTime, setTotalTime] = useState(null);
  const [timeRanges, setTimeRanges] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [topLabelText, setTopLabelText] = useState('');
  const [activeStep, setActiveStep] = useState('');
  const [nextStep, setNextStep] = useState([]);
  const [activeSecond, setActiveSecond] = useState(0);
  const [currentStep, setCurrentStep] = useState({});
  const [activeRange, setActiveRange] = useState({});
  const [playedSounds, setPlayedSounds] = useState([]);

  const videoAlert = new Audio(videoAlertMP3);

  useEffect(() => {
    loadProgramsData();
  }, []);

  const loadProgramsData = async () => {
    try {
      const excercise = await wellnubAPI.exercise.get(props.match.params.id);
      setExcercise(excercise.data);
      if (excercise.data.videoType === 'wellnub') {
        buildTimeRanges(excercise.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleProgress = (e, d) => {
    if (totalTime !== null) {
      setActiveSecond(e.playedSeconds);
      const nextTime = timeLenghtCalculator.secondsToHms(
        parseInt(totalTime) - parseInt(e.playedSeconds),
      );
      setCurrentTime(nextTime);
      getActiveSteps();
      if (!playedSounds.includes(activeIndex)) {
        const seconsToFinish = Math.round(activeRange.endTime - e.playedSeconds);
        if (seconsToFinish === 3){
          videoAlert.play();
          setPlayedSounds(played => {
            return [...played, activeIndex]
          })
        }
      }
    }
  };

  const buildTimeRanges = program => {
    let activeTime = 0;
    const ranges = program.steps.map((step, index) => {
      const endTime = activeTime + timeLenghtCalculator.getStepLen(step);
      const singleRange = getStepRanges(
        step,
        activeTime,
        endTime,
        index,
        null,
        null,
      );
      activeTime = endTime;
      return singleRange;
    });
    console.log('FULL RANGESS');
    console.log(ranges.flat());
    console.log('END FULL RANGES');
    setTimeRanges(ranges.flat());
  };

  const getStepRanges = (
    step,
    startTime,
    endTime,
    index,
    circuitIndex,
    subIndex,
  ) => {
    switch (step.type) {
      case 'exercise':
        return getExcerciseRanges(
          step,
          startTime,
          index,
          circuitIndex,
          subIndex,
        );

      case 'rest':
        return getRestRanges(step, startTime, endTime, index);

      case 'circuit':
        return getCircuitRanges(step, startTime, index);

      default:
        return 0;
    }
  };

  const getExcerciseRanges = (
    step,
    startTime,
    index,
    currentCircuit,
    subIndex,
  ) => {
    const ranges = [];
    let activeTime = startTime;
    const exDuration = step.duration * timeLenghtCalculator.getMeasurementLen(step);
    for (let i = 0; i < step.series; i++) {
      ranges.push({
        startTime: activeTime,
        endTime: activeTime + exDuration,
        index,
        bottomLabel: `${step.duration} ${INITIALS_TRANSLATIONS[step.measurement]} ${
          step.exerciseName
        }`,
        topLabel: `${step.duration} ${ABREV_TRANSLATIONS[step.measurement]}`,
        timerLabel: `${timeLenghtCalculator.getRestLen(step)} s`,
        currentSerie: i,
        currentCircuit,
        subIndex,
      });
      activeTime += exDuration;
      if (i < step.series - 1) {
        ranges.push({
          startTime: activeTime,
          endTime: activeTime + step.restTime,
          index,
          bottomLabel: `${step.restTime} s Descanso`,
          topLabel: `${step.restTime} S`,
          timerLabel: `${step.restTime} S`,
          currentSerie: i,
          currentCircuit,
        });
        activeTime += step.restTime;
      }
    }
    return ranges;
  };

  const getRestRanges = (step, startTime, endTime, index) => ({
    startTime,
    endTime,
    index,
    bottomLabel: `${step.duration} ${step.measurement} ${step.restName}`,
    topLabel: `${step.duration} ${step.measurement}`,
    timerLabel: `${timeLenghtCalculator.getRestLen(step)} S`,
    currentSerie: null,
    currentCircuit: null,
  });

  const getCircuitRanges = (step, startTime, index) => {
    console.log(`Starting circuit in ${startTime}`);
    let circuitRanges = [];
    let activeTime = startTime;
    for (let i = 0; i < step.laps; i++) {
      const lapRanges = step.exercises.map((circuitEx, exIndex) => {
        const endTime = activeTime + timeLenghtCalculator.getStepLen(circuitEx);
        const singleRange = getStepRanges(
          circuitEx,
          activeTime,
          endTime,
          index,
          i,
          exIndex,
        );
        activeTime = endTime;
        return singleRange;
      });
      circuitRanges = [...circuitRanges, ...lapRanges.flat()];
      if (i < step.laps - 1) {
        circuitRanges.push({
          startTime: activeTime,
          endTime: activeTime + step.restTime,
          index,
          bottomLabel: `Descanso Circuito`,
          topLabel: `${step.restTime} S`,
          currentSerie: null,
          currentCircuit: i,
        });
      }
    }
    return circuitRanges;
  };

  const getActiveSteps = () => {
    const rangeIndex = timeRanges.findIndex(
      rng => activeSecond >= rng.startTime && activeSecond < rng.endTime,
    );
    if (rangeIndex !== -1) {
      const range = timeRanges[rangeIndex];
      setActiveRange(range);
      setActiveIndex(rangeIndex);
      setCurrentStep(excercise.steps[range.index]);
      setActiveStep(range.bottomLabel);
      setTopLabelText(range.topLabel);
      const nextStepLabel =
        rangeIndex < timeRanges.length - 1
          ? timeRanges[rangeIndex + 1].bottomLabel
          : '';
      setNextStep(nextStepLabel);
    }
  };

  const handleDuration = e => {
    setTotalTime(e);
  };

  const handle = useFullScreenHandle();

  const hanldeScreenChange = (state, handle) => {
    setIsFullScreenOn(state);
  };

  if (excercise !== null && excercise.videoType === 'external') {
    return (
      <div style={{ backgroundColor: '#000', position: 'absolute', height: '100%', width: '100%' }}>
        <ReactPlayer
          width="100%"
          height="100%"
          url={excercise.videoURL}
          controls
          config={{
            file: {
              attributes: {
                controlslist: 'nodownload nofullscreen',
                disablePictureInPicture: 'true',
              }
            }
          }}
        />
      </div>
    );
  }

  if (excercise === null) {
    return null
  }

  return (
    <FullScreen handle={handle} onChange={hanldeScreenChange}>
      <MainContainer>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: '1 1 75%',
          }}
        >
          <ReactPlayer
            style={{ backgroundColor: '#000', flex: '1 1 85%' }}
            width="100%"
            url={excercise.videoURL}
            onProgress={handleProgress}
            onPlay={e => console.log(e)}
            onStart={e => console.log(e)}
            onDuration={handleDuration}
            progressInterval={250}
            controls
            config={{
              file: {
                attributes: {
                  controlslist: 'nodownload nofullscreen',
                  disablePictureInPicture: 'true',
                }
              }
            }}
          />

          <div style={{ backgroundColor: '#000', flex: '1 1 15%' }}>
            <Grid container spacing={3} style={{ height: '100%' }}>
              <Grid
                item
                xs={5}
                style={{
                  backgroundColor: '#000',
                  color: '#FFF',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography variant="h5">
                  {activeStep}
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
                style={{
                  backgroundColor: '#000',
                  color: '#FFF',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography variant="h5">
                  >> {nextStep}
                </Typography>
              </Grid>
              <Grid
                item
                xs={3}
                style={{
                  backgroundColor: '#000',
                  color: '#FFF',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography variant="h3">{currentTime}</Typography>
              </Grid>
            </Grid>
          </div>
        </div>

        <div
          style={{
            flex: '1 1 25%',
            backgroundColor:
              currentStep.type === 'rest' ? '#01A9DB' : '#FE9A2E',
          }}
        >
          {!isFullScreenOn ? (
            <IconButton onClick={handle.enter}>
              <FullscreenIcon fontSize="large" />
            </IconButton>
          ) : (
            <IconButton onClick={handle.exit}>
              <FullscreenExitIcon fontSize="large" />
            </IconButton>
          )}
          <WorkoutVideoSideBar
            steps={excercise.steps}
            activeRange={activeRange}
            topLabel={topLabelText}
          />
        </div>
      </MainContainer>
    </FullScreen>
  );
}
