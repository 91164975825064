import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';

import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper';

const locationHelper = locationHelperBuilder({});

export const withProtected = connectedRouterRedirect({
  redirectPath: '/',
  authenticatedSelector: state => state.authentication.isAuthenticated,
  wrapperDisplayName: 'UserIsAuthenticated',
});

export const withRedirect = connectedRouterRedirect({
  redirectPath: '/dashboard/main',
  // redirectPath: (state, ownProps) => {
  //   console.log('Estado: ', state);
  //   console.log('Own Props: ', ownProps);
  //   return locationHelper.getRedirectQueryParam(ownProps) || '//dashboard/main';
  // },
  allowRedirectBack: true,
  authenticatedSelector: state => !state.authentication.isAuthenticated,
  wrapperDisplayName: 'UserIsNotAuthenticated',
});
