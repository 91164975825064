import React, { useEffect, useState } from 'react';
import { useController } from 'react-hook-form';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import { isEmpty } from 'lodash';

const WellnubAutocompleteSelectInput = ({
  name,
  control,
  options = [],
  ...restProps
}) => {
  const [inputValue, setInputValue] = useState('');

  const {
    field: { value, onChange, ...restField },
    fieldState: { error },
  } = useController({ name, control });

  const onChangeHandler = (event, newValue) => {
    onChange(newValue);
  };

  const onInputChangeHandler = (event, newValue) => {
    console.log('PERERE');
    setInputValue(newValue);
  };

  return (
    <Autocomplete
      {...restField}
      value={value}
      onChange={onChangeHandler}
      inputValue={inputValue}
      onInputChange={onInputChangeHandler}
      getOptionLabel={option => {
        console.log('Option label watcher: ', option);
        return option;
      }}
      options={options}
      renderInput={params => <TextField {...params} {...restProps} />}
    />
  );
};

export default WellnubAutocompleteSelectInput;
