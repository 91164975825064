import { useLocation } from 'react-router-dom';

import { Box, CircularProgress, Container } from '@material-ui/core';

import { useValidateEmail } from 'hooks/authentication.hook';

import { useStyles } from './EmailValidation.styles';
import { useEffect } from 'react';

const EmailValidationPage = () => {
  const { search } = useLocation();

  const { mutate, isLoading } = useValidateEmail();

  const classes = useStyles();

  useEffect(() => {
    const queryString = new URLSearchParams(search);

    const token = queryString.get('token');

    if (token) {
      mutate({ token });
    }
  }, [search, mutate]);

  return (
    <Box className={classes.backdrop}>
      <Container className={classes.container}>
        <Box className={classes.wrapper}>
          {isLoading && <CircularProgress />}
        </Box>
      </Container>
    </Box>
  );
};

export default EmailValidationPage;
