import { createTheme } from '@material-ui/core';

export const generateTheme = (theme, type) =>
  createTheme({
    palette: {
      type,
      secondary: {
        main: type === 'light' ? '#0B1015' : '#F8F9FA',
      },
    },
    typography: { ...theme.typography },
    overrides: { ...theme.overrides },
  });
