import { useSelector } from 'react-redux';
import { saveAs } from 'file-saver';

import { useSnackbar } from 'notistack';

import { Box, Button, Drawer, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';

import { format } from 'date-fns';
import { es } from 'date-fns/locale';

import { useDrawer } from 'hooks/drawer.hook';
import {
  useAppointment,
  useGetAppointmentTicket,
  useRequestReScheduleAppointment,
} from 'hooks/user.hook';

import { DRAWERS } from 'utils/constants';

import { useStyles } from './AppoinmentDrawer.styles';

const AppoinmentDrawer = () => {
  const { enqueueSnackbar } = useSnackbar();

  const { visibility, handleClose } = useDrawer(DRAWERS.appoinment);

  const {
    data: spot,
    appointmentSchedule,
    appointmentMode,
    appointment,
  } = useSelector(state => state.spot);
  const { passes } = useSelector(state => state.user);

  const { mutateAsync: setAppointment, isLoading } = useAppointment();

  const { mutate: reschedule, isLoading: isRecheduling } =
    useRequestReScheduleAppointment();

  const { isFetching, refetch } = useGetAppointmentTicket(appointment._id);

  const classes = useStyles();

  const onConfirmAppointmentHandler = () => {
    const time = new Date(new Date(appointmentSchedule.time));
    const date = new Date(new Date(appointmentSchedule.date));
    const hours = +format(time, 'H');
    const minutes = +format(time, 'm');
    const day = +format(date, 'd');
    const month = +format(date, 'M') - 1;
    const year = +format(date, 'Y');

    const scheduleDate = new Date(year, month, day, hours, minutes);

    if (appointmentMode === 'RESCHEDULE') {
      const passID = appointment?._id ?? '';
      const payload = {
        id: passID,
        data: {
          scheduleDate,
          inbodyLocationId: spot._id,
        },
      };
      reschedule(payload);
    } else {
      if (passes) {
        setAppointment({
          inbodyLocationId: spot._id,
          scheduleDate,
        })
          .then(() => refetch())
          .then(({ data }) => fetch(`data:image/jpeg;base64,${data}`))
          .then(response => response.blob())
          .then(file => {
            saveAs(file, 'wellnub-ticket.jpeg');
          });
      } else {
        enqueueSnackbar(
          'Adquiere pases para poder reservar tu escaneo corporal',
          {
            variant: 'warning',
          },
        );
      }
    }
  };

  return (
    <Drawer
      anchor="right"
      open={visibility}
      onClose={handleClose}
      classes={{ paper: classes.paper }}
    >
      <Box component="header">
        <IconButton onClick={handleClose}>
          <CloseIcon color="primary" />
        </IconButton>
        <Box>
          <Typography
            variant="h5"
            className={`${classes.title} ${classes.mainTitle}`}
          >
            CONFIRMA TU{' '}
            {appointmentMode === 'RESCHEDULE'
              ? 'MODIFICACIÓN DE RESERVACIÓN'
              : 'RESERVACIÓN'}
          </Typography>
        </Box>
      </Box>

      <Box pt={4} component="main">
        <Typography variant="h5" className={classes.title}>
          ¿Cuándo?
        </Typography>
        <Box my={2} className={classes.dashed}>
          <Typography variant="body2">
            {format(new Date(appointmentSchedule.date), 'PPPP', { locale: es })}
          </Typography>
          <Typography variant="body2" style={{ paddingTop: 8 }}>
            a las: {format(new Date(appointmentSchedule.time), 'p')}
          </Typography>
        </Box>
        <Typography
          variant="h6"
          className={`${classes.title} ${classes.action}`}
        >
          Por favor apégate a estos horarios para que podamos atenderte de la
          mejor manera. Tendrás 15 minutos de tolerancia para tu prueba.
        </Typography>
      </Box>

      <Box pt={4} component="section">
        <Typography variant="h5" className={classes.title}>
          ¿Dónde?
        </Typography>
        <Box mt={2} className={classes.dashed}>
          <Typography variant="body2">
            {spot.name}, {spot.address?.neighborhood ?? 'Colonia'}
          </Typography>
        </Box>
      </Box>

      <Box pt={4} component="section">
        <Typography variant="h5" className={classes.title}>
          ¿Dirección?
        </Typography>
        <Box mt={2} className={classes.dashed}>
          <Typography variant="body2">
            {spot?.address?.street ?? ''}, {spot?.address?.neighborhood ?? ''},{' '}
            {spot?.address?.city ?? ''}, {spot?.address?.state ?? ''},{' '}
            {spot?.address?.zipCode ?? ''}
          </Typography>
        </Box>
      </Box>

      <Box pt={4} component="section">
        <Typography variant="h5" className={classes.title}>
          Indicaciones especiales de la locación
        </Typography>
        <Box mt={2} className={classes.dashed}>
          <Typography variant="body2">{spot?.indications ?? ''}</Typography>
        </Box>
      </Box>

      <Box pt={4} component="section">
        <Typography variant="h5" className={classes.title}>
          Pasos a seguir para realizar tu escaneo corporal
        </Typography>
        <Box pt={2}>
          <Typography variant="body2" className={classes.indication}>
            1. En recepción menciona que vienes de Wellnub a realizarte un
            análisis InBody&reg;.
          </Typography>
          <Typography variant="body2" className={classes.indication}>
            2. Proporciona tu nombre completo para que puedan verificar tu
            reservación.
          </Typography>
          <Typography variant="body2" className={classes.indication}>
            3. Acompaña al staff al equipo InBody&reg; y proporciona tu
            número&nbsp;
            <span className={classes.underlined}>
              celular que diste de alta en Wellnub.
            </span>
          </Typography>
          <Typography variant="body2" className={classes.indication}>
            4. Ingresa o confirma tu edad, sexo y altura en el equipo
            InBody&reg;.
          </Typography>
          <Typography variant="body2" className={classes.indication}>
            5. ¡Listo! Recibirás tus resultados directo en tu perfil de Wellnub
            en menos de 30 minutos.
          </Typography>
        </Box>
      </Box>

      <Box
        pt={4}
        component="section"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Button
          fullWidth
          variant="contained"
          onClick={onConfirmAppointmentHandler}
          className={classes.confirmButton}
          disabled={isLoading || isRecheduling}
          classes={{
            disabled: classes.disabled,
          }}
        >
          Confirmar{' '}
          {appointmentMode === 'RESCHEDULE' ? 'reagendar cita' : 'reservación'}
        </Button>
        <Button
          variant="outlined"
          color="primary"
          fullWidth
          onClick={handleClose}
        >
          Cancelar
        </Button>
      </Box>

      <Box pt={4} component="section">
        <Typography variant="h5" className={classes.title}>
          Recomendaciones
        </Typography>
        <Typography variant="body2" style={{ textAlign: 'center' }}>
          Para mejorar la precisión de tu análisis te sugerimos:
        </Typography>
        <Box pt={2}>
          <Typography variant="body2" className={classes.check}>
            <CheckIcon fontSize="small" />
            &nbsp;Evita bebidas/comidas 2 horas antes.
          </Typography>
          <Typography variant="body2" className={classes.check}>
            <CheckIcon fontSize="small" />
            &nbsp;Procura acudir al baño antes de tu análisis.
          </Typography>
          <Typography variant="body2" className={classes.check}>
            <CheckIcon fontSize="small" />
            &nbsp;Usa ropa ligera y retira accesorios pesados.
          </Typography>
          <Typography variant="body2" className={classes.check}>
            <CheckIcon fontSize="small" />
            &nbsp;Evita realizar ejercicio intenso previamente.
          </Typography>
        </Box>
      </Box>
    </Drawer>
  );
};

export default AppoinmentDrawer;
