import { useEffect, useState } from 'react';
import {
  Box,
  Paper,
  Stepper,
  Step,
  ThemeProvider,
  Button,
  Typography,
  makeStyles,
  StepLabel,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  InputLabel,
  CircularProgress,
  Chip,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import Check from '@material-ui/icons/Check';
import { generateTheme } from 'utils/helpers';
import { useStyles } from './NutritionistFilterTest.styles';
import { useSelector } from 'react-redux';

import { nanoid } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import {
  useGetFilteredNutritionists,
  useGetTestSpecialties,
} from '../../../hooks/nutritionists.hook';
import useAnalyticsEventTracker from '../../../hooks/analytics.hook';

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: '#377990',
  },
  circle: {
    width: 15,
    height: 15,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: '#377990',
    zIndex: 1,
    fontSize: 18,
  },
});

function StyledStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}

const NutritionistFilterTest = ({ onCloseHandler }) => {
  const [questionKey, setQuestionKey] = useState('');
  const [queryParams, setQueryParams] = useState('');

  const [stepCommingFrom, setStepCommingFrom] = useState(0);
  const [step, setStep] = useState(0);
  const [steps, setSteps] = useState([
    { index: 0, disabled: false },
    { index: 1, disabled: false },
    { index: 2, disabled: false },
    { index: 3, disabled: false },
    { index: 4, disabled: false },
  ]);

  const [step0Radio, setStep0Radio] = useState({});
  const [step0RadioOptions, setStep0RadioOptions] = useState([]);
  const [selectOptions, setSelectOptions] = useState([]);
  const [selectOption, setSelectOption] = useState({});

  // ---- STEP 2 ----
  const [selectStep2Options, setSelectStep2Options] = useState([]);
  const [selectStep2Option, setSelectStep2Option] = useState({});

  // ---- STEP 3 ----
  const [step3RadioOption, setStep3RadioOption] = useState({});
  const [step3RadioOptions, setStep3RadioOptions] = useState([]);

  const [diets, setDiets] = useState([]);
  const [diet, setDiet] = useState({});

  const [specialties, setSpecialties] = useState([]);
  const [types, setTypes] = useState([]);

  const [specialFilter, setSpecialFilter] = useState('');

  const {
    data: specs,
    refetch,
    isFetching: isFetchingSpecs,
  } = useGetTestSpecialties(questionKey);

  const {
    data: nutritionists,
    isFetching,
    refetch: refetchNutris,
  } = useGetFilteredNutritionists(queryParams);

  const { eventTracker } = useAnalyticsEventTracker('client-action');

  const options = useSelector(state => state.nutritionistTest.options);

  const classes = useStyles();

  const onNextStepHandler = () => {
    setStepCommingFrom(step);
    if (step === 0) {
      setStep(selectOption.nextStep - 1);
    }
    if (step === 1) {
      setStep(selectStep2Option.nextStep - 1);
    }
    if (step === 2) {
      eventTracker('select-fitness-goal', 'wellnub-test-step-3');
      setStep(step3RadioOption.nextStep - 1);
    }
    if (step === 3) {
      setStep(4);
    }
  };

  const onCloseTestHandler = () => {
    if (step !== 4) {
      eventTracker(`close-test-on-step-${step}`, 'wellnub-test-close');
    }
    onCloseHandler();
  };

  const onPrevStepHandler = () => {
    setStep(stepCommingFrom);
  };

  const onDeleteChipHandler = option => () => {
    switch (option.type) {
      case 'specialty':
        const specsFiltered = {
          ...specs,
          types,
          specialities: specialties.filter(spec => spec.key !== option.key),
        };
        const querySpecs = specsFiltered.specialities
          ?.map(spec => `keySpecialities=${spec.key}`)
          .join('&');
        const queryTypes = specsFiltered.types
          ?.map(type => `nutriTypes=${type.key}`)
          .join('&');
        const queryDiet = diet?.key ?? '';
        const params = `?${querySpecs}&${queryTypes}&diet=${queryDiet}`;
        setQueryParams(params);
        setSpecialties(specsFiltered.specialities);
        return;
      case 'diet':
        setDiet({});
        return;
      case 'type':
        const typesFiltered = {
          ...specs,
          specialities: specialties,
          types: types.filter(type => type.key !== option.key),
        };
        const querySpecsT = typesFiltered.specialities
          ?.map(spec => `keySpecialities=${spec.key}`)
          .join('&');
        const queryTypesT = typesFiltered.types
          ?.map(type => `nutriTypes=${type.key}`)
          .join('&');
        const queryDietT = diet?.key ?? '';
        const paramsT = `?${querySpecsT}&${queryTypesT}&diet=${queryDietT}`;
        setQueryParams(paramsT);
        setTypes(typesFiltered.types);
        return;
      default:
        return;
    }
  };

  // RADIO HANDLER DEL STEP 1
  const onStep0RadioOptionChange = event => {
    const radioOption = step0RadioOptions.find(
      item => item.key === event.target.value,
    );
    setStep0Radio(radioOption);
  };

  // SELECT HANDLER DEL STEP 1
  const onSelectOptionChange = event => {
    const selectOption =
      selectOptions.find(item => item.key === event.target.value) ?? {};
    setSelectOption(selectOption);
    if ([4, 5].includes(selectOption?.nextStep ?? 0)) {
      setQuestionKey(selectOption.key);
    }
  };

  // SELECT HANDLER DEL STEP 2
  const onSelectStep2OptionChange = event => {
    const selectStep2Option =
      selectStep2Options.find(item => item.key === event.target.value) ?? {};
    setSelectStep2Option(selectStep2Option);
    if ([4, 5].includes(selectStep2Option?.nextStep)) {
      setQuestionKey(selectStep2Option.key);
    }
  };

  // RADIO HANDLER DEL STEP 3
  const onStep3RadioChangeHandler = event => {
    const step3RadioOption =
      step3RadioOptions
        .filter(item => item)
        .find(item => item.key === event.target.value) ?? {};
    setStep3RadioOption(step3RadioOption);
    if ([4, 5].includes(step3RadioOption?.nextStep)) {
      setQuestionKey(step3RadioOption.key);
    }
  };

  // SELECT HANDLER DEL STEP 4 (DIETAS)
  const onDietChangeHandler = event => {
    const dietKey = event.target.value;
    const diet = diets.find(diet => diet.key === dietKey) ?? {};
    setDiet(diet);
  };

  // SE LLENAN LAS OPCIONES DE RADIO PARA EL PASO 1
  useEffect(() => {
    if (!isEmpty(options) && step === 0) {
      const step0RadioOptions = options.filter(item => item.currentStep === 0);
      const step0Radio = step0RadioOptions[0] ?? {};
      setStep0RadioOptions(step0RadioOptions);
      setStep0Radio(step0Radio);
    }
  }, [options, step]);

  // MANEJADOR PARA CUANDO CAMBIE EL RADIO DEL PASO 1
  useEffect(() => {
    if (!isEmpty(step0Radio)) {
      const selectOptions = step0Radio.childOptions.map(key =>
        options.find(element => element.key === key),
      );
      const selectOption = selectOptions[0] ?? '';
      setSelectOptions(selectOptions);
      setSelectOption(selectOption);
      if ([4, 5].includes(selectOption?.nextStep ?? 0)) {
        setQuestionKey(selectOption.key);
      }
    }
  }, [step0Radio, options]);

  // LLENA LOS SELECTS DEL PASO 2
  useEffect(() => {
    if (step === 1 && !isEmpty(selectOption)) {
      const step2Options = selectOption.childOptions.map(key =>
        options.find(element => element.key === key),
      );
      const step2Option = step2Options[0] ?? {};
      setSelectStep2Option(step2Option);
      setSelectStep2Options(step2Options);
      if ([4, 5].includes(step2Option?.nextStep)) {
        setQuestionKey(step2Option.key);
      }
    }
  }, [step, selectOption, options]);

  // LLENA LOS RADIOS DEL STEP 3
  useEffect(() => {
    if (step === 2 && !isEmpty(selectStep2Option)) {
      const step3Options = selectStep2Option.childOptions.map(key =>
        options.find(element => element.key === key),
      );
      const step3Option = step3Options[0] ?? {};
      setStep3RadioOptions(step3Options);
      setStep3RadioOption(step3Option);
      if ([4, 5].includes(step3Option?.nextStep)) {
        setQuestionKey(step3Option.key);
      }
    }
  }, [step, selectStep2Option, options]);

  useEffect(() => {
    if (!isEmpty(questionKey)) {
      refetch().then(({ data }) => {
        const diet = data.diets[0] ?? {};
        setDiet(diet);
        setTypes(data.types);
        setSpecialties(data.specialities);
        setDiets(data.diets);
        setSpecialFilter(data.specialFilter ?? '');
      });
    }
  }, [questionKey, refetch]);

  useEffect(() => {
    if (!isEmpty(queryParams)) {
      refetchNutris();
    }
  }, [queryParams, refetchNutris]);

  useEffect(() => {
    if (!isEmpty(specs) && step === 4) {
      if (!isEmpty(specialFilter)) {
        const params = `?specialFilter=${specialFilter}`;
        setQueryParams(params);
        return;
      }
      const querySpecs = specs?.specialities
        ?.map(spec => `keySpecialities=${spec.key}`)
        .join('&');
      const queryTypes = specs?.types
        ?.map(type => `nutriTypes=${type.key}`)
        .join('&');
      const queryDiet = diet?.key ?? '';
      const params = `${!isEmpty(specs?.specialities) ? `?${querySpecs}` : ''}${
        !isEmpty(specs?.types) ? `&${queryTypes}` : ''
      }${!isEmpty(queryDiet) ? `&diets=${queryDiet}` : ''}`;
      setQueryParams(params);
    }
  }, [specs, diet, step, specialFilter]);

  return (
    <ThemeProvider theme={theme => generateTheme(theme, 'light')}>
      <Paper classes={{ root: classes.paper }} elevation={2}>
        <Box textAlign="right">
          <Tooltip title="Cerrar test">
            <IconButton size="small" onClick={onCloseTestHandler}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
        <Typography variant="h5" className={classes.title}>
          Test para encontrar a tu nutriólogo ideal
        </Typography>
        <Box mt={4} component="section">
          <Stepper alternativeLabel activeStep={step}>
            {steps.map(step => (
              <Step key={nanoid()} disabled={step.disabled}>
                <StepLabel StepIconComponent={StyledStepIcon} />
              </Step>
            ))}
          </Stepper>
          {step === 0 && (
            <Box component="section">
              <Typography align="center" variant="h6">
                ¿Para quién buscas un nutriólogo?
              </Typography>
              <FormControl component="fieldset" className={classes.formControl}>
                <RadioGroup
                  name="optionForQuestion"
                  value={step0Radio.key ?? ''}
                  onChange={onStep0RadioOptionChange}
                >
                  {step0RadioOptions.map(item => (
                    <FormControlLabel
                      key={nanoid()}
                      value={item.key}
                      label={item.option}
                      control={<Radio color="secondary" />}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
              <Box textAlign="center" mt={2}>
                <FormControl>
                  <InputLabel id="select-label">
                    Selecciona una opción
                  </InputLabel>
                  <Select
                    id="select-label-component"
                    label="Selecciona una opción"
                    labelId="select-label"
                    value={selectOption.key ?? ''}
                    onChange={onSelectOptionChange}
                    style={{
                      minWidth: '250px',
                    }}
                  >
                    {selectOptions.map(option => (
                      <MenuItem key={nanoid()} value={option.key}>
                        {option.option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>
          )}
          {step === 1 && (
            <Box component="section" textAlign="center">
              <Typography variant="h6">
                ¿Qué objetivo tienes en mente?
              </Typography>
              <Typography style={{ marginTop: 32, marginBottom: 32 }}>
                {step0Radio.option} - {selectOption.option}
              </Typography>
              <FormControl>
                <InputLabel id="select-label-2">
                  Selecciona una opción
                </InputLabel>
                <Select
                  value={selectStep2Option.key ?? ''}
                  onChange={onSelectStep2OptionChange}
                  label="Selecciona una opción"
                  style={{
                    minWidth: '250px',
                  }}
                >
                  {selectStep2Options.map(option => (
                    <MenuItem key={nanoid()} value={option.key}>
                      {option.option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          )}
          {step === 2 && (
            <Box component="section" textAlign="center">
              <Typography variant="h6">
                ¿En cuál de los siguientes objetivos te quieres enfocar?
              </Typography>
              <FormControl>
                <RadioGroup
                  name="aspects"
                  value={step3RadioOption.key ?? ''}
                  onChange={onStep3RadioChangeHandler}
                >
                  {step3RadioOptions
                    .filter(item => item)
                    .map(item => (
                      <FormControlLabel
                        key={nanoid()}
                        value={item?.key ?? ''}
                        label={item?.option ?? ''}
                        control={<Radio color="secondary" />}
                      />
                    ))}
                </RadioGroup>
              </FormControl>
              {step3RadioOption.description && (
                <Box
                  mt={2}
                  style={{
                    padding: 16,
                    backgroundColor: '#E1E9ED',
                    maxWidth: '400px',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                >
                  <Typography variant="h6">Descripción:</Typography>
                  <Typography variant="body2">
                    {step3RadioOption?.description ?? 'popo'}
                  </Typography>
                </Box>
              )}
            </Box>
          )}
          {step === 3 && (
            <Box component="section" textAlign="center">
              <Typography variant="h6">
                ¿Tienes alguna preferencia alimentaria?
              </Typography>
              <FormControl variant="outlined" style={{ marginTop: 24 }}>
                <InputLabel id="select-label-3">
                  Selecciona una opción
                </InputLabel>
                <Select
                  value={diet?.key ?? ''}
                  onChange={onDietChangeHandler}
                  label="Selecciona una opción"
                  style={{
                    minWidth: '250px',
                  }}
                >
                  {diets.map(diet => (
                    <MenuItem key={nanoid()} value={diet.key}>
                      {diet.option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          )}
          {step === 4 && (
            <Box component="section" textAlign="center">
              <Typography variant="h6">
                ¡Muchas gracias por tus respuestas!
              </Typography>
              <Typography variant="h6">
                Estas son nuestras recomendaciones de especialistas para ti
              </Typography>
              {isFetching ? (
                <Box
                  mt={4}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  <Box mt={2}>
                    {specialties.map(item => (
                      <Chip
                        key={nanoid()}
                        label={item.name}
                        onDelete={onDeleteChipHandler({
                          type: 'specialty',
                          key: item.key,
                        })}
                        style={{ marginRight: 5 }}
                      />
                    ))}
                    {types.map(item => (
                      <Chip
                        key={nanoid()}
                        label={item.type}
                        onDelete={onDeleteChipHandler({
                          type: 'type',
                          key: item.key,
                        })}
                        style={{ marginRight: 5 }}
                      />
                    ))}
                    {!isEmpty(diet) && (
                      <Chip
                        label={diet.option}
                        onDelete={onDeleteChipHandler({
                          type: 'diet',
                          key: diet.key,
                        })}
                      />
                    )}
                  </Box>
                  <Box>
                    <Typography variant="body2" align="right">
                      {nutritionists?.length} resultados
                    </Typography>
                  </Box>
                </>
              )}
            </Box>
          )}
          {step !== 4 && (
            <Box
              mt={2}
              display="flex"
              alignItems="center"
              justifyContent="space-evenly"
            >
              <Button
                variant="contained"
                color="secondary"
                disabled={step === 0}
                className={classes.button}
                onClick={onPrevStepHandler}
              >
                Anterior
              </Button>
              <Button
                variant="contained"
                color="secondary"
                disabled={step === 5 || isFetchingSpecs}
                className={classes.button}
                onClick={onNextStepHandler}
              >
                {isFetchingSpecs ? 'Cargando...' : 'Siguiente'}
              </Button>
            </Box>
          )}
        </Box>
      </Paper>
    </ThemeProvider>
  );
};

export default NutritionistFilterTest;
