import { useSelector } from 'react-redux';

import { Box, Button, Typography } from '@material-ui/core';

import { useSetDefaultCard } from 'hooks/payments.hook';

const SetDefaultCardForm = () => {
  const { id } = useSelector(state => state.payments.target);

  const { mutate } = useSetDefaultCard();

  const onClickHandler = event => {
    event.preventDefault();
    mutate(id);
  };

  return (
    <>
      <Typography>
        ¿Confirmas que deseas que esta tarjeta sea tu forma de pago principal?
      </Typography>
      <Box pt={2} textAlign="center">
        <Button variant="contained" onClick={onClickHandler}>
          Confirmar
        </Button>
      </Box>
    </>
  );
};

export default SetDefaultCardForm;
