import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  helper: {
    marginLeft: 0,
    marginRight: 0,
  },
  conekta: {
    width: 120,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));
