import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useForm, Controller } from 'react-hook-form';

import { Box, Button, Grid, IconButton, TextField } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import { setModalStatus } from 'redux/slices/modal.slice';

import { useSignIn } from 'hooks/authentication.hook';

const SignInForm = () => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const { control, handleSubmit } = useForm();

  const { mutate: signIn, status } = useSignIn();

  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const onSubmitHandler = data => {
    signIn(data);
  };

  if (status === 'success') {
    dispatch(
      setModalStatus({
        name: 'SPECIAL_SIGN_IN',
        isActive: false,
      }),
    );
    enqueueSnackbar('Bienvenido', {
      variant: 'success',
    });
  }

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Controller
            name="email"
            defaultValue=""
            control={control}
            rules={{
              required: 'Ingresa el correo electrónico para poder continuar',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Escribe un correo válido',
              },
            }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <TextField
                fullWidth
                label="Email"
                type="email"
                color="secondary"
                variant="outlined"
                error={!!error}
                value={value}
                onChange={onChange}
                helperText={error ? error.message : ''}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="password"
            defaultValue=""
            control={control}
            rules={{
              required: 'Ingresa la contraseña para poder continuar',
            }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <TextField
                fullWidth
                label="Contraseña"
                type={isPasswordVisible ? 'text' : 'password'}
                variant="outlined"
                color="secondary"
                error={!!error}
                value={value}
                onChange={onChange}
                helperText={error ? error.message : ''}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      onClick={() =>
                        setIsPasswordVisible(prevState => !prevState)
                      }
                    >
                      {isPasswordVisible ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  ),
                }}
              />
            )}
          />
        </Grid>
      </Grid>
      <Box pt={4}>
        <Button variant="contained" fullWidth type="submit">
          Iniciar sesión
        </Button>
      </Box>
    </form>
  );
};

export default SignInForm;
