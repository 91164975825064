import { Box, Container, Typography } from '@material-ui/core';

import { WellnubPDFViewer } from '@wellnub/web-common';

import { useStyles } from './Privacy.styles';

const PrivacyPage = () => {
  const classes = useStyles();

  return (
    <Box className={classes.backdrop}>
      <Container className={classes.container}>
        <Typography variant="h4" className={classes.title}>
          Aviso de privacidad
        </Typography>
        <Box mt={4}>
          <WellnubPDFViewer url="https://wellnub.s3.us-west-1.amazonaws.com/legal/parivacy_wellnub.pdf" />
        </Box>
      </Container>
    </Box>
  );
};

export default PrivacyPage;
