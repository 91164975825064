import React from 'react';

import { useSelector, useDispatch } from 'react-redux';

import {
  IconButton,
  Snackbar,
  Typography,
  makeStyles,
} from '@material-ui/core';

import { Alert, AlertTitle } from '@material-ui/lab';

import CloseIcon from '@material-ui/icons/Close';

import { hideAlert } from 'redux/alert/actions';

const useStyles = makeStyles(theme => ({
  title: {
    textTransform: 'uppercase',
    fontSize: theme.typography.body2.fontSize,
  },
  success: {
    color: theme.palette.success.contrastText,
    backgroundColor: theme.palette.success.main,
  },
  error: {
    color: theme.palette.error.contrastText,
    backgroundColor: theme.palette.error.main,
  },
  info: {
    color: theme.palette.info.contrastText,
    backgroundColor: theme.palette.info.main,
  },
  warning: {
    color: theme.palette.warning.contrastText,
    backgroundColor: theme.palette.warning.main,
  },
}));

const AlertSelector = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const { type, title, message, isOpen } = useSelector(
    state => state.alertStatus,
  );

  const closeHandler = () => dispatch(hideAlert());

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={isOpen}
      autoHideDuration={5000}
      onClose={closeHandler}
    >
      <Alert
        variant="filled"
        severity={type}
        classes={{
          filledSuccess: classes.success,
          filledError: classes.error,
          filledInfo: classes.info,
          filledWarning: classes.warning,
        }}
        action={
          <IconButton onClick={closeHandler} color="inherit">
            <CloseIcon />
          </IconButton>
        }
      >
        <AlertTitle>
          <Typography variant="h6" color="inherit" className={classes.title}>
            {title}
          </Typography>
        </AlertTitle>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default AlertSelector;
