import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  chip: {
    width: '100%',
    backgroundColor: ({ isSelected }) =>
      isSelected ? theme.palette.action.main : '#293241',
    '&:hover': {
      backgroundColor: theme.palette.action.dark,
    },
    '&:focus': {
      backgroundColor: ({ isSelected }) =>
        isSelected ? theme.palette.action.main : '#293241',
    },
  },
}));
