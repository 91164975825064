import { useEffect, useState } from 'react';

import { Box, Tab, Tabs } from '@material-ui/core';

import OnsiteTabPanel from '../../Panels/Services/Onsite/OnsiteTabPanel.component';
import OnlineTabPanel from '../../Panels/Services/Online/OnlineTabPanel.component';
import HomeBasedTabPanel from '../../Panels/Services/HomeBased/HomeBasedTabPanel.component';

import { useStyles } from './ServicesTabs.styles';

const ServicesTabs = ({ address, home, site, online, phone }) => {
  const [current, setCurrent] = useState(0);

  const classes = useStyles();

  const onChangeHandler = (event, value) => {
    setCurrent(value);
  };

  useEffect(() => {
    if (online.length !== 0) {
      setCurrent(0);
    } else if (site.length !== 0) {
      setCurrent(1);
    } else if (home.length !== 0) {
      setCurrent(2);
    }
  }, [online, site, home]);

  return (
    <>
      <Tabs
        variant="fullWidth"
        value={current}
        onChange={onChangeHandler}
        classes={{ indicator: classes.indicator }}
      >
        <Tab
          label="Online"
          disabled={online.length === 0}
          classes={{
            root: classes.tab,
            selected: classes.selected,
            disabled: classes.disabled,
          }}
        />
        <Tab
          disabled={site.length === 0}
          label="Presencial"
          classes={{
            root: classes.tab,
            selected: classes.selected,
            disabled: classes.disabled,
          }}
        />
        <Tab
          label="A domicilio"
          disabled={home.length === 0}
          classes={{
            root: classes.tab,
            selected: classes.selected,
            disabled: classes.disabled,
          }}
        />
      </Tabs>
      <Box mt={2} component="section">
        <OnlineTabPanel value={current} index={0} online={online} />
        <OnsiteTabPanel
          value={current}
          index={1}
          site={site}
          address={address}
          phone={phone}
        />
        <HomeBasedTabPanel value={current} index={2} home={home} />
      </Box>
    </>
  );
};

export default ServicesTabs;
