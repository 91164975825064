export const overrides = {
  MuiCssBaseline: {
    '@global': {
      '*, *::before, *::after': {
        margin: 0,
        padding: 0,
        boxSizing: 'border-box',
      },
      html: {
        scrollBehavior: 'smooth',
        WebkitFontSmoothing: 'auto',
      },
      body: {
        wordSpacing: 1,
      },
      ul: {
        listStyle: 'none',
      },
      a: {
        textDecoration: 'none',
      },
    },
  },
  MuiButton: {
    root: {
      textTransform: 'unset',
    },
    contained: {
      color: '#F8F9FA',
      backgroundColor: '#1A7992',
      '&:hover': {
        backgroundColor: '#1b697d',
      },
    },
  },
};
