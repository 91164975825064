import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Box, Button, Typography } from '@material-ui/core';

import { setModalStatus } from 'redux/slices/modal.slice';
import { useDrawer } from 'hooks/drawer.hook';
import { DRAWERS } from 'utils/constants';

const PendingLinkRequestWarningPanel = () => {
  const dispatch = useDispatch();

  const { handleOpen: openCheckoutDrawer } = useDrawer(DRAWERS.checkout);

  const { replace } = useHistory();

  const onAcceptHandler = () => {
    dispatch(
      setModalStatus({
        name: 'HAS_PENDING_LINK_REQUEST_WARNING',
        isActive: false,
      }),
    );
    setTimeout(() => {
      replace('/dashboard/main');
    }, 500);
  };

  const onContinueHandler = () => {
    dispatch(
      setModalStatus({
        name: 'HAS_PENDING_LINK_REQUEST_WARNING',
        isActive: false,
      }),
    );
    setTimeout(() => {
      openCheckoutDrawer();
    }, 500);
  };

  return (
    <Box textAlign="center">
      <Typography>
        Tienes una solicitud de vinculación pendiente, es posible que tu
        nutriólogo te haya enviado un pase para un escaneo Wellnub InBody&reg;.
      </Typography>
      <Typography style={{ marginTop: 8 }}>
        Acepta la vinculación antes de comprar tu pase
      </Typography>
      <Box
        mt={2}
        display="flex"
        alignItems="center"
        justifyContent="space-evenly"
      >
        <Button variant="contained" onClick={onAcceptHandler}>
          Ver nutriólogo
        </Button>
        <Button variant="outlined" color="primary" onClick={onContinueHandler}>
          Continuar compra
        </Button>
      </Box>
    </Box>
  );
};

export default PendingLinkRequestWarningPanel;
