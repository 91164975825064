import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    height: '750px',
    border: '1px solid #c2c2c2',
  },
  canvas: {
    padding: theme.spacing(2, 0),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#c2c2c2',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#222222',
    overflow: 'scroll',
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  navigateButton: {
    color: '#0b1015',
  },
}));
