import { useMutation } from 'react-query';
import { useDispatch, batch } from 'react-redux';

import { useSnackbar } from 'notistack';

import { wellnub } from 'network/wellnub.api';

import {
  requestResendEmailToken,
  requestResendPhoneNumberToken,
  requestResetPassword,
  requestSignIn,
  requestSignUp,
  requestUpdateEmail,
  requestUpdatePhoneNumber,
  requestValidateEmail,
  requestValidatePhoneNumber,
} from 'network/services/authentication.service';

import {
  setAuthentication,
  setIsFirstSignIn,
} from 'redux/slices/authentication.slice';
import { setUser } from 'redux/slices/user.slice';
import { setModalBehavior } from 'redux/modalBehaviors/actions';

import { MODAL_NAMES } from 'utils/constants';
import { useHistory } from 'react-router-dom';
import { setTooltipVisibility } from '../redux/slices/layout.slice';

// ---- SOLICITAR INICIO DE SESIÓN ----
export const useSignIn = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { replace } = useHistory();

  return useMutation(requestSignIn, {
    onSuccess: ({ data }) => {
      const { id, name, email, status, hasLoggedIn } = data.user;
      const user = {
        id,
        name,
        email,
        status,
        isFirstSignIn: !hasLoggedIn,
      };
      wellnub.defaults.headers.common.Authorization = `Bearer ${data.token.accessToken}`;
      localStorage.setItem('accessToken', data.token.accessToken);
      localStorage.setItem('authentication', 'true');
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('refreshToken', data.token.refreshToken);
      localStorage.setItem('email', data.user.email);
      batch(() => {
        dispatch(setTooltipVisibility(true));
        dispatch(setAuthentication(true));
        dispatch(setUser(user));
        dispatch(setIsFirstSignIn(!hasLoggedIn));
      });
    },
    onError: ({ response }) => {
      if (response.data.isPublic) {
        enqueueSnackbar(response.data.message, {
          variant: 'warning',
        });
      } else {
        switch (response.data.apiCode) {
          case 'user-phone-number-not-validated':
            const {
              metadata: { _id, email, phoneNumber, createdByRole },
            } = response.data;
            localStorage.setItem('userID', _id);
            localStorage.setItem('userEmail', email);
            localStorage.setItem('userPhoneNumber', phoneNumber);
            localStorage.setItem('createdByRole', createdByRole);
            setTimeout(() => {
              replace('/phoneValidation');
              enqueueSnackbar(response.data.message, {
                variant: 'warning',
              });
            }, 500);
            break;
          case 'user-email-not-validated':
            const {
              metadata: {
                _id: id1,
                email: email1,
                phoneNumber: phoneNumber1,
                createdByRole: createdByRole1,
              },
            } = response.data;
            localStorage.setItem('userID', id1);
            localStorage.setItem('userEmail', email1);
            localStorage.setItem('userPhoneNumber', phoneNumber1);
            localStorage.setItem('createdByRole', createdByRole1);
            setTimeout(() => {
              enqueueSnackbar(response.data.message, {
                variant: 'warning',
              });
              replace('/pending-verification');
            }, 500);
            break;
          default:
            enqueueSnackbar('Algo salió mal, intenta de nuevo más tarde', {
              variant: 'warning',
            });
            break;
        }
      }
    },
  });
};

// ---- SOLICITAR CREAR REGISTRO NUEVO ----
export const useSignUp = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { push } = useHistory();

  return useMutation(requestSignUp, {
    onSuccess: ({ data }) => {
      const {
        user: { _id, email, phoneNumber, createdByRole },
      } = data;
      localStorage.setItem('userID', _id);
      localStorage.setItem('userEmail', email);
      localStorage.setItem('userPhoneNumber', phoneNumber);
      localStorage.setItem('createdByRole', createdByRole);
      setTimeout(() => {
        push('/pending-verification');
      }, 1000);
    },
    onError: ({ response }) => {
      if (response.data.isPublic) {
        enqueueSnackbar(response.data.message, {
          variant: 'warning',
        });
      } else {
        enqueueSnackbar('Algo salió mal, intenta de nuevo más tarde', {
          variant: 'warning',
        });
      }
    },
    onSettled: () => {
      dispatch(
        setModalBehavior({
          name: MODAL_NAMES.SIGN_UP,
          isVisible: false,
        }),
      );
    },
  });
};

// ---- SOLICITAR RESTABLECER CONTRASEÑA ----
export const useResetPassword = () => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(requestResetPassword, {
    onSuccess: ({ data }) => {
      enqueueSnackbar(
        'Se ha enviado al correo registrado una contraseña temporal para iniciar sesión',
        {
          variant: 'success',
        },
      );
    },
    onError: () => {
      enqueueSnackbar('Algo salió mal, intenta más tarde', {
        variant: 'error',
      });
    },
  });
};

// ---- SOLICITA VALIDACIÓN DE EMAIL ----
export const useValidateEmail = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { replace } = useHistory();

  return useMutation(requestValidateEmail, {
    onSuccess: response => {
      const {
        user: {
          _id: userID,
          email: userEmail,
          phoneNumber: userPhoneNumber,
          createdByRole: userCreatedByRole,
        },
      } = response;
      localStorage.setItem('userID', userID);
      localStorage.setItem('userEmail', userEmail);
      localStorage.setItem('userPhoneNumber', userPhoneNumber);
      localStorage.setItem('createdByRole', userCreatedByRole);
      setTimeout(() => {
        replace('/phoneValidation');
      }, 500);
    },
    onError: ({ response }) => {
      switch (response.data.apiCode) {
        case 'user-validation-token-expired':
          replace('/email-token-expired');
          break;
        case 'user-validation-token-used':
          const {
            metadata: { _id, email, phoneNumber, createdByRole },
          } = response.data;
          localStorage.setItem('userID', _id);
          localStorage.setItem('userEmail', email);
          localStorage.setItem('userPhoneNumber', phoneNumber);
          localStorage.setItem('createdByRole', createdByRole);
          setTimeout(() => {
            replace('/phoneValidation');
          }, 500);

          break;
        case 'user-validation-finished':
          replace('/validation-completed');
          break;
        default:
          enqueueSnackbar('Algo salió mal, intenta de nuevo más tarde', {
            variant: 'error',
          });
          break;
      }
    },
  });
};

// ---- SOLICITA ACTUALIZAR EL CORREO ELECTRÓNICO DE VERIFICACIÓN ----
export const useUpdateVerificationEmail = () => {
  const { enqueueSnackbar } = useSnackbar();

  const { replace } = useHistory();

  return useMutation(requestUpdateEmail, {
    onSuccess: () => {
      enqueueSnackbar('Se actualizó con éxito el correo de vinculación', {
        variant: 'success',
      });
    },
    onError: ({ response }) => {
      if (response.data.isPublic) {
        enqueueSnackbar(response.data.message, {
          variant: 'warning',
        });
      } else {
        enqueueSnackbar('Algo salió mal, intenta de nuevo más tarde', {
          variant: 'error',
        });
      }
    },
  });
};

// ---- SOLICITA UN NUEVO CORREO PARA EMAIL ----
export const useResendEmailToken = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(requestResendEmailToken, {
    onSuccess: () => {
      enqueueSnackbar(
        'Se envió con éxito un nuevo correo con un enlace de validación',
        {
          variant: 'success',
        },
      );
    },
    onError: ({ response }) => {
      if (response.data.isPublic) {
        enqueueSnackbar(response.data.message, {
          variant: 'error',
        });
      } else {
        enqueueSnackbar(
          'No se pudo realizar la operación, intente de nuevo más tarde',
          {
            variant: 'error',
          },
        );
      }
    },
  });
};

// ---- SOLICITA VALIDACIÓN DE TELÉFONO ----
export const useValidatePhoneNumber = () => {
  const { replace } = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(requestValidatePhoneNumber, {
    onSuccess: data => {
      if (data?.createdByRole === 'nutritionist') {
        enqueueSnackbar(
          'Validación con éxito. Ya puedes iniciar sesión con tus credenciales',
        );
        setTimeout(() => {
          replace('/success-verification');
        }, 1000);
      } else if (data?.createdByRole === 'client') {
        enqueueSnackbar(
          'Validacion con éxito, ya puedes iniciar sesión de manera normal',
          {
            variant: 'success',
          },
        );
        setTimeout(() => {
          replace('/success-verification-by-client');
        }, 1000);
      }
    },
    onError: ({ response }) => {
      if (response.data.isPublic) {
        enqueueSnackbar(response.data.message, {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Algo salió mal, intenta más tarde', {
          variant: 'error',
        });
      }
    },
  });
};

// ---- SOLICITA UN NUEVO TOKEN PARA TELEFONO ----
export const useResendPhoneNumberToken = () => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation(requestResendPhoneNumberToken, {
    onSuccess: () => {
      enqueueSnackbar(
        'Se envió con éxito un nuevo código al teléfono asociado',
        {
          variant: 'success',
        },
      );
    },
    onError: ({ response }) => {
      if (response.data.isPublic) {
        enqueueSnackbar(response.data.message, {
          variant: 'error',
        });
      } else {
        enqueueSnackbar(
          'No se pudo realizar la operación, intente de nuevo más tarde',
          {
            variant: 'error',
          },
        );
      }
    },
  });
};

// ---- SOLICITA ACTUALIZAR EL NÚMERO DE TELÉFONO DE VALIDACIÓN ----
export const useUpdatePhoneNumber = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { push } = useHistory();

  return useMutation(requestUpdatePhoneNumber, {
    onSuccess: () => {
      enqueueSnackbar('Se actualizó con éxito el número de teléfono', {
        variant: 'success',
      });
      setTimeout(() => {
        push('/');
      }, 500);
    },
    onError: ({ response }) => {
      if (response.data.isPublic) {
        enqueueSnackbar(response.data.message, {
          variant: 'warning',
        });
      } else {
        enqueueSnackbar('Algo salió mal, intenta de nuevo más tarde', {
          variant: 'error',
        });
      }
    },
  });
};
