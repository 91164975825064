import React, { Fragment } from 'react';

import PropTypes from 'prop-types';

import { makeStyles, Box, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  box: {
    paddingTop: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      paddingTop: 0,
    },
  },
  action: {
    color: theme.palette.action.main,
  },
}));

const GetInTouch = ({ clickHandler }) => {
  const classes = useStyles();

  return (
    <Fragment>
      <Box className={classes.box}>
        <Typography variant="h4" color="secondary" align="center">
          ¿Te interesan mis programas?
        </Typography>
      </Box>
      <Box pt={2}>
        <Typography variant="h4" align="center" color="secondary">
          Ponte en{' '}
          <Typography
            variant="h4"
            component="span"
            onClick={clickHandler}
            className={classes.action}
          >
            contacto
          </Typography>{' '}
          conmigo
        </Typography>
      </Box>
    </Fragment>
  );
};

GetInTouch.propTypes = {
  clickHandler: PropTypes.func,
};

export default GetInTouch;
