import { SET_LIVES } from './constants';

const livesReducer = (state = [], action) => {
  if (action.type === SET_LIVES) {
    return action.payload;
  }
  return state;
};

export default livesReducer;
