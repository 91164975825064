import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  name: '',
  isActive: false,
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setModalStatus: (state, action) => {
      state.name = action.payload.name;
      state.isActive = action.payload.isActive;
    },
  },
});

export const { setModalStatus } = modalSlice.actions;

export const modalReducer = modalSlice.reducer;
