import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Container } from '@material-ui/core';

import { WellnubPDFViewer, WellnubImageViewer } from '@wellnub/web-common';

const ViewerPage = () => {
  const { type, file } = useSelector(state => state.viewer);

  const { id } = useParams();

  return (
    <Container style={{ paddingTop: 32 }}>
      {type === 'PDF' && <WellnubPDFViewer url={file} />}
      {type === 'IMAGE' && <WellnubImageViewer url={file} />}
    </Container>
  );
};

export default ViewerPage;
