import { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Link, useHistory } from 'react-router-dom';

import {
  makeStyles,
  IconButton,
  Drawer,
  Box,
  Button,
  Typography,
  Tooltip,
} from '@material-ui/core';

import MenuIcon from '@material-ui/icons/Menu';

import { setAuthentication } from 'redux/slices/authentication.slice';
import { setTooltipVisibility } from '../../../../../redux/slices/layout.slice';

const useStyles = makeStyles(theme => ({
  accordion: {
    boxShadow: 'none',
    backgroundColor: 'transparent',
  },
  accordionSummary: {
    padding: 0,
  },
  linkText: {
    color: theme.palette.secondary.dark,
  },
  paper: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.gray.main,
  },
  menuWrapper: {
    height: '100%',
  },
  summaryLabel: {
    fontSize: theme.typography.body2.fontSize,
  },
  button: {
    marginTop: theme.spacing(2),
  },
  link: {
    display: 'inline-block',
    marginBottom: theme.spacing(4),
  },
  maxWidth: {
    maxWidth: '15ch',
  },
  arrow: {
    color: theme.palette.action.main,
  },
  tooltip: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.action.main,
  },
}));

const ResponsiveMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const { isAuthenticated } = useSelector(state => state.authentication);

  const { isOpen: isTooltipOpen } = useSelector(state => state.layout.tooltip);

  const dispatch = useDispatch();

  const classes = useStyles();

  const { push, replace } = useHistory();

  const closeDrawerHandler = () => {
    setIsOpen(false);
  };

  const signupHandler = () => {
    setIsOpen(false);
    push('/signup');
  };

  useEffect(() => {
    if (isTooltipOpen) {
      setTimeout(() => {
        dispatch(setTooltipVisibility(false));
      }, 3000);
    }
  }, [isTooltipOpen, dispatch]);

  const onAuthenticationClickHandler = event => {
    event.preventDefault();
    if (isAuthenticated) {
      localStorage.removeItem('authentication');
      localStorage.removeItem('accessToken');
      localStorage.removeItem('user');
      dispatch(setAuthentication(false));
      replace('/');
      setIsOpen(false);
    } else {
      setIsOpen(false);
      push('/signin');
    }
  };

  return (
    <Fragment>
      <Tooltip
        arrow
        open={isTooltipOpen}
        classes={{
          arrow: classes.arrow,
          tooltip: classes.tooltip,
        }}
        title="¡Aquí encontrarás tu menú!"
      >
        <IconButton
          onClick={() => {
            setIsOpen(true);
            if (isTooltipOpen) {
              dispatch(setTooltipVisibility(false));
            }
          }}
          style={{ marginLeft: 'auto' }}
        >
          <MenuIcon />
        </IconButton>
      </Tooltip>
      <Drawer
        anchor="right"
        open={isOpen}
        classes={{ paper: classes.paper }}
        onClose={closeDrawerHandler}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          className={classes.menuWrapper}
        >
          <Box display="flex" flexDirection="column">
            {isAuthenticated && (
              <Link to="/dashboard/main" onClick={closeDrawerHandler}>
                <Typography
                  variant="h6"
                  component="span"
                  color="secondary"
                  className={classes.link}
                >
                  Principal
                </Typography>
              </Link>
            )}
            <Link to="/nutritionists" onClick={closeDrawerHandler}>
              <Typography
                variant="h6"
                component="span"
                color="secondary"
                className={classes.link}
              >
                ¿Buscas nutriólogo?
              </Typography>
            </Link>
            {isAuthenticated && (
              <>
                <Link to="/dashboard/progress" onClick={closeDrawerHandler}>
                  <Typography
                    variant="h6"
                    component="span"
                    color="secondary"
                    className={classes.link}
                  >
                    Mi progreso
                  </Typography>
                </Link>
                <Link to="/dashboard/appointment" onClick={closeDrawerHandler}>
                  <Typography
                    variant="h6"
                    component="span"
                    color="secondary"
                    className={`${classes.link} ${classes.maxWidth}`}
                  >
                    Agenda un análisis Wellnub-InBody&reg;
                  </Typography>
                </Link>
                <Link to="/tutorials" onClick={closeDrawerHandler}>
                  <Typography
                    variant="h6"
                    component="span"
                    color="secondary"
                    className={`${classes.link} ${classes.maxWidth}`}
                  >
                    Tutoriales
                  </Typography>
                </Link>
              </>
            )}
          </Box>

          <Box
            display="flex"
            alignItems="start"
            justifyContent="space-around"
            flexDirection="column"
          >
            {!isAuthenticated && (
              <Button fullWidth variant="contained" onClick={signupHandler}>
                Registarme
              </Button>
            )}
            {isAuthenticated && (
              <>
                <Link to="/settings/profile" onClick={closeDrawerHandler}>
                  <Typography
                    variant="h6"
                    component="span"
                    color="secondary"
                    className={classes.link}
                  >
                    Mi perfil
                  </Typography>
                </Link>
                <Link to="/market" onClick={closeDrawerHandler}>
                  <Typography
                    variant="h6"
                    component="span"
                    color="secondary"
                    className={classes.link}
                  >
                    Compras
                  </Typography>
                </Link>
                <Link to="/settings/payments" onClick={closeDrawerHandler}>
                  <Typography
                    variant="h6"
                    component="span"
                    color="secondary"
                    className={classes.link}
                  >
                    Formas de pago
                  </Typography>
                </Link>
                <Link to="/settings/activity" onClick={closeDrawerHandler}>
                  <Typography
                    variant="h6"
                    component="span"
                    color="secondary"
                    className={classes.link}
                  >
                    Mi actividad
                  </Typography>
                </Link>
              </>
            )}
            <Button
              fullWidth
              variant="outlined"
              color="secondary"
              onClick={onAuthenticationClickHandler}
              className={classes.button}
            >
              {isAuthenticated ? 'Cerrar sesión' : 'Iniciar sesión'}
            </Button>
          </Box>
        </Box>
      </Drawer>
    </Fragment>
  );
};

export default ResponsiveMenu;
