import React from 'react';

import PropTypes from 'prop-types';

import { Box, CircularProgress } from '@material-ui/core';

const Spinner = ({ color }) => (
  <Box display="flex" alignItems="center" justifyContent="center">
    <CircularProgress color={color} />
  </Box>
);

Spinner.propTypes = {
  color: PropTypes.string,
};

export default Spinner;
