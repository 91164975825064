import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  options: [],
};

const nutritionistTestSlice = createSlice({
  name: 'nutritionistTest',
  initialState,
  reducers: {
    setNutritionistTestOptions: (state, action) => {
      state.options = action.payload;
    },
  },
});

export const { setNutritionistTestOptions } = nutritionistTestSlice.actions;
export const NutritionistTestReducer = nutritionistTestSlice.reducer;
