import { Box } from '@material-ui/core';

import ServicesList from '../../../Lists/Services/ServicesList.component';

const OnlineTabPanel = ({ value, index, online }) => {
  return (
    <Box role="tabpanel" hidden={value !== index}>
      <ServicesList services={online} />
    </Box>
  );
};

export default OnlineTabPanel;
