/*
 *
 * Error Message reducer
 *
 */
import produce from 'immer';
import {
  ERROR_MESSAGE,
  WARNING_MESSAGE,
  INFORMATION_MESSAGE,
  SUCCESS_MESSAGE,
  HIDE_MESSAGE,
} from './constants';

const materialSnackTypes = {
  [ERROR_MESSAGE]: 'error',
  [WARNING_MESSAGE]: 'warning',
  [INFORMATION_MESSAGE]: 'info',
  [SUCCESS_MESSAGE]: 'success',
};

export const initialState = {
  message: 'ß',
  isOpen: false,
  variant: 'info',
};

/* eslint-disable default-case, no-param-reassign */
const patientMedicalInfoReducer = (state = initialState, action) =>
  produce(state, (/* draft */) => {
    switch (action.type) {
      case ERROR_MESSAGE:
      case WARNING_MESSAGE:
      case INFORMATION_MESSAGE:
      case SUCCESS_MESSAGE:
        return {
          message: action.payload,
          isOpen: true,
          variant: materialSnackTypes[action.type],
        };

      case HIDE_MESSAGE:
        return {
          ...state,
          isOpen: false,
        };

      default:
        return state;
    }
  });

export default patientMedicalInfoReducer;
