import { useDispatch } from 'react-redux';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  useMediaQuery,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';

import { setModalStatus } from 'redux/slices/modal.slice';

import { useStyles } from './WithModal.styles';

const WithModal = ({
  open,
  title,
  name,
  BodyComponent,
  showCloseButton = true,
}) => {
  const dispatch = useDispatch();

  const isExtraSmallSize = useMediaQuery(theme => theme.breakpoints.down('xs'));

  const classes = useStyles();

  const closeHandler = () => {
    dispatch(
      setModalStatus({
        name,
        isActive: false,
      }),
    );
  };

  return (
    <Dialog open={open} fullScreen={isExtraSmallSize} onClose={closeHandler}>
      <DialogTitle disableTypography classes={{ root: classes.header }}>
        <Typography variant="h6" className={classes.title}>
          {title}
        </Typography>
        {showCloseButton ? (
          <IconButton edge="end" color="inherit" onClick={closeHandler}>
            <CloseIcon />
          </IconButton>
        ) : (
          <span style={{ width: 48, height: 48, display: 'inline-block' }}>
            &nbsp;
          </span>
        )}
      </DialogTitle>
      <DialogContent dividers className={classes.body}>
        {BodyComponent}
      </DialogContent>
    </Dialog>
  );
};

export default WithModal;
