import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  card: {
    textAlign: 'center',
    '& .title': {
      color: 'red',
    },
  },
  image: {
    width: '100%',
    height: 250,
    marginLeft: 'auto',
    marginRight: 'auto',
    transition: '200ms',
    boxShadow: 'none',
    objectFit: 'contain',
    '&:hover': {
      transform: 'scale(1.1)',
      boxShadow: `0 6px 12px 0 #c2c3c4`,
    },
    borderRadius: theme.shape.borderRadius,
    [theme.breakpoints.down('xs')]: {
      width: '75%',
    },
  },
}));
