import axios from 'axios';

import exerciseService from './exercise.service';
import programService from './program.service';
import coachService from './coach.service';
import profileService from './profile.service';
import sessionService from './session.service';

import ProgramsService from './programs.service';
import CoachesService from './coaches.service';
import ContactService from './contact.service';
import ZoomService from './zoom.service';
import AuthService from './auth.service';
import UserService from './user.service';

class WellnubApi {
  constructor() {
    const accessToken = localStorage.getItem('wellnub-access-token');
    axios.defaults.headers.common = {
      Authorization: `Bearer ${accessToken || ''}`,
    };
    this.programs = new ProgramsService(axios);
    this.coaches = new CoachesService(axios);
    this.contact = new ContactService(axios);
    this.zoom = new ZoomService(axios);
    this.auth = new AuthService(axios);
    this.user = new UserService(axios);
  }

  exercise = exerciseService;

  program = programService;

  coach = coachService;

  profile = profileService;

  session = sessionService;
}

export default new WellnubApi();
