import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Box, Button, Container, Link, Typography } from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

import { useResendEmailToken } from 'hooks/authentication.hook';

import { useStyles } from './PendingVerification.styles';

const PendingVerificationPage = () => {
  const [email, setEmail] = useState('');
  const [id, setID] = useState('');
  const [timer, setTimer] = useState(60);
  const [isResendButtonDisabled, setIsResendButtonDisabled] = useState(false);

  const { mutate: resendEmail, isLoading, isSuccess } = useResendEmailToken();

  const { push } = useHistory();

  const wrapperRef = useRef(null);

  const classes = useStyles();

  const onResendEmailHandler = () => {
    resendEmail(id);
  };

  const onRetryEmailHandler = () => {
    push('/emailRetry');
  };

  useEffect(() => {
    if (wrapperRef.current) {
      wrapperRef.current.click();
    }
    const email = localStorage.getItem('userEmail');
    const id = localStorage.getItem('userID');
    setID(id);
    setEmail(email);
  }, []);

  useEffect(() => {
    if (isSuccess) {
      setIsResendButtonDisabled(true);
      const interval = setInterval(() => {
        setTimer(prevState => prevState - 1);
      }, 1000);
      setTimeout(() => {
        setIsResendButtonDisabled(false);
        clearInterval(interval);
      }, 60000);
    }
  }, [isSuccess]);

  return (
    <Box className={classes.backdrop}>
      <Container className={classes.container}>
        <Box className={classes.wrapper}>
          <span ref={wrapperRef}>&nbsp;</span>
          <Typography variant="h5" component="h1" className={classes.title}>
            ¡Faltan los últimos pasos!
          </Typography>
          <Typography
            variant="h5"
            component="h2"
            className={`${classes.title} ${classes.mt}`}
          >
            Hemos enviado un correo a
          </Typography>
          <Typography
            variant="h5"
            component="h2"
            className={`${classes.title} ${classes.mt} ${classes.ellipsis}`}
          >
            {email}
          </Typography>
          <Box component="section" my={2}>
            <Typography>
              Por favor verifica tu correo y sigue las instrucciones
            </Typography>
            <Box mt={2}>
              <EmailIcon fontSize="large" />
            </Box>
            <Box
              mt={2}
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
            >
              <Box mb={2}>
                <Typography variant="body2" style={{ marginBottom: 16 }}>
                  Si no recibiste tu correo, puedes reenviarlo en el siguiente
                  enlace:
                </Typography>
                <Typography
                  variant="body2"
                  className={`${classes.asLink} ${
                    isResendButtonDisabled ? classes.disabled : ''
                  }`}
                  onClick={onResendEmailHandler}
                >
                  Reenviar correo
                </Typography>
                {isResendButtonDisabled && (
                  <Typography variant="body2">
                    Puedes volver a reenviar el código en {timer} segundos
                  </Typography>
                )}
              </Box>
              <Typography
                variant="body2"
                className={`${classes.asLink}`}
                onClick={onRetryEmailHandler}
              >
                ¿Tu correo es incorrecto?
              </Typography>
            </Box>
          </Box>
          <Typography variant="caption">
            * Asegúrate de revisar en tu bandeja de correo no deseado
          </Typography>
        </Box>
        <Box mt={2} textAlign="center">
          <Typography>¿Tienes problemas para registrarte?</Typography>
          <Typography>
            Ponte en contacto con nosotros y te ayudaremos
          </Typography>
          <Box className={classes.infoWrapper} mt={2}>
            <Box>
              <Link
                href="https://wa.me/525630674811"
                target="_blank"
                rel="noopener noreferrer"
              >
                <WhatsAppIcon />
                <Typography variant="body2">+52 56306-74811 </Typography>
              </Link>
            </Box>
            <Box>
              <Link
                href="mailto:hola@wellnub.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <MailOutlineIcon />
                <Typography variant="body2">hola@wellnub.com</Typography>
              </Link>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default PendingVerificationPage;
