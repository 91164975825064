import React, { Fragment } from 'react';

import { get } from 'lodash';

import { Box, Typography } from '@material-ui/core';

import { Button } from 'components/atoms';

import { PROGRAM_TYPES } from 'utils/constants';

const SuscriptionCard = ({ suscription }) => {
  const type = get(suscription, 'program.typeService', 'custom-trainings');

  return (
    <Box
      p={3}
      borderRadius={8}
      component="article"
      bgcolor="secondary.main"
      color="primary.main"
    >
      <Typography variant="h6" color="primary">
        {get(suscription, 'program.programName', 'Mi programa')}
      </Typography>
      <br />
      <Typography>
        Por: {get(suscription, 'program.user.name', 'Coach')}{' '}
        {get(suscription, 'program.user.lastName', 'Apellido')}
      </Typography>
      <br />
      {type === PROGRAM_TYPES.LIVE && (
        <Fragment>
          <Typography>LIVE - Mi suscripción: </Typography>
          <br />
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography>
              Próximo entrenamiento:{' '}
              {new Date(suscription.nextTraining).toLocaleDateString()}
            </Typography>
            <Button variant="contained">Entrenar ahora</Button>
          </Box>
        </Fragment>
      )}
      {type === PROGRAM_TYPES.VWOD && (
        <Fragment>
          <Typography>VWOD - Mi suscripción: </Typography>
          <br />
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography>
              Entrenamientos esta semana: {get(suscription, 'trainingsDone', 0)}
            </Typography>
            <Button variant="contained">Entrenar ahora</Button>
          </Box>
        </Fragment>
      )}
    </Box>
  );
};

export default SuscriptionCard;
