import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { useSnackbar } from 'notistack';

import { Box, Button, Drawer, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';

import { format } from 'date-fns';
import { es } from 'date-fns/locale';

import { useDrawer } from 'hooks/drawer.hook';
import { useAppointment } from 'hooks/user.hook';

import { DRAWERS } from 'utils/constants';

import { useStyles } from './ScheduleDetailDrawer.styles';

const ScheduleDetailDrawer = () => {
  const { visibility, handleClose } = useDrawer(DRAWERS.schedule);

  const { data: spot, appointmentSchedule } = useSelector(state => state.spot);

  const classes = useStyles();

  return (
    <Drawer
      anchor="right"
      open={visibility}
      onClose={handleClose}
      classes={{ paper: classes.paper }}
    >
      <Box component="header">
        <IconButton onClick={handleClose}>
          <CloseIcon color="primary" />
        </IconButton>
        <Box>
          <Typography
            variant="h5"
            className={`${classes.title} ${classes.mainTitle}`}
          >
            DETALLES DE LA LOCACIÓN
          </Typography>
        </Box>
      </Box>

      <Box pt={4} component="section">
        <Typography variant="h5" className={classes.title}>
          Locación
        </Typography>
        <Box mt={2} className={classes.dashed}>
          <Typography variant="body2">
            {spot.name}, {spot.address?.neighborhood ?? 'Colonia'}
          </Typography>
        </Box>
      </Box>

      <Box pt={4} component="section">
        <Typography variant="h5" className={classes.title}>
          Dirección
        </Typography>
        <Box mt={2} className={classes.dashed}>
          <Typography variant="body2">
            {spot?.address?.street ?? ''}, {spot?.address?.neighborhood ?? ''},{' '}
            {spot?.address?.city ?? ''}, {spot?.address?.state ?? ''},{' '}
            {spot?.address?.zipCode ?? ''}
          </Typography>
        </Box>
      </Box>

      <Box pt={4} component="main">
        <Typography variant="h5" className={classes.title}>
          Horarios de atención
        </Typography>
        <Box my={2} className={classes.dashed}>
          {spot?.formatedSchedules?.map(schedule => (
            <Typography variant="body2" key={schedule.day}>
              {`${schedule.day}: ${schedule.times}`}
            </Typography>
          )) ?? []}
        </Box>
      </Box>

      <Box pt={4} component="section">
        <Typography variant="h5" className={classes.title}>
          Indicaciones especiales de la locación
        </Typography>
        <Box my={2} className={classes.dashed}>
          <Typography variant="body2">{spot?.indications ?? ''}</Typography>
        </Box>
      </Box>

      <Box
        pt={4}
        component="section"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Button
          component={Link}
          fullWidth
          variant="contained"
          to={{
            pathname: spot?.address?.mapUrl ?? '',
          }}
          rel="noreferrer"
          target="_blank"
          className={classes.confirmButton}
        >
          Ver mapa
        </Button>
      </Box>
    </Drawer>
  );
};

export default ScheduleDetailDrawer;
