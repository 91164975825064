import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Box, Button, Container, Typography, Link } from '@material-ui/core';

import OtpInput from 'react-otp-input';

import {
  useResendPhoneNumberToken,
  useValidatePhoneNumber,
} from 'hooks/authentication.hook';

import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

import { useStyles } from './PhoneValidation.styles';

const PhoneValidationPage = () => {
  const [OTP, setOTP] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [userID, setUserID] = useState('');
  const [timer, setTimer] = useState(60);
  const [isResendButtonDisabled, setIsResendButtonDisabled] = useState(false);

  const { mutate, isLoading, isError, error } = useValidatePhoneNumber();

  const { mutate: resendToken, isSuccess } = useResendPhoneNumberToken();

  const { push } = useHistory();

  const wrapperRef = useRef(null);

  const classes = useStyles();

  const onOTPChange = data => {
    setOTP(data);
  };

  const onContinueHandler = () => {
    mutate({
      token: OTP,
    });
  };

  const onResendTokenHandler = () => {
    resendToken(userID);
  };

  const onRetryPhoneNumberHandler = () => {
    push('/phone-number-retry');
  };

  useEffect(() => {
    if (wrapperRef.current) {
      wrapperRef.current.click();
    }
    const phoneNumber = localStorage.getItem('userPhoneNumber');
    const id = localStorage.getItem('userID');
    setPhoneNumber(phoneNumber);
    setUserID(id);
  }, []);

  useEffect(() => {
    if (OTP.length === 4) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [OTP]);

  useEffect(() => {
    if (isSuccess) {
      setIsResendButtonDisabled(true);
      const interval = setInterval(() => {
        setTimer(prevState => prevState - 1);
      }, 1000);
      setTimeout(() => {
        setIsResendButtonDisabled(false);
        clearInterval(interval);
      }, 60000);
    }
  }, [isSuccess]);

  return (
    <Box className={classes.backdrop}>
      <Container className={classes.container}>
        <Box className={classes.wrapper}>
          <Typography variant="h5" component="h1" className={classes.title}>
            ¡Último paso!
          </Typography>
          <span ref={wrapperRef}>&nbsp;</span>
          <Typography variant="h5" component="h1" className={classes.title}>
            Hemos mandado un código a este celular {phoneNumber} para verificar
            tus datos
          </Typography>
          <Box component="section" my={2} textAlign="center">
            <Typography>
              Por favor ingresa los 4 dígitos que te enviamos por mensaje
            </Typography>
            <OtpInput
              isInputNum
              inputStyle={classes.inputOTP}
              containerStyle={classes.inputOTPWrapper}
              value={OTP}
              onChange={onOTPChange}
              numInputs={4}
              separator={<span>-</span>}
              hasErrored={isError}
              errorStyle={classes.inputOTPError}
            />
            {isError && (
              <Typography variant="body2" className={classes.error}>
                {error?.response?.data?.message ?? 'Nariz'}
              </Typography>
            )}
            <Typography style={{ marginTop: 8 }} variant="body2">
              El envío de código puede tardar unos minutos
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Box mb={2}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.button}
                disabled={isButtonDisabled}
                onClick={onContinueHandler}
              >
                Continuar
              </Button>
            </Box>
            <Box mb={2}>
              <Typography variant="body2" style={{ marginBottom: 16 }}>
                Si no recibiste tu código, puedes reenviarlo en el siguiente
                enlace
              </Typography>
              <Typography
                variant="body2"
                className={`${classes.asLink} ${
                  isResendButtonDisabled ? classes.disabled : ''
                }`}
                onClick={onResendTokenHandler}
              >
                Reenviar código
              </Typography>
              {isResendButtonDisabled && (
                <Typography variant="body2">
                  Puedes volver a reenviar el código en {timer} segundos
                </Typography>
              )}
            </Box>
            <Typography
              variant="body2"
              className={`${classes.asLink}`}
              onClick={onRetryPhoneNumberHandler}
            >
              ¿Tu número celular es incorrecto?
            </Typography>
          </Box>
        </Box>

        <Box mt={2} textAlign="center">
          <Typography>¿Tienes problemas para registrarte?</Typography>
          <Typography>
            Ponte en contacto con nosotros y te ayudaremos
          </Typography>
          <Box className={classes.infoWrapper} mt={2}>
            <Box>
              <Link
                href="https://wa.me/525630674811"
                target="_blank"
                rel="noopener noreferrer"
              >
                <WhatsAppIcon />
                <Typography variant="body2">+52 56306-74811 </Typography>
              </Link>
            </Box>
            <Box>
              <Link
                href="mailto:hola@wellnub.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <MailOutlineIcon />
                <Typography variant="body2">hola@wellnub.com</Typography>
              </Link>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default PhoneValidationPage;
