import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  title: {
    textAlign: 'center',
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
  },
  backdrop: {
    minHeight: '90vh',
    textAlign: 'center',
    paddingTop: theme.spacing(4),
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.main,
  },
  subtitle: {
    marginTop: theme.spacing(2),
  },
}));
