import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  title: {
    textAlign: 'center',
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
  },
  backdrop: {
    minHeight: '90vh',
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.main,
  },
  wrapper: {
    padding: theme.spacing(4),
    backgroundColor: '#e7e7e7',
    textAlign: 'center',
    width: '50%',
    marginLeft: 'auto',
    marginRight: 'auto',
    border: '1px solid #c2c2c2',
    borderRadius: theme.shape.borderRadius,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  inputOTP: {
    width: '50px !important',
    height: '50px',
  },
  inputOTPWrapper: {
    marginTop: theme.spacing(2),
    justifyContent: 'center',
  },
  inputOTPError: {
    borderColor: '#ff4262',
  },
  button: {
    '&:disabled': {
      backgroundColor: '#c2c2c2',
    },
  },
  error: {
    marginTop: theme.spacing(1),
    color: '#ff4262',
  },
  infoWrapper: {
    width: '50%',
    display: 'flex',
    marginLeft: 'auto',
    marginRight: 'auto',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  asLink: {
    textDecoration: 'underline',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  disabled: {
    color: '#c2c2c2',
    pointerEvents: 'none',
  },
}));
