import { BASE_URL } from '../constants';
import WellnubService from './wellnubService';

class ExerciseService extends WellnubService {
  create = (payload, isSavingAs) =>
    this.axios.post(
      `${BASE_URL}/v1/videoWorkOut?isSavingAs=${isSavingAs}`,
      payload
    );

  uploadCustomVideo = payload =>
    this.axios.post(`${BASE_URL}/v1/videoWorkOut/customVideo`, payload);

  get = id => this.axios.get(`${BASE_URL}/v1/videoWorkOut/${id}`);

  list = () => this.axios.get(`${BASE_URL}/v1/videoWorkOut`);

  update = (id, payload) =>
    this.axios.patch(`${BASE_URL}/v1/videoWorkOut/${id}`, payload);

  delete = id => this.axios.post(`${BASE_URL}/v1/videoWorkOut/${id}`);

  getVideos = () => this.axios.get(`${BASE_URL}/v1/videoWorkOut/exerciseVideo`);

  getMuscles = () => this.axios.get(`${BASE_URL}/v1/videoWorkOut/muscle`);

  getMaterials = () => this.axios.get(`${BASE_URL}/v1/videoWorkOut/material`);

  getDisciplines = () =>
    this.axios.get(`${BASE_URL}/v1/videoWorkOut/discipline`);

  getRests = () => this.axios.get(`${BASE_URL}/v1/videoWorkOut/rest`);

  getS3UploadURL = () =>
    this.axios.get(`${BASE_URL}/v1/videoWorkOut/s3UploadURL`);

  getVideosByProgram = () =>
    this.axios.get(`${BASE_URL}/v1/videoWorkOut/videosByProgram`);
}

export default ExerciseService;
