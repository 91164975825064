import { useDispatch } from 'react-redux';

import { format } from 'date-fns';

import { Chip } from '@material-ui/core';

import {
  setActiveTime,
  setAppointmentScheduleTime,
} from 'redux/slices/spot.slice';

import { useStyles } from './ChipTimePicker.styles';
import { formatInTimeZone } from 'date-fns-tz';

const ChipTimePicker = ({ data, activeTime }) => {
  const dispatch = useDispatch();

  const classes = useStyles({
    isSelected: data.time === activeTime.time && activeTime.isSelected,
  });

  const onClickHandler = () => {
    dispatch(setAppointmentScheduleTime(data.time));
    if (data.time === activeTime.time) {
      dispatch(
        setActiveTime({
          ...activeTime,
          isSelected: !activeTime.isSelected,
        }),
      );
    } else {
      dispatch(
        setActiveTime({
          ...data,
          isSelected: true,
        }),
      );
    }
  };

  return (
    <Chip
      classes={{ root: classes.chip }}
      // label={format(new Date(data.time), 'HH:mm')}
      label={formatInTimeZone(
        new Date(data.time),
        'America/Mexico_City',
        'HH:mm',
      )}
      disabled={!data.isAvailable}
      onClick={onClickHandler}
    />
  );
};

export default ChipTimePicker;
