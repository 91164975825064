import { createTheme } from '@material-ui/core';

import { overrides } from './overrides';
import { palette } from './palette';
import { typography } from './typography';

export const theme = createTheme({
  overrides,
  palette,
  typography,
  shape: {
    borderRadius: 8,
  },
});
