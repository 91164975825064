export const getStepLen = (step, includeRest = true) => {
  switch (step.type) {
    case 'exercise':
      return getExcerciseLen(step, includeRest);

    case 'rest':
      return includeRest ? getRestLen(step) : 0;

    case 'circuit':
      return getCircuitLen(step, includeRest);

    default:
      return 0;
  }
};

const intValue = number => {
  return isNaN(number) ? 0 : parseInt(number);
};

export const getExcerciseLen = (exercise, includeRest = true) => {
  try {
    if (exercise.special.type === 'bySerie') {
      return (
        getMeasurementLen(exercise.special.measurement) *
        parseInt(exercise.special.duration) *
        parseInt(exercise.series)
      );
    } else if (exercise.special.type === 'allSeries') {
      return (
        getMeasurementLen(exercise.special) *
        parseInt(exercise.special.duration)
      );
    }
    const exerciseTime =
      parseInt(exercise.series) *
      parseInt(exercise.duration) *
      getMeasurementLen(exercise);
    const restTime =
      (parseInt(exercise.series) - 1) *
      parseInt(exercise.restTime == null ? 0 : exercise.restTime);
    return includeRest
      ? intValue(exerciseTime) + intValue(restTime)
      : intValue(exerciseTime);
  } catch (err) {
    console.log(err);
    return 0;
  }
};

export const getMeasurementLen = exercise => {
  switch (exercise.measurement) {
    case 'seconds':
      return 1;

    case 'minutes':
      return 60;

    case 'repetitions':
      // return ex.repetitionLenght;
      return 60;
    default:
      return 0;
  }
};

export const getRestLen = rest =>
  parseInt(rest.duration) * getMeasurementLen(rest);

export const getCircuitLen = (circuit, includeRest = true) => {
  try {
    const circuitSeries = circuit.exercises.map(ciEx =>
      getStepLen(ciEx, includeRest),
    );
    const exercisesAndRestLen =
      circuitSeries.reduce((a, b) => a + b, 0) * parseInt(circuit.laps);
    const circuitRestLen =
      (parseInt(circuit.laps) - 1) * parseInt(circuit.restTime);
    return includeRest
      ? intValue(exercisesAndRestLen) + intValue(circuitRestLen)
      : intValue(exercisesAndRestLen);
  } catch (err) {
    return 0;
  }
};

export const secondsToHms = d => {
  const h = Math.floor(d / 3600);
  const m = Math.floor((d % 3600) / 60);
  const s = Math.floor((d % 3600) % 60);
  const hDisplay = h > 0 ? `0${h}`.slice(-2) + (h == 1 ? ':' : ':') : '';
  const mDisplay = m > 0 ? `0${m}`.slice(-2) + (m == 1 ? ':' : ':') : '00:';
  const sDisplay = s > 0 ? `0${s}`.slice(-2) + (s == 1 ? '' : '') : '00';
  return hDisplay + mDisplay + sDisplay;
};

export const getFullWorkoutLengt = steps => {
  let fullLen = steps.map(step => getStepLen(step)).reduce((a, b) => a + b, 0);
  return secondsToHms(intValue(fullLen));
};

export const getActiveWorkoutLengt = steps => {
  let partialLen = steps
    .map(step => getStepLen(step, false))
    .reduce((a, b) => a + b, 0);
  return secondsToHms(intValue(partialLen));
};
