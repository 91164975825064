import React from 'react';

import PropTypes from 'prop-types';

import {
  Button as StyledButton,
  Box,
  CircularProgress,
} from '@material-ui/core';

import { useStyles } from './Button.styles';

const Button = ({ isLoading, fullWidth, children, ...restProps }) => {
  const classes = useStyles({ fullWidth });

  return (
    <Box className={classes.wrapper}>
      <StyledButton
        disabled={isLoading}
        className={classes.button}
        {...restProps}
      >
        {children}
      </StyledButton>
      {isLoading && (
        <Box className={classes.spinner}>
          <CircularProgress
            size={24}
            classes={{ colorPrimary: classes.action }}
          />
        </Box>
      )}
    </Box>
  );
};

Button.propTypes = {
  isLoading: PropTypes.bool,
  fullWidth: PropTypes.bool,
  children: PropTypes.node,
};

Button.defaultPops = {
  isLoading: false,
  fullWidth: false,
};

export default Button;
