import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { isEmpty } from 'lodash';
import { differenceInDays, isToday } from 'date-fns';

import { Box, Button, Typography } from '@material-ui/core';

import { useStyles } from './InbodyTestCard.styles';

const InbodyTestCard = ({ sender, inbodyPass }) => {
  const [differenceDays, setDifferenceDays] = useState(0);
  const [isTodayLimitDate, setIsTodayLimitDate] = useState(false);

  const { push } = useHistory();

  const classes = useStyles();

  const onClickHandler = () => {
    push('/dashboard/appointment');
  };

  useEffect(() => {
    if (!isEmpty(inbodyPass) && inbodyPass.useLimitDate) {
      const { useLimitDate } = inbodyPass;
      const future = new Date(useLimitDate);
      const today = new Date();
      const diff = differenceInDays(future, today);
      setDifferenceDays(diff);
      setIsTodayLimitDate(isToday(future));
    }
  }, [inbodyPass]);

  return (
    <Box component="aside" className={classes.card}>
      <Typography variant="h6" className={classes.title}>
        Nuevo pase para análisis corporal
      </Typography>
      <Typography variant="body2" className={`${classes.mb} ${classes.text}`}>
        Recibiste un pase para:
      </Typography>
      <Typography variant="body2" className={`${classes.mb} ${classes.text}`}>
        Análisis de composición corporal Wellnub-InBody&reg;
      </Typography>
      <Typography variant="body2" className={`${classes.mb} ${classes.text}`}>
        De tu nutriólogo: {sender.name} {sender.lastName}
      </Typography>
      {isTodayLimitDate ? (
        <Typography
          variant="body2"
          className={classes.text}
          style={{ color: '#ff4262' }}
        >
          Tu pase expira el día de hoy
        </Typography>
      ) : differenceDays === 1 ? (
        <Typography variant="body2" className={classes.text}>
          Te queda 1 día para agendar tu análisis
        </Typography>
      ) : differenceDays === 0 ? (
        <Typography
          variant="body2"
          className={classes.text}
          style={{ color: '#ff4262' }}
        >
          Tu pase expira el día de mañana
        </Typography>
      ) : (
        differenceDays > 0 && (
          <Typography variant="body2" className={classes.text}>
            Te quedan {differenceDays} días para agendar tu análisis
          </Typography>
        )
      )}
      <Box
        component="footer"
        mt={2}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box width="80%">
          <Button
            fullWidth
            variant="contained"
            size="small"
            onClick={onClickHandler}
          >
            Agenda tu prueba aquí
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default InbodyTestCard;
