import { Link as RouterLink, useRouteMatch } from 'react-router-dom';

import {
  Avatar,
  Box,
  Button,
  CardMedia,
  Grid,
  Typography,
} from '@material-ui/core';

import MailIcon from 'assets/icons/mail.svg';
import PhoneIcon from 'assets/icons/phone.svg';

import { useStyles } from './NutritionalPlanCard.styles';

const NutritionalPlanCard = ({ plan, status = 'activo' }) => {
  const { url } = useRouteMatch();

  const classes = useStyles();

  return (
    <Box component="aside" className={classes.card}>
      <Box component="header">
        <Typography
          variant="h6"
          component={RouterLink}
          to={`/nutritionists/${plan?.name?.toLowerCase()?.replace(' ', '-')}/${
            plan?._id ?? ''
          }`}
          className={classes.name}
        >
          {plan?.name ?? 'Nombre'} {plan?.lastName ?? 'Apellido'}
        </Typography>
        <Typography variant="body2">
          {plan?.nutritionistProfile?.mainDegree ?? 'Titulo profesional'}
        </Typography>
      </Box>
      <Box component="main" my={2} className={classes.flex}>
        <Avatar
          className={classes.avatar}
          src={plan?.pictureUrl ?? ''}
          component={RouterLink}
          to={`/nutritionists/${plan?.name?.toLowerCase()?.replace(' ', '-')}/${
            plan?._id ?? ''
          }`}
        >
          {plan?.name[0] ?? 'P'}
          {plan?.lastName[0] ?? 'L'}
        </Avatar>
        <Box textAlign="center" className={classes.infoWrapper}>
          <Typography variant="body2">
            Estatus: {{ pending: 'pendiente' }[status] ?? 'activo'}
          </Typography>
          <Box mt={1}>
            <Typography variant="body2">
              contáctame:&nbsp;&nbsp;
              <a
                href={`https://wa.me/${plan?.phoneNumber ?? ''}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <CardMedia
                  component="img"
                  image={PhoneIcon}
                  style={{ width: 15, display: 'inline-block' }}
                />
              </a>
              &nbsp;&nbsp;
              <a
                href={`mailto:${plan?.email ?? ''}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <CardMedia
                  component="img"
                  image={MailIcon}
                  style={{ width: 15, display: 'inline-block' }}
                />
              </a>
            </Typography>
          </Box>
        </Box>
      </Box>
      <Grid container spacing={1} component="footer" justifyContent="flex-end">
        {status === 'activo' && plan.nutritionistProfile.canUseAgenda && (
          <Grid item>
            <Button
              variant="contained"
              size="small"
              component={RouterLink}
              to={`${url}/create-appointment`}
            >
              Agendar nueva Cita
            </Button>
          </Grid>
        )}
        {plan.latestPlan && (
          <Grid item>
            <Button
              variant="contained"
              size="small"
              component={RouterLink}
              to={`${url}/${plan.latestPlan._id}`}
            >
              Mi plan nutricional
            </Button>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default NutritionalPlanCard;
