import { useDrawer } from 'hooks/drawer.hook';
import { DRAWERS } from 'utils/constants';
import { Box, Drawer, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useStyles } from './AppointmentConfirmationDrawer.styles';

const AppointmentConfirmationDrawer = () => {
  const { visibility, handleClose } = useDrawer(
    DRAWERS.appointmentConfirmation,
  );

  const classes = useStyles();

  return (
    <Drawer
      anchor="right"
      open={visibility}
      onClose={handleClose}
      classes={{ paper: classes.paper }}
    >
      <Box component="header">
        <IconButton onClick={handleClose}>
          <CloseIcon color="primary" />
        </IconButton>
        <Typography variant="h5" className={classes.title}>
          CONFIRMAR CITA
        </Typography>
      </Box>
    </Drawer>
  );
};

export default AppointmentConfirmationDrawer;
