import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import {
  makeStyles,
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@material-ui/core';

import { setModalBehavior } from 'redux/modalBehaviors/actions';

import { MODAL_NAMES } from 'utils/constants';

import SignUpForm from '../forms/SignUp.component';

const useStyles = makeStyles(theme => ({
  dialog: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.main,
  },
  center: {
    textAlign: 'center',
    fontSize: 12,
  },
  mt: {
    marginTop: theme.spacing(1),
  },
  link: {
    textTransform: 'uppercase',
    fontSize: 11,
    fontFamily: theme.typography.h1.fontFamily,
    fontWeight: theme.typography.h1.fontWeight,
    color: 'lightblue',
  },
}));

const SignUpModal = () => {
  const isVisible = useSelector(
    state => state.modalBehaviors[MODAL_NAMES.SIGN_UP],
  );

  const dispatch = useDispatch();

  const classes = useStyles();

  return (
    <Dialog
      open={isVisible}
      onClose={() =>
        dispatch(
          setModalBehavior({ name: MODAL_NAMES.SIGN_UP, isVisible: false }),
        )
      }
      classes={{
        paper: classes.dialog,
      }}
    >
      <DialogTitle disableTypography>
        <Typography variant="h2" color="inherit" align="center">
          Regístrate como cliente Wellnub
        </Typography>
        <Typography
          variant="body2"
          className={`${classes.center} ${classes.mt}`}
        >
          En Wellnub podrás encontrar a tu nutriólogo ideal, realizarte análisis
          de composición corporal con tecnología InBody® y mucho más para lograr
          ser la mejor versión de ti mismo
        </Typography>
        <Typography
          variant="body2"
          className={`${classes.center} ${classes.mt}`}
        >
          Este perfil es únicamente para registrarte como cliente, si quieres
          registrarte como nutriólogo, haz clic{' '}
          <a
            href="https://nutrition.wellnub.com/signUp"
            target="_blank"
            rel="noreferrer"
            className={classes.link}
          >
            aquí
          </a>
        </Typography>
      </DialogTitle>
      <DialogContent>
        <SignUpForm type="light" />
      </DialogContent>
    </Dialog>
  );
};

export default SignUpModal;
