import React from 'react';
import { useController } from 'react-hook-form';
import TimezoneSelect from 'react-timezone-select';

const WellnubTimeZoneInput = ({ name, control, ...restProps }) => {
  const {
    field: { value, onChange, ref, ...restField },
  } = useController({ name, control });

  const onChangeHandler = value => {
    onChange(value.value);
  };

  return (
    <TimezoneSelect
      value={value}
      onChange={onChangeHandler}
      {...restField}
      {...restProps}
    />
  );
};

export default WellnubTimeZoneInput;
