import { useDispatch, batch, useSelector } from 'react-redux';

import { Box, Typography } from '@material-ui/core';

import {
  setIsLoadingScheduleData,
  setScheduleVisibility,
  setSpotData,
} from 'redux/slices/spot.slice';

import { useGetSpotDetails } from 'hooks/inbody.hook';
import { useDrawer } from 'hooks/drawer.hook';

import { DRAWERS } from 'utils/constants';

import { useStyles } from './SpotCard.styles';

const SpotCard = ({ spot }) => {
  const { handleOpen } = useDrawer(DRAWERS.schedule);

  const { data } = useSelector(state => state.spot);

  const { refetch } = useGetSpotDetails(spot._id);

  const dispatch = useDispatch();

  const classes = useStyles({
    isActive: data._id === spot._id,
  });

  const onSelectSpotHandler = () => {
    batch(() => {
      dispatch(setSpotData(spot));
      dispatch(setScheduleVisibility(true));
      dispatch(setIsLoadingScheduleData(true));
    });
    refetch().finally(() => {
      dispatch(setIsLoadingScheduleData(false));
    });
  };

  const onOpenScheduleDetailsHandler = () => {
    dispatch(setSpotData(spot));
    handleOpen();
  };

  return (
    <Box
      component="aside"
      className={classes.card}
      onClick={onSelectSpotHandler}
    >
      <Box component="header" className={classes.titleWrapper}>
        <Typography
          variant="body2"
          className={`${classes.title} ${classes.ellipsis} ${classes.link}`}
        >
          {spot.name}
        </Typography>
        <Typography
          variant="body2"
          className={`${classes.title} ${classes.ellipsis} ${classes.link}`}
        >
          {spot.address?.neighborhood ?? 'Colonia'}
        </Typography>
      </Box>
      <Box component="main">
        <Typography
          variant="body2"
          className={`${classes.address} ${classes.ellipsis}`}
        >
          {spot.address?.street ?? 'Calle, Número'},&nbsp;
          {spot.address?.neighborhood ?? 'Colonia'},&nbsp;
          {spot.address?.city ?? 'Ciudad'},&nbsp;
          {spot.address?.state ?? 'Estado'}
        </Typography>
      </Box>
      <Box style={{ paddingTop: '4px', paddingBottom: '4px' }}>
        <Typography
          variant="body2"
          className={classes.link}
          onClick={onOpenScheduleDetailsHandler}
        >
          Ver detalle
        </Typography>
      </Box>
    </Box>
  );
};

export default SpotCard;
