import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useHistory } from 'react-router-dom';

import { Avatar, Menu, MenuItem, Link } from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';

import { setAuthentication } from 'redux/slices/authentication.slice';

import { useStyles } from './ProfileDropdown.styles';

const ProfileDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [anchor, setAnchor] = useState(null);

  const dispatch = useDispatch();

  const { replace } = useHistory();

  const classes = useStyles();

  const onOpenHandler = event => {
    setIsOpen(true);
    setAnchor(event.currentTarget);
  };

  const onCloseHandler = () => {
    setIsOpen(false);
  };

  const onSignOutHandler = () => {
    localStorage.removeItem('authentication');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('user');
    dispatch(setAuthentication(false));
    replace('/');
  };

  return (
    <>
      <Avatar onClick={onOpenHandler} className={classes.avatar}>
        <AccountCircle color="secondary" />
      </Avatar>
      <Menu
        keepMounted
        open={isOpen}
        anchorEl={anchor}
        classes={{ paper: classes.menu }}
        onClose={onCloseHandler}
        onClick={onCloseHandler}
        transformOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem classes={{ root: classes.item }}>
          <Link
            to="/settings"
            color="inherit"
            underline="none"
            component={RouterLink}
          >
            Configuración
          </Link>
        </MenuItem>
        <MenuItem classes={{ root: classes.item }}>
          <Link
            to="/market"
            color="inherit"
            underline="none"
            component={RouterLink}
          >
            Compras
          </Link>
        </MenuItem>
        <MenuItem classes={{ root: classes.item }} onClick={onSignOutHandler}>
          Cerrar sesión
        </MenuItem>
      </Menu>
    </>
  );
};

export default ProfileDropdown;
