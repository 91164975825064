import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import {
  Container,
  Box,
  makeStyles,
  Typography,
  IconButton,
  CardMedia,
  Link,
} from '@material-ui/core';

import fb from 'assets/icons/facebook.png';
import ig from 'assets/icons/instagram.png';

const useStyles = makeStyles(theme => ({
  footer: {
    padding: theme.spacing(3, 0),
    backgroundColor: theme.palette.gray.main,
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  info: {
    color: 'white',
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
    },
  },
  icon: {
    marginLeft: theme.spacing(2),
    width: 30,
  },
  fb: {
    color: '#8b9dc3',
  },
  ig: {
    color: '#c1558b',
  },
  social: {
    width: 30,
    height: 30,
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
}));

const Footer = (props, ref) => {
  const classes = useStyles();

  const facebookURL = new URL(
    '/Wellnub-109247271500626',
    'https://www.facebook.com',
  );

  const instagramURL = new URL('/wellnub', 'https://www.instagram.com');

  return (
    <footer className={classes.footer} ref={ref}>
      <Container className={classes.container}>
        <Box className={classes.wrapper}>
          <Typography
            variant="body2"
            color="secondary"
            className={classes.info}
          >
            Copyright &reg; Wellnub {new Date().getFullYear()}
          </Typography>
          <Link
            to="/conditions"
            component={RouterLink}
            className={classes.info}
            underline="none"
            target="_blank"
          >
            Términos y Condiciones
          </Link>
          <Link
            to="/privacy"
            component={RouterLink}
            className={classes.info}
            underline="none"
            target="_blank"
          >
            Aviso de privacidad de datos
          </Link>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-evenly">
          <IconButton onClick={() => window.open(facebookURL.toString())}>
            <CardMedia component="img" image={fb} className={classes.social} />
            {/*<FacebookIcon classes={{ root: classes.fb }} />*/}
          </IconButton>
          <IconButton onClick={() => window.open(instagramURL.toString())}>
            <CardMedia component="img" image={ig} className={classes.social} />
            {/*<InstagramIcon classes={{ root: classes.ig }} />*/}
          </IconButton>
        </Box>
      </Container>
    </footer>
  );
};

export default forwardRef(Footer);
