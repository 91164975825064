import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  panel: {
    padding: theme.spacing(3, 4),
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.main,
    borderRadius: theme.shape.borderRadius,
  },
  title: {
    textTransform: 'uppercase',
    textAlign: 'center',
    paddingBottom: theme.spacing(2),
  },
  text: {
    textAlign: 'center',
  },
  iconEmpty: {
    color: '#c2c2c2',
  },
  iconFilled: {
    color: theme.palette.action.main,
  },
  ratingName: {
    paddingBottom: theme.spacing(2),
    color: ({ hasError }) => (hasError ? '#ff4262' : '#0b1015'),
  },
  button: {
    '&:disabled': {
      backgroundColor: '#c2c2c2',
    },
  },
}));
