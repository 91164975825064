import React from 'react';

import PropTypes from 'prop-types';

import { Box, makeStyles } from '@material-ui/core';

import { ProgramCard } from 'components/molecules';

const useStyles = makeStyles(() => ({
  list: {
    display: 'grid',
    gap: 30,
    gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
  },
}));

const ProgramList = ({ programs }) => {
  const classes = useStyles();

  return (
    <Box component="ul" className={classes.list}>
      {programs.map(program => (
        <Box key={program._id} component="li">
          <ProgramCard program={program} />
        </Box>
      ))}
    </Box>
  );
};

ProgramList.propTypes = {
  programs: PropTypes.array.isRequired,
};

export default ProgramList;
