import { useEffect, useRef, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import { useForm, Controller } from 'react-hook-form';

import {
  Box,
  Grid,
  TextField,
  Typography,
  ThemeProvider,
  InputLabel,
  Select,
  FormControl,
  Button,
  FormHelperText,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { sub } from 'date-fns';
import { isEmpty } from 'lodash';

import { currencyFormatter, generateTheme } from 'utils/helpers';
import { WellnubSelectInput, WellnubDateInput } from '@wellnub/web-common';

import { setModalStatus } from 'redux/slices/modal.slice';
import { wnUserSelector } from 'redux/slices/wnUser/wnUser.selector';
import {
  setNutritionistGetInTouchData,
  setNutritionistId,
  setNutritionistMode,
} from 'redux/slices/nutritionist.slice';
import { useRequestGetInTouch } from 'hooks/nutritionists.hook';

import { useStyles } from './GetInTouchNutritionistForm.styles';

const GENDER_OPTIONS = [
  { id: 'male', value: 'male', label: 'Hombre' },
  { id: 'female', value: 'female', label: 'Mujer' },
  { id: 'other', value: 'other', label: 'Otro' },
];

const GetInTouchNutritionistForm = () => {
  const [services, setServices] = useState([]);
  const [mode, setActiveMode] = useState('');
  const [service, setService] = useState({});

  const { _id: id, services: list } = useSelector(state => state.nutritionist);
  const wnUser = useSelector(wnUserSelector);

  const { control, handleSubmit, getValues, trigger, formState, setValue } =
    useForm({
      defaultValues: {
        sex: '',
        birthdate: new Date(),
        date: new Date(),
        time: new Date(),
      },
    });

  const { mutate: getInTouch, isLoading } = useRequestGetInTouch();

  const startRef = useRef(null);

  const dispatch = useDispatch();

  const classes = useStyles();

  const onSubmitHandler = data => {
    batch(() => {
      dispatch(setNutritionistId(id));
      dispatch(
        setNutritionistGetInTouchData({ ...data, shouldLinkRequest: true }),
      );
      dispatch(setNutritionistMode('LINK'));
      dispatch(
        setModalStatus({
          name: 'GET_IN_TOUCH_NUTRITION_STEP_2',
          isActive: true,
        }),
      );
    });
  };

  const modes = Object.keys(list).filter(service => list[service].length !== 0);

  const onCheckboxChangedHandler = (value, checked) => {
    const { contactMethod = [] } = getValues();
    if (checked) {
      return [...contactMethod, value];
    } else {
      return contactMethod.filter(s => s !== value);
    }
  };

  const onSendInfoHandler = async () => {
    const data = getValues();
    const result = await trigger();
    if (result) {
      getInTouch({
        id,
        payload: data,
        shouldLinkRequest: false,
      });
    }
  };

  useEffect(() => {
    if (list !== undefined) {
      setServices([...(list[mode] ?? [])]);
    }
  }, [mode]);

  useEffect(() => {
    if (!isEmpty(formState.errors)) {
      startRef.current.scrollIntoView();
    }
  }, [formState]);

  useEffect(() => {
    if (!isEmpty(wnUser)) {
      setValue('birthdate', new Date(wnUser.birthdate));
      setValue('sex', wnUser.sex);
    }
  }, [wnUser, setValue]);

  return (
    <Box>
      <Typography ref={startRef}>
        Esta información será de gran ayuda para que el nutriólogo tenga tu
        perfil completo
      </Typography>
      <form onSubmit={handleSubmit(onSubmitHandler)} className={classes.form}>
        <ThemeProvider theme={theme => generateTheme(theme, 'light')}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <WellnubSelectInput
                fullWidth
                name="sex"
                control={control}
                options={GENDER_OPTIONS}
                label="Sexo *"
                variant="outlined"
                color="secondary"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <WellnubDateInput
                fullWidth
                name="birthdate"
                control={control}
                color="secondary"
                format="dd/MM/yyyy"
                inputVariant="outlined"
                label="Fecha de nacimiento"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="serviceMode"
                defaultValue=""
                control={control}
                rules={{
                  required: 'Este campo es requerido',
                }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <FormControl variant="outlined" fullWidth error={!!error}>
                    <InputLabel htmlFor="mode">Modalidad*</InputLabel>
                    <Select
                      native
                      label="Modalidad*"
                      value={value}
                      onChange={e => {
                        setActiveMode(e.target.value);
                        return onChange(e);
                      }}
                    >
                      <option aria-label="none" value="" />
                      {modes.map(mode => (
                        <option value={mode} key={mode}>
                          {
                            {
                              online: 'Online',
                              home: 'A domicilio',
                              site: 'Presencial',
                            }[mode]
                          }
                        </option>
                      ))}
                    </Select>
                    <FormHelperText>
                      {error
                        ? error.message
                        : 'Selecciona si quieres recibir tu servicio online, presencial o a domicilio'}
                    </FormHelperText>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="serviceId"
                defaultValue=""
                control={control}
                rules={{
                  required: 'Este campo es requerido',
                }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <FormControl variant="outlined" fullWidth error={!!error}>
                    <InputLabel htmlFor="service">Servicio*</InputLabel>
                    <Select
                      native
                      label="Servicio*"
                      value={value}
                      disabled={isEmpty(mode)}
                      className={classes.selectDisabled}
                      // classes={{ disabled: classes.selectDisabled }}
                      onChange={e => {
                        const id = e.target.value;
                        const service = services.find(
                          service => service._id === id,
                        );
                        setService(service);
                        return onChange(e);
                      }}
                    >
                      <option aria-label="none" value="" />
                      {services.map(service => (
                        <option value={service._id} key={service._id}>
                          {service.service.name}
                        </option>
                      ))}
                    </Select>
                    <FormHelperText>
                      {error
                        ? error.message
                        : 'Selecciona el servicio en el que estás interesado'}
                    </FormHelperText>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Box textAlign="center">
                  <Typography>Precio</Typography>
                  {isEmpty(service) ? (
                    <Box mt={2} textAlign="center">
                      <Typography variant="body2">
                        Selecciona un servicio para ver el precio
                      </Typography>
                    </Box>
                  ) : (
                    <Box mt={2}>
                      {service.isPricePublic && (
                        <NumberFormat
                          value={(service?.price ?? 0.0001) * 100}
                          displayType="text"
                          format={currencyFormatter}
                        />
                      )}
                      {!service.isPricePublic && (
                        <Typography variant="body2">
                          Consultar precio con nutriólogo
                        </Typography>
                      )}
                    </Box>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography align="center">Duración:</Typography>
                {isEmpty(service) ? (
                  <Box mt={2} textAlign="center">
                    <Typography variant="body2">
                      Selecciona un servicio para ver la duración
                    </Typography>
                  </Box>
                ) : (
                  <Typography
                    align="center"
                    variant="body2"
                    style={{ marginTop: 16 }}
                  >
                    {service?.minutesLenght} minutos
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography>Descripción del servicio</Typography>
              {service?.description === '' ? (
                <Box className={classes.description}>Sin descripción</Box>
              ) : (
                <Box className={classes.description}>
                  {service?.description ??
                    'Selecciona un servicio disponible para ver su descripción'}
                </Box>
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography>
                Selecciona tu método de contacto favorito*
              </Typography>
              <Controller
                name="contactMethod"
                defaultValue={[]}
                control={control}
                rules={{
                  required: 'Este campo es requerido',
                }}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <FormControl
                    error={!!error}
                    component="fieldset"
                    className={classes.form}
                  >
                    <FormGroup>
                      <Grid container spacing={2}>
                        {['phone', 'whatsapp', 'email'].map(item => (
                          <Grid item xs={4} key={item}>
                            <FormControlLabel
                              label={
                                {
                                  phone: 'Teléfono',
                                  whatsapp: 'Whatsapp',
                                  email: 'Email',
                                }[item]
                              }
                              control={
                                <Checkbox
                                  onChange={(_, checked) => {
                                    onChange(
                                      onCheckboxChangedHandler(item, checked),
                                    );
                                  }}
                                />
                              }
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </FormGroup>
                    <FormHelperText>
                      {error ? error.message : 'Método de contacto'}
                    </FormHelperText>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="comments"
                control={control}
                defaultValue=""
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <TextField
                    fullWidth
                    multiline
                    rows={2}
                    type="text"
                    label="Comentarios adicionales"
                    value={value}
                    error={!!error}
                    variant="outlined"
                    onChange={onChange}
                  />
                )}
              />
            </Grid>
          </Grid>
        </ThemeProvider>
        <Typography variant="h6" align="right">
          * Campos obligatorios
        </Typography>
        <Box
          mt={2}
          textAlign="right"
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Box mr={2}>
            <Button
              variant="outlined"
              size="small"
              color="primary"
              onClick={onSendInfoHandler}
            >
              Quiero información de servicios
            </Button>
          </Box>
          <Button variant="contained" size="small" type="submit">
            Quiero empezar
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default GetInTouchNutritionistForm;
