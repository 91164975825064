import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { format } from 'date-fns';
import es from 'date-fns/locale/es';

import {
  ThemeProvider,
  Box,
  Button,
  TextField,
  Typography,
} from '@material-ui/core';
import { Rating } from '@material-ui/lab';

import { setBackdropVisibility } from 'redux/slices/backdrop.slice';

import { usePostMissingRating } from 'hooks/user.hook';

import { generateTheme } from 'utils/helpers';

import { useStyles } from './RaitingPanel.styles';

const RaitingPanel = () => {
  const [hasError, setHasError] = useState(false);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');

  const { mutate } = usePostMissingRating();

  const {
    id,
    location: { name, date },
  } = useSelector(state => state.user.ratings);

  const dispatch = useDispatch();

  const classes = useStyles({ hasError });

  const onClickHandler = () => {
    if (!!rating) {
      dispatch(setBackdropVisibility(false));
      setHasError(false);
      mutate({
        id,
        data: {
          rating: {
            score: rating,
            comment,
          },
        },
      });
    } else {
      setHasError(true);
    }
  };

  const onChangeRatingHandler = (event, value) => {
    if (hasError) {
      setHasError(false);
    }
    setRating(value);
  };

  const onChangeCommentHandler = event => {
    const { value } = event.target;
    setComment(value);
  };

  return (
    <Box className={classes.panel}>
      <Typography variant="h4" className={classes.title}>
        ¡Queremos darte la mejor experiencia!
      </Typography>
      <Typography className={classes.text}>
        Cuéntanos cómo te fue en tu último escaneo <br />
        Wellnub - InBody en <strong>{name}</strong> el{' '}
        <strong>
          {format(new Date(date || null), "d'-'MMM'-'Y", { locale: es })}
        </strong>
      </Typography>
      <Box mt={2} textAlign="center">
        <Typography className={classes.ratingName}>
          {rating ? (
            {
              1: 'Muy mala',
              2: 'Mala',
              3: 'Regular',
              4: 'Buena',
              5: 'Excelente',
            }[rating]
          ) : (
            <span>Selecciona una calificación</span>
          )}
        </Typography>
        <Rating
          value={rating}
          name="pristine"
          size="large"
          classes={{
            iconEmpty: classes.iconEmpty,
            iconFilled: classes.iconFilled,
          }}
          onChange={onChangeRatingHandler}
        />
      </Box>
      <Box mt={2} textAlign="right">
        <ThemeProvider theme={theme => generateTheme(theme, 'light')}>
          <TextField
            fullWidth
            multiline
            rows={2}
            value={comment}
            onChange={onChangeCommentHandler}
            color="primary"
            variant="outlined"
            label="Comentarios (opcional)"
            inputProps={{
              maxLength: 300,
            }}
          />
        </ThemeProvider>
        <Typography variant="body2">
          {comment.length} de 300 caracteres
        </Typography>
      </Box>
      <Box mt={2} textAlign="center">
        <Button
          disabled={!!!rating}
          onClick={onClickHandler}
          color="primary"
          variant="contained"
          className={classes.button}
        >
          Aceptar
        </Button>
      </Box>
    </Box>
  );
};

export default RaitingPanel;
