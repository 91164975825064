import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch, useHistory } from 'react-router-dom';

import { Avatar, Box, Button, CardMedia, Typography } from '@material-ui/core';

import filter_1 from 'assets/icons/presencial.png';
import filter_2 from 'assets/icons/online.png';
import filter_3 from 'assets/icons/domicilio.png';

import { setNutritionist } from 'redux/slices/nutritionist.slice';
import { setModalStatus } from 'redux/slices/modal.slice';
import { linkRequestsSelector } from 'redux/slices/linkRequests.selector';
import { wnUserSelector } from 'redux/slices/wnUser/wnUser.selector';

import { useStyles } from './NutritionistCard.styles';

const NutritionistCard = ({
  nutritionist: { _id: id, name, lastName, pictureUrl, nutritionistProfile },
}) => {
  const [services] = useState({
    site: filter_1,
    online: filter_2,
    home: filter_3,
  });

  const [modes, setModes] = useState([]);

  const { isAuthenticated } = useSelector(state => state.authentication);
  const linkRequests = useSelector(linkRequestsSelector);
  const wnUser = useSelector(wnUserSelector);

  const { url } = useRouteMatch();
  const { push } = useHistory();

  const classes = useStyles();

  const dispatch = useDispatch();

  const onGetInTouchHandler = () => {
    if (isAuthenticated) {
      if (
        linkRequests.some(
          linkRequest => linkRequest.nutritionistContact?.nutritionist === id,
        ) ||
        wnUser.patientProfile.nutritionist === id
      ) {
        dispatch(
          setModalStatus({
            name: 'LINK_REQUEST_WARNING',
            isActive: true,
          }),
        );
        return;
      }
    }
    const online = nutritionistProfile.services.filter(service =>
      service.mode.includes('online'),
    );
    const site = nutritionistProfile.services.filter(service =>
      service.mode.includes('site'),
    );
    const home = nutritionistProfile.services.filter(service =>
      service.mode.includes('home'),
    );
    dispatch(
      setNutritionist({
        _id: id,
        name: name,
        services: {
          online,
          site,
          home,
        },
      }),
    );

    if (isAuthenticated) {
      push(`${url}/contact/link-request/${id}`);
    } else {
      dispatch(
        setModalStatus({
          name: 'SPECIAL_SIGN_IN',
          isActive: true,
        }),
      );
    }
  };

  const onOpenProfileHandler = () => {
    const format = name.toLowerCase().replace(' ', '-');
    push(`${url}/${format}/${id}`);
  };

  useEffect(() => {
    const sum = nutritionistProfile.services.reduce((accumulator, service) => {
      return [...accumulator, ...service.mode];
    }, []);
    const modes = [...new Set(sum)];
    setModes(modes);
  }, []);

  return (
    <Box component="aside" className={classes.card}>
      <Box component="header" mb={1}>
        <Box className={classes.wrapper}>
          <Typography
            variant="h6"
            component="h1"
            className={classes.name}
            onClick={onOpenProfileHandler}
          >
            {name} {lastName}
          </Typography>
          <Box component="ul" className={classes.flex}>
            {modes.map((mode, index) => (
              <CardMedia
                key={`${mode}-${index}`}
                component="img"
                image={services[mode]}
                className={classes.service}
              />
            ))}
          </Box>
        </Box>
        <Typography variant="body2" className={classes.ellipsis}>
          {nutritionistProfile.mainDegree || 'Titulo universitario'}
        </Typography>
      </Box>
      <Box component="main" mb={1} className={classes.flex}>
        <Avatar
          className={classes.avatar}
          src={pictureUrl}
          onClick={onOpenProfileHandler}
        >
          {name[0]}
          {lastName[0]}
        </Avatar>
        <Box ml={4} className={classes.start}>
          <Typography variant="body2">Especialidades:</Typography>
          <ul className={classes.specs}>
            {nutritionistProfile?.specialities.map(spec => (
              <li key={spec._id}>
                <Typography variant="body2">{spec.name}</Typography>
              </li>
            ))}
          </ul>
        </Box>
      </Box>
      <Box component="footer" textAlign="right">
        <Button
          size="small"
          variant="text"
          onClick={onOpenProfileHandler}
          className={classes.mr}
        >
          Ver mas información
        </Button>
        <Button size="small" variant="contained" onClick={onGetInTouchHandler}>
          Contactar
        </Button>
      </Box>
    </Box>
  );
};

export default NutritionistCard;