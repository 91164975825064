import { Box, Button, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { setModalStatus } from 'redux/slices/modal.slice';
import { useCancelSubscription } from 'hooks/payments.hook';

const CancelPaymentSubscriptionPanel = () => {
  const { subscription } = useSelector(state => state.payments);

  const { mutate: requestCancelSubscription, isLoading } =
    useCancelSubscription();

  const dispatch = useDispatch();

  const onAcceptHandler = () => {
    requestCancelSubscription();
  };

  const onCancelHandler = () => {
    dispatch(
      setModalStatus({
        name: 'CANCEL_PAYMENT_SUBSCRIPTION',
        isActive: false,
      }),
    );
  };

  return (
    <Box>
      <Typography>
        ¿Estás seguro que deseas cancelar la suscripción al programa{' '}
        <Typography component="span" variant="h6">
          {subscription?.storeProduct?.name}
        </Typography>
        ?
      </Typography>
      <Box
        mt={1}
        display="flex"
        alignItems="center"
        justifyContent="space-evenly"
      >
        <Button
          variant="contained"
          color="primary"
          onClick={onAcceptHandler}
          disabled={isLoading}
        >
          Aceptar
        </Button>
        <Button variant="outlined" color="primary" onClick={onCancelHandler}>
          Cancelar
        </Button>
      </Box>
    </Box>
  );
};

export default CancelPaymentSubscriptionPanel;
