import React from 'react';

import PropTypes from 'prop-types';

import { Box, makeStyles } from '@material-ui/core';

import { CoachCard } from 'components/molecules';

const useStyles = makeStyles(() => ({
  list: {
    display: 'grid',
    gap: 30,
    gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
  },
}));

const CoachList = ({ coaches }) => {
  const classes = useStyles();

  return (
    <Box component="ul" className={classes.list}>
      {coaches.map(coach => (
        <Box key={coach._id} component="li">
          <CoachCard coach={coach} />
        </Box>
      ))}
    </Box>
  );
};

CoachList.propTypes = {
  coaches: PropTypes.array.isRequired,
};

export default CoachList;
