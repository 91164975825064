import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import {
  useTheme,
  useMediaQuery,
  makeStyles,
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@material-ui/core';

import { setModalBehavior } from 'redux/modalBehaviors/actions';

import { MODAL_NAMES } from 'utils/constants';

import GetInTouchForm from '../forms/GetInTouch.component';

const useStyles = makeStyles(theme => ({
  dialog: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.main,
  },
}));
const GetInTouchModal = () => {
  const isVisible = useSelector(
    state => state.modalBehaviors[MODAL_NAMES.GET_IN_TOUCH],
  );

  const dispatch = useDispatch();

  const classes = useStyles();

  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Dialog
      fullScreen={matches}
      open={isVisible}
      onClose={() =>
        dispatch(
          setModalBehavior({
            name: MODAL_NAMES.GET_IN_TOUCH,
            isVisible: false,
          }),
        )
      }
      classes={{ paper: classes.dialog }}
    >
      <DialogTitle disableTypography>
        <Typography variant="h2" color="inherit" align="center">
          Gracias por tu interés en mis programas
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText color="inherit" align="center">
          Déjame tus datos y me pondré en contacto contigo para compartirte toda
          la información que necesitas ¡y comenzar a entrenar conmigo!
        </DialogContentText>
        <Box pt={3}>
          <GetInTouchForm />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default GetInTouchModal;
