import React from 'react';
import { useController } from 'react-hook-form';

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import deLocale from 'date-fns/locale/es';

const WellnubDateInput = ({
  name,
  control,
  label,
  rules,
  minDate,
  maxDate,
  helperText,
  ...restProps
}) => {
  const {
    field: { ref, ...inputProps },
    fieldState: { error },
  } = useController({ name, control, rules });

  return (
    <MuiPickersUtilsProvider locale={deLocale} utils={DateFnsUtils}>
      <KeyboardDatePicker
        {...restProps}
        {...inputProps}
        helperText={error?.message ?? helperText}
        minDate={minDate}
        maxDate={maxDate}
        label={label}
        error={!!error}
      />
    </MuiPickersUtilsProvider>
  );
};

export default WellnubDateInput;
