import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { batch, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import NumberFormat from 'react-number-format';
import { yupResolver } from '@hookform/resolvers/yup';
import { add, format, sub } from 'date-fns';
import { isEmpty } from 'lodash';
import { es } from 'date-fns/locale';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  ThemeProvider,
  Typography,
} from '@material-ui/core';

import {
  WellnubDatePicker,
  WellnubTimeChipPicker,
  WellnubDateInput,
  WellnubSelectInput,
  WellnubCheckboxGroupInput,
  WellnubTextInput,
} from '@wellnub/web-common';

import { setModalStatus } from 'redux/slices/modal.slice';

import { currencyFormatter, generateTheme } from 'utils/helpers';

import {
  useGetNutritionist,
  useGetNutritionistAppointmentSchedule,
  useRequestGetInTouch,
} from 'hooks/nutritionists.hook';
import { useGetProfile } from 'hooks/user.hook';

import { FormSchema } from './NutritionistContact.schema';

import {
  setNutritionistGetInTouchData,
  setNutritionistId,
  setNutritionistMode,
} from 'redux/slices/nutritionist.slice';

import { useStyles } from './NutritionistContact.styles';

const CONTACT_METHOD_OPTIONS = [
  { id: 'phone', value: 'phone', label: 'Teléfono' },
  { id: 'whatsapp', value: 'whatsapp', label: 'Whatsapp' },
  { id: 'email', value: 'email', label: 'Email' },
];

const GENDER_OPTIONS = [
  { id: 'male', value: 'male', label: 'Hombre' },
  { id: 'female', value: 'female', label: 'Mujer' },
  { id: 'other', value: 'other', label: 'Otro' },
];

const NutritionistContactPage = () => {
  const [service, setService] = useState({});
  const [services, setServices] = useState([]);
  const [modes, setModes] = useState([]);
  const [query, setQuery] = useState({});
  const [selectedDay, setSelectedDay] = useState({});
  const [excludedDates, setExcludedDates] = useState([]);
  const [office, setOffice] = useState({});

  const [isAppointmentAccepted, setIsAppointmentAccepted] = useState('no');

  const { id } = useParams();

  const { data: nutritionist, isLoading, isError } = useGetNutritionist(id);

  const {
    data: wnUser,
    isLoading: isLoadingUser,
    isError: isErrorOnUser,
  } = useGetProfile();

  const {
    data: schedule,
    refetch,
    isFetching,
  } = useGetNutritionistAppointmentSchedule(query);

  const { mutate: requestInfo } = useRequestGetInTouch();

  const { control, handleSubmit, watch, getValues, trigger, setValue } =
    useForm({
      mode: 'onBlur',
      resolver: yupResolver(FormSchema),
      defaultValues: {
        date: new Date(),
        time: new Date(),
        comments: '',
        gender: '',
        mode: '',
        service: '',
        birthdate: sub(new Date(), { years: 18 }),
        contactMethod: [],
      },
    });

  const dispatch = useDispatch();

  const onSubmitHandler = handleSubmit(data => {
    const {
      mode: serviceMode,
      service: serviceId,
      gender: sex,
      ...restValues
    } = data;
    batch(() => {
      dispatch(setNutritionistId(id));
      dispatch(
        setNutritionistGetInTouchData({
          serviceMode,
          serviceId,
          sex,
          ...restValues,
          withAppointment: isAppointmentAccepted,
          shouldLinkRequest: true,
          office: office?._id ?? '',
        }),
      );
      dispatch(setNutritionistMode('LINK'));
      dispatch(
        setModalStatus({
          name: 'GET_IN_TOUCH_NUTRITION_STEP_2',
          isActive: true,
        }),
      );
    });
  });

  const onRequestInfoClickHandler = async () => {
    const {
      mode: serviceMode,
      service: serviceId,
      gender: sex,
      ...restValues
    } = getValues();
    const isSubmitValid = await trigger();
    if (isSubmitValid) {
      requestInfo({
        id,
        payload: { serviceMode, serviceId, sex, ...restValues },
        shouldLinkRequest: false,
      });
    }
    // dispatch(
    //   setModalStatus({
    //     name: 'GET_IN_TOUCH_NUTRITION',
    //     isActive: true,
    //   }),
    // );
  };

  const onAcceptAppointmentHandler = event => {
    const { value } = event.target;
    setIsAppointmentAccepted(value);
  };

  const [serviceWatcher, modeWatcher, dateWatcher, timeWatcher] = watch([
    'service',
    'mode',
    'date',
    'time',
  ]);

  useEffect(() => {
    if (!isEmpty(nutritionist)) {
      const services = nutritionist.nutritionistProfile.services.map(
        service => ({
          id: service._id,
          modes: service.mode,
          description: service.description,
          value: service._id,
          label: service.service.name,
          price: service.price,
          duration: service.minutesLenght,
          isPricePublic: service.isPricePublic,
        }),
      );
      setServices(services);
    }
  }, [nutritionist]);

  useEffect(() => {
    if (!isEmpty(wnUser)) {
      const { data } = wnUser;
      setValue('birthdate', new Date(data.birthdate));
      setValue('gender', data.sex);
    }
  }, [wnUser, setValue]);

  useEffect(() => {
    if (!isEmpty(services) && !isEmpty(serviceWatcher)) {
      const service =
        services.find(service => service.id === serviceWatcher) ?? {};
      const modes =
        service?.modes?.map(mode => ({
          id: mode,
          value: mode,
          label: {
            online: 'En Línea',
            site: 'Presencial',
            home: 'A domicilio',
          }[mode],
        })) ?? [];
      setService(service);
      setModes(modes);
    }
  }, [serviceWatcher, services]);

  useEffect(() => {
    if (
      !isEmpty(serviceWatcher) &&
      !isEmpty(modeWatcher) &&
      !isEmpty(nutritionist)
    ) {
      const query = {
        nutritionistId: id,
        params: {
          service: serviceWatcher,
          mode: modeWatcher,
          timeZone:
            modeWatcher === 'online'
              ? new Intl.DateTimeFormat().resolvedOptions().timeZone
              : nutritionist?.nutritionistProfile?.settings?.timeZone ?? '',
        },
      };
      setQuery(query);
    }
  }, [serviceWatcher, modeWatcher, id, nutritionist]);

  useEffect(() => {
    if (!isEmpty(query) && nutritionist.nutritionistProfile.canUseAgenda) {
      refetch().catch(_ => {});
    }
  }, [query, refetch, nutritionist]);

  useEffect(() => {
    if (!isEmpty(schedule)) {
      const openDays = schedule.openDays;
      const excludedDates = schedule.schedules
        .map(item => new Date(item.date))
        .filter(item => !openDays.includes(item.getDay()));
      const day =
        schedule.schedules.find(
          item => format(dateWatcher, 'yyyy-MM-dd') === item.date,
        ) || {};
      if (!isEmpty(day.afternoon)) {
        day.afternoon = day.afternoon.map(item => ({
          ...item,
          timeZone:
            modeWatcher === 'online'
              ? new Intl.DateTimeFormat().resolvedOptions().timeZone
              : nutritionist?.nutritionistProfile?.settings?.timeZone ?? '',
        }));
      }
      if (!isEmpty(day.morning)) {
        day.morning = day.morning.map(item => ({
          ...item,
          timeZone:
            modeWatcher === 'online'
              ? new Intl.DateTimeFormat().resolvedOptions().timeZone
              : nutritionist?.nutritionistProfile?.settings?.timeZone ?? '',
        }));
      }
      if (!isEmpty(day.night)) {
        day.night = day.night.map(item => ({
          ...item,
          timeZone:
            modeWatcher === 'online'
              ? new Intl.DateTimeFormat().resolvedOptions().timeZone
              : nutritionist?.nutritionistProfile?.settings?.timeZone ?? '',
        }));
      }
      setSelectedDay(day);
      setExcludedDates(excludedDates);
    }
  }, [schedule, dateWatcher, modeWatcher, nutritionist]);

  useEffect(() => {
    if (!isEmpty(selectedDay) && !isEmpty(timeWatcher)) {
      const office =
        [
          ...selectedDay.morning,
          ...selectedDay.afternoon,
          ...selectedDay.night,
        ]?.find(item => item.time === timeWatcher)?.office ?? {};
      setOffice(office);
    }
  }, [selectedDay, timeWatcher]);

  const classes = useStyles();

  if (isLoading || isLoadingUser) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" mt={4}>
        <CircularProgress />
      </Box>
    );
  }

  if (isError || isErrorOnUser) {
    return (
      <Box mt={4}>
        <Typography>
          No se pudo cargar la información, intenta de nuevo más tarde.
        </Typography>
      </Box>
    );
  }

  return (
    <Box className={classes.backdrop}>
      <Helmet>
        <title>Solicitud de vinculación | Wellnub</title>
      </Helmet>
      <Container className={classes.container}>
        <form onSubmit={onSubmitHandler}>
          <Typography variant="h3" align="center" className={classes.mb2}>
            Sólo unos datos adicionales
          </Typography>
          <Typography align="center" className={classes.mb2}>
            Esta información será de gran ayuda para que el nutriólogo tenga tu
            perfil completo.
          </Typography>
          <ThemeProvider theme={theme => generateTheme(theme, 'light')}>
            <Grid container spacing={4} className={classes.mb2}>
              <Grid item xs={12} sm={6}>
                <WellnubSelectInput
                  name="gender"
                  control={control}
                  options={GENDER_OPTIONS}
                  label="Sexo *"
                  variant="outlined"
                  fullWidth
                  color="secondary"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <WellnubDateInput
                  fullWidth
                  control={control}
                  name="birthdate"
                  color="secondary"
                  format="dd/MM/yyyy"
                  inputVariant="outlined"
                  label="Fecha de nacimiento"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <WellnubSelectInput
                  name="service"
                  control={control}
                  options={services}
                  label="Servicio *"
                  variant="outlined"
                  fullWidth
                  color="secondary"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <WellnubSelectInput
                  name="mode"
                  control={control}
                  disabled={isEmpty(serviceWatcher)}
                  options={modes}
                  label="Modalidad *"
                  variant="outlined"
                  fullWidth
                  color="secondary"
                />
              </Grid>
            </Grid>
          </ThemeProvider>
          {!isEmpty(modeWatcher) && !isEmpty(serviceWatcher) && (
            <Grid container spacing={2} className={classes.mb2}>
              <Grid item xs={12} sm={6}>
                <Typography align="center">
                  Precio del servicio:&nbsp;
                  {service?.isPricePublic ? (
                    service.price === 0 ? (
                      <Typography
                        variant="h6"
                        style={{ display: 'inline-block' }}
                      >
                        $ 0.00
                      </Typography>
                    ) : (
                      <NumberFormat
                        displayType="text"
                        value={service?.price * 100 ?? 0.001}
                        format={currencyFormatter}
                        className={classes.bold}
                      />
                    )
                  ) : (
                    <span className={classes.bold}>
                      Consultar precio con nutriólogo
                    </span>
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography align="center">
                  Duración:{' '}
                  <span className={classes.bold}>
                    {service?.duration ?? 0} minutos
                  </span>
                </Typography>
              </Grid>
            </Grid>
          )}
          {!isEmpty(service) && (
            <Box className={classes.border} mb={2}>
              <Typography variant="body2">
                Descripción: {service.description || 'Sin descripción'}
              </Typography>
            </Box>
          )}
          {nutritionist.nutritionistProfile.canUseAgenda && (
            <Box my={4}>
              <Typography variant="h6" align="center">
                ¿Deseas agendar una cita para conocer a tu nutiólogo?
              </Typography>
              <ThemeProvider theme={theme => generateTheme(theme, 'light')}>
                <RadioGroup
                  row
                  style={{ justifyContent: 'center', marginTop: '8px' }}
                  value={isAppointmentAccepted}
                  onChange={onAcceptAppointmentHandler}
                >
                  <FormControlLabel
                    value="yes"
                    control={<Radio color="secondary" />}
                    label="Si, deseo agendar"
                  />
                  <FormControlLabel
                    value="no"
                    control={<Radio color="secondary" />}
                    label="No, sin agendar"
                  />
                </RadioGroup>
              </ThemeProvider>
            </Box>
          )}
          {/*<Grid container spacing={4}>*/}
          {/*  <Grid item xs={6}></Grid>*/}
          {/*  <Grid item xs={6}></Grid>*/}
          {/*</Grid>*/}
          {isFetching ? (
            <Box
              py={4}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <CircularProgress />
            </Box>
          ) : isEmpty(serviceWatcher) &&
            isEmpty(modeWatcher) &&
            isAppointmentAccepted === 'yes' ? (
            <Box pb={4}>
              <Typography
                align="center"
                variant="h6"
                style={{ color: 'coral' }}
              >
                Para ver los horarios disponibles, selecciona un servicio y una
                modalidad
              </Typography>
            </Box>
          ) : (
            !isEmpty(serviceWatcher) &&
            !isEmpty(modeWatcher) &&
            isAppointmentAccepted === 'yes' && (
              <Box>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={6}>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      flexDirection="column"
                    >
                      <Typography variant="h6" style={{ marginBottom: '16px' }}>
                        Selecciona el día
                      </Typography>
                      <WellnubDatePicker
                        inline
                        locale={es}
                        name="date"
                        control={control}
                        excludeDates={excludedDates}
                        minDate={
                          !isEmpty(schedule)
                            ? new Date(schedule.startDate)
                            : new Date()
                        }
                        maxDate={add(new Date(), { days: 30 })}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      flexDirection="column"
                    >
                      <Typography variant="h6" style={{ marginBottom: '16px' }}>
                        Selecciona el horario
                      </Typography>
                      <WellnubTimeChipPicker
                        name="time"
                        control={control}
                        schedule={selectedDay}
                      />
                    </Box>
                  </Grid>
                </Grid>
                {['site', 'home'].includes(modeWatcher) &&
                  Intl.DateTimeFormat().resolvedOptions().timeZone !==
                    nutritionist?.nutritionistProfile?.settings?.timeZone && (
                    <Box textAlign="center" py={4}>
                      <Typography variant="body2">
                        ZONA HORARIA DE LA CONSULTA:{' '}
                        {nutritionist.nutritionistProfile.settings.timeZone}
                      </Typography>
                      <Typography variant="body2">
                        Recuerda, te encuentras en una zona horaria diferente a
                        la de tu consulta.
                      </Typography>
                    </Box>
                  )}
              </Box>
            )
          )}

          <Box
            className={classes.mb2}
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <Typography>Selecciona tu método de contacto favorito *</Typography>
            <ThemeProvider theme={theme => generateTheme(theme, 'light')}>
              <WellnubCheckboxGroupInput
                row
                name="contactMethod"
                control={control}
                options={CONTACT_METHOD_OPTIONS}
                color="secondary"
              />
            </ThemeProvider>
          </Box>
          <ThemeProvider theme={theme => generateTheme(theme, 'light')}>
            <WellnubTextInput
              fullWidth
              multiline
              rows={2}
              variant="outlined"
              name="comments"
              control={control}
              color="secondary"
              label="Comentarios adicionales"
            />
          </ThemeProvider>
          <Box mt={2}>
            <Typography align="right" variant="h6">
              * Estos campos son obligatorios para continuar
            </Typography>
          </Box>
          <Box textAlign="right" mt={2}>
            {isAppointmentAccepted === 'no' && (
              <>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={onRequestInfoClickHandler}
                >
                  Quiero información de servicios
                </Button>
                <span style={{ paddingRight: 16 }} />
              </>
            )}
            <Button
              type="submit"
              variant="contained"
              disabled={isFetching}
              className={classes.button}
            >
              Quiero empezar
            </Button>
          </Box>
        </form>
      </Container>
    </Box>
  );
};

export default NutritionistContactPage;
