import { Box, Container, Typography } from '@material-ui/core';
import { useStyles } from './AccountInactive.styles';

const AccountInactivePage = () => {
  const classes = useStyles();

  return (
    <Box className={classes.backdrop}>
      <Container>
        <Typography variant="h4" className={classes.title}>
          Cuenta Desactivada
        </Typography>
        <Typography className={classes.subtitle}>
          Por seguridad, tu cuenta ha sido desactivada, Si consideras que hay un
          error, por favor comunícate con nosotros para más detalles.
        </Typography>
      </Container>
    </Box>
  );
};

export default AccountInactivePage;
