import axios from 'axios';
import interceptor from 'axios-auth-refresh';

const baseURL = process.env.REACT_APP_BASE_URL;

export const wellnub = axios.create({
  baseURL,
  headers: {
    common: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
    },
  },
});

const refreshAuthLogic = request => {
  if (request.response.data.message === 'jwt expired') {
    const email = localStorage.getItem('email');
    const refreshToken = localStorage.getItem('refreshToken');
    return wellnub
      .post(`/v1/auth/refresh-token`, { email, refreshToken })
      .then(response => {
        localStorage.setItem('accessToken', response.data.accessToken);
        localStorage.setItem('refreshToken', response.data.refreshToken);
        wellnub.defaults.headers.common.Authorization = `Bearer ${response.data.accessToken}`;
        request.response.config.headers['Authorization'] =
          'Bearer ' + response.data.accessToken;
        return Promise.resolve();
      })
      .catch(_ => {
        window.location.replace('/');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('authentication');
        localStorage.removeItem('refreshToken');
      });
  } else {
    return Promise.resolve();
  }
};

interceptor(wellnub, refreshAuthLogic, {
  pauseInstanceWhileRefreshing: true,
});
