import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: {},
  services: [],
};

const myCurrentNutritionistSlice = createSlice({
  name: 'myCurrentNutritionist',
  initialState,
  reducers: {
    setMyCurrentNutritionist: (state, action) => {
      state.data = action.payload;
    },
    setMyCurrentNutritionistServices: (state, action) => {
      state.services = action.payload;
    },
  },
});

export const { setMyCurrentNutritionist, setMyCurrentNutritionistServices } =
  myCurrentNutritionistSlice.actions;
export const MyCurrentNutritionistReducer = myCurrentNutritionistSlice.reducer;
