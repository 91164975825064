import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { Link as RouterLink } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty } from 'lodash';

import MUIPhoneInput from 'material-ui-phone-number';

import { setModalBehavior } from 'redux/modalBehaviors/actions';

import { useSignUp } from 'hooks/authentication.hook';

import {
  makeStyles,
  Button,
  Box,
  Grid,
  Link,
  Checkbox,
  Typography,
  TextField,
  ThemeProvider,
  IconButton,
  FormControlLabel,
  useMediaQuery,
} from '@material-ui/core';

import {
  WellnubCountryCodeInput,
  WellnubNumberInput,
  WellnubTextInput,
} from '@wellnub/web-common';

import { Visibility, VisibilityOff } from '@material-ui/icons';

import { MODAL_NAMES } from 'utils/constants';

import { defaultValues, signUpSchema } from './SignUp.schema';

import { generateTheme } from './utils';

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '100%',
  },
  link: {
    color: theme.palette.action.main,
    marginLeft: -10,
  },
  special: {
    margin: 0,
  },
  low: {
    fontSize: 12,
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
}));

const SignupForm = ({ type }) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const [isPasswordMatchVisible, setIsPasswordMatchVisible] = useState(false);

  const isMobile = useMediaQuery(theme => theme.breakpoints.down('xs'));

  const { handleSubmit, control, watch, formState } = useForm({
    defaultValues,
    resolver: yupResolver(signUpSchema),
  });

  const formRef = useRef(null);

  const { mutate: signUp } = useSignUp();

  const dispatch = useDispatch();

  const classes = useStyles();

  const isAccepted = watch('isAccepted');

  const onSubmitHandler = data => {
    const {
      passwordConfirm,
      isAccepted,
      phoneNumber,
      countryCode,
      ...restData
    } = data;
    const payload = {
      ...restData,
      countryCode,
      phoneNumber,
    };
    signUp(payload);
  };

  useEffect(() => {
    if (!isEmpty(formState.errors) && isMobile) {
      formRef.current.scrollIntoView();
    }
  }, [formState]);

  return (
    <ThemeProvider theme={theme => generateTheme(theme, type)}>
      {isMobile && (
        <Typography variant="h6" style={{ marginBottom: 32 }} ref={formRef}>
          Datos de registro
        </Typography>
      )}
      <form onSubmit={handleSubmit(onSubmitHandler)} noValidate>
        <Grid container spacing={4}>
          {/* --- NOMBRE --- */}
          <Grid item xs={12} md={6}>
            <WellnubTextInput
              fullWidth
              label="Nombre *"
              color="secondary"
              name="name"
              control={control}
              helperText="Escribe tu(s) nombre(s)"
            />
          </Grid>
          {/* --- APELLIDO --- */}
          <Grid item xs={12} md={6}>
            <WellnubTextInput
              fullWidth
              label="Apellidos *"
              color="secondary"
              name="lastName"
              control={control}
              helperText="Escribe tu(s) apellidos(s)"
            />
          </Grid>
          {/* --- E-MAIL --- */}
          <Grid item xs={12} md={6}>
            <WellnubTextInput
              fullWidth
              label="email *"
              type="email"
              color="secondary"
              name="email"
              control={control}
              helperText="Escribe tu email"
            />
          </Grid>
          {/* --- NUMERO DE TELEFONO --- */}
          <Grid item xs={12} md={6}>
            <Grid container spacing={1}>
              <Grid item xs={6} sm={6}>
                <WellnubCountryCodeInput
                  label="Región"
                  control={control}
                  name="countryCode"
                  variant="standard"
                  rules={{ required: 'Este campo es requerido' }}
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <WellnubNumberInput
                  fullWidth
                  label="Celular *"
                  name="phoneNumber"
                  control={control}
                  rules={{
                    required: 'Este campo es obligatorio',
                    pattern: {
                      value: /[0-9]{10}/,
                      message: 'Escribe un número válido',
                    },
                  }}
                  format="## #### ####"
                  helperText="Celular de contacto a 10 dígitos"
                />
              </Grid>
            </Grid>
            {/*<Controller*/}
            {/*  control={control}*/}
            {/*  defaultValue=""*/}
            {/*  name="phoneNumber"*/}
            {/*  render={({*/}
            {/*    field: { value, onChange },*/}
            {/*    fieldState: { error },*/}
            {/*  }) => (*/}
            {/*    <MUIPhoneInput*/}
            {/*      value={value}*/}
            {/*      fullWidth*/}
            {/*      error={!!error}*/}
            {/*      label="Celular *"*/}
            {/*      defaultCountry="mx"*/}
            {/*      onChange={onChange}*/}
            {/*      helperText={error?.message ?? 'Escribe el número de teléfono'}*/}
            {/*      onlyCountries={['mx', 'us']}*/}
            {/*    />*/}
            {/*  )}*/}
            {/*/>*/}
          </Grid>

          {/* --- PASSWORD --- */}
          <Grid item xs={12} md={6}>
            <Controller
              name="password"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  fullWidth
                  type={isPasswordVisible ? 'text' : 'password'}
                  label="Contraseña *"
                  color="secondary"
                  error={!!error}
                  value={value}
                  onChange={onChange}
                  helperText={error ? error.message : 'Escribe tu contraseña'}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        onClick={() =>
                          setIsPasswordVisible(prevState => !prevState)
                        }
                      >
                        {isPasswordVisible ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          {/* --- PASSWORD CONFIRM --- */}
          <Grid item xs={12} md={6}>
            <Controller
              name="passwordConfirm"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  fullWidth
                  type={isPasswordMatchVisible ? 'text' : 'password'}
                  label="Confirmar contraseña *"
                  color="secondary"
                  error={!!error}
                  value={value}
                  onChange={onChange}
                  helperText={error ? error.message : 'Confirma tu contraseña'}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        onClick={() =>
                          setIsPasswordMatchVisible(prevState => !prevState)
                        }
                      >
                        {isPasswordMatchVisible ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    ),
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
        <Box pt={3}>
          <Typography variant="caption">&#42; Campos obligatorios</Typography>
        </Box>
        <Box pt={2} className={classes.wrapper}>
          <Controller
            name="isAccepted"
            control={control}
            defaultValue={false}
            rules={{ required: 'Debes aceptar para continuar' }}
            render={({ field: { onChange, value } }) => (
              <FormControlLabel
                classes={{ label: classes.low }}
                label="Acepto"
                control={
                  <Checkbox
                    color="secondary"
                    onChange={onChange}
                    checked={value}
                  />
                }
              />
            )}
          />
          <Typography>
            <Link
              to="/conditions"
              target="_blank"
              rel="noopener noreferrer"
              underline="none"
              className={classes.link}
              component={RouterLink}
            >
              <Typography
                color="inherit"
                component="span"
                className={classes.low}
              >
                Términos y Condiciones
              </Typography>
            </Link>
            <span className={classes.low}>&nbsp;y&nbsp;</span>
            <Link
              to="/privacy"
              target="_blank"
              rel="noopener noreferrer"
              underline="none"
              className={`${classes.link} ${classes.special}`}
              component={RouterLink}
            >
              <Typography
                color="inherit"
                component="span"
                className={classes.low}
              >
                Aviso de Privacidad
              </Typography>
            </Link>
            <Typography
              color="inherit"
              component="span"
              className={classes.low}
            >
              &nbsp;de Wellnub
            </Typography>
          </Typography>
        </Box>
        <Box
          pt={2}
          display="flex"
          alignItems="center"
          justifyContent="space-evenly"
        >
          {/* TODO: Agregar spinner dentro de botton de registro  */}
          <Button type="submit" variant="contained" disabled={!isAccepted}>
            Registrarme
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() =>
              dispatch(
                setModalBehavior({
                  name: MODAL_NAMES.SIGN_UP,
                  isVisible: false,
                }),
              )
            }
          >
            Cancelar
          </Button>
        </Box>
      </form>
    </ThemeProvider>
  );
};

SignupForm.defaultProps = {
  type: 'dark',
};

export default SignupForm;
