import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  form: {
    marginTop: theme.spacing(2),
  },
  description: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.secondary.dark}`,
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
  selectDisabled: {
    '& :disabled': {
      cursor: 'not-allowed',
    },
  },
}));
