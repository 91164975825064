import AppoinmentDrawer from './Appoinment/AppoinmentDrawer.component';
import CreditCardDrawer from './CreditCard/CreditCardDrawer.component';
import CheckoutDrawer from './Checkout/CheckoutDrawer.component';
import IndicationsDrawer from './Indications/IndicationsDrawer.component';
import ScheduleDetailDrawer from './ScheduleDetail/ScheduleDetailDrawer.component';
import PaymentSubscriptionDrawer from './PaymentSubscription/PaymentSubscriptionDrawer.component';
import NutritionistAppointmentDrawer from './NutritionistAppointment/NutritionistAppointmentDrawer.component';
import AppointmentConfirmationDrawer from './AppointmentConfirmation/AppointmentConfirmationDrawer.component';

const DrawerManager = () => (
  <>
    <AppoinmentDrawer />
    <CreditCardDrawer />
    <CheckoutDrawer />
    <IndicationsDrawer />
    <ScheduleDetailDrawer />
    <PaymentSubscriptionDrawer />
    <NutritionistAppointmentDrawer />
    <AppointmentConfirmationDrawer />
  </>
);

export default DrawerManager;
