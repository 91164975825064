import { makeStyles } from '@material-ui/core';

import { indigo, pink } from '@material-ui/core/colors';

export const useStyles = makeStyles(theme => ({
  card: {
    padding: theme.spacing(2, 4),
    border: `1px solid ${theme.palette.action.main}`,
    borderRadius: theme.shape.borderRadius,
  },
  title: {
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  info: {
    paddingLeft: theme.spacing(1),
  },
  locationIcon: {
    color: pink[300],
  },
  scheduleIcon: {
    color: indigo[300],
  },
  action: {
    color: theme.palette.action.main,
    cursor: 'pointer',
  },
}));
