import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(4),
    maxWidth: 500,
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.light,
  },
  title: {
    textAlign: 'center',
  },
  subtitle: {
    paddingTop: theme.spacing(2),
    textAlign: 'center',
  },
}));
