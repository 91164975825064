import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  mt: {
    marginTop: theme.spacing(1),
  },
  title: {
    textAlign: 'center',
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
  },
  backdrop: {
    minHeight: '90vh',
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.main,
  },
  wrapper: {
    padding: theme.spacing(4),
    backgroundColor: '#e7e7e7',
    textAlign: 'center',
    width: '50%',
    marginLeft: 'auto',
    marginRight: 'auto',
    border: '1px solid #c2c2c2',
    borderRadius: theme.shape.borderRadius,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}));
