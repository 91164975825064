import React from 'react';

import { Box, Portal } from '@material-ui/core';

import { useStyles } from './WellnubBackdrop.styles';

const WellnubBackdrop = ({ open, Component }) => {
  const classes = useStyles();

  if (!open) {
    return null;
  }

  return (
    <Portal>
      <Box component="aside" className={classes.backdrop}>
        {Component}
      </Box>
    </Portal>
  );
};

export default WellnubBackdrop;
