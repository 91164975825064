import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import {
  Box,
  Button,
  Container,
  Typography,
  ThemeProvider,
  IconButton,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { WellnubTextInput } from '@wellnub/web-common';

import { generateTheme } from 'utils/helpers';

import { useUpdateVerificationEmail } from 'hooks/authentication.hook';

import { useStyles } from './EmailRetry.styles';

const EmailRetryPage = () => {
  const [id, setId] = useState();

  const { handleSubmit, control } = useForm({
    defaultValues: {
      email: '',
    },
  });

  const { push } = useHistory();

  const { mutate, isLoading } = useUpdateVerificationEmail();

  const classes = useStyles();

  const onSubmitHandler = data => {
    const payload = {
      id,
      data: {
        email: data.email,
      },
    };
    mutate(payload);
  };

  const onRedirectHandler = () => {
    push('/pending-verification');
  };

  useEffect(() => {
    const id = localStorage.getItem('userID');
    setId(id);
  }, []);

  return (
    <Box className={classes.backdrop}>
      <Container className={classes.container}>
        <Box className={classes.wrapper}>
          <Box className={classes.backButtonWrapper}>
            <IconButton size="small" onClick={onRedirectHandler}>
              <ArrowBackIcon fontSize="small" color="primary" />
            </IconButton>
            <Typography variant="body2">
              Volver a validación de email
            </Typography>
          </Box>
          <Typography variant="h5" component="h1" className={classes.title}>
            Ingresa tu correo nuevamente para verificarlo
          </Typography>
          <Box component="section" my={2}>
            <Typography>
              Recuerda que con este correo iniciarás sesión en Wellnub, así como
              recibir nuestras notificaciones
            </Typography>
            <ThemeProvider theme={theme => generateTheme(theme, 'light')}>
              <form
                onSubmit={handleSubmit(onSubmitHandler)}
                className={classes.form}
              >
                <WellnubTextInput
                  name="email"
                  type="email"
                  color="secondary"
                  control={control}
                  rules={{ required: 'Este campo es obligatorio' }}
                  label="Email*"
                  helperText="user@example.com"
                  fullWidth
                  variant="outlined"
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  size="small"
                  disabled={isLoading}
                >
                  Continuar
                </Button>
              </form>
            </ThemeProvider>
          </Box>
          <Typography variant="caption">
            * Asegúrate de revisar en tu bandeja de correo no deseado
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default EmailRetryPage;
