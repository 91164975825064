import axios from './auth.axios';
import { BASE_URL } from '../constants';

class SessionService {
  create = payload => axios.post(`${BASE_URL}/v1/session`, payload);

  get = id => axios.get(`${BASE_URL}/v1/session/${id}`);

  list = () => axios.get(`${BASE_URL}/v1/session`);

  update = (id, payload) => axios.patch(`${BASE_URL}/v1/session${id}`, payload);

  delete = id => axios.post(`${BASE_URL}/v1/session/${id}`);
}

export default new SessionService();
