import { useHistory } from 'react-router-dom';

import { Box, Button, Container, Typography } from '@material-ui/core';

import { useStyles } from './SuccessfullyVerificationByClient.styles';

const SuccessfullyVerificationPageByClient = () => {
  const { replace } = useHistory();

  const classes = useStyles();

  const onClickHandler = () => {
    replace('/');
  };

  return (
    <Box className={classes.backdrop}>
      <Container className={classes.container}>
        <Box className={classes.wrapper}>
          <Typography variant="h5" component="h1" className={classes.title}>
            Registro Exitoso
          </Typography>
          <Box component="section" my={2}>
            <Typography>
              Wellnub te da la bienvenida <br />
              ¡Inicia sesión con tus datos para comenzar!
            </Typography>
            <Box mt={2}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={onClickHandler}
              >
                Aceptar
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default SuccessfullyVerificationPageByClient;
