import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  details: {},
  bundles: [],
  active: {},
};

const inbodyPassesSlice = createSlice({
  name: 'inbodyPasses',
  initialState,
  reducers: {
    setDetails: (state, action) => {
      state.details = action.payload;
    },
    setInbodyBundles: (state, action) => {
      state.bundles = action.payload;
    },
    setActiveBundle: (state, action) => {
      state.active = action.payload;
    },
  },
});

export const { setDetails, setInbodyBundles, setActiveBundle } =
  inbodyPassesSlice.actions;

export const inbodyPassesReducer = inbodyPassesSlice.reducer;
