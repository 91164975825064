import axios from 'axios';
import ExerciseService from './services/exercise.service';

class WellnubApi {
  constructor() {
    const accessToken = localStorage.getItem('accessToken');
    axios.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };

    this.exercise = new ExerciseService(axios);

  }
}

export default new WellnubApi();
