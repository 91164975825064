import { useHistory } from 'react-router-dom';

import { Box, Button, Container, Typography } from '@material-ui/core';

import { useStyles } from './ValidationCompleted.styles';

const ValidationCompletedPage = () => {
  const { replace } = useHistory();

  const classes = useStyles();

  const onClickHandler = () => {
    replace('/');
  };

  return (
    <Box className={classes.backdrop}>
      <Container className={classes.container}>
        <Box className={classes.wrapper}>
          <Typography variant="h5" component="h1" className={classes.title}>
            Validación completada
          </Typography>
          <Typography
            variant="h5"
            component="h2"
            className={`${classes.title} ${classes.mt}`}
          >
            Wellnub te da la bienvenida
          </Typography>
          <Box component="section" my={2}>
            <Typography>
              Ya se ha validado su email y teléfono. Puedes iniciar sesión de
              manera normal con tu usuario y contraseña.
            </Typography>
            <Box mt={2}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={onClickHandler}
              >
                Aceptar
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default ValidationCompletedPage;
