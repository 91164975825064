import { orange, lightGreen, red, cyan } from '@material-ui/core/colors';

export const palette = {
  type: 'dark',
  primary: {
    dark: '#000000',
    main: '#0B1015',
    light: '#23282c',
    contrastText: '#F8F9FA',
  },
  secondary: {
    dark: '#c6c7c8',
    main: '#F8F9FA',
    light: '#FFFFFF',
    contrastText: '#0B1015',
  },
  action: {
    dark: '#156175',
    main: '#1A7992',
    light: '#4894a8',
  },
  gray: {
    dark: '#41464b',
    main: '#51575E',
    light: '#74797e',
  },
  success: {
    light: lightGreen[100],
    main: lightGreen[200],
    dark: lightGreen[400],
    contrastText: '#155239',
  },
  error: {
    light: red[100],
    main: red[200],
    dark: red[400],
    contrastText: '#611717',
  },
  warning: {
    light: orange[100],
    main: orange[200],
    dark: orange[400],
    contrastText: '#5C4813',
  },
  info: {
    light: cyan[100],
    main: cyan[200],
    dark: cyan[400],
    contrastText: '#114443',
  },
  transparency: {
    main: 'rgba(11, 16, 21, .5)',
    secondary: 'rgba(248, 249, 250, .2)',
  },
  background: {
    default: '#0B1015',
  },
  text: {
    primary: '#c6c7c8',
  },
};
