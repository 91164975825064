import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useForm, Controller } from 'react-hook-form';

import {
  ThemeProvider,
  Grid,
  TextField,
  Typography,
  Box,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
  FormHelperText,
} from '@material-ui/core';

import MuiPhoneNumber from 'material-ui-phone-number';

import { setModalBehavior } from 'redux/modalBehaviors/actions';

import { requestEmail } from 'redux/forms/GetInTouch/actions';

import { MODAL_NAMES } from 'utils/constants';

import { generateTheme } from './utils';

const GetInTouchForm = () => {
  const { handleSubmit, control } = useForm();

  const dispatch = useDispatch();

  const { data: coach } = useSelector(state => state.coaches.coach);

  const onSubmitHandler = data => {
    const payload = {
      coach: coach._id,
      client: null,
      program: null,
      clientName: data.name,
      email: data.email,
      phoneNumber: data.phoneNumber,
      contactMethod: ['email', 'phone', 'whatsapp'],
    };
    dispatch(requestEmail(payload));
    dispatch(
      setModalBehavior({
        name: MODAL_NAMES.GET_IN_TOUCH,
        isVisible: false,
      }),
    );
  };

  return (
    <ThemeProvider theme={theme => generateTheme(theme, 'light')}>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Controller
              name="name"
              control={control}
              defaultValue=""
              rules={{
                required: 'Este campo es requerido',
                pattern: {
                  value: /^([^0-9]*)$/,
                  message: 'El nombre no debe de contener números',
                },
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  fullWidth
                  type="text"
                  label="Nombre"
                  color="secondary"
                  value={value}
                  error={!!error}
                  onChange={onChange}
                  helperText={error ? error.message : 'Nombre de contacto'}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="email"
              control={control}
              defaultValue=""
              rules={{
                required: 'Este campo es requerido',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Escribe un correo válido',
                },
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  fullWidth
                  type="email"
                  label="E-mail"
                  color="secondary"
                  value={value}
                  error={!!error}
                  onChange={onChange}
                  helperText={error ? error.message : 'user@example.com'}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="phoneNumber"
              control={control}
              defaultValue=""
              rules={{
                required: 'Este campo es requerido',
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <MuiPhoneNumber
                  fullWidth
                  color="secondary"
                  label="Celular"
                  regions="america"
                  preferredCountries={['mx']}
                  defaultCountry="mx"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={
                    error ? error.message : 'Ingresa un teléfono de contácto'
                  }
                />
              )}
            />
          </Grid>
        </Grid>
        <Box pt={3}>
          <Typography>¿Por cuál medio prefieres que te contacte?</Typography>
        </Box>
        <Box pt={3}>
          <Controller
            name="contact"
            control={control}
            defaultValue={{
              email: '',
              whatsapp: '',
              phone: '',
            }}
            rules={{
              required: 'Debes seleccionar al menos una forma de contacto',
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FormControl required error={!!error} fullWidth>
                <FormGroup>
                  <Grid container>
                    <Grid item md={4} xs={12}>
                      <FormControlLabel
                        label="E-mail"
                        control={
                          <Checkbox
                            onChange={onChange}
                            name="email"
                            value={value}
                          />
                        }
                      />
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <FormControlLabel
                        label="Whatsapp"
                        control={
                          <Checkbox
                            onChange={onChange}
                            name="whatsapp"
                            value={value}
                          />
                        }
                      />
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <FormControlLabel
                        label="Teléfono"
                        control={
                          <Checkbox
                            onChange={onChange}
                            name="phone"
                            value={value}
                          />
                        }
                      />
                    </Grid>
                  </Grid>
                </FormGroup>
                <FormHelperText>
                  {error ? error.message : 'Selecciona una forma de contacto'}
                </FormHelperText>
              </FormControl>
            )}
          />
        </Box>
        <Box
          pt={4}
          display="flex"
          alignItems="center"
          justifyContent="space-evenly"
        >
          <Button type="submit" variant="contained">
            Enviar
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() =>
              dispatch(
                setModalBehavior({
                  name: MODAL_NAMES.GET_IN_TOUCH,
                  isVisible: false,
                }),
              )
            }
          >
            Cancelar
          </Button>
        </Box>
      </form>
    </ThemeProvider>
  );
};

export default GetInTouchForm;
