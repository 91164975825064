import { createTheme } from '@material-ui/core';

export const generateTheme = (theme, type) =>
  createTheme({
    palette: {
      type,
      secondary: {
        main: type === 'light' ? '#0B1015' : '#F8F9FA',
      },
    },
    typography: { ...theme.typography },
    overrides: { ...theme.overrides },
  });

export const currencyFormatter = value => {
  if (!Number(value)) return '';

  const amount = new Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: 'MXN',
  }).format(value / 100);

  return `${amount}`;
};

export const validatePhoneNumber = value => {
  const phoneNumber = value.replace(/[^\d]/g, '');
  if (phoneNumber) {
    if (value.includes('+52')) {
      const rest = value.substring(3);
      return rest.length === 10 || 'El número debe ser de 10 dígitos';
    }
    if (value.includes('+1')) {
      const [, ...rest] = value.split(' ');
      const last = rest.join('').replace(/[^\d]/g, '');
      return last.length === 10 || 'El número debe ser de 10 dígitos';
    } else {
      return 'Selecciona un código de área válido';
    }
  } else {
    return 'Este campo es obligatorio';
  }
};

export const getDatesInRange = (startDate, endDate) => {
  const date = new Date(startDate.getTime());
  const dates = [];
  while (date < endDate) {
    dates.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }
  return dates;
}