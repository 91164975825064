import React, { Children } from 'react';

import PropTypes from 'prop-types';

import Slider from 'react-slick';

import { makeStyles } from '@material-ui/core';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const useStyles = makeStyles(() => ({
  carousel: {
    width: '100%',
  },
}));

const Carousel = ({ children }) => {
  const classes = useStyles();

  const childrenCount = Children.count(children);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: childrenCount > 5 ? 5 : childrenCount,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Slider className={classes.carousel} {...settings}>
      {children}
    </Slider>
  );
};

Carousel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default Carousel;
