import { Box, Button, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { setModalStatus } from 'redux/slices/modal.slice';
import { useHistory } from 'react-router-dom';

const LinkRequestWarningPanel = () => {
  const dispatch = useDispatch();

  const { replace } = useHistory();

  const onAcceptHandler = () => {
    dispatch(
      setModalStatus({
        name: 'LINK_REQUEST_WARNING',
        isActive: false,
      }),
    );
    setTimeout(() => {
      replace('/dashboard');
    }, 500);
  };

  const onCancelHandler = () => {
    dispatch(
      setModalStatus({
        name: 'LINK_REQUEST_WARNING',
        isActive: false,
      }),
    );
  };

  return (
    <Box>
      <Typography variant="h6" align="center">
        Ya solicitaste una vinculación con este nutriólogo(a)
      </Typography>
      <Typography variant="body2" align="center">
        Te recordamos que solo puedes mandar una solicitud por nutriólogo,
        puedes visualizar tus solicitudes en "Mis programas" en tu perfil.
      </Typography>
      <Box
        mt={2}
        display="flex"
        alignItems="center"
        justifyContent="space-evenly"
      >
        <Button variant="contained" color="primary" onClick={onAcceptHandler}>
          Mis programas
        </Button>
        <Button variant="outlined" color="primary" onClick={onCancelHandler}>
          Aceptar
        </Button>
      </Box>
    </Box>
  );
};

export default LinkRequestWarningPanel;
