import React from 'react';

import PropTypes from 'prop-types';

import { makeStyles, Box, Typography } from '@material-ui/core';

import LockIcon from '@material-ui/icons/Lock';

const useStyles = makeStyles(theme => ({
  card: {
    position: 'relative',
    width: 200,
    height: 200,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.secondary.main,
  },
  name: {
    padding: theme.spacing(1),
    textAlign: 'center',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  duration: {
    paddingTop: theme.spacing(1),
    textAlign: 'center',
  },
  footer: {
    position: 'absolute',
    padding: theme.spacing(1),
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: theme.palette.gray.main,
    borderBottomRightRadius: theme.shape.borderRadius,
    borderBottomLeftRadius: theme.shape.borderRadius,
  },
  lockLayer: {
    position: 'absolute',
    display: 'flex',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 2,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.transparency.main,
  },
}));

const TrainingCard = ({ training }) => {
  const classes = useStyles();

  return (
    <Box mr="auto" ml="auto" component="article" className={classes.card}>
      <Box className={classes.lockLayer}>
        <LockIcon color="secondary" fontSize="large" />
      </Box>
      <Typography variant="body1" className={classes.name} color="primary">
        {training.vWoD.name}
      </Typography>
      <Box component="footer" className={classes.footer}>
        &nbsp;
        {/* <Typography variant="body2" className={classes.duration}>
          Duración {Math.ceil(training.vWoD.totalSecLength / 60)} min
        </Typography> */}
      </Box>
    </Box>
  );
};

TrainingCard.propTypes = {
  training: PropTypes.shape({
    _id: PropTypes.string,
    day: PropTypes.string,
    vWoD: PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
      videoURL: PropTypes.string,
      videoType: PropTypes.string,
      totalSecLength: PropTypes.number,
      isGeneratingVideo: PropTypes.bool,
    }),
    __v: PropTypes.number,
  }),
};

export default TrainingCard;
