import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  backdrop: {
    minHeight: '90vh',
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.main,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}));
