import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import {
  Box,
  Button,
  Container,
  Typography,
  ThemeProvider,
  IconButton,
  Grid,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import {
  WellnubNumberInput,
  WellnubCountryCodeInput,
} from '@wellnub/web-common';

import { generateTheme } from 'utils/helpers';

import { useUpdatePhoneNumber } from 'hooks/authentication.hook';

import { useStyles } from './PhoneNumberRetry.styles';

const PhoneNumberRetryPage = () => {
  const [id, setId] = useState();

  const { handleSubmit, control } = useForm({
    defaultValues: {
      phoneNumber: '',
      countryCode: '52',
    },
  });

  const { push } = useHistory();

  const { mutate, isLoading } = useUpdatePhoneNumber();

  const classes = useStyles();

  const onSubmitHandler = data => {
    const payload = {
      id,
      data: {
        phoneNumber: data.phoneNumber,
        countryCode: data.countryCode,
      },
    };
    mutate(payload);
  };

  const onRedirectHandler = () => {
    push('/phoneValidation');
  };

  useEffect(() => {
    const id = localStorage.getItem('userID');
    setId(id);
  }, []);

  return (
    <Box className={classes.backdrop}>
      <Container className={classes.container}>
        <Box className={classes.wrapper}>
          <Box className={classes.backButtonWrapper}>
            <IconButton size="small" onClick={onRedirectHandler}>
              <ArrowBackIcon fontSize="small" color="primary" />
            </IconButton>
            <Typography variant="body2">
              Volver a validación de teléfono
            </Typography>
          </Box>
          <Typography variant="h5" component="h1" className={classes.title}>
            Ingresa tu celular nuevamente para verificarlo
          </Typography>
          <Box component="section" my={2}>
            <Typography>
              Recuerda que con este celular te identificaremos en Wellnub y
              recibiras nuestras notificaciones
            </Typography>
            <ThemeProvider theme={theme => generateTheme(theme, 'light')}>
              <form
                onSubmit={handleSubmit(onSubmitHandler)}
                className={classes.form}
              >
                <Grid container spacing={1}>
                  <Grid item xs={3}>
                    <WellnubCountryCodeInput
                      label="Región"
                      control={control}
                      name="countryCode"
                      rules={{ required: 'Este campo es requerido' }}
                    />
                  </Grid>
                  <Grid item xs={9}>
                    <WellnubNumberInput
                      fullWidth
                      variant="outlined"
                      label="Celular"
                      name="phoneNumber"
                      color="secondary"
                      control={control}
                      format="## #### ####"
                      rules={{
                        required: 'Este campo es obligatorio',
                        pattern: {
                          value: /[0-9]{10}/,
                          message: 'Escribe un número válido',
                        },
                      }}
                    />
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  size="small"
                  disabled={isLoading}
                  style={{ marginTop: 16 }}
                >
                  Continuar
                </Button>
              </form>
            </ThemeProvider>
          </Box>
          <Typography variant="caption">
            * Asegúrate de revisar en tu bandeja de correo no deseado
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default PhoneNumberRetryPage;
