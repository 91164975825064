import React from 'react';

import { Backdrop, Box, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { useStyles } from './YTPlayer.styles';

const WellnubYTPlayer = ({ videoID, open, closeHandler }) => {
  const classes = useStyles();

  return (
    <Backdrop open={open} onClick={closeHandler} className={classes.backdrop}>
      <Box className={classes.playerWrapper}>
        <IconButton onClick={closeHandler} className={classes.icon}>
          <CloseIcon fontSize="small" />
        </IconButton>
        <iframe
          className={classes.iframe}
          src={`https://www.youtube.com/embed/${videoID}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
      </Box>
    </Backdrop>
  );
};

export default WellnubYTPlayer;
