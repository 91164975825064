import React from 'react';
import { useController } from 'react-hook-form';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';

import { countries } from '../../constants/constants';

const CountryCodeInput = ({
  name,
  control,
  rules,
  defaultValue,
  ...restProps
}) => {
  const {
    field: { ref, ...restField },
    fieldState: { error },
  } = useController({ name, control, rules, defaultValue });

  return (
    <FormControl
      fullWidth
      variant={restProps?.variant ?? 'outlined'}
      error={!!error}
      {...restProps}
    >
      <InputLabel id="select-country-code">
        {restProps?.label ?? 'Etiqueta'}
      </InputLabel>
      <Select
        label={restProps?.label ?? 'Etiqueta'}
        innerRef={ref}
        {...restField}
      >
        {countries.map(option => (
          <MenuItem key={option.value} value={option.code}>
            <Typography variant="body2">
              (+{option.code}) {option.label}
            </Typography>
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{error?.message ?? restProps?.helperText}</FormHelperText>
    </FormControl>
  );
};

export default CountryCodeInput;
