const toEnum = (obj) => {
  return Object.values(obj);
};

const toArray = (obj) => {
  return Object.entries(obj).map(entry => ({key: entry[0], value: entry[1]}))
}

const PROGRAM_SERVICE_TYPE = {
  'live-classes': 'Clases en vivo',
  'recorded-trainings': 'V-WoD',
  'custom-trainings': 'Entrenamientos personalizados',
}

const PROGRAM_SERVICE_TYPE_LIST = toArray(PROGRAM_SERVICE_TYPE);
const PROGRAM_SERVICE_TYPE_ENUM = toEnum(PROGRAM_SERVICE_TYPE);

export {
  PROGRAM_SERVICE_TYPE,
  PROGRAM_SERVICE_TYPE_LIST,
  PROGRAM_SERVICE_TYPE_ENUM,
}