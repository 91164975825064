import {
  Box,
  CardMedia,
  Drawer,
  IconButton,
  Radio,
  Typography,
} from '@material-ui/core';
import { useDrawer } from 'hooks/drawer.hook';
import { DRAWERS } from 'utils/constants';
import { useStyles } from './PaymentSubscriptionDrawer.styles';
import { useSelector } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import { Link } from 'react-router-dom';
import { format, add } from 'date-fns';
import { es } from 'date-fns/locale';
import visa from '../../../assets/icons/creditcards/card-visa.svg';
import master from '../../../assets/icons/creditcards/card-master.svg';
import amex from '../../../assets/icons/creditcards/card-amex.svg';

const PaymentSubscriptionDrawer = () => {
  const { visibility, handleClose } = useDrawer(DRAWERS.paymentSubscription);

  const { subscription } = useSelector(state => state.payments);

  const classes = useStyles();

  return (
    <Drawer
      anchor="right"
      open={visibility}
      onClose={handleClose}
      classes={{ paper: classes.paper }}
    >
      <Box component="header">
        <IconButton onClick={handleClose}>
          <CloseIcon color="primary" />
        </IconButton>
        <Typography
          variant="h5"
          className={`${classes.title} ${classes.center}`}
        >
          {subscription?.storeProduct?.name}
        </Typography>
      </Box>

      <Box component="section" mt={4} textAlign="center">
        <Typography variant="h6">Detalles de la suscripción</Typography>
        <Typography variant="body2" style={{ marginTop: 16 }}>
          Suscrito desde: <br />
          {format(
            new Date(
              (subscription?.conektaSubscription?.subscription_start ?? 0) *
                1000,
            ),
            'PPPP',
            { locale: es },
          )}
        </Typography>
        <Typography variant="body2" style={{ marginTop: 16 }}>
          Próxima fecha de asignación de nuevo pase:
          <br />
          {format(
            add(
              new Date(
                (subscription?.conektaSubscription?.subscription_start ?? 0) *
                  1000,
              ),
              {
                months:
                  subscription?.storeProduct?.name === 'Programa Trimestral'
                    ? 3
                    : 1,
              },
            ),
            'PPPP',
            { locale: es },
          )}
        </Typography>
        <Typography variant="body2" style={{ marginTop: 16 }}>
          Método de pago:{' '}
        </Typography>
        <Box display="flex" alignItems="center" justifyContent="center" mt={1}>
          <CardMedia
            component="img"
            image={
              {
                visa,
                mastercard: master,
                american_express: amex,
              }[subscription?.conektaCard?.brand]
            }
            style={{ maxWidth: 45 }}
          />
          <Typography variant="body2" style={{ paddingLeft: 8 }}>
            &#8226;&#8226;&#8226;&#8226; {subscription?.conektaCard?.last4}
          </Typography>
        </Box>
      </Box>

      <Box component="footer" mt={4} textAlign="center">
        <Typography
          variant="body2"
          component={Link}
          to="/dashboard/progress"
          onClick={handleClose}
          className={classes.asLink}
          style={{ marginTop: 16 }}
        >
          VER MI PROGRESO
        </Typography>
        <Typography
          variant="body2"
          component={Link}
          to="/payment-subscriptions"
          onClick={handleClose}
          className={classes.asLink}
          style={{ marginTop: 16 }}
        >
          DESEO ACTUALIZAR MI SUSCRIPCIÓN
        </Typography>
      </Box>
    </Drawer>
  );
};

export default PaymentSubscriptionDrawer;
