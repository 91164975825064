import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: {},
  passes: 0,
  inbodyResults: [],
  biochemicalResults: [],
  linkRequestId: '',
  ratings: {
    id: '',
    location: {
      name: '',
      date: '',
    },
  },
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.data = action.payload;
    },
    setPasses: (state, action) => {
      state.passes = action.payload;
    },
    setInbodyResults: (state, action) => {
      state.inbodyResults = action.payload;
    },
    setBiochemicalResults: (state, action) => {
      state.biochemicalResults = action.payload;
    },
    setLinkRequestId: (state, action) => {
      state.linkRequestId = action.payload;
    },
    setRatingLocation: (state, action) => {
      state.ratings.location.name = action.payload.name;
      state.ratings.location.date = action.payload.date;
      state.ratings.id = action.payload.id;
    },
  },
});

export const {
  setUser,
  setPasses,
  setInbodyResults,
  setLinkRequestId,
  setRatingLocation,
  setBiochemicalResults,
} = userSlice.actions;

export const userReducer = userSlice.reducer;
