import { produce } from 'immer';

import {
  SET_COACH,
  SET_IS_FETCHING_COACH,
  SET_COACHES,
  SET_IS_FETCHING_COACHES,
} from './constants';

const initialState = {
  coach: {
    data: {},
    isLoading: false,
  },
  list: {
    data: [],
    isLoading: false,
  },
};

const coachesReducer = produce((draft, action) => {
  switch (action.type) {
    case SET_COACH:
      draft.coach.data = action.payload;
      break;
    case SET_IS_FETCHING_COACH:
      draft.coach.isLoading = action.payload;
      break;
    case SET_COACHES:
      draft.list.data = action.payload;
      break;
    case SET_IS_FETCHING_COACHES:
      draft.list.isLoading = action.payload;
      break;
  }
}, initialState);

// const coachesReducer = (state = initialState, { type, payload }) => {
//   switch (type) {
//     case SET_COACHES:
//       return { ...state, list: payload };
//     case SET_ACTIVE_COACH:
//       return { ...state, active: payload };
//     default:
//       return state;
//   }
// };

export default coachesReducer;
