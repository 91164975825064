import { SET_VISIBILITY } from './constants';

const initialState = {
  name: '',
  isActive: false,
};

export const drawerReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_VISIBILITY:
      return {
        ...state,
        name: action.payload.name,
        isActive: action.payload.isActive,
      };
    default:
      return state;
  }
};
