import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveAs } from 'file-saver';

import { Box, Drawer, IconButton, Typography } from '@material-ui/core';

import { Button } from '../../atoms';

import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';

import { format, differenceInHours } from 'date-fns';
import { es } from 'date-fns/locale';

import { useDrawer } from 'hooks/drawer.hook';
import { useGetAppointmentTicket } from 'hooks/user.hook';

import { DRAWERS } from 'utils/constants';

import { useStyles } from './IndicationsDrawer.styles';
import { useHistory } from 'react-router-dom';
import { setAppointmentMode } from 'redux/slices/spot.slice';

const IndicationsDrawer = () => {
  const [hoursLeft, setHoursLeft] = useState(0);
  const { visibility, handleClose } = useDrawer(DRAWERS.indications);

  const {
    data: spot,
    appointmentSchedule,
    appointment,
  } = useSelector(state => state.spot);

  const { push } = useHistory();

  const dispatch = useDispatch();

  const { isFetching, refetch } = useGetAppointmentTicket(appointment._id);

  const classes = useStyles();

  const onDownloadTicketClickHandler = () => {
    refetch()
      .then(({ data }) => fetch(`data:image/jpeg;base64,${data}`))
      .then(response => response.blob())
      .then(file => {
        saveAs(file, 'wellnub-ticket.jpeg');
      });
  };

  const onRescheduleClickHandler = () => {
    handleClose();
    setTimeout(() => {
      dispatch(setAppointmentMode('RESCHEDULE'));
      push('/dashboard/appointment');
    }, 500);
  };

  useEffect(() => {
    if (appointmentSchedule) {
      const now = new Date();
      const date = new Date(appointmentSchedule.date);
      const time = new Date(appointmentSchedule.time);
      date.setHours(time.getHours());
      date.setMinutes(time.getMinutes());
      date.setSeconds(time.getSeconds());
      const difference = differenceInHours(date, now);
      setHoursLeft(difference);
    }
  }, [appointmentSchedule]);

  return (
    <Drawer
      anchor="right"
      open={visibility}
      onClose={handleClose}
      classes={{ paper: classes.paper }}
    >
      <Box component="header">
        <IconButton onClick={handleClose}>
          <CloseIcon color="primary" />
        </IconButton>
        <Typography variant="h5" className={classes.title}>
          TU RESERVACIÓN WELLNUB - INBODY&reg;
        </Typography>
      </Box>

      <Box pt={7} component="section">
        <Button
          fullWidth
          variant="contained"
          isLoading={isFetching}
          onClick={onDownloadTicketClickHandler}
        >
          Descargar ticket
        </Button>
        <br />
        <br />
        {hoursLeft >= 3 ? (
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            onClick={onRescheduleClickHandler}
          >
            Reagendar
          </Button>
        ) : (
          <Typography
            variant="h6"
            className={`${classes.action} ${classes.title}`}
          >
            Ya no es posible reagendar en este momento
          </Typography>
        )}
      </Box>

      <Box pt={4} component="section">
        <Typography variant="h5" className={classes.title}>
          ¿Cuándo?
        </Typography>
        <Box my={2} className={classes.dashed}>
          <Typography variant="body2">
            {format(new Date(appointmentSchedule.date), 'PPPP', {
              locale: es,
            })}
          </Typography>
          <Typography variant="body2" style={{ paddingTop: 8 }}>
            a las: {format(new Date(appointmentSchedule.time), 'p')}
          </Typography>
        </Box>
        <Typography
          variant="h6"
          className={`${classes.title} ${classes.action}`}
        >
          Por favor apégate a estos horarios para que podamos atenderte de la
          mejor manera. Tendrás 15 minutos de tolerancia para tu prueba.
        </Typography>
      </Box>

      <Box pt={4} component="section">
        <Typography variant="h5" className={classes.title}>
          ¿Dónde?
        </Typography>
        <Box mt={2} className={classes.dashed}>
          <Typography variant="body2">
            {spot.name}, {spot.address?.neighborhood ?? 'Colonia'}
          </Typography>
        </Box>
      </Box>

      <Box pt={4} component="section">
        <Typography variant="h5" className={classes.title}>
          ¿Dirección?
        </Typography>
        <Box my={2} className={classes.dashed}>
          <Typography variant="body2">
            {spot?.address?.street ?? ''}, {spot?.address?.neighborhood ?? ''},{' '}
            {spot?.address?.city ?? ''}, {spot?.address?.state ?? ''},{' '}
            {spot?.address?.zipCode ?? ''}
          </Typography>
        </Box>
      </Box>

      <Box pt={4} component="section">
        <Typography variant="h5" className={classes.title}>
          Indicaciones especiales de la locación
        </Typography>
        <Box my={2} className={classes.dashed}>
          <Typography variant="body2">{spot?.indications ?? ''}</Typography>
        </Box>
      </Box>

      <Box pt={4} component="section">
        <Typography variant="h5" className={classes.title}>
          Pasos a seguir para realizar tu escaneo corporal
        </Typography>
        <Box pt={2}>
          <Typography variant="body2" className={classes.indication}>
            1. En recepción menciona que vienes de Wellnub a realizarte un
            análisis InBody&reg;.
          </Typography>
          <Typography variant="body2" className={classes.indication}>
            2. Proporciona tu nombre completo para que puedan verificar tu
            reservación.
          </Typography>
          <Typography variant="body2" className={classes.indication}>
            3. Acompaña al staff al equipo InBody&reg; y proporciona tu
            número&nbsp;
            <span className={classes.underlined}>
              celular que diste de alta en Wellnub.
            </span>
          </Typography>
          <Typography variant="body2" className={classes.indication}>
            4. Ingresa o confirma tu edad, sexo y altura en el equipo
            InBody&reg;.
          </Typography>
          <Typography variant="body2" className={classes.indication}>
            5. ¡Listo! Recibirás tus resultados directo en tu perfil de Wellnub
            en menos de 30 minutos.
          </Typography>
        </Box>
      </Box>

      <Box pt={4} component="section">
        <Typography variant="h5" className={classes.title}>
          Recomendaciones
        </Typography>
        <Typography variant="body2" style={{ textAlign: 'center' }}>
          Para mejorar la precisión de tu análisis te sugerimos:
        </Typography>
        <Box pt={2}>
          <Typography variant="body2" className={classes.check}>
            <CheckIcon fontSize="small" />
            &nbsp;Evita bebidas/comidas 2 horas antes.
          </Typography>
          <Typography variant="body2" className={classes.check}>
            <CheckIcon fontSize="small" />
            &nbsp;Procura acudir al baño antes de tu análisis.
          </Typography>
          <Typography variant="body2" className={classes.check}>
            <CheckIcon fontSize="small" />
            &nbsp;Usa ropa ligera y retira accesorios pesados.
          </Typography>
          <Typography variant="body2" className={classes.check}>
            <CheckIcon fontSize="small" />
            &nbsp;Evita realizar ejercicio intenso previamente.
          </Typography>
        </Box>
      </Box>
    </Drawer>
  );
};

export default IndicationsDrawer;
