import React, { useState, useEffect, useRef, Fragment } from 'react';

import { useDispatch } from 'react-redux';

import { useParams, useHistory, Link as RouterLink } from 'react-router-dom';

import { Helmet } from 'react-helmet';

import { isEmpty } from 'lodash';

import {
  makeStyles,
  Container,
  Typography,
  Box,
  CircularProgress,
  Link,
  Grid,
  Button,
  CardMedia,
} from '@material-ui/core';

import { ArrowBackIos } from '@material-ui/icons';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import LockIcon from '@material-ui/icons/Lock';

import { Carousel, TrainingCard } from 'components/molecules';

import { CategoriesList } from 'components/organisms';

import { setModalBehavior } from 'redux/modalBehaviors/actions';

import wellnubAPI from 'utils/network/wellnub.api';

import { MODAL_NAMES } from 'utils/constants';

import { Dropdown } from 'components/organisms/layout/Navbar/subcomponents';

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  action: {
    color: theme.palette.action.main,
  },
  programImage: {
    width: '100%',
    height: 300,
    borderRadius: theme.shape.borderRadius,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundImage: ({ programImage }) =>
      programImage ? `url(${programImage})` : 'none',
  },
  coachImage: {
    display: 'block',
    borderRadius: '50%',
  },
  icon: {
    display: 'block',
    width: '100%',
    height: '100%',
  },
  benefitsWrapper: {
    position: 'absolute',
    display: 'flex',
    width: '100%',
    left: 0,
    bottom: 0,
    zIndex: 1,
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'space-around',
    backgroundColor: 'rgba(255, 255, 255, .2)',
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
  },
  benefitIconWrapper: {
    width: 50,
    height: 50,
    [theme.breakpoints.down('xs')]: {
      width: 40,
      height: 40,
    },
  },
}));

const Vwod = () => {
  const [vwod, setVWOD] = useState({});
  const [coach, setCoach] = useState({});
  const [activeWeek, setActiveWeek] = useState(0);
  const [isWeeklyDropdownOpen, setIsWeeklyDropdownOpen] = useState(false);

  const dispatch = useDispatch();

  const { vwodID } = useParams();

  const { push } = useHistory();

  const weeklyTrainingsRef = useRef();

  const classes = useStyles({
    programImage: vwod.pictureUrl,
  });

  const activeWeekHandler = weekNumber => {
    setActiveWeek(weekNumber);
  };

  const fetchVWOD = async id => {
    const { data } = await wellnubAPI.programs.get(id);
    setVWOD(data);
    setCoach(data.user);
  };

  useEffect(() => {
    fetchVWOD(vwodID).catch(error => console.log(error));
  }, []);

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Helmet>
        <meta name="description" content="Entrenamientos on demand" />
        <title>Wellnub | VWOD</title>
      </Helmet>
      {isEmpty(coach) ? (
        <Box display="flex" alignItems="center" justifyContent="center">
          <CircularProgress color="secondary" />
        </Box>
      ) : (
        <Fragment>
          {/* ---- BACKSTEP LINK ---- */}
          <Box>
            <Link to="/vwods" underline="none" component={RouterLink}>
              <ArrowBackIos fontSize="small" color="secondary" />
              <Typography
                variant="body2"
                component="span"
                color="secondary"
                className={classes.uppercase}
              >
                Volver
              </Typography>
            </Link>
          </Box>

          {/* ---- PROGRAM NAME ---- */}
          <Box pt={4}>
            <Typography variant="h1" color="secondary">
              {vwod.programName}
            </Typography>
          </Box>

          {/* ---- PROGRAM COACH NAME ---- */}
          <Box pt={4}>
            <Typography variant="h3" color="secondary">
              Coach:{' '}
              <Link
                to={`/coaches/${coach._id}`}
                underline="none"
                component={RouterLink}
              >
                <Typography
                  variant="h3"
                  component="span"
                  className={classes.action}
                >
                  {coach.name} {coach.lastName}
                </Typography>
              </Link>
            </Typography>
          </Box>

          {/* ---- PROGRAM IMAGE AND DESCRIPTION ---- */}
          <Box pt={4}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <Box position="relative" className={classes.programImage}>
                  <Box className={classes.benefitsWrapper}>
                    {vwod.benefitsList.map(benefit => (
                      <Box
                        key={benefit.id}
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                      >
                        <Box pb={1}>
                          <Typography variant="caption" color="secondary">
                            {benefit.benefitName}
                          </Typography>
                        </Box>
                        <Box className={classes.benefitIconWrapper}>
                          <CardMedia
                            component="img"
                            image={benefit.imageURL}
                            title={benefit.benefitName}
                          />
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  p={2}
                  border={1}
                  borderColor="gray.main"
                  borderRadius={8}
                  height="100%"
                  textAlign="center"
                >
                  <Typography variant="h6" color="secondary" align="center">
                    {vwod.durationTrainings} entrenamientos disponibles
                  </Typography>
                  <Box pt={4}>
                    <Typography>Elige los días que quieres entrenar</Typography>
                  </Box>
                  <Box pt={4}>
                    <Typography>
                      Desde ${vwod.lowestPrice} / entrenamiento
                    </Typography>
                  </Box>
                  <Box pt={4}>
                    <Button
                      variant="contained"
                      onClick={() =>
                        dispatch(
                          setModalBehavior({
                            name: MODAL_NAMES.GET_IN_TOUCH,
                            isVisible: true,
                          }),
                        )
                      }
                    >
                      Estoy interesado
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>

          {/* ---- PROGRAM CATEGORIES INFO CARDS ---- */}
          <Box pt={4}>
            <CategoriesList
              level={vwod.fitnessLevel}
              duration={vwod.durationTrainings}
              disciplines={vwod.disciplinesList}
              materials={vwod.materialList}
            />
          </Box>

          {/* ---- WEEKLY TRAININGS CAROUSEL ---- */}
          <Box
            pt={4}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h6" color="secondary">
              Entrenamientos
            </Typography>
            <Typography
              variant="h6"
              color="secondary"
              ref={weeklyTrainingsRef}
              onClick={() => setIsWeeklyDropdownOpen(prevState => !prevState)}
            >
              Semana {activeWeek + 1}{' '}
              {vwod.weekVWODS.length > 1 && (
                <ArrowDropDownIcon fontSize="small" />
              )}
            </Typography>
            {vwod.weekVWODS.length > 1 && (
              <Dropdown
                open={isWeeklyDropdownOpen}
                anchor={weeklyTrainingsRef.current}
                closeHandler={() => setIsWeeklyDropdownOpen(false)}
              >
                {vwod.weekVWODS.map((_, index) => (
                  <Typography
                    key={`week-${index}`}
                    onClick={() => activeWeekHandler(index)}
                  >
                    Semana {index + 1}
                  </Typography>
                ))}
              </Dropdown>
            )}
          </Box>
          <Box pt={4} pr={4} pl={4}>
            <Carousel>
              {vwod.weekVWODS[activeWeek].days
                .filter(training => training.vWoD !== null)
                .map(item => (
                  <TrainingCard key={item._id} training={item} />
                ))}
            </Carousel>
          </Box>

          {/* ---- PROGRAM DESCRIPTION ---- */}
          <Box pt={4}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <Box height="100%" display="flex" flexDirection="column">
                  <Box pb={2}>
                    <Typography variant="h6" color="secondary">
                      Descripción del programa
                    </Typography>
                  </Box>
                  <Box
                    p={2}
                    border={1}
                    borderColor="gray.main"
                    borderRadius={8}
                    flexGrow={1}
                  >
                    <Typography>{vwod.description}</Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box>
                  <Box pb={2}>
                    <Typography variant="h6" color="secondary">
                      Sobre el coach{' '}
                      <Typography
                        variant="h5"
                        component="span"
                        className={classes.action}
                      >
                        {coach.name}
                      </Typography>
                    </Typography>
                  </Box>
                  <Box
                    p={2}
                    border={1}
                    borderColor="gray.main"
                    borderRadius={8}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <CardMedia
                          component="img"
                          image={coach.pictureUrl}
                          title={`${coach.name} ${coach.lastName}`}
                          className={classes.coachImage}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Box
                          height="100%"
                          display="flex"
                          flexDirection="column"
                          justifyContent="space-between"
                          textAlign="center"
                        >
                          <Typography align="center">{coach.about}</Typography>
                          <Box pt={2}>
                            <Button
                              variant="contained"
                              onClick={() => push(`/coaches/${coach._id}`)}
                            >
                              Ver todos mis programas
                            </Button>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Fragment>
      )}
    </Container>
  );
};

export default Vwod;
