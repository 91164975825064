import React from 'react';
import { ABREV_TRANSLATIONS } from '../../utils/strings/translations';
import { Typography } from '@material-ui/core';
const WorkoutVideoSideBar = props => {
  // console.log(props);
  const getSerieInCircuit = (exIndex, step) => {
    // console.log(props.activeRange);
    // When we are in the circuit rest
    if (props.activeRange.currentSerie === null) {
      return step.series;
    }
    // When the exercise alredy finished it's series
    if (exIndex < props.activeRange.subIndex) {
      return step.series;
    }
    // When the exercise is after the current one
    if (exIndex > props.activeRange.subIndex) {
      return 1;
    }
    // When the active range is the current exercise
    return props.activeRange.currentSerie + 1;
  };

  const getStepData = (step, index) => {
    switch (step.type) {
      case 'exercise':
        const currentSerie =
          index == 0 ? props.activeRange.currentSerie + 1 : 1;
        return (
          <div>
            <p>SERIES: {`${currentSerie} / ${step.series}`}</p>
            <p>
              {`[Ejercicio ${step.exerciseName}] ${step.duration} ${
                ABREV_TRANSLATIONS[step.measurement]
              } / `}
              {step.restTime !== 0 && step.restTime !== null
                ? `${step.restTime} seg descanso`
                : ''}
            </p>
          </div>
        );

      case 'rest':
        return (
          <div>
            <p>{`${step.restName} ${step.duration} ${step.measurement}`}</p>
          </div>
        );

      // TODO: DRY this excercise function
      case 'circuit':
        const cicuitLap = index == 0 ? props.activeRange.currentCircuit + 1 : 1;
        return (
          <div>
            <p>{`Circuito ${cicuitLap}/${step.laps}`}</p>
            <p>
              {step.exercises.map((cirStep, exIndex) => {
                if (index !== 0) {
                  return getStepData(cirStep, -1);
                }
                return (
                  <div>
                    <p>
                      SERIES:{' '}
                      {`${getSerieInCircuit(exIndex, cirStep)} / ${
                        cirStep.series
                      }`}
                    </p>
                    <p>
                      {`[CircuitoEjercicio ${cirStep.exerciseName}] ${
                        cirStep.duration
                      } ${ABREV_TRANSLATIONS[cirStep.measurement]} / `}
                      {cirStep.restTime !== 0
                        ? `${cirStep.restTime} seg descanso entre series`
                        : ''}
                    </p>
                  </div>
                );
              })}
              <p>{`${step.restTime} seg de descanso entre vueltas`}</p>
            </p>{' '}
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div>
      <Typography variant="h5" style={{ marginLeft: 20 }}>
        {props.topLabel}
      </Typography>
      <hr />
      {props.steps.slice(props.activeRange.index).map((step, index) => (
        <>
          <div style={{ marginLeft: 20 }}>{getStepData(step, index)}</div>
          <hr />
        </>
      ))}
    </div>
  );
};

export default WorkoutVideoSideBar;
