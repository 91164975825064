import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  accordion: {
    boxShadow: 'none',
    backgroundColor: 'transparent',
    '&::before': {
      display: 'none',
    },
  },
  summary: {
    padding: 0,
  },
  summaryLabel: {
    fontSize: theme.typography.body2.fontSize,
  },
  linkLabel: {
    color: theme.palette.secondary.dark,
  },
}));
