import React from 'react';

import PropTypes from 'prop-types';

import { Link as RouterLink } from 'react-router-dom';

import { Link as StyledLink } from '@material-ui/core';

import { useStyles } from './Link.styles';

const Link = ({ children, ...restProps }) => {
  const classes = useStyles();

  return (
    <StyledLink
      className={classes.link}
      component={RouterLink}
      underline="none"
      {...restProps}
    >
      {children}
    </StyledLink>
  );
};

Link.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node]),
};

export default Link;
