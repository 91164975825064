import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(4),
    maxWidth: 500,
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.light,
  },
  title: {
    textAlign: 'center',
  },
  subtitle: {
    paddingTop: theme.spacing(2),
    textAlign: 'center',
  },
  confirmButton: {
    marginBottom: theme.spacing(2),
  },
  disabled: {
    backgroundColor: `${theme.palette.secondary.dark} !important`,
  },
  dashed: {
    padding: theme.spacing(2),
    textAlign: 'center',
    borderRadius: theme.shape.borderRadius,
    border: `1px dashed ${theme.palette.action.main}`,
  },
  action: {
    color: theme.palette.action.main,
  },
  indication: {
    paddingBottom: theme.spacing(1),
  },
  check: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: theme.spacing(1),
  },
  indicator: {
    backgroundColor: theme.palette.action.main,
    color: theme.palette.secondary.main,
    width: 30,
    height: 30,
  },
  mainTitle: {
    paddingLeft: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
    },
  },
  underlined: {
    textDecoration: 'underline',
  },
}));
