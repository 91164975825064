import { showAlert } from 'redux/alert/actions';

import wellnubAPI from 'utils/network/wellnub.api';

import { ALERT_TYPES } from 'utils/constants';

import {
  SET_COACH,
  SET_IS_FETCHING_COACH,
  SET_COACHES,
  SET_IS_FETCHING_COACHES,
} from './constants';

export const setCoach = coach => ({
  type: SET_COACH,
  payload: coach,
});

export const setIsFetchingCoach = status => ({
  type: SET_IS_FETCHING_COACH,
  payload: status,
});

export const setCoaches = coaches => ({
  type: SET_COACHES,
  payload: coaches,
});

export const setIsFetchingCoaches = status => ({
  type: SET_IS_FETCHING_COACHES,
  payload: status,
});

export const fetchCoach = id => dispatch => {
  dispatch(setIsFetchingCoach(true));
  wellnubAPI.coaches
    .get(id)
    .then(response => {
      const { data } = response;
      dispatch(setCoach(data));
    })
    .catch(error => {
      dispatch(
        showAlert({
          type: ALERT_TYPES.ERROR,
          title: 'ERROR',
          message: 'Algo salió mal, intenta más tarde',
        }),
      );
    })
    .finally(() => dispatch(setIsFetchingCoach(false)));
};

export const fetchCoaches = () => dispatch => {
  dispatch(setIsFetchingCoaches(true));
  wellnubAPI.coaches
    .list()
    .then(response => {
      const coaches = response.data.filter(coach => coach.role === 'coach');
      dispatch(setCoaches(coaches));
    })
    .catch(error => {
      dispatch(
        showAlert({
          type: ALERT_TYPES.ERROR,
          title: 'ERROR',
          message: 'Algo salió mal, intenta más tarde',
        }),
      );
    })
    .finally(() => dispatch(setIsFetchingCoaches(false)));
};
