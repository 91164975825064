import React, { useState } from 'react';

import { Controller, useForm } from 'react-hook-form';

import {
  Box,
  Grid,
  TextField,
  IconButton,
  ThemeProvider,
} from '@material-ui/core';

import { Visibility, VisibilityOff } from '@material-ui/icons';

import { Button } from 'components/atoms';

import { useUpdatePassword } from 'hooks/user.hook';
import { generateTheme } from './utils';

const UserPasswordForm = ({ variant = 'dark' }) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isPasswordMatchVisible, setIsPasswordMatchVisible] = useState(false);

  const { control, handleSubmit, watch } = useForm();

  const { mutate, isLoading } = useUpdatePassword();

  const password = watch('newPassword');

  const onSubmitHandler = data => {
    const { newPassword, currentPassword } = data;
    const payload = {
      password: newPassword,
      currentPassword,
    };
    mutate(payload);
  };

  return (
    <ThemeProvider theme={theme => generateTheme(theme, variant)}>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={12}>
            <Controller
              name="currentPassword"
              control={control}
              defaultValue=""
              rules={{
                required: 'Este campo es requerido',
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  fullWidth
                  type="password"
                  label="Contraseña actual"
                  color="secondary"
                  value={value}
                  error={!!error}
                  onChange={onChange}
                  helperText={
                    error ? error.message : 'Escribe tu contraseña actual'
                  }
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Controller
              name="newPassword"
              control={control}
              defaultValue=""
              rules={{
                required: 'Este campo es requerido',
                minLength: {
                  value: 6,
                  message:
                    'La nueva contraseña debe de tener al menos 6 caracteres',
                },
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  fullWidth
                  type={isPasswordVisible ? 'text' : 'password'}
                  label="Nueva contraseña"
                  color="secondary"
                  value={value}
                  error={!!error}
                  onChange={onChange}
                  helperText={
                    error ? error.message : 'Escribe tu nueva contraseña'
                  }
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        onClick={() =>
                          setIsPasswordVisible(prevState => !prevState)
                        }
                      >
                        {isPasswordVisible ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    ),
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Controller
              name="passwordConfirm"
              defaultValue=""
              control={control}
              rules={{
                required: 'Este campo es requerido',
                validate: value =>
                  value === password || 'Las contraseñas no coinciden',
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  fullWidth
                  type={isPasswordMatchVisible ? 'text' : 'password'}
                  label="Confirmar nueva contraseña"
                  color="secondary"
                  error={!!error}
                  value={value}
                  onChange={onChange}
                  helperText={
                    error ? error.message : 'Confirma tu nueva contraseña'
                  }
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        onClick={() =>
                          setIsPasswordMatchVisible(prevState => !prevState)
                        }
                      >
                        {isPasswordMatchVisible ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    ),
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
        <Box pt={4} textAlign="right">
          <Button type="submit" variant="contained" isLoading={isLoading}>
            Actualizar contraseña
          </Button>
        </Box>
      </form>
    </ThemeProvider>
  );
};

export default UserPasswordForm;
