import { useEffect, useState } from 'react';

import { Box, Button, Container, Typography } from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';

import { useResendEmailToken } from 'hooks/authentication.hook';

import { useStyles } from './EmailTokenExpired.styles';

const EmailTokenExpiredPage = () => {
  const [email, setEmail] = useState('');
  const [id, setID] = useState('');
  const [timer, setTimer] = useState(60);
  const [isResendButtonDisabled, setIsResendButtonDisabled] = useState(false);

  const { mutate: resendEmail, isLoading, isSuccess } = useResendEmailToken();

  const classes = useStyles();

  const onResendEmailHandler = () => {
    resendEmail(id);
  };

  useEffect(() => {
    const email = localStorage.getItem('userEmail');
    const id = localStorage.getItem('userID');
    setID(id);
    setEmail(email);
  }, []);

  useEffect(() => {
    if (isSuccess) {
      setIsResendButtonDisabled(true);
      const interval = setInterval(() => {
        setTimer(prevState => prevState - 1);
      }, 1000);
      setTimeout(() => {
        setIsResendButtonDisabled(false);
        clearInterval(interval);
      }, 60000);
    }
  }, [isSuccess]);

  return (
    <Box className={classes.backdrop}>
      <Container className={classes.container}>
        <Box className={classes.wrapper}>
          <Typography variant="h5" component="h1" className={classes.title}>
            Reenvío de correo
          </Typography>
          <Typography
            variant="h5"
            component="h2"
            className={`${classes.title} ${classes.mt}`}
          >
            A {email}
          </Typography>
          <Box component="section" my={2}>
            <Typography>
              Por seguridad el enlace anterior ya no tiene vigencia, por favor
              envía nuevamente un correo con el enlace
            </Typography>
            <Box mt={2}>
              <EmailIcon fontSize="large" />
            </Box>
            <Box mt={2} textAlign="center">
              <Button
                variant="outlined"
                disabled={isResendButtonDisabled}
                color="primary"
                size="small"
                onClick={onResendEmailHandler}
                className={classes.button}
              >
                Reenviar correo
              </Button>
              {isResendButtonDisabled && (
                <Typography variant="body2">
                  Puedes volver a reenviar el correo en {timer} segundos
                </Typography>
              )}
            </Box>
          </Box>
          <Typography variant="caption">
            * Asegúrate de revisar en tu bandeja de correo no deseado
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default EmailTokenExpiredPage;
