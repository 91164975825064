import axios from './auth.axios';
import { BASE_URL } from '../constants';

class ExerciseService {
  create = payload => axios.post(`${BASE_URL}/v1/exercise`, payload);

  get = id => axios.get(`${BASE_URL}/v1/exercise/${id}`);

  list = () => axios.get(`${BASE_URL}/v1/exercise`);

  update = (id, payload) =>
    axios.patch(`${BASE_URL}/v1/exercise${id}`, payload);

  delete = id => axios.post(`${BASE_URL}/v1/exercise/${id}`);

  getVWoD = id => axios.get(`${BASE_URL}/v1/videoWorkOut/${id}`);
}

export default new ExerciseService();
