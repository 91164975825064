import React from 'react';

import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';

import { Route, Redirect } from 'react-router-dom';

const ProtectedRoute = ({ children, ...restProps }) => {
  const { isAuthenticated } = useSelector(state => state.userAuthentication);

  return (
    <Route
      {...restProps}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: {
                from: location,
              },
            }}
          />
        )
      }
    />
  );
};

ProtectedRoute.propTypes = {
  children: PropTypes.any,
};

export default ProtectedRoute;
