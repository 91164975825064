import { useLocation, Link, useRouteMatch } from 'react-router-dom';

import { Tab, Tabs, useMediaQuery } from '@material-ui/core';

import { useStyles } from './DashboardNavigation.styles';
import { batch, useDispatch, useSelector } from 'react-redux';
import {
  setAppointmentMode,
  setScheduleVisibility,
} from '../../../redux/slices/spot.slice';

const DashboardNavigation = () => {
  const { appointmentMode } = useSelector(state => state.spot);
  const { pathname } = useLocation();
  const { url } = useRouteMatch();

  const responsive = useMediaQuery(theme => theme.breakpoints.down('xs'));

  const dispatch = useDispatch();

  const classes = useStyles();

  const tabValueHandler = () => {
    if (pathname.includes('main')) return 'main';
    if (pathname.includes('progress')) return 'progress';
    if (pathname.includes('appointment')) return 'appointment';
    return 'main';
  };

  const onTabClickHandler = () => {
    if (appointmentMode === 'RESCHEDULE') {
      batch(() => {
        dispatch(setAppointmentMode('NORMAL'));
        dispatch(setScheduleVisibility(false));
      });
    }
  };

  return (
    <Tabs
      variant="scrollable"
      className={classes.tabs}
      value={tabValueHandler()}
      scrollButtons={responsive ? 'on' : 'off'}
      orientation={responsive ? 'horizontal' : 'vertical'}
      indicatorColor="primary"
    >
      <Tab
        value="main"
        component={Link}
        label="Principal"
        to={`${url}/main`}
        onClick={onTabClickHandler}
        classes={{
          wrapper: classes.tab,
        }}
      />
      <Tab
        value="progress"
        component={Link}
        label="Mi progreso"
        to={`${url}/progress`}
        onClick={onTabClickHandler}
        classes={{
          wrapper: classes.tab,
        }}
      />
      <Tab
        value="appointment"
        component={Link}
        label="Agenda un análisis InBody®"
        to={`${url}/appointment`}
        onClick={onTabClickHandler}
        classes={{
          wrapper: classes.tab,
        }}
      />
    </Tabs>
  );
};

export default DashboardNavigation;
