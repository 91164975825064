import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  makeStyles,
  ThemeProvider,
  Typography,
} from '@material-ui/core';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setModalStatus } from 'redux/slices/modal.slice';
import { useUnlinkNutritionist } from 'hooks/user.hook';
import { generateTheme } from 'utils/helpers';

const useStyles = makeStyles(theme => ({
  button: {
    '&:disabled': {
      backgroundColor: '#c2c2c2',
    },
  },
}));

const UnlinkNutritionistPanel = () => {
  const [isAccepted, setIsAccepted] = useState(false);

  const nutritionistName = useSelector(state => state.nutritionist.name);

  const { mutate: unlinkNutritionist, isLoading } = useUnlinkNutritionist();

  const dispatch = useDispatch();

  const classes = useStyles();
  const onToggleCheckboxHandler = event => {
    const { checked } = event.target;
    setIsAccepted(checked);
  };

  const onAcceptHandler = () => {
    unlinkNutritionist();
  };
  const onCancelHandler = () => {
    dispatch(
      setModalStatus({
        name: 'UNLINK_NUTRITIONIST_WARNING',
        isActive: false,
      }),
    );
  };

  return (
    <Box textAlign="center">
      <Typography variant="h6" style={{ paddingBottom: 16 }}>
        ¿Estás seguro que deseas desvincularte?
      </Typography>
      <Typography variant="body2" style={{ paddingBottom: 16 }}>
        Con esta acción ya no estarás vinculado a: {nutritionistName}
      </Typography>
      <Typography variant="body2" style={{ paddingBottom: 16 }}>
        En caso de que tengas pases enviados por tu nutriólogo(a){' '}
        {nutritionistName} o consultas pendientes, quedarán automáticamente
        canceladas
      </Typography>
      <Typography variant="body2" style={{ paddingBottom: 16 }}>
        Al desvincularte, tu nutriólogo (a) ya no podrá acceder a tu perfil ni a
        tu información personal.
      </Typography>
      <Typography variant="body2" style={{ paddingBottom: 16 }}>
        Podrás seguir realizándote pruebas Wellnub-InBody, adquirir pases y
        consultar tu perfil y progreso como usuario independiente.
      </Typography>
      <ThemeProvider theme={theme => generateTheme(theme, 'light')}>
        <FormControlLabel
          control={
            <Checkbox
              checked={isAccepted}
              onChange={onToggleCheckboxHandler}
              color="secondary"
            />
          }
          label="Entiendo y acepto los términos y condiciones"
        />
      </ThemeProvider>
      <Box
        pt={2}
        display="flex"
        alignItems="center"
        justifyContent="space-evenly"
      >
        <Button variant="outlined" color="primary" onClick={onCancelHandler}>
          Cancelar
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onAcceptHandler}
          disabled={!isAccepted || isLoading}
          className={classes.button}
        >
          Aceptar
        </Button>
      </Box>
    </Box>
  );
};

export default UnlinkNutritionistPanel;
