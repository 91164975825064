import axios from './auth.axios';
import { BASE_URL } from '../constants';

class ProgramService {
  create = payload => axios.post(`${BASE_URL}/v1/coach`, payload);

  get = id => axios.get(`${BASE_URL}/v1/coach/${id}`);

  list = () => axios.get(`${BASE_URL}/v1/coach`);

  update = (id, payload) => axios.patch(`${BASE_URL}/v1/coach${id}`, payload);

  delete = id => axios.post(`${BASE_URL}/v1/coach/${id}`);
}

export default new ProgramService();
