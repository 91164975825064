import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  biochemicalPlan: {
    active: {},
  },
};

const wnModalContentSlice = createSlice({
  name: 'wnModalContent',
  initialState,
  reducers: {
    setWnModalContentActiveBiochemicalPlan: (state, action) => {
      state.biochemicalPlan.active = action.payload;
    },
  },
});

export const { setWnModalContentActiveBiochemicalPlan } =
  wnModalContentSlice.actions;
export const WnModalContentReducer = wnModalContentSlice.reducer;
