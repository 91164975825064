import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Box, Button, Typography } from '@material-ui/core';

import { setModalStatus } from 'redux/slices/modal.slice';
import { setIsFirstSignIn } from 'redux/slices/authentication.slice';

const FirstSignInForm = () => {
  const { replace } = useHistory();

  const dispatch = useDispatch();

  const onClickHandler = () => {
    dispatch(
      setModalStatus({
        name: 'FIRST_SIGN_IN',
        isActive: false,
      }),
    );
    setTimeout(() => {
      replace('/settings/profile');
    }, 100);
  };

  useEffect(() => {
    return () => {
      dispatch(setIsFirstSignIn(false));
    };
  }, [dispatch]);

  return (
    <Box>
      <Typography>
        Para crear fácilmente tu perfil en Wellnub, hemos utilizado tu celular a
        10 dígitos como contraseña para acceder por primera vez a Wellnub.
      </Typography>
      <Typography style={{ paddingTop: 16 }}>
        Sin embargo, mantener tu celular como tu contraseña en el futuro podría
        ser poco seguro, por lo que te recomendamos cambiarla a la brevedad.
      </Typography>
      <Box mt={4}>
        <Button variant="contained" color="primary" onClick={onClickHandler}>
          Cambiar contraseña ahora
        </Button>
      </Box>
    </Box>
  );
};

export default FirstSignInForm;
