import React from 'react';
import { useController } from 'react-hook-form';
import { TextField } from '@material-ui/core';
import PhoneInput from 'react-phone-input-material-ui';

const PhoneNumberInput = ({ name, control, ...restProps }) => {
  const {
    field: { value, onChange, ...restField },
    fieldState: { error },
  } = useController({ name, control });
  return (
    // <TextField
    //   value={value}
    //   onChange={onChange}
    //   InputProps={{ inputComponent: PhoneInput }}
    //   inputRef={ref}
    //   {...restField}
    // />
    <PhoneInput
      value={value}
      onChange={onChange}
      {...restField}
      {...restProps}
      component={TextField}
    />
  );
};

export default PhoneNumberInput;
