import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  tooltip: {
    isOpen: false,
  },
};

const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    setTooltipVisibility: (state, action) => {
      state.tooltip.isOpen = action.payload;
    },
  },
});

export const { setTooltipVisibility } = layoutSlice.actions;
export const LayoutReducer = layoutSlice.reducer;
