import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  backdrop: {
    minHeight: '90vh',
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.main,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  mb2: {
    marginBottom: theme.spacing(2),
  },
  bold: {
    fontWeight: 700,
    fontFamily: theme.typography.h1.fontFamily,
  },
  displayCenter: {
    display: 'flex',
    justifyContent: 'center',
  },
  border: {
    border: `1px solid ${theme.palette.action.light}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
  },
  button: {
    '&:disabled': {
      backgroundColor: '#c2c2c2',
    },
  },
}));
