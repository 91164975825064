import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  ThemeProvider,
  Typography,
} from '@material-ui/core';

import ServicesList from '../../../Lists/Services/ServicesList.component';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { generateTheme } from '../../../../utils/helpers';

const OnsiteTabPanel = ({ value, index, site, address, phone }) => {
  const { services, offices } = useSelector(state => state.nutritionist);

  return (
    <Box role="tabpanel" hidden={value !== index}>
      <ServicesList services={site} />
      <ThemeProvider theme={theme => generateTheme(theme, 'light')}>
        {offices
          .filter(item => item.status === 'active' && item.mode === 'onsite')
          .map(office => (
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="body2">{office.name}</Typography>
              </AccordionSummary>
              <AccordionDetails style={{ display: 'block' }}>
                <Typography variant="body2">
                  Calle: {office.address.street}
                </Typography>
                <Typography variant="body2">
                  Colonia: {office.address.neighborhood}
                </Typography>
                <Typography variant="body2">
                  Ciudad: {office.address.city}
                </Typography>
                <Typography variant="body2">
                  Estado: {office.address.state}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
      </ThemeProvider>

      {address.neighborhood && (
        <Typography variant="body2">Colonia: {address.neighborhood}</Typography>
      )}
      {address.city && (
        <Typography variant="body2">Ciudad: {address.city}</Typography>
      )}
      {address.state && (
        <Typography variant="body2">Estado: {address.state}</Typography>
      )}
      {phone && <Typography variant="body2">Teléfono: {phone}</Typography>}
    </Box>
  );
};

export default OnsiteTabPanel;
