import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: {},
  mode: 'RESCHEDULE',
};

const nutritionistAppointmentSlice = createSlice({
  name: 'nutritionistAppointment',
  initialState,
  reducers: {
    setNutritionistAppointment: (state, action) => {
      state.data = action.payload;
    },
    setNutritionistAppointmentMode: (state, action) => {
      state.mode = action.payload;
    },
  },
});

export const { setNutritionistAppointment, setNutritionistAppointmentMode } =
  nutritionistAppointmentSlice.actions;
export const NutritionistAppointmentReducer =
  nutritionistAppointmentSlice.reducer;
