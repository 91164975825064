import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  card: {
    padding: theme.spacing(2, 3),
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.primary.light,
  },
  name: {
    color: theme.palette.action.light,
    textTransform: 'uppercase',
    cursor: 'pointer',
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  avatar: {
    width: 100,
    height: 100,
    cursor: 'pointer',
  },
  mr: {
    marginRight: theme.spacing(2),
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
  start: {
    alignSelf: 'flex-start',
  },
  service: {
    width: 20,
    height: 20,
    '&:not(:last-child)': {
      marginRight: theme.spacing(2),
    },
  },
  specs: {
    margin: theme.spacing(1, 0, 0, 2),
    listStyle: 'circle',
    minHeight: 128,
  },
  ellipsis: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));
