import { Box, Button, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import { isEmpty } from 'lodash';

import { useDrawer } from 'hooks/drawer.hook';

import { DRAWERS } from 'utils/constants';

import { CreditCardTable } from 'components';

import { useStyles } from './Payments.styles';

const PaymentsPage = ({ cards }) => {
  const { handleOpen } = useDrawer(DRAWERS.addCard);

  const classes = useStyles();

  const onAddCardClickHandler = event => {
    event.preventDefault();
    handleOpen();
  };

  return (
    <Box component="main">
      <Typography variant="h4" className={classes.title}>
        Formas de pago
      </Typography>
      <Box className={classes.contentWrapper}>
        {isEmpty(cards) && (
          <Typography variant="body2" style={{ marginTop: 16 }}>
            Aún no tienes formas de pago agregadas
          </Typography>
        )}
        {!isEmpty(cards) && <CreditCardTable cards={cards} />}
        <Box pt={4}>
          <Button
            variant="outlined"
            color="primary"
            endIcon={<AddIcon />}
            onClick={onAddCardClickHandler}
          >
            Agregar forma de pago
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default PaymentsPage;
