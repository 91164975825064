import WellnubService from './WellnubService';

import { BASE_URL } from '../constants';

class ProgramsService extends WellnubService {
  get = id => this.axios.get(`${BASE_URL}/v1/client/program/${id}`)

  list = () => this.axios.get(`${BASE_URL}/v1/client/program`);
}

export default ProgramsService;