export const TRANSLATIONS = {
  seconds: 'Segundos',
  minutes: 'Minutos',
  rest: 'Descanso',
  activeRest: 'Descanso activo',
  preparation: 'Preparación',
  warmUp: 'Calentamiento',
  coolDown: 'Enfriamiento',
  repetitions: 'Repeticiones',
  amrap: 'AMRAP',
  bySerie: 'For time - serie',
  allSeries: 'For time - total',
  forLap: 'For time - vuelta',
  allLaps: 'For time - total',

  monday: 'lunes',
  tuesday: 'martes',
  wednesday: 'miércoles',
  thursday: 'jueves',
  friday: 'viernes',
  saturday: 'sábado',
  sunday: 'domingo',
};

export const ABREV_TRANSLATIONS = {
  monday: 'Lu',
  tuesday: 'Ma',
  wednesday: 'Mi',
  thursday: 'Ju',
  friday: 'Vi',
  saturday: 'Sa',
  sunday: 'Do',
  seconds: 'seg',
  minutes: 'min',
};

export const INITIALS_TRANSLATIONS = {
  seconds: 's',
  minutes: 'm',
};