import { useForm, Controller } from 'react-hook-form';

import { useSnackbar } from 'notistack';
import NumberFormat from 'react-number-format';

import {
  Box,
  Button,
  CardMedia,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Select,
  TextField,
  ThemeProvider,
  Typography,
} from '@material-ui/core';

import { useCreateCard } from 'hooks/payments.hook';

import { generateTheme } from 'utils/helpers';
import { MONTHS, YEARS } from 'utils/constants';

import { useStyles } from './CreditCardForm.styles';
import conekta from 'assets/images/conekta.png';

const CreditCardForm = ({ type }) => {
  const { control, handleSubmit } = useForm();
  const { enqueueSnackbar } = useSnackbar();

  const { mutate: createCard, isLoading } = useCreateCard();

  const classes = useStyles();

  const onErrorHandler = error => {
    enqueueSnackbar(error.message_to_purchaser, {
      variant: 'error',
    });
  };

  const onSubmitHandler = data => {
    const payload = {
      card: {
        ...data,
      },
    };
    window.Conekta.Token.create(
      payload,
      token => {
        createCard({
          tokenId: token.id,
          cardName: data.name,
        });
      },
      onErrorHandler,
    );
  };

  return (
    <ThemeProvider theme={theme => generateTheme(theme, type)}>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Controller
              name="name"
              defaultValue=""
              control={control}
              rules={{
                required: 'Este campo es obligatorio',
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  fullWidth
                  type="text"
                  color="secondary"
                  variant="outlined"
                  label="Nombre"
                  error={!!error}
                  value={value}
                  onChange={onChange}
                  helperText={
                    error ? error.message : 'Nombre que aparece en la tarjeta'
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="number"
              defaultValue=""
              control={control}
              rules={{
                required: 'Este campo es obligatorio',
                validate: value =>
                  window.Conekta.card.validateNumber(value) ||
                  'Ingresa un número de tarjeta válido',
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <NumberFormat
                  fullWidth
                  variant="outlined"
                  color="secondary"
                  label="Número de tarjeta"
                  format="#### #### #### ####"
                  value={value}
                  error={!!error}
                  customInput={TextField}
                  onValueChange={e => onChange(e.value)}
                  helperText={
                    error ? error.message : 'Los 16 dígitos de la tarjeta'
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              name="exp_year"
              defaultValue={YEARS[0].value}
              control={control}
              rules={{
                required: 'Este campo es obligatorio',
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl
                  fullWidth
                  variant="outlined"
                  error={!!error}
                  color="secondary"
                >
                  <InputLabel htmlFor="year">Año</InputLabel>
                  <Select
                    native
                    label="Año"
                    value={value}
                    onChange={onChange}
                    inputProps={{
                      name: 'year',
                      id: 'year',
                    }}
                  >
                    {YEARS.map(year => (
                      <option key={year.name} value={year.value}>
                        {year.name}
                      </option>
                    ))}
                  </Select>
                  <FormHelperText classes={{ contained: classes.helper }}>
                    {error ? error.message : 'Año de vencimiento'}
                  </FormHelperText>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              name="exp_month"
              defaultValue={MONTHS[0].value}
              control={control}
              rules={{
                required: 'Este campo es obligatorio',
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl
                  fullWidth
                  variant="outlined"
                  error={!!error}
                  color="secondary"
                >
                  <InputLabel htmlFor="month">Mes</InputLabel>
                  <Select
                    native
                    label="Mes"
                    value={value}
                    onChange={onChange}
                    inputProps={{
                      name: 'month',
                      id: 'month',
                    }}
                  >
                    {MONTHS.map(month => (
                      <option key={month.name} value={month.value}>
                        {month.name}
                      </option>
                    ))}
                  </Select>
                  <FormHelperText classes={{ contained: classes.helper }}>
                    {error ? error.message : 'Mes de vencimiento'}
                  </FormHelperText>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              name="cvc"
              defaultValue=""
              control={control}
              rules={{
                required: 'Este campo es obligatorio',
                validate: value =>
                  window.Conekta.card.validateCVC(value) ||
                  'Escribe un CVV válido',
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <NumberFormat
                  allowLeadingZeros
                  fullWidth
                  customInput={TextField}
                  value={value}
                  variant="outlined"
                  color="secondary"
                  onValueChange={e => onChange(e.value)}
                  label="CVV"
                  error={!!error}
                  helperText={error ? error.message : ' '}
                />
              )}
            />
          </Grid>
        </Grid>
        <Box pt={2}>
          <Button
            fullWidth
            variant="contained"
            type="submit"
            disabled={isLoading}
          >
            Agregar Tarjeta
          </Button>
        </Box>
        <Box pt={2}>
          <Typography variant="body2">
            Datos protegidos encriptados y pagos seguros con Conekta&reg;
          </Typography>
          <CardMedia
            component="img"
            image={conekta}
            className={classes.conekta}
          />
        </Box>
      </form>
    </ThemeProvider>
  );
};

export default CreditCardForm;
