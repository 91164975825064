import React from 'react';
import { useController } from 'react-hook-form';

import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import deLocale from 'date-fns/locale/es';

const WellnubTimeInput = ({
  name,
  control,
  label,
  rules,
  helperText,
  ...restProps
}) => {
  const {
    field: { ref, ...inputProps },
    fieldState: { error },
  } = useController({ name, control, rules });

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardTimePicker
        {...restProps}
        {...inputProps}
        helperText={error?.message ?? helperText}
        label={label}
        error={!!error}
      />
    </MuiPickersUtilsProvider>
  );
};

export default WellnubTimeInput;
