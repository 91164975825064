import React from 'react';

import PropTypes from 'prop-types';

import { makeStyles, Box, Grid, Typography } from '@material-ui/core';

import { isEmpty } from 'lodash';

import LEVEL_ICON from 'assets/icons/materials/nivel.svg';
import TOOL_ICON from 'assets/icons/materials/material.svg';
import DURATION_ICON from 'assets/icons/materials/duracion.svg';
import DISCIPLINE_ICON from 'assets/icons/materials/disciplina.svg';

import { FITNESS_LEVELS, DISCIPLINES } from 'utils/constants';

const useStyles = makeStyles(() => ({
  icon: {
    display: 'block',
    width: '100%',
    height: '100%',
  },
}));

const CategoriesList = ({ level, duration, disciplines, materials }) => {
  const classes = useStyles();

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={3}>
        <Box
          p={2}
          height="100%"
          component="aside"
          borderRadius={8}
          bgcolor="gray.main"
        >
          <Box display="flex" alignItems="center" justifyContent="center">
            <Box width={25} height={25} mr={2}>
              <img
                src={LEVEL_ICON}
                alt="icono de materiales"
                className={classes.icon}
              />
            </Box>
            <Typography variant="h6" color="secondary">
              Nivel
            </Typography>
          </Box>
          <Box pt={1}>
            <Typography variant="body2" align="center">
              {FITNESS_LEVELS[level]}
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} md={3}>
        <Box
          p={2}
          height="100%"
          component="aside"
          borderRadius={8}
          bgcolor="gray.main"
        >
          <Box display="flex" alignItems="center" justifyContent="center">
            <Box width={25} height={25} mr={2}>
              <img
                src={DURATION_ICON}
                alt="icono de materiales"
                className={classes.icon}
              />
            </Box>
            <Typography variant="h6" color="secondary">
              Duración
            </Typography>
          </Box>
          <Box pt={1}>
            <Typography variant="body2" align="center">
              {duration} min
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} md={3}>
        <Box
          p={2}
          height="100%"
          component="aside"
          borderRadius={8}
          bgcolor="gray.main"
        >
          <Box display="flex" alignItems="center" justifyContent="center">
            <Box width={25} height={25} mr={2}>
              <img
                src={DISCIPLINE_ICON}
                alt="icono de materiales"
                className={classes.icon}
              />
            </Box>
            <Typography variant="h6" color="secondary">
              Disciplinas
            </Typography>
          </Box>
          <Box
            pt={1}
            display="flex"
            justifyContent="space-evenly"
            textAlign="center"
          >
            {disciplines
              .slice(0, 3)
              .map(item => DISCIPLINES[item])
              .join(' | ')
              .split(' ')
              .map((item, index) => (
                <Typography
                  key={`discipline-${item}-${index}`}
                  variant="body2"
                  component="span"
                >
                  {item}
                </Typography>
              ))}
          </Box>
          <Box
            pt={1}
            display="flex"
            justifyContent="space-evenly"
            textAlign="center"
          >
            {disciplines
              .slice(3, 6)
              .map(item => DISCIPLINES[item])
              .join(' | ')
              .split(' ')
              .map((item, index) => (
                <Typography
                  key={`discipline-${item}-${index}`}
                  variant="body2"
                  component="span"
                >
                  {item}
                </Typography>
              ))}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} md={3}>
        <Box
          p={2}
          height="100%"
          component="aside"
          borderRadius={8}
          bgcolor="gray.main"
        >
          <Box display="flex" alignItems="center" justifyContent="center">
            <Box width={25} height={25} mr={2}>
              <img
                src={TOOL_ICON}
                alt="icono de materiales"
                className={classes.icon}
              />
            </Box>
            <Typography variant="h6" color="secondary">
              Materiales
            </Typography>
          </Box>
          <Box pt={1}>
            <Typography variant="body2" align="center">
              {isEmpty(materials) ? (
                <Typography variant="body2" component="span">
                  Sin materiales
                </Typography>
              ) : (
                materials
                  .map(e => e.material)
                  .join(' | ')
                  .split(' ')
                  .map((item, index) => (
                    <Typography
                      key={`item-${item}-${index}`}
                      variant="body2"
                      component="span"
                    >
                      {item}
                    </Typography>
                  ))
              )}
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

CategoriesList.propTypes = {
  level: PropTypes.string,
  duration: PropTypes.string,
  disciplines: PropTypes.arrayOf(PropTypes.string),
  materials: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      matRequired: PropTypes.bool,
      material: PropTypes.string,
    }),
  ),
};

export default CategoriesList;
