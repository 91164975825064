import React from 'react';
import { useController, useWatch } from 'react-hook-form';

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  group: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
}));

const WellnubCheckboxGroupInput = ({
  name,
  row,
  rules,
  options,
  grid = false,
  control,
  fullWidth,
  helperText,
  defaultValue,
}) => {
  const {
    field: { ref, value, onChange, ...inputProps },
    fieldState: { error },
  } = useController({
    name,
    rules,
    control,
    defaultValue,
  });

  const values = useWatch({ control, name });

  const classes = useStyles();

  const onChangeHandler = (value, checked) => {
    if (checked) return [...values, value];
    else return values.filter(item => item !== value);
  };

  return (
    <FormControl fullWidth={fullWidth} error={!!error} {...inputProps}>
      <FormGroup row={row} className={classes.group}>
        {grid ? (
          <Grid container spacing={1}>
            {options.map(option => (
              <Grid item xs={3} key={option.id}>
                <FormControlLabel
                  label={option.label}
                  control={
                    <Checkbox
                      disabled={option?.disabled ?? false}
                      color="primary"
                      checked={value.includes(option.value)}
                      onChange={(_, checked) =>
                        onChange(onChangeHandler(option.value, checked))
                      }
                    />
                  }
                />
              </Grid>
            ))}
          </Grid>
        ) : (
          options.map(option => (
            <FormControlLabel
              key={option.id}
              label={option.label}
              control={
                <Checkbox
                  disabled={option?.disabled ?? false}
                  color="primary"
                  checked={value.includes(option.value)}
                  onChange={(_, checked) =>
                    onChange(onChangeHandler(option.value, checked))
                  }
                />
              }
            />
          ))
        )}
      </FormGroup>
      <FormHelperText>{error?.message ?? helperText}</FormHelperText>
    </FormControl>
  );
};

export default WellnubCheckboxGroupInput;
